/* eslint-disable prefer-const */
import { useMemo, useState } from 'react';
import styled from 'styled-components';
import {
  FilterBoxContainer,
  FilterLabel,
  SelectWrapper,
  SubTitle,
  Title,
} from '../../common/components/StyledComponents';
import { DateSelectAndReset } from '../../common/components/filters/DateSelectAndReset';

import styles from './pruufStatus.module.css';
import { FlexRow } from '../../common/components/FlexRow';
import { SearchFilter } from '../../common/components/filters/SearchFilter';
import { Table } from '../../common/components/Table';
import { TableColumn } from 'react-data-table-component';
import { axiosInstance } from '../../common/providers & services/AxiosService';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import DefaultLayer from '../../common/components/panels/defaultLayer';
import DefaultPopupPanel from '../../common/components/panels/defaultPopupPanel';
import PruufDetail from './components/PruufDetail';
import UserDetailInfo from '../../common/components/users/UserDetailInfo';
import PruufChart from './components/PruufChart';
import LoadingIndicator from '../../common/components/LoadingIndicator';
import useUserInfo from '../../hooks/useUserInfo';

const initialState = {
  searchTerm: '',
  searchType: 'all',
  start: dayjs().subtract(7, 'day').format('YYYY-MM-DD'),
  end: dayjs().format('YYYY-MM-DD'),
};

export type GraphType = 'all' | 'conference' | 'company' | 'solution' | 'event';
export type LayerType = 'pruufDetail' | 'userDetail';

let dataAll: any[] = [];

const PruufStatus = () => {
  const [layerPopupOpen, setLayerPopupOpen] = useState(false);
  const [layerType, setLayerType] = useState<LayerType>('pruufDetail');
  const [layerValue, setLayerValue] = useState<string | number>('');
  const [filterParams, setFilterParams] = useState(initialState);
  const [graphState, setGraphState] = useState<GraphType>('all');

  const userInfo = useUserInfo();

  let columnSetup: TableColumn<any>[];

  columnSetup = [
    {
      name: '번호',
      selector: row => row.listIdx,
      sortable: true,
      grow: 0,
    },
    {
      name: '참가자',
      cell(row) {
        const handlePopup = (id: string | number) => {
          setLayerPopupOpen(true);
          setLayerType('userDetail');
          setLayerValue(id);
        };
        return (
          row.user.name
          // <span style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => handlePopup(row.user.id)}>
          //   {row.user.name}
          // </span>
        );
      },
      grow: 1,
    },

    {
      name: '행사명',
      selector: row => row.conference.name_kor,
      grow: 2,
    },
    {
      name: '구분',
      selector: row => row.typeToText,
      grow: 1,
    },
    {
      name: '상세내용',
      cell(row) {
        if (row.type === 'company') return row.company_setup?.name;
        else if (row.type === 'solution') return row.solution?.name;
        else if (row.type === 'event') return row.event?.name;
        return row.typeToText;
      },
      grow: 2,
    },
    {
      name: '발급일시',
      selector: row => dayjs(row.created_at).format('YYYY.MM.DD HH:mm'),
      grow: 1,
    },
    {
      name: 'PRUUF 보기',
      cell(row) {
        const handlePopup = (id: string) => {
          setLayerPopupOpen(true);
          setLayerType('pruufDetail');
          setLayerValue(id);
        };
        return (
          <span style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => handlePopup(row.id)}>
            상세보기
          </span>
        );
      },
      grow: 1,
    },
  ];

  if (graphState !== 'all') {
    columnSetup = [
      {
        name: '번호',
        selector: row => row.listIdx,
        sortable: true,
        grow: 0,
      },
      {
        name: '참가자',
        cell(row) {
          const handlePopup = (id: string | number) => {
            setLayerPopupOpen(true);
            setLayerType('userDetail');
            setLayerValue(id);
          };
          return (
            row.user.name
            // <span style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => handlePopup(row.user.id)}>
            //   {row.user.name}
            // </span>
          );
        },
        grow: 1,
      },
      {
        name: '행사명',
        selector: row => row.conference.name_kor,
        grow: 1,
      },
      {
        name: '상세내용',
        cell(row) {
          if (row.type === 'company') return row.company_setup?.name;
          else if (row.type === 'solution') return row.solution.name;
          else if (row.type === 'event') return row.event.name;
          return row.typeToText;
        },
        grow: 2,
      },
      {
        name: '발급일시',
        selector: row => dayjs(row.created_at).format('YYYY.MM.DD HH:mm'),
        grow: 1,
      },
    ];
  }
  //graph state 따라서 변경
  const columns: TableColumn<any>[] = columnSetup;

  //데이터 가져오기
  const fetchData = async () => {
    try {
      const getInfos = {
        listType: graphState,
        start_date: filterParams.start,
        end_date: filterParams.end,
        ...(userInfo.type !== 'admin' && userInfo.company_setup_id && { company_setup_id: userInfo.company_setup_id }),
      };

      const searchParams = new URLSearchParams(getInfos);
      const response = await axiosInstance.get(`pruufs?${searchParams}`);
      return response.data.data.pruufs;
    } catch (e) {
      console.error('error...');
      return [{}];
    }
  };

  const { data, isLoading, isError } = useQuery(['pruufStatus', graphState, filterParams], fetchData, {
    refetchOnWindowFocus: false,
    notifyOnChangeProps: ['data'],
    select: data => {
      // data 변수에 필터링된 데이터를 저장
      const filteredData = data //
        .filter((item: any) => {
          const { searchTerm, searchType } = filterParams;

          if (searchType === 'all' && searchTerm) {
            // 모든 조건에 대해 OR 검색을 수행합니다.
            return (
              item.conference.name_kor.includes(searchTerm) ||
              item.company_setup?.name.includes(searchTerm) ||
              item.user.name.includes(searchTerm)
            );
          }

          if (searchType === 'conference' && searchTerm && !item.conference.name_kor.includes(searchTerm)) {
            return false;
          }

          if (searchType === 'host' && searchTerm && !item.company_setup?.name.includes(searchTerm)) {
            return false;
          }

          if (searchType === 'company' && searchTerm && !item.company_setup?.name.includes(searchTerm)) {
            return false;
          }

          if (searchType === 'user' && searchTerm && !item.user.name.includes(searchTerm)) {
            return false;
          }
          return true;
        }) //
        .map((item: any) => {
          const { type } = item;
          let typeToText = '전체';
          switch (type) {
            case 'conference':
              typeToText = '행사 참여';
              break;
            case 'company':
              typeToText = '참가사 방문';
              break;
            case 'solution':
              typeToText = '솔루션 체험';
              break;
            case 'event':
              typeToText = '이벤트';
              break;
            default:
              typeToText = '전체';
              break;
          }
          return { ...item, typeToText };
        });

      // graphState가 'all'인 경우 dataAll 변수에 복사
      if (graphState === 'all') {
        dataAll = [...filteredData];
      }

      // return filteredData;
      return filteredData.map((item: any, index: number) => {
        return {
          ...item,
          listIdx: filteredData.length - index,
        };
      });
    },
  });

  const handleGraphState = (state: GraphType) => {
    setGraphState(state);
  };

  const excelData = useMemo(() => {
    if (isLoading || data === undefined) return [];

    return data.map((item: any, index: number) => {
      let detailInfo = '';
      switch (item.type) {
        case 'company':
          detailInfo = item.company_setup?.name;
          break;
        case 'solution':
          detailInfo = item.solution.name;
          break;
        case 'event':
          detailInfo = item.event?.name;
          break;

        default:
          detailInfo = item.typeToText;
          break;
      }

      if (graphState === 'all') {
        return {
          번호: data.length - index,
          참가자: item.user.name,
          행사명: item.conference.name_kor,
          구분: item.typeToText,
          상세내용: detailInfo,
          발급일시: dayjs(item.created_at).format('YYYY.MM.DD HH:mm:ss'),
        };
      } else {
        return {
          번호: data.length - index,
          참가자: item.user.name,
          행사명: item.conference.name_kor,
          상세내용: detailInfo,
          발급일시: dayjs(item.created_at).format('YYYY.MM.DD HH:mm:ss'),
        };
      }
    });
  }, [data, graphState]);

  if (isLoading || isError) return <LoadingIndicator />;

  return (
    <Container>
      {layerPopupOpen && (
        <DefaultLayer
          open={layerPopupOpen}
          actionClose={() => {
            setLayerPopupOpen(false);
          }}
        >
          <>
            {layerType === 'pruufDetail' && <PruufDetail pruufId={layerValue} />}
            {layerType === 'userDetail' && (
              <DefaultPopupPanel
                open={layerPopupOpen}
                actionClose={() => {
                  setLayerPopupOpen(false);
                }}
                title={'참가자 정보'}
              >
                <UserDetailInfo userId={layerValue} />
              </DefaultPopupPanel>
            )}
          </>
        </DefaultLayer>
      )}
      <Title>PRUUF 발급현황</Title>
      <SubTitle>발급 현황 조건 검색</SubTitle>
      <FilterBoxContainer>
        <FlexRow gap={'5px'} justify={'space-between'} width={'100%'}>
          <FlexRow gap={'5px'} justify={'flex-start'}>
            <FilterLabel>검색</FilterLabel>
            <SelectWrapper>
              <select value={filterParams.searchType}
                      onChange={e => setFilterParams(prev => ({ ...prev, searchType: e.target.value }))}>
                <option value={'all'}>전체</option>
                <option value={'conference'}>행사명</option>
                <option value={'host'}>주최사</option>
                <option value={'company'}>참가사</option>
                <option value={'user'}>참가자</option>
              </select>
            </SelectWrapper>
            <SearchFilter
              onChange={searchTerm => setFilterParams(prev => ({ ...prev, searchTerm }))}
              placeholder={'검색어를 입력해주세요.'}
              debounce={500}
              label={false}
              text={filterParams.searchTerm}
            />
          </FlexRow>
        </FlexRow>

        <DateSelectAndReset
          label={'조회기간'}
          onStartChange={start => {
            setFilterParams(prev => ({ ...prev, start }));
            setGraphState('all');
          }}
          onEndChange={end => {
            setFilterParams(prev => ({ ...prev, end }));
            setGraphState('all');
          }}
          start={filterParams.start}
          end={filterParams.end}
        />
      </FilterBoxContainer>

      <PruufChart apiData={data} apiTotalData={dataAll} onTypeChange={handleGraphState} graphState={graphState} />

      <section className={styles.datahWrap}>
        <Table columns={columns} data={data} filename={`PRUUF발급현황_${filterParams.start}_${filterParams.end}`}
               excelData={excelData} />
      </section>
    </Container>
  );
};

export default PruufStatus;

const Container = styled.div`
    width: 1200px;
    height: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    padding-top: 50px;
    padding-bottom: 20px;
    gap: 30px;
`;
