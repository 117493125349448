import React, { useEffect, KeyboardEvent } from 'react';
import { CloseOutlined } from '@ant-design/icons';

import styels from './solutionPopupPanel.module.css';

type Props = {
  open?: boolean;
  title: string;
  children: React.ReactElement;
  actionClose: () => void;
};

const SolutionPopupPanel = ({ open = false, actionClose, title = '정보', children }: Props) => {
  useEffect(() => {
    const escKeyEvent = (e: Event) => {
      const keyboardEvent = e as unknown as KeyboardEvent;
      if (keyboardEvent.key === 'Escape') {
        actionClose();
      }
    };

    window.addEventListener('keydown', escKeyEvent);

    return () => {
      window.removeEventListener('keydown', escKeyEvent);
    };
  }, []);

  if (open === false) {
    return <></>;
  }

  return (
    <div className={styels.wrap}>
      <div className={styels.titleWrap}>
        <h1>{title}</h1>
        <CloseOutlined onClick={actionClose} />
      </div>

      <article>{children}</article>
    </div>
  );
};

export default SolutionPopupPanel;
