import React from 'react';
import styles from './firshtLoginInfo.module.css';
import { useNavigate } from 'react-router-dom';

type Props = {
  type: 'host' | 'company';
  id: string;
};

const FirstLoginInfo = ({ type, id }: Props) => {
  const navigate = useNavigate();
  return (
    <section className={styles.wrap}>
      <p>회사 정보를 먼저 등록해주세요.</p>
      <p>{type === 'host' ? '주최사' : '참가사'} 정보수정으로 이동합니다.</p>
      <button
        onClick={() => {
          navigate(`/signup/${id}`);
        }}
      >
        확인
      </button>
    </section>
  );
};

export default FirstLoginInfo;
