import React, { useState } from 'react';
import { Button, message } from 'antd';
import { Table } from '../../../common/components/Table';
import { TableColumn } from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';
import { appRoutes } from '../../../AppRoutes';
import { Conference, User } from '../../../common/api/responseTypes';
import styles from './applicationShow.module.css';
import { axiosInstance } from '../../../common/providers & services/AxiosService';
import { useQuery, useQueryClient } from '@tanstack/react-query';

const initialState = {
  searchTerm: '',
  start: '',
  end: '',
  status: 'all',
};

type DataObject = {
  id: string;
  user: User;
  conference: Conference;
};

const ApplicationShow = () => {
  const navigate = useNavigate();
  const [selectedRows, setSelectedRows] = useState([]);
  const [messageApi, contextHolder] = message.useMessage();
  const queryClient = useQueryClient();

  const fetchData = async () => {
    try {
      const response = await axiosInstance.get(`conferenceRegistration?state=pending`);
      return response.data.data.conferenceRegistration;
    } catch (e) {
      console.error('error...');
      return {};
    }
  };

  const { data: registration, isLoading } = useQuery(['confercneRegistration_pending'], fetchData);

  const handleRowClick = (row: Conference) => {
    navigate(appRoutes.conferenceInfo.children.detail.path(row.id));
  };

  const handleRowSelected = (rows: any) => {
    //TODO: 선택된 row정보로 처리
    setSelectedRows(rows.selectedRows);
  };

  const handleConfirm = async () => {
    if (selectedRows?.length < 1) {
      messageApi.open({
        key: 'apiMsg',
        type: 'warning',
        content: '처리할 내역이 없습니다.',
        duration: 0,
      });
      return false;
    }

    messageApi.open({
      key: 'apiMsg',
      type: 'loading',
      content: '승인 처리중...',
      duration: 0,
    });

    try {
      const requests = selectedRows.map((item: any) => {

        const submitData = {
          conference_id: item.conference.id,
          company_setup_id: item.company_setup.id,
          state: 'approved',
        };

        return axiosInstance.post('conferenceRegistration', { ...submitData });
      });
      await Promise.all(requests);

      messageApi.open({
        key: 'apiMsg',
        type: 'success',
        content: '성공적으로 승인 처리했습니다.',
        duration: 1,
        onClose: () => {
          queryClient.invalidateQueries(['confercneRegistration_pending']);
        },
      });
    } catch (e) {
      messageApi.open({
        key: 'apiMsg',
        type: 'error',
        content: '승인 처리중 실패하였습니다. 지속되면 문의해주세요.',
      });
    }
  };

  const handleReject = async () => {
    if (selectedRows?.length < 1) {
      messageApi.open({
        key: 'apiMsg',
        type: 'warning',
        content: '처리할 내역이 없습니다.',
        duration: 0,
      });
      return false;
    }

    messageApi.open({
      key: 'apiMsg',
      type: 'loading',
      content: '거절 처리중...',
      duration: 0,
    });

    try {
      const requests = selectedRows.map((item: any) => {

        const submitData = {
          conference_id: item.conference.id,
          company_setup_id: item.company_setup.id,
          state: 'rejected',
        };

        return axiosInstance.post('conferenceRegistration', { ...submitData });
      });

      await Promise.all(requests);

      messageApi.open({
        key: 'apiMsg',
        type: 'success',
        content: '성공적으로 거절 처리했습니다.',
        duration: 1,
        onClose: () => {
          queryClient.invalidateQueries(['confercneRegistration']);
        },
      });
    } catch (e) {
      messageApi.open({
        key: 'apiMsg',
        type: 'error',
        content: '거절 처리중 실패하였습니다. 지속되면 문의해주세요.',
      });
    }
  };

  if (isLoading) {
    return <></>;
  }
  return (
    <section className={styles.wrap}>
      {contextHolder}
      <Table
        columns={columns}
        data={registration}
        // onRowClick={handleRowClick}
        filename={`행사정보`}
        selectableRows={true}
        onSelectedRowsChange={handleRowSelected}
        showExcel={false}
      />

      <div className={styles.btnWrap}>
        <Button onClick={handleReject}>거절하기</Button>
        <Button onClick={handleConfirm} type={'primary'}>
          승인하기
        </Button>
      </div>
    </section>
  );
};

export default ApplicationShow;

const columns: TableColumn<any>[] = [
  {
    name: '행사명',
    selector: row => row.conference.name_kor,
    grow: 1,
  },
  {
    name: '참가 신청',
    // selector: row => `[${row.user.company_setup.name}] ${row.user.name}`,
    selector: row => row.company_setup?.name,
    sortable: true,
    grow: 1,
  },
  {
    name: '참가신청 일시',
    selector: row => row.created_at,
    sortable: true,
    grow: 1,
  },
];
