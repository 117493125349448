import React from 'react';
import { FilterLabel, SelectWrapper } from '../StyledComponents';
import { FlexRow } from '../FlexRow';
import { useLocations } from '../../utils/apiHooks';

interface Props {
  onChange: (location: string) => void;
  label?: string;
}

const LocationSelect: React.FC<Props> = ({ onChange, label }) => {
  const locations = useLocations();

  return (
    <FlexRow gap={'5px'}>
      <FilterLabel>{label ?? '지역'}</FilterLabel>
      <SelectWrapper>
        <select onChange={e => onChange(e.target.value)}>
          <option value={'all'}>전국</option>
          {locations.map(l => (
            <option key={l} value={l}>
              {l}
            </option>
          ))}
        </select>
      </SelectWrapper>
    </FlexRow>
  );
};

export default React.memo(LocationSelect);
