import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { TableColumn } from 'react-data-table-component';

import { appRoutes } from '../../../AppRoutes';
import { Conference, User } from '../../../common/api/responseTypes';

import { Table } from '../../../common/components/Table';
import { FlexRow } from '../../../common/components/FlexRow';
import {
  FilledButton,
  FilterBoxContainer,
  FilterLabel,
  OutlineButton,
  SelectWrapper,
} from '../../../common/components/StyledComponents';
import { formatDateString } from '../../../common/utils/utils';
import { SearchFilter } from '../../../common/components/filters/SearchFilter';
import { useNavigate } from 'react-router-dom';
import { axiosInstance } from '../../../common/providers & services/AxiosService';
import { message, Popconfirm } from 'antd';
import { useQuery } from '@tanstack/react-query';
import { ConferenceStatusIndicator } from '../../conference-info/components/ConferenceStatusIndicator';
import dayjs from 'dayjs';

type Props = {
  companySetupId?: string;
  hostId?: string;
};
const initialState = {
  searchTerm: '',
  start: '',
  end: '',
  status: 'all',
};
type DataObject = {
  id: string;
  user: User;
  conference: Conference;
};
//TODO: 올바른 백엔드 연결 필요
const HostParticipateInfo = ({ hostId, companySetupId }: Props) => {
  const [filterParams, setFilterParams] = useState(initialState);
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();

  const fetchData = async () => {
    try {
      const response = await axiosInstance.get(`conferenceRegistration?company_setup_id=${companySetupId}`);
      return response.data.data.conferenceRegistration;
    } catch (e) {
      console.error('error...');
      return {};
    }
  };
  // const conferences = useAllConferences(...Object.values(filterParams));

  const { data: companyUsers, isLoading } = useQuery(['companyList', companySetupId], fetchData, {
    refetchOnWindowFocus: false,
    select: data => {
      const { searchTerm, status } = filterParams;
      const filteredData = data.filter((item: DataObject) => {
        if (
          searchTerm &&
          !(
            item.conference.name_kor?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.conference.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.conference.name_eng?.toLowerCase().includes(searchTerm.toLowerCase())
          )
        ) {
          return false;
        }

        // 상태가 'all'이 아니고 일치하지 않는 경우
        if (status !== 'all' && item.conference.state !== status) {
          return false;
        }

        return true;
      });

      return filteredData.map((item: any, index: number) => {
        return {
          ...item,
          listIdx: filteredData.length - index,
        };
      });
    },
  });

  const excelData = useMemo(() => {
    if (companyUsers === undefined) return [];
    return companyUsers.map((item: any, index: number) => {
      const { conference } = item;
      return {
        번호: companyUsers.length - index,
        행사명_국문: conference.name_kor,
        행사명_영문: conference.name_eng,
        행사일시: `${dayjs(conference.start_time).format('YYYY-MM-DD HH:mm')} ~ ${dayjs(conference.end_time).format('YYYY-MM-DD HH:mm')}`,
        행사주소: `${conference.location} ${conference.location_detail} ${conference.place}`,
        주최: JSON.parse(conference.hosts).join(', '),
        주관: JSON.parse(conference.organizers).join(', '),
        행사홈페이지: conference.homepage,
        부스정보링크: conference.booth_info_link,
        행사관련정보: conference.description,
        PRUUF발급갯수: conference?.pruuf_count,
        등록일: dayjs(conference.created_at).format('YYYY-MM-DD HH:mm'),
      };
    });
  }, [companyUsers]);

  const handleRowClick = (row: Conference) => {
    navigate(appRoutes.conferenceInfo.children.detail.path(row.id));
  };

  const handleDelete = async () => {
    try {
      await axiosInstance.put(`company/${hostId}`, { is_deleted: true });

      location.href = appRoutes.company.route;
    } catch (e) {
      console.error(e);
      messageApi.open({
        type: 'error',
        content: '삭제에 실패했습니다. 지속되면 문의해주세요.',
      });
    }
  };

  if (isLoading) {
    return <></>;
  }

  return (
    <Container gap={10}>
      <FilterBoxContainer>
        <SearchFilter onChange={searchTerm => setFilterParams(prev => ({ ...prev, searchTerm }))} placeholder={'행사명'}
                      debounce={150} />

        <FlexRow gap={'5px'} justify={'space-between'} width={'100%'}>
          <FlexRow gap={'5px'} justify={'flex-start'}>
            <FilterLabel>상태</FilterLabel>
            <SelectWrapper>
              <select value={filterParams.status}
                      onChange={e => setFilterParams(prev => ({ ...prev, status: e.target.value }))}>
                <option value={'all'}>전체</option>
                <option value={'prepare'}>대기중</option>
                <option value={'progress'}>진행중</option>
                <option value={'complete'}>종료</option>
              </select>
            </SelectWrapper>
          </FlexRow>
        </FlexRow>
      </FilterBoxContainer>

      <Table columns={columns} data={companyUsers} filename={`참가사 정보`} listname={''} excelData={excelData} />

      <FlexRow justify={'flex-end'} gap={'1rem'} width={'100%'}>
        <FilledButton color={'dark-blue'} onClick={() => navigate(appRoutes.company.route)}>
          참가사 목록
        </FilledButton>

        <OutlineButton height={35} onClick={() => navigate(`${appRoutes.company.route}/form/${hostId}`)}>
          참가사 수정
        </OutlineButton>

        {/*<OutlineButton height={35} onClick={() => navigate(`${appRoutes.company.route}/form`)}>*/}
        {/*  참가사 삭제*/}
        {/*</OutlineButton>*/}

        <OutlineButton height={35}>
          <Popconfirm
            title='침기사 삭제'
            description='정말로 참가사를 삭제하시겠습니까?'
            okText='삭제'
            cancelText='취소'
            onConfirm={handleDelete}
          >
            참가사 삭제
          </Popconfirm>
        </OutlineButton>
      </FlexRow>
    </Container>
  );
};

export default HostParticipateInfo;

const Container = styled('div')<{ gap: number }>`
  width: 1200px;
  height: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: ${props => props.gap}px;
`;

const columns: TableColumn<any>[] = [
  {
    name: '행사명',
    selector: row => row.conference.name_kor,
    grow: 1,
  },
  {
    name: '행사일자',
    selector: row => `${formatDateString(row.conference.start_time)} ~ ${row.conference.end_time}`,
    sortable: true,
    grow: 1,
  },
  {
    name: '행사장소',
    selector: row => row.conference.location,
    sortable: true,
    grow: 1,
  },
  {
    name: '상태',
    // cell: row => <ConferenceStatusIndicator status={row.conference.state} />,
    cell: row => (
      <ConferenceStatusIndicator status={row.conference.state} start={row.conference.start_time}
                                 end={row.conference.end_time} />
    ),
    sortable: true,
    grow: 1,
  },
];
