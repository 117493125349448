import React, { useState } from 'react';
import styled from 'styled-components';
import { TableColumn } from 'react-data-table-component';

import { appRoutes } from '../../../AppRoutes';
import { Table } from '../../../common/components/Table';
import { FlexRow } from '../../../common/components/FlexRow';
import { FilledButton, FilterBoxContainer } from '../../../common/components/StyledComponents';
import { SearchFilter } from '../../../common/components/filters/SearchFilter';
import { useNavigate } from 'react-router-dom';
import { axiosInstance } from '../../../common/providers & services/AxiosService';
import { useQuery } from '@tanstack/react-query';
import { LayerType, pruufTypetoText } from '../../conference-info/components/PruufReport';
import dayjs from 'dayjs';
import DefaultLayer from '../../../common/components/panels/defaultLayer';
import PruufDetail from '../../pruuf-status/components/PruufDetail';
import DefaultPopupPanel from '../../../common/components/panels/defaultPopupPanel';
import UserDetailInfo from '../../../common/components/users/UserDetailInfo';

type Props = {
  userId?: string;
};
const initialState = {
  searchTerm: '',
  start: '',
  end: '',
  status: 'all',
};

const VisitorPruufInfo = ({ userId }: Props) => {
  const [filterParams, setFilterParams] = useState(initialState);
  const navigate = useNavigate();
  const [layerPopupOpen, setLayerPopupOpen] = useState(false);
  const [layerType, setLayerType] = useState<LayerType>('pruufDetail');
  const [layerValue, setLayerValue] = useState<string | number>('');

  const columns: TableColumn<any>[] = [
    {
      name: '번호',
      selector: row => row.listIdx,
      sortable: true,
      grow: 0,
    },
    {
      name: '행사명',
      selector: row => row.conference.name_kor,
      sortable: true,
      grow: 2,
    },
    {
      name: '구분',
      selector: row => pruufTypetoText(row.type),
      sortable: true,
      grow: 1,
    },
    {
      name: '발급일시',
      selector: row => dayjs(row.created_at).format('YYYY.MM.DD HH:mm'),
      sortable: true,
      grow: 2,
    },
    {
      name: 'PRUUF 보기',
      cell: row => {
        const handlePopup = (id: string) => {
          setLayerPopupOpen(true);
          setLayerType('pruufDetail');
          setLayerValue(id);
        };
        return (
          <span style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => handlePopup(row.id)}>
              상세보기
            </span>
        );
      },
      sortable: true,
      grow: 1,
    },
  ];

  const fetchData = async () => {
    try {
      const searchParams = new URLSearchParams({
        listType: 'all',
        ...(userId && { user_id: `${userId}` }),
      });

      const response = await axiosInstance.get(`pruufs?${searchParams}`);
      return response.data.data.pruufs;
    } catch (e) {
      console.error('error...');
      return [{}];
    }
  };

  const { data, isLoading, isError } = useQuery(['pruufList', 'all', userId], fetchData, {
    refetchOnWindowFocus: false,
    select: data => {
      const filteredData = data.filter((item: any) => {
        const { searchTerm } = filterParams;

        if (searchTerm && !item.conference.name_kor.includes(searchTerm)) {
          return false;
        }

        return true;
      });
      // return filteredData;
      return filteredData.map((item: any, index: number) => {
        return {
          ...item,
          listIdx: filteredData.length - index,
        };
      });
    },
  });

  const handleRowClick = (row: any) => {
    // navigate(appRoutes.conferenceInfo.children.detail.path(row.id));
  };

  if (isLoading || isError) return <></>;

  return (
    <Container gap={10}>

      {layerPopupOpen && (
        <DefaultLayer
          open={layerPopupOpen}
          actionClose={() => {
            setLayerPopupOpen(false);
          }}
        >
          <>
            {layerType === 'pruufDetail' && <PruufDetail pruufId={layerValue} />}
            {layerType === 'userDetail' && (
              <DefaultPopupPanel
                open={layerPopupOpen}
                actionClose={() => {
                  setLayerPopupOpen(false);
                }}
                title={'참가자 정보'}
              >
                <UserDetailInfo userId={layerValue} />
              </DefaultPopupPanel>
            )}
          </>
        </DefaultLayer>
      )}

      <FilterBoxContainer>
        <SearchFilter
          onChange={searchTerm => setFilterParams(prev => ({ ...prev, searchTerm }))}
          placeholder={'행사명으로 검색해주세요.'}
          debounce={150}
        />
      </FilterBoxContainer>

      <Table columns={columns} data={data} onRowClick={handleRowClick} filename={`주최사 정보`} listname={''} />

      <FlexRow justify={'flex-end'} gap={'1rem'} width={'100%'}>
        <FilledButton color={'dark-blue'} onClick={() => navigate(appRoutes.visitor.route)}>
          참가자 목록
        </FilledButton>

        {/*<OutlineButton height={35} onClick={() => navigate(`${appRoutes.host.route}/form`)}>*/}
        {/*  참가자 삭제*/}
        {/*</OutlineButton>*/}
      </FlexRow>
    </Container>
  );
};

export default VisitorPruufInfo;

const Container = styled('div')<{ gap: number }>`
    width: 1200px;
    height: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: ${props => props.gap}px;
`;
