import { useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import classNames from 'classnames';

import useQRCodeGenerator from '../../../common/utils/useQRCodeGenerator';

import styles from './qrTemplate.module.css';

type Props = {
  link: string;
  templateInfo: object;
  templateType: 'company' | 'solution';
};

const QrTemplate = ({ link, templateInfo, templateType }: Props) => {
  useEffect(() => {
    localStorage.setItem('templateInfo', JSON.stringify(templateInfo));
    localStorage.setItem('templateType', templateType);

    return () => {
      localStorage.removeItem('templateInfo');
      localStorage.removeItem('templateType');
    };
  }, []);

  return (
    <section className={styles.wrap}>
      <h2>원하는 템플릿(A4)의 인쇄 버튼을 눌러주세요.</h2>

      <div className={styles.container}>
        <article>
          <Template1 link={link} showBtn={false} templateInfo={templateInfo} templateType={templateType} />
          <button
            onClick={() => {
              window.open(`/qrTemplate1?url=${link}`, '');
            }}
          >
            인쇄하기(미리보기)
          </button>
        </article>
        <article>
          <Template2 link={link} showBtn={false} templateInfo={templateInfo} templateType={templateType} />
          <button
            onClick={() => {
              window.open(`/qrTemplate2?url=${link}`, '');
            }}
          >
            인쇄하기(미리보기)
          </button>
        </article>
        <article>
          <Template3 link={link} showBtn={false} templateInfo={templateInfo} templateType={templateType} />
          <button
            onClick={() => {
              window.open(`/qrTemplate3?url=${link}`, '');
            }}
          >
            인쇄하기(미리보기)
          </button>
        </article>
        <article>
          <Template4 link={link} />
          <button
            onClick={() => {
              window.open(`/qrTemplate4?url=${link}`, '');
            }}
          >
            인쇄하기(미리보기)
          </button>
        </article>
      </div>
    </section>
  );
};

export const Template1 = ({ link, showBtn = true, templateInfo, templateType }: any) => {
  const [searchParams] = useSearchParams();
  const urlLink = searchParams.get('url');
  const { canvas, saveSvg, savePng } = useQRCodeGenerator({ link: link ?? urlLink, width: '320', height: '320' });

  const componentRef = useRef<HTMLDivElement>(null);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  let Info = templateInfo;
  let Type = templateType;
  if (!templateInfo) {
    const templateInfo = localStorage.getItem('templateInfo');
    Info = { ...JSON.parse(templateInfo as string) };
  }
  if (!templateInfo) {
    Type = localStorage.getItem('templateType');
  }

  return (
    <div className={classNames(`${styles.template}`, { [styles.preview]: showBtn === false })}>
      {showBtn && <button onClick={handlePrint}>인쇄하기</button>}
      <div className={`${styles.template1} ${styles.printContent}`} ref={componentRef}>
        <div className={styles.printContainer}>
          <div className={styles.textWrap}>
            <h3>{Info.conference.name_kor}</h3>

            {Type === 'solution' && <h2>{Info.company_setup.name}</h2>}
          </div>

          <h1>
            {Type === 'solution' && (Info.solution?.name ?? Info.conference.solution?.name)}
            {Type === 'company' && Info.company_setup.name}
            {/* {Info.company_setup.name} */}
          </h1>

          <div className={styles.qrcodeWrap}>
            <div className={styles.qrcode}>
              <canvas ref={canvas}></canvas>
            </div>
            <p>
              프루프 앱으로 QR코드를 스캔하고
              <br />
              프루프를 수집해보세요!
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export const Template2 = ({ link, showBtn = true, templateInfo, templateType }: any) => {
  const [searchParams] = useSearchParams();
  const urlLink = searchParams.get('url');
  const { canvas, saveSvg, savePng } = useQRCodeGenerator({ link: link ?? urlLink, width: '248', height: '248' });

  const componentRef = useRef<HTMLDivElement>(null);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  let Info = templateInfo;
  let Type = templateType;
  if (!templateInfo) {
    const templateInfo = localStorage.getItem('templateInfo');
    Info = { ...JSON.parse(templateInfo as string) };
  }
  if (!templateInfo) {
    Type = localStorage.getItem('templateType');
  }
  return (
    <div className={classNames(`${styles.template}`, { [styles.preview]: showBtn === false })}>
      {showBtn && <button onClick={handlePrint}>인쇄하기</button>}
      <div className={`${styles.template2} ${styles.printContent}`} ref={componentRef}>
        <div className={styles.printContainer}>
          <div className={styles.textWrap}>
            <h3>{Info.conference.name_kor}</h3>
            {Type === 'solution' && <h2>{Info.company_setup.name}</h2>}
          </div>
          <h1>
            {Type === 'solution' && (Info.solution?.name ?? Info.conference.solution?.name)}
            {Type === 'company' && Info.company_setup.name}
          </h1>

          <div className={styles.qrcodeWrap}>
            <div className={styles.qrcode}>
              <canvas ref={canvas}></canvas>
            </div>
            <p>
              프루프 앱으로 QR코드를 스캔하고
              <br />
              프루프를 수집해보세요!
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export const Template3 = ({ link, showBtn = true, templateInfo, templateType }: any) => {
  const [searchParams] = useSearchParams();
  const urlLink = searchParams.get('url');
  const { canvas, saveSvg, savePng } = useQRCodeGenerator({ link: link ?? urlLink, width: '300', height: '300' });

  const componentRef = useRef<HTMLDivElement>(null);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  let Info = templateInfo;
  let Type = templateType;
  if (!templateInfo) {
    const templateInfo = localStorage.getItem('templateInfo');
    Info = { ...JSON.parse(templateInfo as string) };
  }
  if (!templateInfo) {
    Type = localStorage.getItem('templateType');
  }

  return (
    <div className={classNames(`${styles.template}`, { [styles.preview]: showBtn === false })}>
      {showBtn && <button onClick={handlePrint}>인쇄하기</button>}
      <div className={`${styles.template3} ${styles.printContent}`} ref={componentRef}>
        <div className={styles.printContainer}>
          <div className={styles.textWrap}>
            <h3>{Info.conference.name_kor}</h3>
            {Type === 'solution' && <h2>{Info.company_setup.name}</h2>}
          </div>
          <h1>
            {Type === 'solution' && (Info.solution?.name ?? Info.conference.solution?.name)}
            {Type === 'company' && Info.company_setup.name}
          </h1>

          <div className={styles.qrcodeWrap}>
            <div className={styles.qrcode}>
              <canvas ref={canvas}></canvas>
            </div>
            <p>
              프루프 앱으로 QR코드를 스캔하고
              <br />
              프루프를 수집해보세요!
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export const Template4 = ({ link }: any) => {
  const [searchParams] = useSearchParams();
  const urlLink = searchParams.get('url');
  const { canvas, saveSvg, savePng } = useQRCodeGenerator({ link: link ?? urlLink });
  return (
    <div>
      <canvas ref={canvas}></canvas>
    </div>
  );
};

export default QrTemplate;
