import { message } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { HTMLTable, SubTitle, Td, Th, Tr } from '../../common/components/StyledComponents';

import styles from './solutionInfoDetail.module.css';
import { useQuery } from '@tanstack/react-query';
import { axiosInstance } from '../../common/providers & services/AxiosService';
import dayjs from 'dayjs';
import SolutionParticipateInfo from './components/SolutionParticipateInfo';

const SolutionInfoDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const fetchData = async () => {
    try {
      const response = await axiosInstance.get(`solution/${id}`);
      return response.data.data.solution;
    } catch (e) {
      console.log('e', e);
      return {};
    }
  };

  const { data, isLoading, isError } = useQuery(['solutionDetail', id], fetchData, {
    staleTime: 5000,
  });

  const handleResetPassword = (e?: React.MouseEvent<HTMLElement>) => {
    console.log(e);
    message.success('Click on Yes');
  };

  const cancel = (e?: React.MouseEvent<HTMLElement>) => {
    console.log(e);
    message.error('Click on No');
  };

  if (isLoading || isError) {
    return <></>;
  }

  return (
    <section className={styles.wrap}>
      <SubTitle marginBottom={-20}>기본정보</SubTitle>
      <HTMLTable>
        <tbody>
        <Tr>
          <Th>솔루션</Th>
          <Td>{data.name}</Td>
        </Tr>
        <Tr>
          <Th>참가사</Th>
          <Td>{data.user.company_setup.name}</Td>
        </Tr>
        <Tr>
          <Th>담당자</Th>
          <Td>{data.person}</Td>
        </Tr>

        <Tr>
          <Th>담당자 연락처</Th>
          <Td>{data.user.tel}</Td>
        </Tr>
        <Tr>
          <Th>문의 연락처</Th>
          <Td>{data.tel}</Td>
        </Tr>
        <Tr>
          <Th>문의 이메일</Th>
          <Td>{data.email}</Td>
        </Tr>
        <Tr>
          <Th>솔루션 홈페이지</Th>
          <Td>
            {data.homepage && (
              <a target={'_blank'} href={data.homepage} rel='noreferrer'>
                {data.homepage}
              </a>
            )}
          </Td>
        </Tr>
        <Tr>
          <Th>솔루션 소개</Th>
          <Td>{data.description}</Td>
        </Tr>
        <Tr>
          <Th>해시태그</Th>
          <Td>{data.hash ? JSON.parse(data.hash) : ''}</Td>
        </Tr>
        <Tr>
          <Th>소개자료</Th>
          <Td>{data.reference}</Td>
        </Tr>

        <Tr>
          <Th>등록일</Th>
          <Td>{dayjs(data.created_at).format('YYYY-MM-DD')}</Td>
        </Tr>
        </tbody>
      </HTMLTable>
      <SubTitle marginBottom={-20}>참가한 행사</SubTitle>

      {/* <CompanyParticipateInfo hostId={id} /> */}
      <SolutionParticipateInfo solutionId={data.id} companySetupId={data.user.company_setup?.id} />

      {/* <FlexRow justify={'flex-end'} gap={'1rem'} width={'100%'}>
        <FilledButton color={'dark-blue'} onClick={() => navigate(appRoutes.solution.route)}>
          솔루션 목록
        </FilledButton>

        <OutlineButton height={35} onClick={() => navigate(`${appRoutes.host.route}/form`)}>
          솔루션 삭제
        </OutlineButton>
      </FlexRow> */}
    </section>
  );
};

export default SolutionInfoDetail;
