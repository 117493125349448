import {
  Certificate,
  CompanyDetail,
  CompanyNftData,
  ConferenceDetail,
  ConferenceEvent,
  ConferenceFragmentResponse,
  ConferenceNftData,
  ConferenceResponse,
  Lecture,
  NftChartData,
  Product,
  VisitorAllDetail,
  VisitorDetail,
  VisitorNftData,
} from './responseTypes';
import { axiosInstance } from '../providers & services/AxiosService';
import { ConferenceRequest } from './requestTypes';
import { parseUrlParams } from '../utils/utils';

export const getConferences = async (params: ConferenceRequest): Promise<ConferenceResponse> => {
  const paramString = parseUrlParams(params);
  const { data } = await axiosInstance.get(`conferences?${paramString}`);
  const conferences = data.data.conferences as ConferenceResponse;
  return {
    ...conferences,
    data: conferences.data.map(d => ({
      ...d,
      hostsParsed: JSON.parse(d.hosts),
      organizersParsed: JSON.parse(d.organizers),
    })),
  };
};

export const getAllOrganizers = async (): Promise<{ id: string; name: string }[]> => {
  const { data } = await axiosInstance.get('organizers');
  return data.data.organizers;
};

export const getAllHostUsers = async (): Promise<{ id: string; name: string }[]> => {
  const { data } = await axiosInstance.get('hosts');
  return data.data.hosts;
};

export const getAllCompanyUsers = async (): Promise<{ id: string; name: string }[]> => {
  const { data } = await axiosInstance.get('companies');
  return data.data.companies;
};

export const getConference = async (id: string): Promise<ConferenceDetail> => {
  const { data } = await axiosInstance.get(`conferences/${id}`);
  const productRank = await axiosInstance.get(`conferences/${id}/products/ranks`);
  const companyRank = await axiosInstance.get(`conferences/${id}/companies/ranks`);
  const detail = data.data as ConferenceDetail;
  return {
    ...detail,
    id: id,
    conference: {
      ...detail.conference,
      hostsParsed: JSON.parse(detail.conference.hosts),
      organizersParsed: JSON.parse(detail.conference.organizers),
    },
    visitors: [],
    companies: [],
    lectures: [],
    events: [],
    certificates: [],
    products: [],
    productRank: productRank.data.data.rank,
    companyRank: companyRank.data.data.rank,
  };
};

export const getConferenceVisitors = async (id: string): Promise<ConferenceFragmentResponse<VisitorDetail[]>> => {
  const { data } = await axiosInstance.get(`conferences/${id}/visitors?excel=1`);
  return { id, data: data.data.visitors };
};

export const getConferenceCompanies = async (id: string): Promise<ConferenceFragmentResponse<CompanyDetail[]>> => {
  const { data } = await axiosInstance.get(`conferences/${id}/companies?excel=1`);
  return { id, data: data.data.companies };
};

export const getConferenceLectures = async (id: string): Promise<ConferenceFragmentResponse<Lecture[]>> => {
  const response = await axiosInstance.get(`conferences/${id}/lectures?excel=1`);
  const data = response.data.data.lectures as Lecture[];
  const lectures = data.map((l: Lecture) => ({
    ...l,
    materialsParsed: JSON.parse(l.materials),
  })) as Lecture[];
  return { id, data: lectures };
};

export const getConferenceEvents = async (id: string): Promise<ConferenceFragmentResponse<ConferenceEvent[]>> => {
  const { data } = await axiosInstance.get(`conferences/${id}/events?excel=1`);
  return { id, data: data.data.events };
};

export const getConferenceCertificate = async (id: string): Promise<ConferenceFragmentResponse<Certificate>> => {
  const { data } = await axiosInstance.get(`conferences/${id}/certificate`);
  return { id, data: data.data.certificate };
};

export const getConferenceProducts = async (id: string): Promise<ConferenceFragmentResponse<Product[]>> => {
  const response = await axiosInstance.get(`conferences/${id}/products?excell=1`);
  const products = response.data.data.products.data as Product[];
  const data: Product[] = products.map(p => ({ ...p, materialsParsed: JSON.parse(p.materials) }));
  return { id, data };
};

export const getConferenceNftData = async (id: string, type?: number): Promise<ConferenceFragmentResponse<NftChartData>> => {
  const { data } = await axiosInstance.get(`conferences/${id}/chart${type ? `?type=${type}` : ''}`);
  return { id, data: data.data };
};

export const getConferenceCompanyChartData = async (conferenceId: string, companyId: string): Promise<CompanyNftData> => {
  const { data } = await axiosInstance.get(`conferences/${conferenceId}/companies/${companyId}`);
  return data.data;
};

export const getConferenceProductNftData = async (conferenceId: string, productId: string): Promise<ConferenceNftData[]> => {
  const { data } = await axiosInstance.get(`conferences/${conferenceId}/products/${productId}/nfts?excel=1`);
  return data.data.nfts;
};
export const getConferenceLectureNftData = async (conferenceId: string, lectureId: string): Promise<ConferenceNftData[]> => {
  const { data } = await axiosInstance.get(`conferences/${conferenceId}/lectures/${lectureId}/nfts?excel=1`);
  return data.data.nfts;
};
export const getConferenceEventNftData = async (conferenceId: string, eventId: string): Promise<ConferenceNftData[]> => {
  const { data } = await axiosInstance.get(`conferences/${conferenceId}/events/${eventId}/nfts?excel=1`);
  return data.data.nfts;
};

export const getConferenceCertificateNftData = async (conferenceId: string, certificateId: string): Promise<ConferenceNftData[]> => {
  const { data } = await axiosInstance.get(`conferences/${conferenceId}/certificates/${certificateId}/nfts?excel=1`);
  return data.data.nfts;
};

export const getConferenceVisitorNftData = async (conferenceId: string, visitorId: string): Promise<VisitorNftData[]> => {
  const { data } = await axiosInstance.get(`conferences/${conferenceId}/visitors/${visitorId}/nfts?excel=1`);
  return data.data.nfts;
};

export const getConferenceVisitorAllDetail = async (conferenceId: string, visitorId: string): Promise<VisitorAllDetail> => {
  const { data } = await axiosInstance.get(`conferences/${conferenceId}/visitors/${visitorId}`);
  return data.data.visitor;
};
export const getConferenceCompanyNftData = async (conferenceId: string, companyId: string, type?: number): Promise<ConferenceNftData[]> => {
  const { data } = await axiosInstance.get(`conferences/${conferenceId}/companies/${companyId}/nfts?excel=1${type ? `&type=${type}` : ''}`);
  return data.data.nfts;
};
