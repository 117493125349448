import React, { startTransition, useMemo, useState } from 'react';
import {
  Container,
  FilledButton,
  FilterBoxContainer,
  FilterLabel,
  HTMLTable,
  Input,
  SelectWrapper,
  SubTitle,
  Td,
  Th,
  Tr,
} from '../../../common/components/StyledComponents';
import { FlexRow } from '../../../common/components/FlexRow';
import { TableColumn } from 'react-data-table-component';
import { Table } from './Table';
import { Lecture } from '../../../common/api/responseTypes';
import { useFragment } from '../../../common/utils/apiHooks';
import { filterDateRange, searchFilter } from '../../../common/utils/dataFilters';
import { DateSelectAndReset } from '../../../common/components/filters/DateSelectAndReset';
import { LectureModal } from '../../../common/components/modals/LectureModal';
import { useAppSelector } from '../../../store/store';
import { selectorConferenceName } from '../../../store/conference/conferenceSelectors';

interface Props {
  conferenceId: string;
}

const initialState = {
  searchKey: 'name',
  searchTerm: '',
  start: '',
  end: '',
};

// todo - 강연자 소속 + 강연참가사 == lecture_group (search filter dropdown labels don't match table headers)?, company??
export const ConferenceInfoDetailLectures: React.FC<Props> = ({ conferenceId }) => {
  const [detailView, setDetailView] = useState<Lecture>();
  const [modalOpen, setModalOpen] = useState(false);

  const [filterParams, setFilterParams] = useState(initialState);

  const conferenceName = useAppSelector(state => selectorConferenceName(state, conferenceId));

  const lectures = useFragment<Lecture[]>('lectures', conferenceId);

  const data = useMemo(() => {
    let temp: Lecture[] = [];

    if (lectures && filterParams) {
      startTransition(() => {
        temp = lectures.filter(
          l =>
            filterDateRange(filterParams.start, filterParams.end, [{ date: l.start_time }, { date: l.end_time }]) &&
            searchFilter([filterParams.searchKey], filterParams.searchTerm, l),
        );
      });
    }

    return temp;
  }, [lectures, filterParams]);

  return (
    <>
      <Container gap={10}>
        {!detailView && (
          <>
            <SubTitle>강연 정보</SubTitle>
            <FilterBoxContainer>
              <FlexRow gap={'5px'}>
                <FilterLabel>검색</FilterLabel>
                <SelectWrapper>
                  <select value={filterParams.searchKey} onChange={e => setFilterParams(prev => ({ ...prev, searchKey: e.target.value }))}>
                    <option value={'name'}>강의명</option>
                    <option value={'location'}>강연장소</option>
                    <option value={'lecturer'}>강연자</option>
                    {/*<option>참가사</option>*/}
                  </select>
                </SelectWrapper>
                <Input
                  width={400}
                  placeholder={'검색어를 검색해주세요.'}
                  value={filterParams.searchTerm}
                  onChange={e => setFilterParams(prev => ({ ...prev, searchTerm: e.target.value }))}
                />
              </FlexRow>
              <DateSelectAndReset
                label={'강연일자'}
                onStartChange={start => setFilterParams(prev => ({ ...prev, start }))}
                onEndChange={end => setFilterParams(prev => ({ ...prev, end }))}
                onReset={() => setFilterParams(initialState)}
              />
            </FilterBoxContainer>

            <Table data={data} columns={columns} onRowClick={setDetailView} filename={`${conferenceName}_참가사`} />
          </>
        )}
        {detailView && (
          <>
            <SubTitle>기본정보</SubTitle>

            <HTMLTable>
              <tbody>
                <Tr>
                  <Th>강의명</Th>
                  <Td>{detailView.name}</Td>
                </Tr>
                <Tr>
                  <Th>강연일시</Th>
                  <Td>{detailView.start_time}</Td>
                </Tr>
                <Tr>
                  <Th>강연장소</Th>
                  <Td>{detailView.location}</Td>
                </Tr>
                <Tr>
                  <Th>강연자</Th>
                  <Td>{detailView.lecturer}</Td>
                </Tr>
                <Tr>
                  <Th>강연자 소속</Th>
                  <Td>{detailView.lecturer_group}</Td>
                </Tr>
                <Tr>
                  <Th>강연 자료</Th>
                  <Td>
                    {detailView.materialsParsed.map(m => (
                      <>
                        <a target='_blank' rel='noopener noreferrer' href={m.full_path} style={{ lineHeight: '20px' }}>
                          {m.origin_name}
                        </a>
                        <br />
                      </>
                    ))}
                  </Td>
                </Tr>
                <Tr>
                  <Th>강연 링크</Th>
                  <Td></Td>
                </Tr>
              </tbody>
            </HTMLTable>
            <FlexRow width={'100%'} justify={'flex-end'} gap={'8px'}>
              <FilledButton height={45} color={'charcoal'} onClick={() => setDetailView(undefined)}>
                목록보기
              </FilledButton>
              <FilledButton height={45} color={'charcoal'} onClick={() => setModalOpen(true)}>
                NFT발급현황 자세히 보기
              </FilledButton>
            </FlexRow>
          </>
        )}
      </Container>
      <LectureModal conferenceId={conferenceId} open={modalOpen} onClose={() => setModalOpen(false)} lectureId={detailView?.id} />
    </>
  );
};

const columns: TableColumn<Lecture>[] = [
  {
    name: '번호',
    cell: (_, i) => i + 1,
    sortable: true,
    grow: 0,
  },
  {
    name: '강의명',
    selector: row => row.name,
    sortable: true,
    grow: 1,
  },
  {
    name: '강연일시',
    selector: row => row.start_time,
    sortable: true,
  },
  {
    name: '강연자',
    selector: row => row.lecturer,
    sortable: true,
  },
  {
    name: '강연참가사',
    selector: row => row.lecturer_group,
    sortable: true,
  },
  {
    name: 'NFT 발급갯수',
    selector: row => row.nfts_count,
    sortable: true,
  },
];
