/* eslint-disable no-useless-escape */
import { customAlphabet } from 'nanoid';

export const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

const defaultAlphabet = '1234567890abcdefghijklmnop';
export const uniqId = (getInAlphabet?: string, length?: number): string => {
  const alphabet = getInAlphabet || defaultAlphabet;
  const idLength = length || 8;

  return customAlphabet(alphabet, idLength)();
};

export const onlyNumber = (value: string) => {
  const regex = /[^0-9]/g;
  return value.replace(regex, '');
};
export const addhttp = (url: string) => {
  if (!/^(?:f|ht)tps?\:\/\//.test(url)) {
    url = 'http://' + url;
  }
  return url;
};

export const getRandomNumber = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const makeTelNumberHypen = (tel: string) => {
  return tel.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);
};
export const removeSubstrings = (str: string, substrings?: string[]): string => {
  if (!substrings) return str;

  let result = str;
  for (const substring of substrings) {
    result = result.replace(substring, '');
  }
  return result;
};

export const validURL = (str: string) => {
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$',
    'i',
  ); // fragment locator
  return !!pattern.test(str);
};

export const randomFromArray = <T>(arr: T[]): T | undefined => {
  return arr.sort(() => Math.random() - 0.5).shift();
};

// Tel 관련 input 처리
export const handleTelInput = (tel: string): string | false => {
  const regex = /[^0-9]/g;
  const onlyNumber = tel.replace(regex, '');
  let resultValue = tel;
  if (onlyNumber.substring(0, 2) === '02') {
    if (onlyNumber.length === 10) {
      const newTel = onlyNumber.replace(/^(\d{0,2})(\d{0,4})(\d{0,4})$/g, '$1-$2-$3').replace(/\-{1,2}$/g, '');
      resultValue = newTel;
    } else if (onlyNumber.length < 10) {
      const newTel = onlyNumber.replace(/^(\d{0,2})(\d{0,3})(\d{0,4})$/g, '$1-$2-$3').replace(/\-{1,2}$/g, '');
      resultValue = newTel;
    } else {
      return false;
    }
    return resultValue;
  }
  if (onlyNumber.length === 11) {
    const newTel = onlyNumber.replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, '$1-$2-$3').replace(/\-{1,2}$/g, '');
    resultValue = newTel;
  } else if (onlyNumber.length < 11) {
    const newTel = onlyNumber.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})$/g, '$1-$2-$3').replace(/\-{1,2}$/g, '');
    resultValue = newTel;
  } else {
    return false;
  }

  return resultValue;
};

export const dataUrlToBlob = (dataUrl: string): Blob => {
  const [dataType, dataBase64] = dataUrl.split(',');
  const byteString = atob(dataBase64);
  const arrayBuffer = new ArrayBuffer(byteString.length);
  const uint8Array = new Uint8Array(arrayBuffer);

  for (let i = 0; i < byteString.length; i++) {
    uint8Array[i] = byteString.charCodeAt(i);
  }

  return new Blob([arrayBuffer], { type: dataType.split(':')[1] });
};

export const getUserInfoFromStorage = () => {
  const userInfo = localStorage.getItem('userInfo');
  return userInfo ? JSON.parse(userInfo) : {};
};

export const toBusinessRegistrationNumber = (number: string): string => {
  const digits = number.slice(0, 10);
  return digits.replace(/(\d{3})(\d{2})(\d{5})/, '$1-$2-$3');
};
