import React, { useEffect, useState } from 'react';
import { getSavedAuth, KEY_SAVED_AUTH } from '../utils/storage';
import { useAppDispatch, useAppSelector } from '../../store/store';
import { appRoutes } from '../../AppRoutes';
import { AuthResponse } from '../api/responseTypes';
import { useNavigate } from 'react-router-dom';
import { selectorAxiosReady } from '../../store/app/appSelectors';
import { signOut } from '../../store/app/appSlice';
import { getUserInfoFromStorage } from '../../lib/function';

export const AuthContext = React.createContext<null | boolean>(null);

export const useAuth = () => {
  return React.useContext(AuthContext);
};

export const AuthProvider = (props: Props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const axiosReady = useAppSelector(selectorAxiosReady);
  const [auth, setAuth] = useState<AuthResponse>();

  useEffect(() => {
    // Refresh token doesn't have access to state so listen an update here
    // Don't check isLogin if no saved auth as it will set savedAuth to null causing infinite loop
    window.addEventListener(KEY_SAVED_AUTH, () => {
      const res = getSavedAuth();
      if (res) {
        setAuth(res);
        isLogin();
      } else {
        setAuth(undefined);
      }
    });
  }, []);

  useEffect(() => {
    (() => {
      const allowedPaths = ['/qr/', '/dl', '/privacy', '/changePassword', '/signup'];

      if (allowedPaths.some(path => location.pathname.includes(path))) {
        return onLoginComplete();
      }

      if (!isLogin()) {
        const userInfo = getUserInfoFromStorage();
        const { type } = userInfo;
        if (location.pathname.includes('/admin')) {
          return navigate('/admin', { replace: true });
        } else {
          return navigate('/');
        }


        // if (!location.pathname.includes('/admin') || (type && type !== 'admin')) {
        //   console.log('접근한 페이지가 관리자로그인이 아니고, 나는 관리자가 아니다!!!');
        //   localStorage.clear();
        //   if (type) {
        //     return navigate('/admin', { replace: true });
        //   } else {
        //     return navigate('/');
        //   }
        // } else {
        //   console.log('나는 누굴까?');
        //   if (type) {
        //     return navigate('/');
        //   } else {
        //     return navigate('/admin', { replace: true });
        //   }
        // }
      }

      if (!axiosReady) return;

      onLoginComplete();
    })();
  }, [auth, axiosReady]);

  const onLoginComplete = async () => {
    let origin = `${location.pathname}${location.search}${location.hash}` || appRoutes.home.path();
    origin =
      origin === '/' || (origin === '/sign-in' && isLogin()) || (origin.includes('/sign-up-social') && isLogin())
        ? appRoutes.home.path()
        : origin;

    navigate(origin, { replace: true });
  };

  const isLogin = () => {
    const res = getSavedAuth();
    if (res) {
      return res.isLogin;
    } else {
      if (axiosReady) {
        dispatch(signOut());
      }
    }
    return false;
  };

  return <AuthContext.Provider value={isLogin()}>{props.children}</AuthContext.Provider>;
};

interface Props {
  children: React.ReactNode;
}
