import { message } from 'antd';
import styles from './loginAdmin.module.css';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useState } from 'react';
import DefaultLayer from '../../../common/components/panels/defaultLayer';
import DefaultPopupPanel from '../../../common/components/panels/defaultPopupPanel';
import PasswordReset from '../../login/components/passwordReset';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { getApiUrl } from '../../../common/providers & services/AxiosService';
import { setSavedAuth } from '../../../common/utils/storage';
import { AuthResponse } from '../../../common/api/responseTypes';

type ButtonAction = 'join' | 'passwordReset';

const LoginAdmin = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const [layerPopupOpen, setLayerPopupOpen] = useState(false);

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  // 관리자 로그인
  const onSubmit: SubmitHandler<{ [key: string]: string }> = async data => {
    // console.log(data); // api post Login

    const { email, password } = data;

    try {
      // await postSignIn(email, password);

      const baseUrl = getApiUrl();

      const response = await axios.post(`${baseUrl}sign-in/admin`, {
        account: email,
        password,
      });

      const tokenData = response.data.data.token as AuthResponse;
      setSavedAuth({
        ...tokenData,
        isLogin: true,
      });

      navigate('/');
    } catch (e) {
      console.error(e);
      messageApi.open({
        type: 'error',
        content: '로그인에 실패했습니다. 입력하신 정보를 확인해주세요.',
        duration: 2,
      });
    }
  };

  const validEmail = (value: string) => {
    if (/^\S+@\S+\.\S+$/.test(value)) return true;
    return '이메일 형식이 맞지 않습니다.';
  };

  const validPassword = (value: string) => {
    const hasMinimumLength = value.length >= 8;
    const hasEnglishLetter = /[a-zA-Z]/.test(value);
    const hasNumber = /\d/.test(value);
    if (!(hasMinimumLength && hasEnglishLetter && hasNumber)) {
      return '비밀번호는 영문+숫자 8자리 이상으로 해주세요';
    }
    return true;
  };

  const handleButtonAction = (type: ButtonAction) => {
    //TODO: 회원가입 시 가입 페이지로 전환
    // if (type === 'join') {
    //   messageApi.open({
    //     type: 'info',
    //     content: '회원가입은 작업중입니다.',
    //     duration: 5,
    //   });
    // }

    //TODO: 비밀번호 초기화 시 modal로 이메일주소 입력
    if (type === 'passwordReset') {
      setLayerPopupOpen(true);
    }
  };

  const actionClose = () => {
    setLayerPopupOpen(false);
  };

  return (
    <section className={styles.container}>
      {contextHolder}
      {/* {layerPopupOpen && (
        <DefaultLayer open={layerPopupOpen}>
          <DefaultPopupPanel actionClose={() => setLayerPopupOpen(false)} title={'관리자 회원가입'}>
            <div style={{ height: '520px', overflowY: 'hidden' }}>
              <SignUpAdmin />
            </div>
          </DefaultPopupPanel>
        </DefaultLayer>
      )} */}

      {layerPopupOpen && (
        <DefaultLayer open={layerPopupOpen}>
          <DefaultPopupPanel actionClose={actionClose} title={'가입한 이메일 주소를 입력해주세요.'}>
            <PasswordReset />
          </DefaultPopupPanel>
        </DefaultLayer>
      )}
      <div className={styles.contentWrapper}>
        {/*<div*/}
        {/*  style={{*/}
        {/*    border: '2px solid #fff',*/}
        {/*    borderRadius: '5px',*/}
        {/*    padding: '10px 20px',*/}
        {/*    marginBottom: '10px',*/}
        {/*  }}*/}
        {/*>*/}
        {/*  <h4>JiranSecurity</h4>*/}
        {/*  <h4>시스템 관리자 로그인</h4>*/}
        {/*</div>*/}
        <div className={styles.headTitle}>
          <span>JiranSecurity</span>
          <h2>PRUUF</h2>
        </div>
        <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
          <div>
            <i className='xi-profile-o'></i>
            <input
              type='text'
              placeholder='이메일 아이디'
              {...register('email', {
                required: '이메일을 입력해주세요.',
                validate: validEmail,
              })}
            />
          </div>
          {errors.email && <p className={styles.errorMessage}>{errors.email?.message as string}</p>}
          <div>
            <i className='xi-lock-o'></i>
            <input
              type='password'
              placeholder='비밀번호'
              {...register('password', {
                required: '비밀번호를 입력해주세요.',
                validate: validPassword,
              })}
            />
          </div>
          {errors.password && <p className={styles.errorMessage}>{errors.password?.message as string}</p>}
          <button type='submit'>Login</button>
        </form>

        <div className={styles.buttonWrapper}>
          <button onClick={() => navigate('../signup')}>회원가입</button>
          <button
            onClick={e => {
              e.preventDefault();
              handleButtonAction('passwordReset');
            }}
          >
            비밀번호 초기화
          </button>
        </div>
      </div>
    </section>
  );
};

export default LoginAdmin;
