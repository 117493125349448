import React, { useCallback } from 'react';
import { appRoutes, AppRoutes } from './AppRoutes';
import { AppNavBar } from './common/components/AppNavBar';
import { useLocation } from 'react-router-dom';
import './App.css';
import { AppFooter } from './common/components/AppFooter';
import { AxiosService } from './common/providers & services/AxiosService';
import { AuthProvider } from './common/providers & services/AuthProvider';
import { TokenRefreshService } from './common/providers & services/TokenRefreshService';

import 'dayjs/locale/ko';
import { ConfigProvider } from 'antd';
import locale from 'antd/locale/ko_KR';

function App() {
  const location = useLocation();

  const authenticatedRoute = useCallback(() => {
    const excludedPaths = [
      '/',
      appRoutes.login.route,
      '/privacy',
      '/admin',
      appRoutes.loginAdmin.route,
      appRoutes.signup.route,
      appRoutes.signupAdmin.route,
      '/notfound',
    ];
    const excludedPrefixes = [
      '/qr/',
      '/dl',
      `/${appRoutes.changePassword.key}`,
      '/qrTemplate1',
      '/qrTemplate2',
      '/qrTemplate3',
      '/qrTemplate4',
      '/signup/',
    ];

    return !excludedPaths.includes(location.pathname) && !excludedPrefixes.some(prefix => location.pathname.startsWith(prefix));
  }, [location.pathname]);

  return (
    <AuthProvider>
      <AxiosService />
      <TokenRefreshService />
      <ConfigProvider locale={locale}>
        {authenticatedRoute() && <AppNavBar />}
        <AppRoutes />
        {authenticatedRoute() && <AppFooter />}
      </ConfigProvider>
    </AuthProvider>
  );
}

export default App;
