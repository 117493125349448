/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Divider, message, Select } from 'antd';
import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import useUserInfo from '../../../hooks/useUserInfo';
import { useAppDispatch } from '../../../store/store';
import styles from './solutionSelect.module.css';
import { axiosInstance } from '../../../common/providers & services/AxiosService';
import { useQuery } from '@tanstack/react-query';
import LoadingIndicator from '../../../common/components/LoadingIndicator';
import { useParams } from 'react-router-dom';
import { FilledButton } from '../../../common/components/StyledComponents';

export interface Solution {
  user_id: string;
  solution_id: string;
  conference_id?: any;
  solution_name: string;
  solution_manager: string;
  solution_tel: string;
  solution_email: string;
  solution_badge: string;
  solution_homepage: string;
  solution_introduction: string;
  solution_refrence_1: string;
  solution_reference_2: string;
  solution_hashtags: string[];
}

const SolutionSelect = () => {
  // const mySolutionList: Solution[] = [
  //   {
  //     user_id: '100',
  //     solution_id: '200',
  //     solution_name: '넥슨게임즈 솔루션',
  //     solution_manager: '박지수',
  //     solution_tel: '010-2000-1234',
  //     solution_email: 'nexon@example.com',
  //     solution_badge: 'nx',
  //     solution_homepage: 'https://www.nexon.com/',
  //     solution_introduction: '넥슨게임즈와 함께 하는 게임의 새로운 세계!',
  //     solution_refrence_1: '인기 게임 타이틀 소개',
  //     solution_reference_2: '게이머 커뮤니티 참여 이벤트',
  //     solution_hashtags: ['넥슨게임즈', '게임', '엔터테인먼트'],
  //   },
  //   {
  //     user_id: '100',
  //     solution_id: '201',
  //     solution_name: '블루아카이브 솔루션',
  //     solution_manager: '김유진',
  //     solution_tel: '010-2010-5678',
  //     solution_email: 'blue_archive@example.com',
  //     solution_badge: 'ba',
  //     solution_homepage: 'https://www.bluearchive.com/',
  //     solution_introduction: '블루아카이브를 최대한 활용하는 솔루션!',
  //     solution_refrence_1: '게임플레이 최적화 전략',
  //     solution_reference_2: '캐릭터 티어 리스트 및 리뷰',
  //     solution_hashtags: ['블루아카이브', '모바일게임', '전략게임'],
  //   },
  //   {
  //     user_id: '100',
  //     solution_id: '202',
  //     solution_name: '아이유의 음악세계',
  //     solution_manager: '이지훈',
  //     solution_tel: '010-2020-9876',
  //     solution_email: 'iu_music@example.com',
  //     solution_badge: 'iu',
  //     solution_homepage: 'https://www.iu-music.com/',
  //     solution_introduction: '아이유의 음악의 매력에 빠져보세요!',
  //     solution_refrence_1: '아이유의 음반 강좌',
  //     solution_reference_2: '콘서트 경험과 리뷰',
  //     solution_hashtags: ['아이유', '음악', 'K팝', '엔터테인먼트'],
  //   },
  //   {
  //     user_id: '100',
  //     solution_id: '203',
  //     solution_name: '나의 아저씨 동반자',
  //     solution_manager: '장혜진',
  //     solution_tel: '010-2030-5432',
  //     solution_email: 'my_mister@example.com',
  //     solution_badge: 'mm',
  //     solution_homepage: 'https://www.mymister-companion.com/',
  //     solution_introduction: '나의 아저씨와 함께 하는 특별한 시간!',
  //     solution_refrence_1: '드라마 하이라이트 모음',
  //     solution_reference_2: '캐릭터 감상 및 리뷰',
  //     solution_hashtags: ['나의아저씨', '드라마', '감동'],
  //   },
  //   {
  //     user_id: '100',
  //     solution_id: '204',
  //     solution_name: '르브론 제임스 피트니스',
  //     solution_manager: 'LBJ',
  //     solution_tel: '010-2040-6543',
  //     solution_email: 'lebron_fitness@example.com',
  //     solution_badge: 'lj',
  //     solution_homepage: 'https://www.lebron-fitness.com/',
  //     solution_introduction: '르브론 제임스와 함께 하는 피트니스 여정!',
  //     solution_refrence_1: '르브론의 트레이닝 루틴 소개',
  //     solution_reference_2: '건강한 라이프스타일 팁',
  //     solution_hashtags: ['르브론제임스', '운동', '피트니스'],
  //   },
  //   {
  //     user_id: '100',
  //     solution_id: '205',
  //     solution_name: '스테픈커리',
  //     solution_manager: '스테프 매니저',
  //     solution_tel: '010-xxxx-xxxx',
  //     solution_email: 'step@example.com',
  //     solution_badge: '스텝',
  //     solution_homepage: 'https://www.step.com/',
  //     solution_introduction: '스테프에 대한 간단한 소개',
  //     solution_refrence_1: '참고사항 1',
  //     solution_reference_2: '참고사항 2',
  //     solution_hashtags: ['해시태그1', '해시태그2'],
  //   },
  //   {
  //     user_id: '100',
  //     solution_id: '206',
  //     solution_name: 'coldplay',
  //     solution_manager: 'Manager Name',
  //     solution_tel: '010-xxxx-xxxx',
  //     solution_email: 'email@example.com',
  //     solution_badge: 'badge',
  //     solution_homepage: 'https://www.homepage.com/',
  //     solution_introduction: 'Short intro for this solution',
  //     solution_refrence_1: 'Reference 1',
  //     solution_reference_2: 'Reference 2',
  //     solution_hashtags: ['tag1', 'tag2'],
  //   },
  //   {
  //     user_id: '100',
  //     solution_id: '207',
  //     solution_name: 'Apple Products Solution',
  //     solution_manager: 'Apple Manager',
  //     solution_tel: '010-1234-5678',
  //     solution_email: 'apple@example.com',
  //     solution_badge: 'AP',
  //     solution_homepage: 'https://www.apple.com/',
  //     solution_introduction: 'Enhancing life with innovative technology',
  //     solution_refrence_1: 'New product launches',
  //     solution_reference_2: 'User experience and reviews',
  //     solution_hashtags: ['Apple', 'Technology', 'Innovation'],
  //   },
  //   {
  //     user_id: '100',
  //     solution_id: '208',
  //     solution_name: 'AirPods Innovation',
  //     solution_manager: 'Audio Manager',
  //     solution_tel: '010-XXXX-XXXX',
  //     solution_email: 'audio@example.com',
  //     solution_badge: 'APD',
  //     solution_homepage: 'https://www.airpods-innovation.com/',
  //     solution_introduction: 'Revolutionizing portable sound experiences',
  //     solution_refrence_1: 'Sound quality advancements',
  //     solution_reference_2: 'User testimonials and feedback',
  //     solution_hashtags: ['AirPods', 'Audio', 'Innovation'],
  //   },
  //   {
  //     user_id: '100',
  //     solution_id: '209',
  //     solution_name: 'Plani Services',
  //     solution_manager: 'Plani Manager',
  //     solution_tel: '010-XXXX-XXXX',
  //     solution_email: 'plani@example.com',
  //     solution_badge: 'PLN',
  //     solution_homepage: 'https://www.plani-services.com/',
  //     solution_introduction: 'Your one-stop solution provider',
  //     solution_refrence_1: 'Service offerings and benefits',
  //     solution_reference_2: 'Client testimonials and case studies',
  //     solution_hashtags: ['Plani', 'Services', 'Solutions'],
  //   },
  //   {
  //     user_id: '100',
  //     solution_id: '210',
  //     solution_name: '고윤정 Management',
  //     solution_manager: 'Gyj Manager',
  //     solution_tel: '010-XXXX-XXXX',
  //     solution_email: 'gyj@example.com',
  //     solution_badge: 'GYJ',
  //     solution_homepage: 'https://www.gyj-management.com/',
  //     solution_introduction: 'Innovative management solutions',
  //     solution_refrence_1: 'Management approaches and services',
  //     solution_reference_2: 'Client success stories and testimonials',
  //     solution_hashtags: ['Management', 'Innovation', 'Solutions'],
  //   },
  //   {
  //     user_id: '100',
  //     solution_id: '211',
  //     solution_name: '죄와벌 Solutions',
  //     solution_manager: 'JWB Manager',
  //     solution_tel: '010-XXXX-XXXX',
  //     solution_email: 'jwb@example.com',
  //     solution_badge: 'JWB',
  //     solution_homepage: 'https://www.jwb-solutions.com/',
  //     solution_introduction: 'Bridging justice and innovation',
  //     solution_refrence_1: 'Legal strategies and services',
  //     solution_reference_2: 'Client testimonials and success stories',
  //     solution_hashtags: ['Justice', 'Innovation', 'Legal'],
  //   },
  //   {
  //     user_id: '100',
  //     solution_id: '212',
  //     solution_name: 'NextJS Innovations',
  //     solution_manager: 'NextJS Manager',
  //     solution_tel: '010-XXXX-XXXX',
  //     solution_email: 'nextjs@example.com',
  //     solution_badge: 'NextJS',
  //     solution_homepage: 'https://www.nextjs-innovations.com/',
  //     solution_introduction: 'Revolutionizing web development',
  //     solution_refrence_1: 'NextJS tools and strategies',
  //     solution_reference_2: 'Developer success stories and testimonials',
  //     solution_hashtags: ['NextJS', 'Web Development', 'Innovation'],
  //   },
  //   {
  //     user_id: '100',
  //     solution_id: '213',
  //     solution_name: 'Corsair Solutions',
  //     solution_manager: 'Corsair Manager',
  //     solution_tel: '010-XXXX-XXXX',
  //     solution_email: 'corsair@example.com',
  //     solution_badge: 'Corsair',
  //     solution_homepage: 'https://www.corsair-solutions.com/',
  //     solution_introduction: 'Navigating the digital seas with precision',
  //     solution_refrence_1: 'Cutting-edge tech and services',
  //     solution_reference_2: 'Client success stories and testimonials',
  //     solution_hashtags: ['Tech', 'Innovation', 'Digital'],
  //   },
  //   {
  //     user_id: '100',
  //     solution_id: '214',
  //     solution_name: 'Oracle Innovations',
  //     solution_manager: 'Oracle Manager',
  //     solution_tel: '010-XXXX-XXXX',
  //     solution_email: 'oracle@example.com',
  //     solution_badge: 'Oracle',
  //     solution_homepage: 'https://www.oracle-innovations.com/',
  //     solution_introduction: 'Reimagining technology for tomorrow',
  //     solution_refrence_1: 'Cutting-edge tech and services',
  //     solution_reference_2: 'Client success stories and testimonials',
  //     solution_hashtags: ['Technology', 'Innovation', 'Future'],
  //   },
  //   {
  //     user_id: '100',
  //     solution_id: '215',
  //     solution_name: 'Tokyo Innovations',
  //     solution_manager: 'Tokyo Manager',
  //     solution_tel: '010-XXXX-XXXX',
  //     solution_email: 'tokyo@example.com',
  //     solution_badge: 'Tokyo',
  //     solution_homepage: 'https://www.tokyo-innovations.com/',
  //     solution_introduction: 'Blending tradition with futuristic technology',
  //     solution_refrence_1: 'Cutting-edge tech and services',
  //     solution_reference_2: 'Client success stories and testimonials',
  //     solution_hashtags: ['Technology', 'Innovation', 'Cultural Fusion'],
  //   },
  //   {
  //     user_id: '100',
  //     solution_id: '216',
  //     solution_name: 'New York Solutions',
  //     solution_manager: 'NY Manager',
  //     solution_tel: '010-XXXX-XXXX',
  //     solution_email: 'ny@example.com',
  //     solution_badge: 'NY',
  //     solution_homepage: 'https://www.ny-solutions.com/',
  //     solution_introduction: 'City that never sleeps meets innovative solutions',
  //     solution_refrence_1: 'Urban innovation and technology',
  //     solution_reference_2: 'Client success stories and testimonials',
  //     solution_hashtags: ['Innovation', 'Tech', 'Urban'],
  //   },
  //   {
  //     user_id: '100',
  //     solution_id: '217',
  //     solution_name: '지란지교 Solutions',
  //     solution_manager: '지란지교 Manager',
  //     solution_tel: '010-XXXX-XXXX',
  //     solution_email: 'jiranjigyo@example.com',
  //     solution_badge: '지란지교',
  //     solution_homepage: 'https://www.jiranjigyo-solutions.com/',
  //     solution_introduction: '융합, 창의, 성공을 위한 혁신적인 솔루션',
  //     solution_refrence_1: '최신 기술 및 서비스',
  //     solution_reference_2: '고객 성공 사례 및 추천서',
  //     solution_hashtags: ['혁신', '기술', '성공'],
  //   },
  //   {
  //     user_id: '100',
  //     solution_id: '218',
  //     solution_name: '슈어소프트테크 Innovations',
  //     solution_manager: '슈어소프트테크 Manager',
  //     solution_tel: '010-XXXX-XXXX',
  //     solution_email: 'suresoft@example.com',
  //     solution_badge: '슈어소프트테크',
  //     solution_homepage: 'https://www.suresoft-innovations.com/',
  //     solution_introduction: '최신 기술을 활용한 혁신적인 솔루션',
  //     solution_refrence_1: '첨단 기술 및 서비스',
  //     solution_reference_2: '고객 성공 사례 및 추천서',
  //     solution_hashtags: ['기술', '혁신', '성공'],
  //   },
  //   {
  //     user_id: '100',
  //     solution_id: '219',
  //     solution_name: 'HL만도',
  //     solution_manager: 'HL만도 Manager',
  //     solution_tel: '010-XXXX-XXXX',
  //     solution_email: 'hlmando@example.com',
  //     solution_badge: 'HL만도',
  //     solution_homepage: 'https://hlmando.com/',
  //     solution_introduction: '첨단 기술로 산업을 선도합니다.',
  //     solution_refrence_1: '산업 분야 기술 솔루션',
  //     solution_reference_2: '고객 성공 사례 및 테스트모니얼',
  //     solution_hashtags: ['산업기술', '성공', '솔루션'],
  //   },
  //   {
  //     user_id: '100',
  //     solution_id: '220',
  //     solution_name: '텔레칩스',
  //     solution_manager: '텔레칩스 Manager',
  //     solution_tel: '010-XXXX-XXXX',
  //     solution_email: 'telechips@example.com',
  //     solution_badge: '텔레칩스',
  //     solution_homepage: 'https://www.telechips.com/',
  //     solution_introduction: '차세대 칩 솔루션을 선도합니다.',
  //     solution_refrence_1: '칩 기술 및 솔루션',
  //     solution_reference_2: '제품 홍보 및 테스트모니터링',
  //     solution_hashtags: ['칩솔루션', '차세대기술', '기술업계'],
  //   },
  //   {
  //     user_id: '100',
  //     solution_id: '221',
  //     solution_name: '지란지교시큐리티',
  //     solution_manager: '지란지교시큐리티 Manager',
  //     solution_tel: '010-XXXX-XXXX',
  //     solution_email: 'jiranjigyosec@example.com',
  //     solution_badge: '지란지교시큐리티',
  //     solution_homepage: 'https://www.jiranjigyosec.com/',
  //     solution_introduction: '보안 기술 및 솔루션을 제공합니다.',
  //     solution_refrence_1: '보안 솔루션 및 기술',
  //     solution_reference_2: '고객 사례 및 시큐리티 솔루션',
  //     solution_hashtags: ['보안솔루션', '기술', '시큐리티'],
  //   },
  //   {
  //     user_id: '100',
  //     solution_id: '222',
  //     solution_name: '모비젠',
  //     solution_manager: '모비젠 Manager',
  //     solution_tel: '010-XXXX-XXXX',
  //     solution_email: 'mobigen@example.com',
  //     solution_badge: '모비젠',
  //     solution_homepage: 'https://www.mobigen.com/',
  //     solution_introduction: '기업용 솔루션 및 데이터 제공합니다.',
  //     solution_refrence_1: '데이터 및 기술 솔루션',
  //     solution_reference_2: '고객 성공 사례 및 데이터 분석',
  //     solution_hashtags: ['기업솔루션', '데이터', '기술'],
  //   },
  // ];

  /*  */
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [messageApi, contextHolder] = message.useMessage();
  const userInfo = useUserInfo();
  const { id } = useParams();
  const [isChanged, setIsChanged] = useState(false);
  let defaultValue: any[] | null | undefined = [];

  const fetchData = async () => {
    try {
      const response = await axiosInstance.get(`companySolutions?company_setup_id=${userInfo.company_setup_id}`);
      return response.data.data.solutions;
    } catch (e) {
      console.error('error...');
      return {};
    }
  };

  const {
    data: mySolutionList,
    // data,
    isLoading,
    isError,
  } = useQuery(['companySolutions', userInfo.company_setup_id], fetchData, {
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    select: data => {
      return data.map((item: any, index: number) => {
        return {
          user_id: '100',
          solution_id: item.id,
          solution_name: item.name,
          solution_manager: item.person,
          conference_id: item.conferences?.map((i: any) => i.id),
          solution_tel: item.tel,
          solution_email: 'nexon@example.com',
          solution_badge: 'nx',
          solution_homepage: 'https://www.nexon.com/',
          solution_introduction: '넥슨게임즈와 함께 하는 게임의 새로운 세계!',
          solution_refrence_1: '인기 게임 타이틀 소개',
          solution_reference_2: '게이머 커뮤니티 참여 이벤트',
          solution_hashtags: ['넥슨게임즈', '게임', '엔터테인먼트'],
        };
      });
    },
  });

  const [selectedSolutionIds, setSelectedSolutionIds] = useState<string[]>([]);

  const handleSolutionChange = (selectedIds: string[]) => {
    console.log('handleSolutionChange selectedIds ===>', selectedIds);
    setIsChanged(true);
    setSelectedSolutionIds(selectedIds);
  };

  if (isLoading || mySolutionList === undefined) {
    return (<LoadingIndicator />);
  }

  const selectOptions = mySolutionList.map((solution: Solution) => ({
    label: solution.solution_name,
    value: solution.solution_id,
  }));

  defaultValue = mySolutionList
    .filter((item: any) => {
      return item.conference_id?.includes(id);
    })
    .map((item: any) => {
      return item.solution_id;
    });

  // useEffect(() => {
  //   // setSelectedSolutionIds([...])
  //   if(isLoading===false){
  //     setSelectedSolutionIds(defaultValue)
  //   }
  // }, [isLoading]);

  const handleSubmit = async () => {
    const isConfirmed = window.confirm('선택하신 솔루션을 등록하시겠습니까?');

    if (isConfirmed) {
      const selectedSolutions = mySolutionList.filter((solution: Solution) => selectedSolutionIds.includes(solution.solution_id));
      console.log('defaultValue', defaultValue);
      console.log('handleSubmit selectedSolutions ===>', selectedSolutions);

      messageApi.open({
        key: 'apiMsg',
        type: 'loading',
        content: '등록중...',
        duration: 0,
      });


      try {
        await axiosInstance.post('companySolutions', {
          selectedSolutions,
          conference_id: id,
          company_setup_id: userInfo.company_setup_id,
        });
        messageApi.open({
          key: 'apiMsg',
          type: 'success',
          content: '성공적으로 처리되었습니다.',
          duration: 1,
          onClose: () => {
            setIsChanged(false);
          },
        });
      } catch (e) {
        console.log(e);
        messageApi.open({
          key: 'apiMsg',
          type: 'error',
          content: `실패하였습니다. 지속되면 문의해주세요.`,
        });
      }

    } else {
      console.log('Submission cancelled.');
    }
  };

  return (
    <div className={styles.solution_select_container}>
      {contextHolder}
      <Divider className={styles.solution_select_divider} />
      <header className={styles.solution_select_header}>참가제품 등록</header>
      <div className={styles.wrap}>
        <Select
          mode='multiple'
          size='large'
          placeholder={<div className={styles.solution_select_placeholder}>행사에 등록 가능한 참가제품 리스트입니다.</div>}
          style={{ width: '70.5%' }}
          options={selectOptions}
          // value={selectedSolutionIds}
          defaultValue={defaultValue}
          onChange={handleSolutionChange}
        />

        {
          isChanged &&
          <FilledButton
            color={'dark-blue'}
            onClick={handleSubmit}
          >
            적용하기
          </FilledButton>
        }


      </div>
    </div>
  );
};

export default SolutionSelect;
