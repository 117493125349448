import React, { useState } from 'react';
import { Container, FilledButton, HTMLTable, SubTitle, Td, Th, Tr } from '../../../common/components/StyledComponents';
import { FlexRow } from '../../../common/components/FlexRow';
import styled from 'styled-components';
import { useFragment } from '../../../common/utils/apiHooks';
import { Certificate } from '../../../common/api/responseTypes';
import { CertificatesModal } from '../../../common/components/modals/CertificatesModal';

interface Props {
  conferenceId: string;
}

// todo - wtf is the modal for??
export const ConferenceInfoDetailCertificate: React.FC<Props> = ({ conferenceId }) => {
  const certificates = useFragment<Certificate[]>('certificates', conferenceId);
  const [modalOpen, setModalOpen] = useState(false);
  return (
    <>
      <Container gap={10}>
        <SubTitle>수료증 발급 기준</SubTitle>
        <Text>기본 조건 : 입장, 퇴장 NFT 획득</Text>
        <Text>수료증 발급 조건에 따라 행사 종료 후 수료증 NFT가 발급됩니다.</Text>

        <HTMLTable style={{ marginTop: '20px' }}>
          <tbody>
            <Tr>
              <Th>강연 NFT</Th>
              <Td>{certificates && certificates?.length > 0 ? `${certificates[0].criteria_lecture_count}개` : ''}</Td>
            </Tr>
            <Tr>
              <Th>참가사 방문 NFT</Th>
              <Td>{certificates && certificates?.length > 0 ? `${certificates[0].criteria_company_count}개` : ''}</Td>
            </Tr>
            <Tr>
              <Th>솔루션 체험 NFT</Th>
              <Td>{certificates && certificates?.length > 0 ? `${certificates[0].criteria_product_count}개` : ''}</Td>
            </Tr>
          </tbody>
        </HTMLTable>
        <FlexRow width={'100%'} justify={'flex-end'} gap={'8px'}>
          <FilledButton height={45} color={'charcoal'} onClick={() => setModalOpen(true)}>
            NFT발급현황 자세히 보기
          </FilledButton>
        </FlexRow>
      </Container>
      <CertificatesModal conferenceId={conferenceId} open={modalOpen} onClose={() => setModalOpen(false)} certificateId={''} />
    </>
  );
};

const Text = styled.div`
  font-size: 14px;
  letter-spacing: -0.28px;
  color: #444444;
`;
