import { ConferenceNftData, EventStatus, NftData } from '../api/responseTypes';
import moment from 'moment';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';

dayjs.extend(isBetween);

export const parseUrlParams = (params: Record<string, any>) => {
  return Object.keys(params)
    .map(key => {
      if (typeof params[key] === 'object') {
        parseUrlParams(params[key] as Record<string, any>);
      } else if (params[key] && params[key] !== 'all') {
        return `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`;
      } else return '';
    })
    .filter(p => !!p)
    .join('&');
};

export const eventStateToHumanString = (status: EventStatus) => {
  switch (status) {
    case 'progress':
      return '진행';
    case 'complete':
      return '종료';
    case 'prepare':
      return '대기';
  }
};

export const eventStateToHumanStringFromDate = (start: string | dayjs.Dayjs, end: string | dayjs.Dayjs) => {
  const currentTime = dayjs();
  const startTime = dayjs(start);
  const endTime = dayjs(end);

  let eventStatus: string;
  if (currentTime.isBefore(startTime)) {
    eventStatus = '대기';
  } else if (currentTime.isBetween(startTime, endTime)) {
    eventStatus = '진행';
  } else {
    eventStatus = '종료';
  }
  if (startTime.isSame(endTime)) {
    eventStatus = '종료';
  }

  return eventStatus;
};

export const eventDDay = (eventStart: string, eventEnd: string) => {
  const start = moment(eventStart);
  const end = moment(eventEnd);
  const now = moment();
  if (start.isAfter(now, 'day')) {
    return `D-${start.diff(now, 'days') + 1}`;
  }
  if (start.isSameOrBefore(now) && end.isSameOrAfter(now)) {
    return '00';
  }
  return `D+${now.diff(end, 'days') + 1}`;
};

export const formatDateString = (string: string) =>
  string.substring(0, string.indexOf(' ') > 0 ? string.indexOf(' ') : string.length).replaceAll('-', '.');

export const generateHexColour = (seed: number) => {
  const x = Math.abs(Math.sin(seed));
  return `#${Math.floor(x * 16777215).toString(16)}`;
};

export const calcEventStatus = (start: string, end: string): EventStatus => {
  const now = moment();
  const startDate = moment(start);
  if (now < startDate) return 'prepare';

  const endDate = moment(end);
  if (now >= startDate && now < endDate) return 'progress';
  return 'complete';
};

export const leftPad = (input: string, targetLength: number, pad: string): string => {
  if (input.length >= targetLength) return input;
  return leftPad(`${pad}${input}`, targetLength, pad);
};

export const calcRowNumber = (row: ConferenceNftData | NftData, i?: number) => {
  if (row.page) {
    return (row.page - 1) * row.perPage + ((i ?? 0) + 1);
  }
  return (i ?? 0) + 1;
};
export const generateRandomString = (length: number): string => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
};
