import { Button, notification } from 'antd';
import styles from './management.module.css';
import { useForm } from 'react-hook-form';
import { ChangeEvent, useState } from 'react';
import { Address, useDaumPostcodePopup } from 'react-daum-postcode';
import SubTabs from './components/SubTabs';
import Enrollment from './components/Enrollment';

export type Tabs = {
  id: number;
  key: string;
  title: string;
};

const tabMenu = [
  { id: 1, key: 'management', title: '관리' },
  { id: 2, key: 'detail', title: '상세보기' },
  { id: 3, key: 'enrollment', title: '등록하기' },
];

const Managements = () => {
  const [selected, setSelected] = useState(sessionStorage.getItem('selected_tab') ?? 'management');

  return (
    <section className={styles.container}>
      <SubTabs tabMenu={tabMenu} selected={selected} setSelected={setSelected} />
      {/* 선택된 하위 메뉴에 따라 하위 내용 상세 변경 */}
      <div>
        <h2>{tabMenu.map((tab: Tabs) => selected === tab.key && tab.title)}</h2>
        <h4>기본정보</h4>
        <>{selected === 'enrollment' && <Enrollment />}</>
      </div>
    </section>
  );
};

export default Managements;
