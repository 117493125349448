import React, { useState } from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import { ConferenceNftData } from '../api/responseTypes';
import { usePaginatedNftData } from '../utils/apiHooks';
import { ConferenceNftDataRequest } from '../api/requestTypes';

const rows = [5, 10, 25, 50, 100];

interface Props {
  columns: TableColumn<ConferenceNftData>[];
  type: number;
  conferenceId: string;
  params: ConferenceNftDataRequest;
}

export const RemoteTable: React.FC<Props> = ({ type, columns, conferenceId, params }) => {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(5);
  const data = usePaginatedNftData(conferenceId, type, page, perPage, params);

  return (
    <>
      {data && (
        <DataTable
          data={data.data.map(d => ({ ...d, page: data?.current_page, perPage: data?.per_page }))}
          columns={columns}
          pagination={true}
          paginationServer={true}
          paginationTotalRows={data.total}
          onChangePage={setPage}
          onChangeRowsPerPage={(newPerPage, currentPage) => {
            setPerPage(newPerPage);
            setPage(currentPage);
          }}
          noDataComponent={'데이터가 없습니다.'}
          paginationComponentOptions={{ rowsPerPageText: '페이지당 행 수' }}
          paginationPerPage={5}
          paginationRowsPerPageOptions={rows}
        />
      )}
    </>
  );
};
