import DefaultLayer from './panels/defaultLayer';
import { Spin } from 'antd';

type Props = {
  msg?: string;
};

const LoadingIndicator = ({ msg = 'Loading...' }: Props) => {
  return (
    <DefaultLayer open={true}>
      <Spin tip={msg} size={'large'}>
        <div style={{ padding: '5rem', background: 'rgba(0,0,0,0.05)', borderRadius: '4px' }}></div>
      </Spin>
    </DefaultLayer>
  );
};

export default LoadingIndicator;
