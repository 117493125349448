import { Button, Result } from 'antd';
import { useNavigate } from 'react-router-dom';

import styles from './notFound.module.css';

const NotFound = () => {
  const navigate = useNavigate();
  return (
    <div className={styles.wrap}>
      <Result
        status='404'
        title='올바르지 않은 접근'
        subTitle='잘못된 접근입니다. 홈으로 가서 다시 시도해주세요.'
        extra={
          <Button
            type='primary'
            onClick={() => {
              navigate('/home');
            }}
          >
            홈으로
          </Button>
        }
      />
    </div>
  );
};

export default NotFound;
