import { useNavigate } from 'react-router-dom';
import styles from './signupNav.module.css';

const SignupNav = () => {
  const navigate = useNavigate();

  return (
    <nav className={styles.nav}>
      <ul>
        <li>
          <h1 onClick={() => navigate('/')} />
        </li>
        <li>
          <button onClick={() => navigate('/')}>로그인</button>
        </li>
      </ul>
    </nav>
  );
};

export default SignupNav;
