import React from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import { FlexRow } from '../../../common/components/FlexRow';
import styled from 'styled-components';
import { ExcelButton } from '../../../common/components/ExcelButton';

export const Table = <D extends { id: string | number }>({
  data,
  columns,
  onRowClick,
  rows,
  filename,
  excelData,
}: Props<D>): JSX.Element => {
  const getRowsPerPageOptions = (): number[] => {
    const val = [10, 25, 50, 100];
    if (rows) {
      val.unshift(rows);
    }
    return val;
  };
  return (
    <>
      <FlexRow justify={'space-between'} width={'100%'} style={{ borderBottom: '2px solid black', padding: '7px 0' }}>
        <TableInfo>
          등록된 행사 리스트 총 <b>{data.length} </b>건
        </TableInfo>
        <ExcelButton data={excelData ?? data} filename={filename} />
      </FlexRow>
      <DataTable
        className={`data-table ${onRowClick ? 'clickable' : ''}`}
        columns={columns}
        data={data}
        pagination={true}
        paginationComponentOptions={{ rowsPerPageText: '페이지당 행 수' }}
        onRowClicked={onRowClick}
        noDataComponent={'데이터가 없습니다.'}
        paginationPerPage={rows}
        paginationRowsPerPageOptions={getRowsPerPageOptions()}
      />
    </>
  );
};

interface Props<D extends { id: string | number }> {
  data: Array<D>;
  columns: TableColumn<D>[];
  onRowClick?: (row: D, e: React.MouseEvent) => void;
  rows?: number;
  filename: string;
  excelData?: unknown[];
}

const TableInfo = styled.div`
  color: #787878;
  font-size: 15px;
`;
