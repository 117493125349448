import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import styles from './subtabs.module.css';
import { Tabs } from '../Managements';

const SubTabs = ({
  tabMenu,
  selected,
  setSelected,
}: {
  tabMenu: Tabs[];
  selected: string;
  setSelected: Dispatch<SetStateAction<string>>;
}) => {
  const handleSelectedTab = (selected: string) => {
    setSelected(selected);
    sessionStorage.setItem('selected_tab', selected);
  };
  // console.log(selected);
  // console.log(intro);

  useEffect(() => {
    const selectedTab = sessionStorage.getItem('selected_tab');
    if (selectedTab && selected !== selectedTab) setSelected(selectedTab);
  }, []);

  return (
    <div className={styles.tabs}>
      {tabMenu.map((tab: Tabs) => (
        <button key={tab.id} data-select={selected === tab.key && 'selected'} onClick={() => handleSelectedTab(tab.key)}>
          {tab.title}
        </button>
      ))}
    </div>
  );
};

export default SubTabs;
