/* eslint-disable no-prototype-builtins */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useState } from 'react';
import { Button, Col, Divider, Image, Input, message, Row, Upload } from 'antd';
import { Controller, SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import validator from 'validator';
import _ from 'lodash';

import styles from './conferenceSolutionForm.module.css';
import { useNavigate, useParams } from 'react-router-dom';
import FormAlert from '../../../common/components/FormAlert';
import { axiosInstance } from '../../../common/providers & services/AxiosService';
import useUserInfo from '../../../hooks/useUserInfo';
import { useQueryClient } from '@tanstack/react-query';
import { handleTelInput } from '../../../lib/function';

type Props = {
  conferenceId: string;
  onCancle: () => void;
};
type Inputs = {
  name: string;
  person: string;
  tel: string;
  email: string;
  homepage: string;
  description: string;
  reference: string;
  hashes: { hash: string }[];
};

const ConferenceSolutionForm = ({
                                  conferenceId,
                                  onCancle = () => {
                                    history.back();
                                  },
                                }: Props) => {
  const navigate = useNavigate();
  const modifiedMode = false;
  const defaultValues: Partial<Inputs> = {
    hashes: [{ hash: '' }],
  };
  const isValidWebsite = (value: string | null) => {
    if (_.isEmpty(value)) {
      return true;
    }
    return validator.isURL(value as string, { require_protocol: true });
  };
  const {
    register,
    watch,
    handleSubmit,
    control,
    setError,
    clearErrors,
    setValue,
    setFocus,
    reset,
    getValues,
    formState: { errors, isValid },
  } = useForm<Inputs>({
    // mode: 'onChange',
    defaultValues,
  });

  // 해시태그
  const {
    fields: hashFields,
    append: hashAppend,
    remove: hashRemove,
  } = useFieldArray({
    control,
    name: 'hashes',
  });

  const [messageApi, contextHolder] = message.useMessage();
  const { id } = useParams();
  const userInfo = useUserInfo();
  const queryClient = useQueryClient();

  const [fileLists, setFileLists] = useState<any[]>([[], [], [], []]);

  const isImage = (file: File) => {
    // 파일의 MIME 타입을 확인하여 이미지인지 확인
    return file.type.startsWith('image/');
  };

  const handleBeforeUpload = (file: File, index: number, onlyImage: boolean) => {
    if (!isImage(file) && onlyImage) {
      message.error('이미지 파일만 업로드 가능합니다.');
    }
    const newFileLists = [...fileLists];
    newFileLists[index] = [file];
    setFileLists(newFileLists);

    return false;
  };

  const handleFileChange = (fileList: any[], index: number) => {
    const newFileLists = [...fileLists];
    newFileLists[index] = fileList;
    setFileLists(newFileLists);
  };

  const renderUpload = (index: number, onlyImage: boolean) => {
    const uploadProps = {
      beforeUpload: (file: File) => handleBeforeUpload(file, index, onlyImage),
      onChange: ({ fileList }: { fileList: any[] }) => handleFileChange(fileList, index),
      fileList: fileLists[index],
    };

    const blob = new Blob([fileLists[index][0]?.originFileObj], { type: fileLists[index][0]?.originFileObj?.type });
    const thumNail = fileLists[index][0]?.url || (fileLists[index].length > 0 ? URL.createObjectURL(blob) : '');

    return (
      <>
        <Upload {...uploadProps}>
          <Button tabIndex={-1}>파일등록</Button>
        </Upload>
        {thumNail && <Image src={thumNail} width={200} />}
      </>
    );
  };

  const handleTelChange = (tel: string) => {
    const afterTel = handleTelInput(tel);

    if (afterTel === false) {
      return false;
    }

    setValue('tel', afterTel);
  };
  const validEmail = (value: string) => {
    if (/^\S+@\S+\.\S+$/.test(value)) return true;
    return '이메일 형식이 맞지 않습니다.';
  };

  const submitAction: SubmitHandler<Inputs> = async data => {
    console.log('data', data);

    const submitData = {
      conference_id: id,
      user_id: userInfo.id,
      name: data.name,
      person: data.person,
      tel: data.tel,
      email: data.email,
      homepage: data.homepage,
      description: data.description,
      hash: JSON.stringify(data.hashes.map((item: any) => item.hash)),
      conference_badge: fileLists[0][0] ? new Blob([fileLists[0][0]?.originFileObj], { type: fileLists[0][0]?.originFileObj.type }) : '',
      reference1: fileLists[1][0] ? new Blob([fileLists[1][0]?.originFileObj], { type: fileLists[1][0]?.originFileObj.type }) : '',
      reference2: fileLists[2][0] ? new Blob([fileLists[2][0]?.originFileObj], { type: fileLists[2][0]?.originFileObj.type }) : '',
    };

    // FormData 객체를 생성합니다.
    const formData = new FormData();

    // submitData의 각 속성을 FormData에 추가합니다.
    for (const key in submitData) {
      if (submitData.hasOwnProperty(key)) {
        // Blob인 경우에만 파일 데이터로 추가합니다.
        //@ts-ignore
        if (submitData[key] instanceof Blob) {
          //@ts-ignore
          // formData.append(key, submitData[key], `${key}.${getFileExtensionFromBlob(submitData[key])}`); // 파일 데이터 추가
        } else {
          //@ts-ignore
          formData.append(key, submitData[key] as string); // 일반 데이터 추가
        }
      }
    }

    if (fileLists[0][0]?.originFileObj)
      formData.append(
        'conference_badge',
        new Blob([fileLists[0][0]?.originFileObj], { type: fileLists[0][0]?.originFileObj.type }),
        fileLists[0][0].name,
      );
    if (fileLists[1][0]?.originFileObj)
      formData.append(
        'reference1',
        new Blob([fileLists[1][0]?.originFileObj], { type: fileLists[1][0]?.originFileObj.type }),
        fileLists[1][0].name,
      );
    if (fileLists[2][0]?.originFileObj)
      formData.append(
        'reference2',
        new Blob([fileLists[2][0]?.originFileObj], { type: fileLists[2][0]?.originFileObj.type }),
        fileLists[2][0].name,
      );

    console.log('submitData', submitData);
    // return false;

    try {
      messageApi.open({
        key: 'apiMsg',
        type: 'loading',
        content: modifiedMode ? '수정중...' : '등록중...',
        duration: 0,
      });

      await axiosInstance.post('solutions', formData, { headers: { 'Content-Type': 'multipart/form-data' } });
      messageApi.open({
        key: 'apiMsg',
        type: 'success',
        content: modifiedMode ? '성공적으로 수정되었습니다.' : '성공적으로 등록되었습니다.',
        onClose: () => {
          queryClient.invalidateQueries(['conferenceCompanyList', id]);
          onCancle();
        },
      });
    } catch (e) {
      console.error(`솔루션${modifiedMode ? '수정' : '등록'} 에러`, e);
      messageApi.open({
        key: 'apiMsg',
        type: 'error',
        content: `${modifiedMode ? '수정' : '등록'}에 실패하였습니다. 지속되면 문의해주세요.`,
      });
    }
  };

  console.log('errors', errors);
  return (
    <section className={styles.wrap}>
      {contextHolder}
      <h2>솔루션 등록하기</h2>
      <h4>기본정보</h4>
      <form className={styles.formWrap} onSubmit={handleSubmit(submitAction)}>
        <Divider style={{ borderWidth: '2px', borderColor: '#555' }} />
        <Row align={'middle'}>
          <Col span={4}>
            <span>솔루션명</span>
          </Col>
          <Col span={20}>
            <Controller
              control={control}
              name={'name'}
              rules={{ required: '필수입니다.' }}
              render={({ field }) => <Input {...field} placeholder={'솔루션명을 입력해주세요.'} />}
            />
            {errors.name?.message && <FormAlert msg={errors.name.message} />}
          </Col>
        </Row>
        <Divider />
        <Row align={'middle'}>
          <Col span={4}>
            <span>담당자</span>
          </Col>
          <Col span={20}>
            <Controller
              control={control}
              name={'person'}
              rules={{ required: '필수입니다.' }}
              render={({ field }) => <Input {...field} placeholder={'담당자명을 입력해주세요.'} />}
            />
            {errors.person?.message && <FormAlert msg={errors.person.message} />}
          </Col>
        </Row>
        <Divider />
        <Row align={'middle'}>
          <Col span={4}>
            <span>문의 연락처</span>
          </Col>
          <Col span={20}>
            <Controller
              control={control}
              name={'tel'}
              rules={{
                required: '연락처를 입력해주세요.',
              }}
              render={({ field }) => (
                <Input
                  {...field}
                  placeholder={'문의 연락처를 입력해주세요.'}
                  onChange={e => {
                    handleTelChange(e.target.value);
                  }}
                />
              )}
            />
            {errors.tel?.message && <FormAlert msg={errors.tel.message} />}
          </Col>
        </Row>
        <Divider />
        <Row align={'middle'}>
          <Col span={4}>
            <span>문의 이메일</span>
          </Col>
          <Col span={20}>
            <Controller
              control={control}
              name={'email'}
              rules={{
                required: '이메일을 입력해주세요.',
                validate: validEmail,
              }}
              render={({ field }) => <Input {...field} placeholder={'문의 이메일을 입력해주세요.'} />}
            />
            {errors.email?.message && <FormAlert msg={errors.email.message} />}
          </Col>
        </Row>
        <Divider />
        <Row align={'middle'}>
          <Col span={4}>
            <span>솔루션 배지</span>
          </Col>
          <Col span={6}>
            {renderUpload(0, true)}
            <Row align={'middle'}>
              <small>배지 이미지는 600*600 사이즈를 권장합니다.</small>
            </Row>
          </Col>
        </Row>
        <Divider />
        <Row align={'middle'}>
          <Col span={4}>
            <span>솔루션 홈페이지</span>
          </Col>
          <Col span={20}>
            <Controller
              control={control}
              name={'homepage'}
              rules={{
                validate: {
                  validWebsite: (value: string | null) => isValidWebsite(value) || '유효한 홈페이지 주소 형식이 아닙니다',
                },
              }}
              render={({ field }) => <Input {...field} placeholder={'솔루션 홈페이지를 입력해주세요.'} />}
            />
            {errors.homepage?.message && <FormAlert msg={errors.homepage.message} />}
          </Col>
        </Row>
        <Divider />
        <Row align={'middle'}>
          <Col span={4}>
            <span>솔루션 소개</span>
          </Col>
          <Col span={20}>
            <Controller
              control={control}
              name={'description'}
              rules={{ required: '필수입니다.' }}
              render={({ field }) => (
                <Input.TextArea style={{ resize: 'none' }} {...field} rows={5} placeholder={'솔루션 소개를 입력해주세요.'} />
              )}
            />
            {errors.description?.message && <FormAlert msg={errors.description.message} />}
          </Col>
        </Row>
        <Divider />
        <Row align={'middle'}>
          <Col span={4}>
            <span>소개자료 1</span>
          </Col>
          <Col span={6}>{renderUpload(1, false)}</Col>
        </Row>
        <Divider />
        <Row align={'middle'}>
          <Col span={4}>
            <span>소개자료 2</span>
          </Col>

          <Col span={6}>{renderUpload(2, false)}</Col>
        </Row>
        <Divider />
        <Row align={'middle'}>
          <Col span={4}>
            <span>해시태그</span>
          </Col>
          <Col span={20}>
            {getValues('hashes').map((hash, index) => {
              const style = index > 0 ? { marginTop: '15px' } : {};
              return (
                <Row align={'middle'} gutter={10} key={index} style={{ ...style }}>
                  <Col span={20}>
                    <Controller
                      name={`hashes.${index}.hash`}
                      control={control}
                      defaultValue={hash.hash}
                      rules={{ required: '필수입니다.', minLength: 2 }}
                      render={({ field }) => (
                        <Input
                          {...field}
                          placeholder='해시태그를 입력해주세요.'
                          onChange={e => {
                            field.onChange(e);
                            setValue(`hashes.${index}.hash`, e.target.value);
                          }}
                        />
                      )}
                    />

                    {
                      //@ts-ignore
                      errors?.hashes?.[index]?.hash?.message && <FormAlert msg={errors.hashes[index].hash.message} />
                    }
                  </Col>
                  <Col span={2} onClick={() => hashAppend({ hash: '' })}>
                    <Button>추가</Button>
                  </Col>
                  {index > 0 && (
                    <Col span={2} onClick={() => hashRemove(index)}>
                      <Button>삭제</Button>
                    </Col>
                  )}
                </Row>
              );
            })}
          </Col>
        </Row>
        <Divider />
        <Divider style={{ borderWidth: '2px', borderColor: '#555' }} />
        <Row justify={'end'} gutter={20}>
          <Col>
            <Button type={'primary'} htmlType={'submit'} size={'large'}>
              {modifiedMode ? '수정하기' : '등록하기'}
            </Button>
          </Col>

          <Col>
            <Button size={'large'} onClick={onCancle}>
              뒤로가기
            </Button>
          </Col>
        </Row>
      </form>
    </section>
  );
};

export default ConferenceSolutionForm;
