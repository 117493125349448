import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { Button, Input, notification } from 'antd';
import { LockOutlined } from '@ant-design/icons';
import _ from 'lodash';
import axios from 'axios';

import FormAlert from '../../common/components/FormAlert';

import styles from './changePassword.module.css';

const baseUrl = `${process.env.REACT_APP_API_URL}/api/v1/`;

type Inputs = {
  password: string;
  passwordCheck: string;
};

const ChangePassword = () => {
  const [api, contextHolder] = notification.useNotification();
  const navigate = useNavigate();

  const defaultValues: Inputs = {
    password: '',
    passwordCheck: '',
  };
  const {
    handleSubmit,
    control,
    watch,
    reset,
    formState: { errors },
  } = useForm<Inputs>({ defaultValues });

  // UseTo: token 검증.
  const { token } = useParams();

  const hasValidPassword = (value: string) => {
    const hasMinimumLength = value.length >= 8;
    const hasEnglishLetter = /[a-zA-Z]/.test(value);
    const hasNumber = /\d/.test(value);
    if (!(hasMinimumLength && hasEnglishLetter && hasNumber)) {
      return '비밀번호는 영문+숫자 8자리 이상으로 해주세요';
    }
    return true;
  };

  const submitAction: SubmitHandler<Inputs> = async data => {
    // const networkStatusResult = _.random(1);

    let networkStatusResult = _.random(1);
    try {
      await axios.post(`${baseUrl}changePassword`, { ...data }, { headers: { Authorization: `Bearer ${token}` } });
      networkStatusResult = 1;
    } catch (error) {
      console.log('Axios error', error);
      networkStatusResult = 0;
    }

    // 완료 or 오류 문구 처리
    if (networkStatusResult > 0) {
      api.destroy('errorMsg');
      api.success({
        message: '비밀번호 변경 완료',
        description: (
          <>
            <p>비밀번호 면경이 완료되었어요.</p>
          </>
        ),
        duration: 3,
        placement: 'top',
        style: {
          minWidth: 500,
        },
        key: 'successMsg',
        onClose: () => {
          navigate('/');
          reset({
            password: '',
            passwordCheck: '',
          });
        },
      });
    } else {
      api.destroy('successMsg');
      api.error({
        message: '비밀번호 변경 실패',
        description: (
          <>
            <p>비밀번호 변경 중 문제가 발생하였어요.</p>
            <p>지속되면 문의주세요.</p>
          </>
        ),
        duration: 3,
        placement: 'top',
        style: {
          minWidth: 500,
        },
        key: 'errorMsg',
      });
    }
  };

  const watchedPassword = watch('password', '');

  return (
    <section className={styles.wrap}>
      {contextHolder}
      <form className={styles.formWrap} onSubmit={handleSubmit(submitAction)}>
        <div className={'f-20-m'}>JiranSecurity</div>
        <span className={styles.logo}>PRUUF</span>

        <div className={styles.des}>
          <h1>프루프 시스템에서 사용할 새로운 비밀번호를 입력해주세요.</h1>
          <span>영문+숫자 8자 이상</span>
        </div>

        <label>
          새 비밀번호
          <Controller
            control={control}
            name={'password'}
            rules={{
              required: '비밀번호를 입력해주세요.',
              validate: hasValidPassword,
            }}
            render={({ field }) => (
              <Input.Password prefix={<LockOutlined />} {...field} placeholder={'새 비밀번호를 입력하세요'} size={'large'} />
            )}
          />
          {errors.password && errors.password.message && <FormAlert msg={errors.password.message} />}
        </label>

        <label>
          새 비밀번호 확인
          <Controller
            control={control}
            name={'passwordCheck'}
            rules={{
              required: true,
              validate: value => value === watchedPassword || '비밀번호와 같지 않습니다.',
            }}
            render={({ field }) => (
              <Input.Password prefix={<LockOutlined />} {...field} placeholder={'다시 한번 입력해주세요'} size={'large'} />
            )}
          />
          {errors.passwordCheck && errors.passwordCheck.message && <FormAlert msg={errors.passwordCheck.message} />}
        </label>
        <Button size={'large'} htmlType={'submit'} type={'primary'}>
          비밀번호 변경하기
        </Button>
      </form>
    </section>
  );
};

export default ChangePassword;
