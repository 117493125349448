import dayjs from 'dayjs';
import styles from '../solutionInfoDetail.module.css';
import { HTMLTable, SubTitle, Td, Th, Tr } from '../../../common/components/StyledComponents';
import { makeTelNumberHypen } from '../../../lib/function';

type Props = {
  infoData: any;
};

const SolutionDetailPopup = ({ infoData }: Props) => {
  console.log(infoData)
  return (
    <section className={styles.wrap}>
      <SubTitle marginBottom={-20}>기본정보</SubTitle>
      <HTMLTable>
        <tbody>
        <Tr>
          <Th>솔루션</Th>
          <Td>{infoData.name}</Td>
        </Tr>
        <Tr>
          <Th>참가사</Th>
          <Td>{infoData.company?.name}</Td>
        </Tr>
        <Tr>
          <Th>담당자</Th>
          <Td>{infoData.person}</Td>
        </Tr>
        <Tr>
          <Th>문의 연락처</Th>
          <Td>{makeTelNumberHypen(infoData.tel)}</Td>
        </Tr>
        <Tr>
          <Th>문의 이메일</Th>
          <Td>{infoData.email}</Td>
        </Tr>
        <Tr>
          <Th>솔루션 홈페이지</Th>
          <Td>
            {infoData.homepage && (
              <a target={'_blank'} href={infoData.homepage} rel='noreferrer'>
                {infoData.homepage}
              </a>
            )}
          </Td>
        </Tr>
        <Tr>
          <Th>솔루션 소개</Th>
          <Td>{infoData.description}</Td>
        </Tr>
        <Tr>
          <Th>해시태그</Th>
          <Td>{infoData.hash ? JSON.parse(infoData.hash) : ''}</Td>
        </Tr>
        <Tr>
          <Th>소개자료</Th>
          <Td>{infoData.reference}</Td>
        </Tr>

        <Tr>
          <Th>등록일</Th>
          <Td>{dayjs(infoData.created_at).format('YYYY-MM-DD')}</Td>
        </Tr>
        </tbody>
      </HTMLTable>
    </section>
  );
};

export default SolutionDetailPopup;
