import React, { useEffect, useState } from 'react';
import { FilterLabel, Input } from '../StyledComponents';
import { FlexRow } from '../FlexRow';
import { useDebounce } from 'use-debounce';

interface Props {
  onChange: (val: string) => void;
  placeholder: string;
  debounce?: number;
  label?: boolean;
  filterLabelText?: string;
  text?: string;
}

export const SearchFilter: React.FC<Props> = ({ onChange, placeholder, debounce, label = true, text = '', filterLabelText = '검색' }) => {
  const [value, setValue] = useState(text);
  const [debouncedValue] = useDebounce(value, debounce ?? 500);

  useEffect(() => {
    onChange(debouncedValue);
  }, [debouncedValue]);

  if (label === false) {
    return <Input placeholder={placeholder} width={400} value={value} onChange={e => setValue(e.target.value)} />;
  }
  return (
    <FlexRow gap={'5px'} justify={'flex-start'}>
      <FilterLabel>{filterLabelText}</FilterLabel>
      <Input placeholder={placeholder} width={400} value={value} onChange={e => setValue(e.target.value)} />
    </FlexRow>
  );
};
