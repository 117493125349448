import React, { useState } from 'react';
// import { Table } from './Table';
import { useQuery } from '@tanstack/react-query';
import { TableColumn } from 'react-data-table-component';
import { axiosInstance } from '../../../common/providers & services/AxiosService';
import { Container, FilterBoxContainer, SubTitle } from '../../../common/components/StyledComponents';
import DefaultLayer from '../../../common/components/panels/defaultLayer';
import DefaultPopupPanel from '../../../common/components/panels/defaultPopupPanel';
import QrTemplate from '../../conference-info/components/QrTemplate';
import PruufReport from '../../conference-info/components/PruufReport';
import SolutionDetailPopup from '../../solution-info/components/SolutionDetailPopup';
import { SearchFilter } from '../../../common/components/filters/SearchFilter';
import { Table } from '../../../common/components/Table';
import useUserInfo from '../../../hooks/useUserInfo';
import {useDetailedConference} from "../../../common/utils/apiHooks";
import { Link } from 'react-router-dom';

const initialState = {
  searchTerm: '',
  searchKey: 'name',
};

type LayerType = 'qr' | 'pruuf' | 'solutionDetail';

interface Props {
  conferenceId: string;
}
export const ConferenceInfoDetailProducts: React.FC<Props> = ({ conferenceId }) => {
  const [layerPopupOpen, setLayerPopupOpen] = useState(false);
  const [filterParams, setFilterParams] = useState(initialState);
  const [layerType, setLayerType] = useState<LayerType>('qr');
  const [layerValue, setLayerValue] = useState<string>('');
  const [templateValue, setTemplateValue] = useState<any>({});
  const [solutionInfo, setSolutionInfo] = useState<any>({});

  const conference = useDetailedConference(conferenceId);
  console.log('--conference--', conference)

  const userInfo = useUserInfo();
  console.log('--userInfo--',userInfo)

  const handleRowClick = (row: any) => {
    if (userInfo.company_setup_id === row.company.id || userInfo.type === 'admin') {
      setLayerPopupOpen(true);
      setLayerType('solutionDetail');
      setSolutionInfo({ ...row });
    }
  };

  const fetchData = async () => {
    try {
      
      // const response = await axiosInstance.get(`conferences/solution?conference_id=2`);
      const response = await axiosInstance.get(`conferences/solution?conference_id=${conferenceId}`);
      return response.data.data.solutions;
    } catch (e) {
      console.error('error...');
      return [{}];
    }
  };

  const columns: TableColumn<any>[] = [
    {
      name: '번호',
      selector: row => row.listIdx,
      sortable: true,
      grow: 0,
    },
    {
      name: '참가제품',
      selector: row => row.name,
      sortable: true,
      grow: 1,      
    },
    {
      name: '회사명',
      selector: row => row.company.name,
      sortable: true,
    },
    {
      name: '연락처',
      selector: row => row.tel,
      sortable: true,
    },
    // {
    //   name: '이메일',
    //   selector: row => row.email,
    //   sortable: true,
    // },
    // {
    //   name: '홈페이지',
    //   selector: row => row.homepage,
    //   sortable: true,
    // },
    // {
    //   name: '부스번호',
    //   selector: row => row.conference_registration?.booth,
    //   sortable: true,
    // },
    {
      name: 'QR코드',
      sortable: true,
      cell: row => {
        const handleQRpopup = (id: string) => {
          setLayerPopupOpen(true);
          setLayerType('qr');
          const baseUrl = `${process.env.REACT_APP_API_URL}/api/v1/`;
          const getInfos = {
            type: 'solution',
            company_setup_id: userInfo.company_setup_id,
            conference_id: conferenceId,
            solution_id: row.id
          };
    
          const templateData = {
            conference: conference?.conference,
            company_setup: userInfo?.company_setup,
            company_setup_id: userInfo?.company_setup_id,
            booth: conference?.conference_registrations?.find((r: any) => r.company_setup_id === userInfo?.company_setup_id)?.booth ?? '',
            solution: row,
          };
    
          setTemplateValue(templateData);
          const searchParams = new URLSearchParams(getInfos);
          setLayerValue(`${baseUrl}pruufCollect?${searchParams}`);
        };
    
        // 로그인한 사용자의 회사와 현재 제품의 회사가 같은 경우에만 QR 코드를 표시합니다.
        const loggedInUserCompanyId = userInfo.company_setup_id;
        const productCompanyId = row.company.id;
        if (loggedInUserCompanyId === productCompanyId || userInfo.type === 'admin' || userInfo.type === 'host') {
          return (
            <span style={{ textDecoration: 'underline' }} onClick={() => handleQRpopup(row.id)}>
              다운로드
            </span>
          );
        } else {
          return null; // 다른 경우에는 아무것도 반환하지 않습니다.
        }
      },
    },
    {
      name: '발급된 PRUUF',
      cell: row => {
        const handlePRUUFpopup = (id: string) => {
          setLayerPopupOpen(true);
          setLayerType('pruuf');
          setLayerValue(row.company_setup_id);
        };
    
        // 로그인한 사용자의 회사와 현재 제품의 회사가 같은 경우에만 발급된 PRUUF를 표시합니다.
        const loggedInUserCompanyId = userInfo.company_setup_id;
        const productCompanyId = row.company.id;
        if (loggedInUserCompanyId === productCompanyId || userInfo.type === 'admin' || userInfo.type === 'host') {
          return (
            <span style={{ textDecoration: 'underline' }} onClick={() => handlePRUUFpopup(row.id)}>
              {row.pruufs?.length ?? 0}
            </span>
          );
        } else {
          return null; // 다른 경우에는 아무것도 반환하지 않습니다.
        }

        
      },
      sortable: true,
    },
    
  ];

  const {
    data: mySolutionList,
    isLoading,
    isError,
  } = useQuery(['mySolutionList', 1], fetchData, {
    refetchOnWindowFocus: false,
    select: data => {
      const filteredData = data.filter((item: any) => {
        const { searchTerm } = filterParams;

        if (searchTerm && !item.name.includes(searchTerm)) {
          return false;
        }

        return true;
      });

      return filteredData.map((item: any, index: number) => {
        return {
          ...item,
          listIdx: filteredData.length - index,
        };
      });
    },
  });

  // const excelData = useMemo(() => {
  //   if (mySolutionList?.length < 1 || mySolutionList === undefined) return [];
  //   return mySolutionList.map((item: any, index: number) => {
  //     return {
  //       번호: mySolutionList.length - index,
  //       참가사행사명: item?.conference?.name_kor,
  //       참가제품: item.name,
  //       참가사명: item.company.name,
  //       부스번호: item?.conference_registration?.booth,
  //       발급된PRUUF: item.pruufs.length,
  //       등록일: item.created_at,
  //     };
  //   });
  // }, [mySolutionList]);

  if (isLoading || isError) return <></>;

  return (
    <Container gap={25}>
      {layerPopupOpen && (
        <DefaultLayer open={layerPopupOpen}>
          <DefaultPopupPanel
            actionClose={() => {
              setLayerPopupOpen(false);
            }}
            title={layerType === 'qr' ? 'QR코드 템플릿' : layerType === 'pruuf' ? '참가제품 PRUUF 발급 현황' : '참가제품 상세보기'}
          >
            <>
              {layerType === 'qr' &&
                <QrTemplate link={layerValue} templateInfo={templateValue} templateType={'solution'} />}
              {layerType === 'pruuf' && <PruufReport listType={'solution'} company_setup_id={layerValue} />}
              {layerType === 'solutionDetail' && <SolutionDetailPopup infoData={solutionInfo} />}
            </>
          </DefaultPopupPanel>
        </DefaultLayer>
      )}
      <SubTitle>솔루션 검색</SubTitle>
      <FilterBoxContainer>
        <SearchFilter
          onChange={searchTerm => setFilterParams(prev => ({ ...prev, searchTerm }))}
          placeholder={'검색어를 입력해주세요.'}
          debounce={150}
        />
      </FilterBoxContainer>

      <Table
        data={mySolutionList}
        columns={columns}
        filename={`${mySolutionList[0]?.conference?.name_kor || ''}_참가제품`}
        onRowClick={handleRowClick}
        showExcel={false}
        // excelData={excelData}
      />
    </Container>
  );
};

export default ConferenceInfoDetailProducts;
