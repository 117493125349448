import { message } from 'antd';
import { useParams } from 'react-router-dom';
import { HTMLTable, SubTitle, Td, Th, Tr } from '../../common/components/StyledComponents';
import VisitorPruufInfo from './components/VisitorPruufInfo';

import styles from './visitorInfoDetail.module.css';
import { axiosInstance } from '../../common/providers & services/AxiosService';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';

const snsOauthTypetoText = (type: string) => {
  let text = '';
  switch (type) {
    case 'kakao':
      text = '카카오';
      break;
    case 'naver':
      text = '네이버';
      break;

    case 'google':
      text = '구글';
      break;
    case 'apple':
      text = '애플';
      break;
    default:
      text = '기타';
      break;
  }

  return text;
};

const VisitorInfoDetail = () => {
  const { id } = useParams();

  //TODO: 참가자 정보 조회하기...
  const fetchData = async () => {
    try {
      const response = await axiosInstance.get(`visitorInfo/${id}`);
      return response.data.data.userInfo;
    } catch (e) {
      console.error('error...');
      return [{}];
    }
  };

  const { data, isLoading, isError } = useQuery(['userDetail', id], fetchData, {
    staleTime: 5000,
    refetchOnWindowFocus: false,
  });

  const handleResetPassword = (e?: React.MouseEvent<HTMLElement>) => {
    console.log(e);
    message.success('Click on Yes');
  };

  const cancel = (e?: React.MouseEvent<HTMLElement>) => {
    console.log(e);
    message.error('Click on No');
  };

  if (isLoading || isError) {
    return <></>;
  }

  return (
    <section className={styles.wrap}>
      <SubTitle marginBottom={-20}>기본정보</SubTitle>
      <HTMLTable>
        <tbody>
        <Tr>
          <Th>참가자</Th>
          <Td>{data.name}</Td>
        </Tr>
        <Tr>
          <Th>소속</Th>
          {/*<Td>{data.company_setup?.name ?? data.company}</Td>*/}
          <Td>{data?.company}</Td>
        </Tr>

        <Tr>
          <Th>직급</Th>
          <Td>{data.position}</Td>
        </Tr>
        <Tr>
          <Th>부서</Th>
          <Td>{data.department}</Td>
        </Tr>
        <Tr>
          <Th>연락처</Th>
          <Td>{data.tel}</Td>
        </Tr>
        <Tr>
          <Th>가입방법</Th>
          <Td>{snsOauthTypetoText(data.sns_oauth.type)}</Td>
        </Tr>
        {/* <Tr>
            <Th>마케팅동의</Th>
            <Td>동의</Td>
          </Tr> */}

        <Tr>
          <Th>가입일</Th>
          <Td>{dayjs(data.created_at).format('YYYY-MM-DD')}</Td>
        </Tr>
        </tbody>
      </HTMLTable>
      <SubTitle marginBottom={-20}>PRUUF 발급현황</SubTitle>
      {/* TODO: 참가자 PRUUF 발급 현황 처리하기... */}
      <VisitorPruufInfo userId={id} />
    </section>
  );
};

export default VisitorInfoDetail;
