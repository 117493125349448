import { TableColumn } from 'react-data-table-component';
import { useNavigate, useParams } from 'react-router-dom';

import { FilledButton, HTMLTable, OutlineButton, SubTitle, Td, Th, Tr } from '../../common/components/StyledComponents';

import styles from './eventDetail.module.css';
import { FlexRow } from '../../common/components/FlexRow';
import { message, Popconfirm } from 'antd';
import { axiosInstance } from '../../common/providers & services/AxiosService';
import { appRoutes } from '../../AppRoutes';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import LoadingIndicator from '../../common/components/LoadingIndicator';
import { ConferenceStatusIndicator } from '../conference-info/components/ConferenceStatusIndicator';
import React from 'react';

const typeOptions = [
  { value: 'notice', label: '알림' },
  { value: 'random', label: '돌발' },
];

const pruufOptions = [
  { value: '랜덤', label: '랜덤' },
  { value: '선착순', label: '선착순' },
  // { value: '지정', label: '지정' },
];

const pruuf_type_text = {
  '랜덤': 'random',
  '선착순': 'target',
  '지정': 'order',
};

function getKeyByValue(object: Record<string, string>, value: string): string | undefined {
  return Object.keys(object).find(key => object[key] === value);
}

const EventDetail = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [messageApi, contextHolder] = message.useMessage();

  const columns: TableColumn<any>[] = [
    {
      name: '번호',
      selector: row => row.conference.name_kor,
      grow: 1,
    },
    {
      name: '이름',
      selector: row => row.conference.name_kor,
      grow: 1,
    },
    {
      name: '발급일시',
      selector: row => row.conference.name_kor,
      grow: 1,
    },
    {
      name: '소속',
      selector: row => row.conference.name_kor,
      grow: 1,
    },
    {
      name: '직급',
      selector: row => row.conference.name_kor,
      grow: 1,
    },
    {
      name: '부서',
      selector: row => row.conference.name_kor,
      grow: 1,
    },
    {
      name: 'PRUUF 보기',
      selector: row => row.conference.name_kor,
      grow: 1,
    },
  ];

  const fetchData = async () => {
    try {
      const response = await axiosInstance.get(`event/${id}`);
      return response.data.data.eventInfo;
    } catch (e) {
      console.error('error...');
      return {};
    }
  };

  const { data, isLoading, isError } = useQuery(['eventDetail', id], fetchData, {
    refetchOnWindowFocus: false,
  });

  //TODO: 당첨자 목록 표기
  const handleDelete = async () => {
    try {
      await axiosInstance.post(`eventDelete`, { event_id: id });
      history.back();
    } catch (e) {
      console.error(e);
      messageApi.open({
        type: 'error',
        content: '삭제에 실패했습니다. 지속되면 문의해주세요.',
      });
    }
  };

  if (isLoading || isError) {
    return <LoadingIndicator />;
  }

  return (
    <section className={styles.wrap}>
      {contextHolder}
      <SubTitle marginBottom={-20}>기본정보</SubTitle>
      <HTMLTable>
        <tbody>
        <Tr>
          <Th>이벤트명</Th>
          <Td>{data.name}</Td>
        </Tr>

        <Tr>
          <Th>이벤트 기간</Th>
          <Td>{`${dayjs(data.start_time).format('YYYY.MM.DD')} ~ ${dayjs(data.end_time).format('YYYY.MM.DD')}`}</Td>
        </Tr>

        <Tr>
          <Th>진행 상태</Th>
          <Td><ConferenceStatusIndicator start={data.start_time} end={data.end_time} status={'complete'} /></Td>
        </Tr>

        {
          data.noticeUse &&
          <Tr>
            <Th>공지사항</Th>
            <Td>{data.notice}</Td>
          </Tr>
        }


        <Tr>
          <Th>이벤트 내용</Th>
          <Td>{data.introduction}</Td>
        </Tr>

        <Tr>
          <Th>당첨 내용</Th>
          <Td>{data.prize}</Td>
        </Tr>

        <Tr>
          <Th>당첨자 수</Th>
          <Td>{data.winner}</Td>
        </Tr>

        <Tr>
          <Th>이벤트종류</Th>
          <Td>{typeOptions.find(option => option.value === data.event_type)?.label}</Td>
        </Tr>

        {
          data.pruuf > 0 &&

          <>
            <Tr>
              <Th>Pruuf 발행조건</Th>
              <Td>{getKeyByValue(pruuf_type_text, data.pruuf_type)}</Td>
            </Tr>

            <Tr>
              <Th>Pruuf 발행갯수</Th>
              <Td>{data.pruuf_count}</Td>
            </Tr>
          </>
        }



        </tbody>
      </HTMLTable>

      {/*<Table columns={columns} data={[]} filename={`주최사 정보`} listname={''} showExcel={false} />*/}

      <FlexRow justify={'flex-end'} gap={'1rem'} width={'100%'}>
        <FilledButton color={'dark-blue'}
                      onClick={() => navigate(`${appRoutes.conferenceInfo.route}/${data.conference_id}#events`)}>
          이벤트 목록
        </FilledButton>

        {data.id && (
          <OutlineButton height={35}
                         onClick={() => navigate(`${appRoutes.conferenceInfo.children.eventDetailForm.base}/${data.id}`)}>
            수정하기
          </OutlineButton>
        )}

        <OutlineButton height={35}>
          <Popconfirm
            title='이벤트 삭제'
            description='정말로 이벤트를 삭제하시겠습니까?'
            okText='삭제'
            cancelText='취소'
            onConfirm={handleDelete}
          >
            삭제하기
          </Popconfirm>
        </OutlineButton>
      </FlexRow>
    </section>
  );
};

export default EventDetail;
