import React from 'react';

export const PrivacyPolicy: React.FC = () => {
  return (
    <div style={{ padding: '20px 10px' }}>
      <p>
        지란지교 시큐리티는 「개인정보 보호법」 제30조에 따라 정보주체의 개인정보를 보호하고 이와 관련한 고충을 신속하고 원활하게 처리할 수
        있도록 하기 위하여 다음과 같이 개인정보 처리방침을 수립·공개합니다.
      </p>
      <p>○ 이 개인정보처리방침은 2023년 5월 1부터 적용됩니다.</p>
      <br />
      <p>
        <strong>
          제1조(개인정보의 처리 목적)
          <br />
          지란지교 시큐리티는 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고 있는 개인정보는 다음의 목적 이외의 용도로는 이용되지
          않으며 이용 목적이 변경되는 경우에는 「개인정보 보호법」 제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.
        </strong>
      </p>
      <ul>
        <p>1. 재화 또는 서비스 제공</p>
        <p>서비스 제공을 목적으로 개인정보를 처리합니다.</p>
      </ul>
      <p>
        <strong>제2조(개인정보의 처리 및 보유 기간)</strong>
        <br />
        ① 지란지교 시큐리티는 법령에 따른 개인정보 보유·이용기간 또는 정보주체로부터 개인정보를 수집 시에 동의받은 개인정보 보유·이용기간
        내에서 개인정보를 처리·보유합니다.
        <br />② 각각의 개인정보 처리 및 보유 기간은 다음과 같습니다.
      </p>
      <ul>
        <li>1. 재화 또는 서비스 제공</li>
        <li>
          {`<재화 또는 서비스 제공>`}와 관련한 개인정보는 수집.이용에 관한 동의일로부터 NFT 지갑에서 처리되는 개인정보는 필요한 범위
          내에서만 보유하며, 처리목적이 달성된 후에는 즉시 파기합니다.
        </li>
        <li>
          보유근거 : NFT 지갑에서 처리되는 개인정보는 이용자의 동의를 받은 경우, 또는 법적 의무를 수행하기 위해 필요한 경우 등 개인정보 보호
          법령에서 규정하는 경우를 제외하고는 수집하거나 이용하지 않습니다.
        </li>
      </ul>
      <br />
      <br />
      <p>
        <strong>제3조(처리하는 개인정보의 항목) </strong>
        <br />① 지란지교 시큐리티는 다음의 개인정보 항목을 처리하고 있습니다.
      </p>
      <ul>
        <li>재화 또는 서비스 제공</li>
        <li>필수항목 : 지갑주소</li>
      </ul>
      <br />
      <br />
      <p>
        <strong>제4조(개인정보의 제3자 제공에 관한 사항)</strong>
        <br /> ① 지란지교 시큐리티는 개인정보를 제1조(개인정보의 처리 목적)에서 명시한 범위 내에서만 처리하며, 정보주체의 동의, 법률의
        특별한 규정 등 「개인정보 보호법」 제17조 및 제18조에 해당하는 경우에만 개인정보를 제3자에게 제공합니다.
      </p>
      <p>
        ② <span>지란지교 시큐리티</span>는 다음과 같이 개인정보를 제3자에게 제공하고 있습니다.
      </p>
      <ul>
        <li>1. Wepin</li>
        <li>개인정보를 제공받는 자 : Wepin</li>
        <li>제공받는 자의 개인정보 이용목적 : 지갑주소</li>
      </ul>
      <br />
      <br />
      <p>
        <strong>제5조(개인정보처리의 위탁에 관한 사항)</strong>
        <br /> ① 지란지교 시큐리티는 원활한 개인정보 업무처리를 위하여 다음과 같이 개인정보 처리업무를 위탁하고 있습니다.
      </p>
      <ul>
        <li>위탁받는 자 (수탁자) : ioTrust</li>
        <li>위탁하는 업무의 내용 : 소셜 계정을 통한 회원가입, 지갑 생성</li>
        <li>위탁기간 : 영구</li>
      </ul>
      <p>
        ② <span>지란지교 시큐리티</span>는 위탁계약 체결시 「개인정보 보호법」 제26조에 따라 위탁업무 수행목적 외 개인정보 처리금지,
        기술적․관리적 보호조치, 재위탁 제한, 수탁자에 대한 관리․감독, 손해배상 등 책임에 관한 사항을 계약서 등 문서에 명시하고, 수탁자가
        개인정보를 안전하게 처리하는지를 감독하고 있습니다.
      </p>
      <p>③ 위탁업무의 내용이나 수탁자가 변경될 경우에는 지체없이 본 개인정보 처리방침을 통하여 공개하도록 하겠습니다.</p>
      <br />
      <br />
      <p>
        <strong>제6조(개인정보의 파기절차 및 파기방법)</strong>
      </p>
      <p>
        <br />
        ① 지란지교 시큐리티 는 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체없이 해당 개인정보를
        파기합니다.
        <br />
        ② 개인정보 파기의 절차 및 방법은 다음과 같습니다.
        <br />
        1. 파기절차
        <br /> 지란지교 시큐리티 는 파기 사유가 발생한 개인정보를 선정하고, 지란지교 시큐리티 의 개인정보 보호책임자의 승인을 받아
        개인정보를 파기합니다.
        <br />
      </p>
      <p>2. 파기방법</p>
      <p>전자적 파일 형태의 정보는 기록을 재생할 수 없는 기술적 방법을 사용합니다</p>
      <br />
      <br />
      <p>
        <strong>제7조(정보주체와 법정대리인의 권리·의무 및 그 행사방법에 관한 사항)</strong>
      </p>
      <p>
        <br />
        <br />① 정보주체는 지란지교 시큐리티에 대해 언제든지 개인정보 열람·정정·삭제·처리정지 요구 등의 권리를 행사할 수 있습니다.
      </p>
      <p>
        ② 제1항에 따른 권리 행사는지란지교 시큐리티에 대해 「개인정보 보호법」 시행령 제41조제1항에 따라 서면, 전자우편, 모사전송(FAX) 등을
        통하여 하실 수 있으며 지란지교 시큐리티는 이에 대해 지체 없이 조치하겠습니다.
      </p>
      <p>
        ③ 제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다.이 경우 “개인정보 처리
        방법에 관한 고시(제2020-7호)” 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.
      </p>
      <p>
        ④ 개인정보 열람 및 처리정지 요구는 「개인정보 보호법」 제35조 제4항, 제37조 제2항에 의하여 정보주체의 권리가 제한 될 수 있습니다.
      </p>
      <p>⑤ 개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.</p>
      <p>
        ⑥ 지란지교 시큐리티는 정보주체 권리에 따른 열람의 요구, 정정·삭제의 요구, 처리정지의 요구 시 열람 등 요구를 한 자가 본인이거나
        정당한 대리인인지를 확인합니다.
      </p>
      <br />
      <p>
        <strong>
          제8조(개인정보의 안전성 확보조치에 관한 사항)
          <br />
          지란지교 시큐리티는 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다.
        </strong>
      </p>
      <p>
        1. 개인정보에 대한 접근 제한
        <br /> 개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의 부여,변경,말소를 통하여 개인정보에 대한 접근통제를 위하여 필요한
        조치를 하고 있으며 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있습니다.
        <br />
        2. 접속기록의 보관 및 위변조 방지
        <br /> 개인정보처리시스템에 접속한 기록을 최소 1년 이상 보관, 관리하고 있으며,다만, 5만명 이상의 정보주체에 관하여 개인정보를
        추가하거나, 고유식별정보 또는 민감정보를 처리하는 경우에는 2년이상 보관, 관리하고 있습니다.
        <br />
        또한, 접속기록이 위변조 및 도난, 분실되지 않도록 보안기능을 사용하고 있습니다.
        <br />
        3. 개인정보의 암호화
        <br /> 이용자의 개인정보는 비밀번호는 암호화 되어 저장 및 관리되고 있어, 본인만이 알 수 있으며 중요한 데이터는 파일 및 전송 데이터를
        암호화 하거나 파일 잠금 기능을 사용하는 등의 별도 보안기능을 사용하고 있습니다.
        <br />
        4. 비인가자에 대한 출입 통제
        <br /> 개인정보를 보관하고 있는 물리적 보관 장소를 별도로 두고 이에 대해 출입통제 절차를 수립, 운영하고 있습니다.
        <br />
      </p>
      <br />
      <p>
        <strong>제9조(개인정보를 자동으로 수집하는 장치의 설치·운영 및 그 거부에 관한 사항)</strong>
      </p>
      <p>
        <br />
        <br />
        지란지교 시큐리티 는 정보주체의 이용정보를 저장하고 수시로 불러오는 ‘쿠키(cookie)’를 사용하지 않습니다.
        <br />
        <br />
        <p></p>
        <p></p>
        <br />
        <p>
          <strong>제10조(정보주체의 권익침해에 대한 구제방법)</strong>
        </p>
        <br />
        <br />
        정보주체는 개인정보침해로 인한 구제를 받기 위하여 개인정보분쟁조정위원회, 한국인터넷진흥원 개인정보침해신고센터 등에 분쟁해결이나
        상담 등을 신청할 수 있습니다. 이 밖에 기타 개인정보침해의 신고, 상담에 대하여는 아래의 기관에 문의하시기 바랍니다.
        <br />
        <br />
        1. 개인정보분쟁조정위원회 : (국번없이) 1833-6972 (www.kopico.go.kr)
        <br />
        2. 개인정보침해신고센터 : (국번없이) 118 (privacy.kisa.or.kr)
        <br />
        3. 대검찰청 : (국번없이) 1301 (www.spo.go.kr)
        <br />
        4. 경찰청 : (국번없이) 182 (ecrm.cyber.go.kr)
        <br />
        <br />
        <br />
        「개인정보보호법」제35조(개인정보의 열람), 제36조(개인정보의 정정·삭제), 제37조(개인정보의 처리정지 등)의 규정에 의한 요구에 대 하여
        공공기관의 장이 행한 처분 또는 부작위로 인하여 권리 또는 이익의 침해를 받은 자는 행정심판법이 정하는 바에 따라 행정심판을 청구할 수
        있습니다.
        <br />
        <br />
        <br />
        ※ 행정심판에 대해 자세한 사항은 중앙행정심판위원회(www.simpan.go.kr) 홈페이지를 참고하시기 바랍니다.
        <br />
        <br />
        <p>
          <strong>제11조(개인정보 처리방침 변경)</strong>
        </p>
        <br />
      </p>
      <p>① 이 개인정보처리방침은 2023년 5월 1부터 적용됩니다.</p>
    </div>
  );
};
