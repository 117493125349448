import React, { startTransition, useEffect, useState } from 'react';
import {
  FilterBoxContainer,
  FilterLabel,
  Input,
  OutlineButton,
  SelectWrapper,
  SubTitle,
  Title,
} from '../../common/components/StyledComponents';
import { FlexRow } from '../../common/components/FlexRow';
import styled from 'styled-components';
import DataTable, { TableColumn } from 'react-data-table-component';
import { getAllNftData } from '../../common/api/nftClient';
import { NftData } from '../../common/api/responseTypes';
import { NftDataRequest } from '../../common/api/requestTypes';
import { useAllConferences, useNftTypes, useOrganizers } from '../../common/utils/apiHooks';
import { useDebounce } from 'use-debounce';
import { calcRowNumber } from '../../common/utils/utils';

const initialState: NftDataRequest = {
  search: '',
  organizer: '',
  type: '',
  generation: '',
  location: '',
  conference: '',
};
const rows = [5, 10, 25, 50, 100];

export const NftStatus: React.FC = () => {
  const conferences = useAllConferences();
  const organizers = useOrganizers();
  const nftTypes = useNftTypes();
  const [data, setData] = useState<NftData[]>([]);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [params, setParams] = useState<NftDataRequest>(initialState);
  const [debouncedParams] = useDebounce(params, 400);

  useEffect(() => {
    fetchData(1);
  }, [debouncedParams]);

  const fetchData = async (page: number, newPerPage?: number) => {
    startTransition(() => {
      getAllNftData(page, newPerPage ?? perPage, params).then(res => {
        setTotalRows(res.total);
        setData(res.data.map(d => ({ ...d, page, perPage: newPerPage ?? perPage })));
      });
    });
  };

  const handleRowsPerPageChange = async (newPerPage: number, page: number) => {
    if (newPerPage !== perPage) {
      fetchData(page, newPerPage);
      setPerPage(newPerPage);
    }
  };

  return (
    <>
      <Container>
        <Title>NFT 발급현황</Title>
        <SubTitle>NFT 정보</SubTitle>
        <FilterBoxContainer>
          <FlexRow gap={'5px'}>
            <FilterLabel>검색</FilterLabel>
            <Input
              width={400}
              placeholder={'이름'}
              value={params.search}
              onChange={e => setParams(prev => ({ ...prev, search: e.target.value }))}
            />
          </FlexRow>

          <FlexRow gap={'5px'} justify={'flex-start'}>
            <FilterLabel>NFT 구분</FilterLabel>
            <SelectWrapper>
              <select value={params.type} onChange={e => setParams(prev => ({ ...prev, type: e.target.value }))}>
                <option value={'all'}>전체</option>
                {nftTypes.map(type => (
                  <option key={`nft_type_${type.id}`} value={type.id}>
                    {type.name}
                  </option>
                ))}
              </select>
            </SelectWrapper>

            <FlexRow gap={'30px'} style={{ padding: '0 30px' }}>
              <FlexRow gap={'15px'}>
                <FilterLabel>발급대상 지역</FilterLabel>
                <SelectWrapper>
                  <select value={params.location} onChange={e => setParams(prev => ({ ...prev, location: e.target.value }))}>
                    <option>전국</option>
                  </select>
                </SelectWrapper>
              </FlexRow>
              <FlexRow gap={'15px'}>
                <FilterLabel>발급대상 연령대</FilterLabel>
                <SelectWrapper>
                  <select value={params.generation} onChange={e => setParams(prev => ({ ...prev, generation: e.target.value }))}>
                    <option>전체</option>
                    <option value={'10'}>10 대</option>
                    <option value={'20'}>20 대</option>
                    <option value={'30'}>30 대</option>
                    <option value={'40'}>40 대</option>
                    <option value={'50'}>50 대</option>
                    <option value={'60'}>60 대</option>
                    <option value={'70'}>70 대</option>
                    <option value={'80'}>80 대</option>
                  </select>
                </SelectWrapper>
              </FlexRow>
            </FlexRow>
          </FlexRow>

          <FlexRow gap={'5px'}>
            <FilterLabel>행사</FilterLabel>
            <SelectWrapper>
              <select value={params.conference} onChange={e => setParams(prev => ({ ...prev, conference: e.target.value }))}>
                <option>전체</option>
                {conferences.map(c => (
                  <option key={`conference_${c.id}`} value={c.id}>
                    {c.name}
                  </option>
                ))}
              </select>
            </SelectWrapper>

            <FilterLabel style={{ marginLeft: '80px' }}>주최사</FilterLabel>
            <SelectWrapper>
              <select value={params.organizer} onChange={e => setParams(prev => ({ ...prev, organizer: e.target.value }))}>
                <option>전체</option>
                {organizers.map(o => (
                  <option key={`organizer${o.id}`} value={o.id}>
                    {o.name}
                  </option>
                ))}
              </select>
            </SelectWrapper>
          </FlexRow>

          <FlexRow gap={'5px'} justify={'space-between'} width={'100%'}>
            <FlexRow gap={'5px'} justify={'flex-start'}>
              <FilterLabel>참석일자</FilterLabel>
              <Input
                type={'date'}
                width={125}
                value={params.start}
                onChange={e => setParams(prev => ({ ...prev, start: e.target.value }))}
              />
              <div>~</div>
              <Input type={'date'} width={125} value={params.end} onChange={e => setParams(prev => ({ ...prev, end: e.target.value }))} />
            </FlexRow>
            <OutlineButton height={45} style={{ marginLeft: 'auto' }} onClick={() => setParams(initialState)}>
              초기화
            </OutlineButton>
          </FlexRow>
        </FilterBoxContainer>

        <DataTable
          data={data}
          columns={columns}
          pagination={true}
          paginationServer={true}
          paginationTotalRows={totalRows}
          onChangePage={page => fetchData(page)}
          onChangeRowsPerPage={handleRowsPerPageChange}
          noDataComponent={'데이터가 없습니다.'}
          paginationComponentOptions={{ rowsPerPageText: '페이지당 행 수' }}
          paginationPerPage={10}
          paginationRowsPerPageOptions={rows}
        />
      </Container>
    </>
  );
};

const Container = styled.div`
  width: 1200px;
  height: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  padding-top: 50px;
  padding-bottom: 20px;
  gap: 30px;
`;

const columns: TableColumn<NftData>[] = [
  {
    name: '번호',
    cell: (row, rowIndex) => calcRowNumber(row, rowIndex),
    sortable: true,
    grow: 0,
  },
  {
    name: '발급일시',
    selector: row => row.created_at,
    sortable: true,
  },
  {
    name: 'NFT 구분',
    selector: row => row.type.name,
    sortable: true,
  },
  {
    name: '행사',
    selector: row => row.name,
    sortable: true,
    grow: 1,
  },
  {
    name: '주최사',
    selector: row => row.conference.organizer.name,
    sortable: true,
  },
  {
    name: '연령대',
    selector: row => row.visitor.generation,
    sortable: true,
  },
];
