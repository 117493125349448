import { RootState } from '../store';
import { createSelector } from '@reduxjs/toolkit';
import { EventStatus } from '../../common/api/responseTypes';
import { filterDateRange, searchFilter } from '../../common/utils/dataFilters';

export const selectorAllConferences = createSelector(
  [
    (state: RootState) => state.conference.conferences,
    (state: RootState, searchTerm?: string) => searchTerm,
    (state: RootState, searchTerm?: string, fromDate?: string) => fromDate,
    (state: RootState, searchTerm?: string, fromDate?: string, toDate?: string) => toDate,
    (state: RootState, searchTerm?: string, fromDate?: string, toDate?: string, status?: EventStatus) => status,
  ],
  (conferences, searchTerm, fromDate, toDate, status) => {
    return conferences.filter(
      conf =>
        (searchFilter(['name_kor'], searchTerm ?? '', conf) || searchFilter(['name_eng'], searchTerm ?? '', conf)) &&
        filterDateRange(fromDate ?? '', toDate ?? '', [{ date: conf.start_time }, { date: conf.end_time }]),
    );
  },
);

export const selectorAllOrganizers = (state: RootState) => state.conference.organizers;

export const selectorPrepareConferences = createSelector([(state: RootState) => state.conference.conferences], conferences =>
  conferences.filter(c => c.state === 'prepare'),
);

export const selectorProcessConferences = createSelector([(state: RootState) => state.conference.conferences], conferences =>
  conferences.filter(c => c.state === 'prepare'),
);

export const selectorCompleteConferences = createSelector([(state: RootState) => state.conference.conferences], conferences =>
  conferences.filter(c => c.state === 'prepare'),
);

export const selectorDetailedConference = createSelector(
  [(state: RootState) => state.conference.detailedConferences, (state: RootState, id: string) => id],
  (conferences, id) => conferences.find(c => c.conference.id === id),
);

export const selectorConferenceName = createSelector(
  [(state: RootState) => state.conference.conferences, (state: RootState, id?: string) => id],
  (conferences, id) => conferences.find(c => c.id === id),
);
