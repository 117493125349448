import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { appRoutes } from '../../AppRoutes';
import { Table } from '../../common/components/Table';
import { TableColumn } from 'react-data-table-component';
import { FilterBoxContainer, SubTitle, Title } from '../../common/components/StyledComponents';
// import { ConferenceStatusIndicator } from './components/ConferenceStatusIndicator';
import { SearchFilter } from '../../common/components/filters/SearchFilter';
import { axiosInstance } from '../../common/providers & services/AxiosService';
import { useQuery } from '@tanstack/react-query';
import DefaultLayer from '../../common/components/panels/defaultLayer';
import DefaultPopupPanel from '../../common/components/panels/defaultPopupPanel';
import PruufReport from '../conference-info/components/PruufReport';
import QrTemplate from '../conference-info/components/QrTemplate';

const initialState = {
  searchTerm: '',
  searchCompany: '',
  searchTel: '',
  start: '',
  end: '',
  status: 'all',
};
type LayerType = 'qr' | 'pruuf';

// 검색할 특수 문자를 제거하는 함수
function removeSpecialChars(str: string) {
  return str.replace(/[^\w\s]/gi, ''); // 특수 문자 제거
}

const VisitorInfo = () => {
  const navigate = useNavigate();
  const [layerPopupOpen, setLayerPopupOpen] = useState(false);
  const [filterParams, setFilterParams] = useState(initialState);
  const [layerType, setLayerType] = useState<LayerType>('qr');
  const [layerValue, setLayerValue] = useState<string>('');
  const [templateValue, setTemplateValue] = useState<any>({});

  const fetchData = async () => {
    try {
      const response = await axiosInstance.get('conferences/user');
      return response.data.data.users;
    } catch (e) {
      console.error('error...');
      return [{}];
    }
  };

  const columns: TableColumn<any>[] = [
    {
      name: '번호',
      selector: row => row.listIdx,
      sortable: true,
      grow: 0,
    },
    {
      name: '이름',
      selector: row => row.userName,
      sortable: true,
    },
    {
      name: '소속',
      selector: row => row.companyName,
      sortable: true,
    },
    {
      name: '직급',
      selector: row => row.userPosition,
      sortable: true,
    },
    {
      name: '부서',
      selector: row => row.userDepartment,
      sortable: true,
    },
    {
      name: '발급된 PRUUF',
      // selector: row => row.userPruufs.length,
      cell: row => {
        const handleQRpopup = (id: string) => {
          setLayerPopupOpen(true);
          setLayerType('pruuf');
          setLayerValue(row.user_id);
        };
        return (
          <span style={{ textDecoration: 'underline' }} onClick={() => handleQRpopup(row.id)}>
            {row.userPruufs.length}
          </span>
        );
      },
      sortable: true,
    },
  ];

  const {
    data: conferenceUser,
    isLoading,
    isError,
  } = useQuery(['UsersList'], fetchData, {
    refetchOnWindowFocus: false,
    select: data => {
      const newArray: any = [];
      // JSON 데이터를 순회하면서 필요한 정보 추출
      // data.forEach((item: any) => {
      //   const companyName = item.company_setup.name;
      //   item.company_setup.users.forEach((user: any) => {
      //     const userInfo = {
      //       companyName: companyName,
      //       userName: user.name,
      //       userPosition: user.position,
      //       userEmail: user.email,
      //       userTel: user.tel,
      //       userDepartment: user.department,
      //       userPruufs: user.pruufs,
      //       company_setup_id: user.company_setup,
      //       user_id: user.id,
      //     };
      //     newArray.push(userInfo);
      //   });
      // });

      data.forEach((item: any) => {
        const companyName = item.company_setup?.name;
        const userInfo = {
          companyName: companyName,
          userName: item.name,
          userPosition: item.position,
          userEmail: item.email,
          userTel: item.tel,
          userDepartment: item.department,
          userPruufs: item.pruufs,
          company_setup_id: item.company_setup,
          user_id: item.id,
        };
        newArray.push(userInfo);
      });


      // 중복 제거를 위한 Map을 사용한 필터링
      const uniqueUserInfoArray = Array.from(
        newArray
          .reduce((map: any, userInfo: any) => {
            map.set(userInfo.user_id, userInfo);
            return map;
          }, new Map())
          .values(),
      );

      const filteredData = uniqueUserInfoArray.filter((item: any) => {
        const { searchTerm, searchCompany, searchTel } = filterParams;

        if (searchTerm && !item.userName.includes(searchTerm)) {
          return false;
        }

        if (searchCompany && !item.companyName.includes(searchCompany)) {
          return false;
        }
        const sanitizedUserTel = removeSpecialChars(item.userTel);
        const sanitizedSearchTel = removeSpecialChars(searchTel);

        if (sanitizedSearchTel && !sanitizedUserTel.includes(sanitizedSearchTel)) {
          return false;
        }

        return true;
      });
      // return filteredData;
      return filteredData.map((item: any, index: number) => {
        return {
          ...item,
          listIdx: filteredData.length - index,
        };
      });
    },
  });

  const excelData = useMemo(() => {
    return conferenceUser?.map((item: any, index: number) => {
      return {
        번호: item.listIdx,
        이름: item.userName,
        이메일: item.userEmail,
        연락처: item.userTel,
        소속: item.companyName,
        직급: item.userPosition,
        부서: item.userDepartment,
        발급된PRUUF: item.userPruufs.length,
      };
    });
  }, [conferenceUser]);

  if (isLoading || isError) return <></>;

  const handleRowClick = (row: any) => {
    navigate(`${appRoutes.visitor.route}/${row.user_id}`);
  };

  return (
    <Container gap={30} style={{ padding: '50px 0' }}>
      <Title>참가자 관리</Title>

      {layerPopupOpen && (
        <DefaultLayer open={layerPopupOpen}>
          <DefaultPopupPanel
            actionClose={() => {
              setLayerPopupOpen(false);
            }}
            title={layerType === 'qr' ? 'QR코드 템플릿' : '참가자 PRUUF 발급 현황'}
          >
            <>
              {layerType === 'qr' &&
                <QrTemplate link={layerValue} templateInfo={templateValue} templateType={'solution'} />}
              {layerType === 'pruuf' && <PruufReport listType={'all'} user_id={layerValue} />}
            </>
          </DefaultPopupPanel>
        </DefaultLayer>
      )}

      <SubTitle>참가자 검색</SubTitle>
      <Container gap={10}>
        <FilterBoxContainer>
          <SearchFilter
            onChange={searchTerm => setFilterParams(prev => ({ ...prev, searchTerm }))}
            placeholder={'참가자 이름을 검색해주세요'}
            debounce={350}
            filterLabelText={'참가자'}
          />

          <SearchFilter
            onChange={searchCompany => setFilterParams(prev => ({ ...prev, searchCompany }))}
            placeholder={'소속을 검색해주세요'}
            debounce={350}
            filterLabelText={'소속'}
          />

          <SearchFilter
            onChange={searchTel => setFilterParams(prev => ({ ...prev, searchTel }))}
            placeholder={'연락처를 검색해주세요'}
            debounce={350}
            filterLabelText={'연락처'}
          />
        </FilterBoxContainer>

        <Table
          columns={columns}
          data={conferenceUser}
          onRowClick={handleRowClick}
          excelData={excelData}
          filename={`참가자 정보`}
          listname={'참가자 리스트'}
        />
      </Container>
    </Container>
  );
};

export default VisitorInfo;

const Container = styled('div')<{ gap: number }>`
  width: 1200px;
  height: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: ${props => props.gap}px;
`;
