import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  Certificate,
  CompanyDetail,
  Conference,
  ConferenceDetail,
  ConferenceEvent,
  ConferenceFragmentResponse,
  ConferenceResponse,
  Lecture,
  NftChartData,
  Product,
  User,
  UserResponse,
  VisitorDetail,
} from '../../common/api/responseTypes';
import * as conferenceClient from '../../common/api/conferenceClient';
import { getUserInfoFromStorage } from '../../lib/function';

interface ConferenceState {
  conferences: Conference[];
  hostUsers: User[];
  companyUsers: User[];
  detailedConferences: ConferenceDetail[];
  organizers: { id: string; name: string }[];
}

const initialState: ConferenceState = {
  conferences: [],
  hostUsers: [],
  companyUsers: [],
  detailedConferences: [],
  organizers: [],
};

type FetchAllConferencesArgs = {
  listType?: string;
};
export const fetchAllConferences = createAsyncThunk<ConferenceResponse, FetchAllConferencesArgs>(
  'conferences/fetchAll',
  async ({ listType = '' }) => {
    if (listType === 'user') {
      const userInfo = getUserInfoFromStorage();
      return conferenceClient.getConferences({ excel: true, company_setup_id: userInfo.company_setup_id });
    } else {
      return conferenceClient.getConferences({ excel: true });
    }
  },
);

export const fetchAllOrganizers = createAsyncThunk<{ id: string; name: string }[]>(
  'conferences/fetchAllOrganizers',
  async () => await conferenceClient.getAllOrganizers(),
);

export const fetchAllHosts = createAsyncThunk<UserResponse>(
  'conferences/fetchAllHosts',
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  async () => await conferenceClient.getAllHostUsers(),
);

export const fetchAllCompanies = createAsyncThunk<UserResponse>(
  'conferences/fetchAllCompanies',
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  async () => await conferenceClient.getAllCompanyUsers(),
);

export const fetchDetailedConference = createAsyncThunk<ConferenceDetail, string>(
  'conference/fetchDetailedConference',
  async id => await conferenceClient.getConference(id),
);

export const fetchConferenceVisitors = createAsyncThunk<ConferenceFragmentResponse<VisitorDetail[]>, string>(
  'conference/fetchConferenceVisitors',
  async id => conferenceClient.getConferenceVisitors(id),
);

export const fetchConferenceCompanies = createAsyncThunk<ConferenceFragmentResponse<CompanyDetail[]>, string>(
  'conference/fetchConferenceCompanies',
  async id => conferenceClient.getConferenceCompanies(id),
);

export const fetchConferenceLectures = createAsyncThunk<ConferenceFragmentResponse<Lecture[]>, string>(
  'conference/fetchConferenceLectures',
  async id => conferenceClient.getConferenceLectures(id),
);

export const fetchConferenceEvents = createAsyncThunk<ConferenceFragmentResponse<ConferenceEvent[]>, string>(
  'conference/fetchConferenceEvents',
  async id => conferenceClient.getConferenceEvents(id),
);

export const fetchConferenceCertificate = createAsyncThunk<ConferenceFragmentResponse<Certificate>, string>(
  'conference/fetchConferenceCertificate',
  async id => conferenceClient.getConferenceCertificate(id),
);

export const fetchConferenceProducts = createAsyncThunk<ConferenceFragmentResponse<Product[]>, string>(
  'conference/fetchConferenceProducts',
  async id => conferenceClient.getConferenceProducts(id),
);

export const fetchConferenceNftData = createAsyncThunk<ConferenceFragmentResponse<NftChartData>, {
  id: string;
  type?: number
}>(
  'conference/fetchConferenceNftData',
  async ({ id, type }) => conferenceClient.getConferenceNftData(id, type),
);

const conferenceSlice = createSlice({
  name: 'conferenceSlice',
  initialState,
  reducers: {
    resetConferenceSlice: () => initialState,
  },
  extraReducers: builder => {
    builder
      .addCase(fetchAllConferences.fulfilled, (state, action: PayloadAction<ConferenceResponse>) => {
        state.conferences = action.payload.data;
      })
      .addCase(fetchAllOrganizers.fulfilled, (state, action: PayloadAction<{ id: string; name: string }[]>) => {
        state.organizers = action.payload;
      })
      .addCase(fetchAllHosts.fulfilled, (state, action: PayloadAction<UserResponse>) => {
        state.hostUsers = action.payload.data;
      })
      .addCase(fetchAllCompanies.fulfilled, (state, action: PayloadAction<UserResponse>) => {
        state.companyUsers = action.payload.data;
      })
      .addCase(fetchDetailedConference.fulfilled, (state, action: PayloadAction<ConferenceDetail>) => {
        // Strict mode + dev mode fires useEffect twice duplicating data - non issue in production
        if (!state.detailedConferences.some(c => c.id === action.payload.id)) {
          state.detailedConferences = [...state.detailedConferences, action.payload];
        }
      })
      .addCase(fetchConferenceVisitors.fulfilled, (state, action: PayloadAction<ConferenceFragmentResponse<VisitorDetail[]>>) => {
        for (let i = 0; i < state.detailedConferences.length; i++) {
          if (state.detailedConferences[i].id === action.payload.id) {
            state.detailedConferences[i].visitors = action.payload.data;
            break;
          }
        }
      })
      .addCase(fetchConferenceCompanies.fulfilled, (state, action: PayloadAction<ConferenceFragmentResponse<CompanyDetail[]>>) => {
        for (let i = 0; i < state.detailedConferences.length; i++) {
          if (state.detailedConferences[i].id === action.payload.id) {
            state.detailedConferences[i].companies = action.payload.data;
            break;
          }
        }
      })
      .addCase(fetchConferenceLectures.fulfilled, (state, action: PayloadAction<ConferenceFragmentResponse<Lecture[]>>) => {
        for (let i = 0; i < state.detailedConferences.length; i++) {
          if (state.detailedConferences[i].id === action.payload.id) {
            state.detailedConferences[i].lectures = action.payload.data;
            break;
          }
        }
      })
      .addCase(fetchConferenceEvents.fulfilled, (state, action: PayloadAction<ConferenceFragmentResponse<ConferenceEvent[]>>) => {
        for (let i = 0; i < state.detailedConferences.length; i++) {
          if (state.detailedConferences[i].id === action.payload.id) {
            state.detailedConferences[i].events = action.payload.data;
            break;
          }
        }
      })
      .addCase(fetchConferenceCertificate.fulfilled, (state, action: PayloadAction<ConferenceFragmentResponse<Certificate>>) => {
        for (let i = 0; i < state.detailedConferences.length; i++) {
          if (state.detailedConferences[i].id === action.payload.id) {
            state.detailedConferences[i].certificates = [action.payload.data];
            break;
          }
        }
      })
      .addCase(fetchConferenceProducts.fulfilled, (state, action: PayloadAction<ConferenceFragmentResponse<Product[]>>) => {
        for (let i = 0; i < state.detailedConferences.length; i++) {
          if (state.detailedConferences[i].id === action.payload.id) {
            state.detailedConferences[i].products = action.payload.data;
            break;
          }
        }
      })
      .addCase(fetchConferenceNftData.fulfilled, (state, action: PayloadAction<ConferenceFragmentResponse<NftChartData>>) => {
        for (let i = 0; i < state.detailedConferences.length; i++) {
          if (state.detailedConferences[i].id === action.payload.id) {
            state.detailedConferences[i].chartData = action.payload.data;
            break;
          }
        }
      });
  },
});

export default conferenceSlice.reducer;
export const { resetConferenceSlice } = conferenceSlice.actions;
