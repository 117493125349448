import React from 'react';
import styled from 'styled-components';
import { EventStatus } from '../../../common/api/responseTypes';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { eventStateToHumanString, eventStateToHumanStringFromDate } from '../../../common/utils/utils';
dayjs.extend(isBetween);

export const ConferenceStatusIndicator: React.FC<{ status: EventStatus; start?: string | dayjs.Dayjs; end?: string | dayjs.Dayjs }> = ({
  status,
  start,
  end,
}) => {
  if (start && end) {
    const dateToStatusString = eventStateToHumanStringFromDate(start, end);
    // const statusString = getText(dateToStatusString);

    return <Container status={getStatus(dateToStatusString)}>{dateToStatusString}</Container>;
  }

  return <Container status={status}>{getText(status)}</Container>;
};

const getStatus = (status: string): EventStatus => {
  switch (status) {
    case '종료':
      return 'complete';
    case '진행':
      return 'progress';
    case '대기':
      return 'prepare';
  }

  return 'prepare';
};

const getText = (status: EventStatus) => {
  switch (status) {
    case 'complete':
      return '종료';
    case 'progress':
      return '진행중';
    case 'prepare':
      return '대기중';
  }
};

const getColor = (status: EventStatus) => {
  switch (status) {
    case 'complete':
      return 'grey';
    case 'progress':
      return 'blue';
    case 'prepare':
      return 'green';
  }
};

const Container = styled('div')<{ status: EventStatus }>`
  width: 60px;
  height: 28px;
  background-color: var(--light-${props => getColor(props.status)});
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
`;
