import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch } from '../store';
import { resetConferenceSlice } from '../conference/conferenceSlice';
import { NftType } from '../../common/api/responseTypes';
import { getNftTypes } from '../../common/api/nftClient';
import { setSavedAuth } from '../../common/utils/storage';
import { getLocations } from '../../common/api/appClient';

interface AppState {
  axiosReady: boolean;
  nftTypes: NftType[];
  locations: string[];
}

const initialState: AppState = {
  axiosReady: false,
  nftTypes: [],
  locations: [],
};

export const fetchNftTypes = createAsyncThunk<NftType[]>('app/fetchNftTypes', async () => getNftTypes());

export const fetchLocations = createAsyncThunk<string[]>('app/fetchLocations', async () => getLocations());

export const signOut = createAsyncThunk<void, void, { dispatch: AppDispatch }>('app/signOut', async (_, { dispatch }) => {
  dispatch(resetAppSlice());
  dispatch(resetConferenceSlice());
  setSavedAuth(null);
});

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setAxiosReady: (state, action: PayloadAction<boolean>) => {
      state.axiosReady = action.payload;
    },
    resetAppSlice: () => initialState,
  },
  extraReducers: builder =>
    builder
      .addCase(fetchNftTypes.fulfilled, (state, action: PayloadAction<NftType[]>) => {
        state.nftTypes = action.payload;
      })
      .addCase(fetchLocations.fulfilled, (state, action: PayloadAction<string[]>) => {
        state.locations = action.payload;
      }),
});
export const { setAxiosReady, resetAppSlice } = appSlice.actions;
export default appSlice.reducer;
