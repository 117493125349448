/* eslint-disable no-useless-escape */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Button, Col, Divider, Input, message, notification, Row, Upload, UploadFile, UploadProps } from 'antd';
import { useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { useQuery, useQueryClient } from '@tanstack/react-query';
import FormAlert from '../../common/components/FormAlert';
import DefaultLayer from '../../common/components/panels/defaultLayer';
import SolutionPopupPanel from '../../common/components/panels/solutionPopupPanel';
import { axiosInstance, getApiUrl } from '../../common/providers & services/AxiosService';
import {
  checkPassword,
  formatBusinessNumber,
  formatPhoneNumber,
  handleBusinessNumberInput,
  hasValidPassword,
} from '../../common/utils/myInfoUtils';
import useUserInfo from '../../hooks/useUserInfo';
import { handleTelInput } from '../../lib/function';
import MySolutionList from './MySolutionList';
import SolutionInsert from './SolutionInsert';
import styles from './myInfoUpdate.module.css';
import axios from 'axios';
import { UploadOutlined } from '@ant-design/icons';
import { RcFile } from 'antd/es/upload';

type Props = {
  onCancle: () => void;
};

type Inputs = {
  new_password: string; // 새 비밀번호
  new_password_check: string; // 새 비밀번호 확인
  business_number: string; //사업자등록번호
  company_name: string; //참가사명
  manager: string; //담당자
  tel: string; //담당자 연락처
  homepage: string; //홈페이지
  introduction: string; //참가사 소개
  address : string;
  address_detail : string;
};

/* MyInfoUpdate Component */
const MyInfoUpdate = ({
                        onCancle = () => {
                          history.back();
                        },
                      }: Partial<Props>) => {
  /* 참가사 정보 가져오기 */
  const userData = useUserInfo();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  /* Solution Insert Variable*/
  const [layerPopupOpen, setLayerPopupOpen] = useState(false);
  const [layerType, setLayerType] = useState('solutionInsert');

  const [hasCompanyNumber, setHasCompanyNumber] = useState({
    hasNumber: true,
    isValid: false,
  });
  const [companyNumberChangeCheck, setCompanyNumberChangeCheck] = useState(false);

  const [messageApi, contextHolder] = message.useMessage();
  const [api, contextHolderNotice] = notification.useNotification();

  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [removeFile, setRemoveFile] = useState<[]>([]);

  //const modifiedMode = eventId !== undefined;
  const modifiedMode = true;

  /* input default values */
  const defaultValues: Inputs = {
    new_password: '',
    new_password_check: '',
    business_number: '',
    company_name: userData ? userData.company : '', // Use userData or an empty string if it's not available
    manager: '',
    tel: '',
    homepage: '',
    introduction: '',
    address : '',
    address_detail : '',
  };

  const {
    control,
    watch,
    reset,
    handleSubmit,
    setValue,
    setFocus,
    getValues,
    formState: { errors },
  } = useForm<Inputs>({
    defaultValues,
  });

  /* backend 통신해서 user info 가져오기 */
  const fetchData = async () => {
    try {
      if (!userData) {
        return {};
      }
      const response = await axiosInstance.get(`userInfoDetail`);
      return response.data.userInfo;
    } catch (e) {
      console.error('error...');
      return {};
    }
  };

  /* react-query */
  const {
    data: userInfo,
    isLoading,
    isError,
  } = useQuery(['userInfo'], fetchData, {
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    select: data => {
      return data;
    },
  });

  /* useEffect defaultValues 초기화 */
  useEffect(() => {

    if (!isLoading && userInfo !== undefined) {
      // @ts-ignore
      reset({
        business_number: formatBusinessNumber(userInfo?.company_setup.number),
        company_name: userInfo?.company_setup.name,
        manager: userInfo?.name,
        tel: formatPhoneNumber(userInfo?.tel),
        homepage: userInfo?.company_setup.company_info?.homepage,
        introduction: userInfo?.company_setup.company_info?.description,
        address: userInfo?.company_setup.company_info?.address ?? "",
        address_detail: userInfo?.company_setup.company_info?.address_detail ?? "",
      });

      // 첨부파일정보..
      const uploadedFileInfo = userInfo?.company_setup?.company_info?.reference && JSON.parse(userInfo?.company_setup?.company_info?.reference);
      if (uploadedFileInfo) {
        const newFileInfo = uploadedFileInfo.map((item: any, index: number) => {
          return {
            uid: item.server_name,
            name: item.origin_name,
            status: 'done',
            url: item.full_path,
            path: item.path,
          };
        });
        setFileList([...newFileInfo]);
      }


    }
  }, [isLoading]);

  /* 사업자번호 유효성 검사 */
  const handleBusinessNumber = (business_number: string) => {
    const afterTel = handleBusinessNumberInput(business_number);

    if (business_number !== formatBusinessNumber(userInfo.company_setup.number)) {
      setCompanyNumberChangeCheck(true);
    } else {
      setCompanyNumberChangeCheck(false);
    }

    if (afterTel === false) {
      return false;
    }
    setValue('business_number', afterTel);
  };

  /* 연락처 유효성 검사 */
  const handleTelChange = (tel: string) => {
    const afterTel = handleTelInput(tel);
    if (afterTel === false) {
      return false;
    }
    setValue('tel', afterTel);
  };

  if (isLoading || isError) return <></>;
  /* 솔루션 INSERT pop-up open */
  const handleSolutionInsert = () => {
    setLayerPopupOpen(true);
  };

  /* 솔루션 INSERT pop-up close */
  const handleSolutionInsertClose = () => {
    setLayerPopupOpen(false);
    location.reload();
  };

  // File Upload..
  const uploadProps: UploadProps = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();

      const newremoveFile = [...removeFile];
      // @ts-ignore
      newremoveFile.push(fileList[index]);
      // @ts-ignore
      setRemoveFile(newremoveFile);

      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      const maxSize = 20 * 1024 * 1024; // 10MB
      if (file.size > maxSize) {
        // 파일 크기가 20MB를 초과하는 경우 업로드를 막음
        message.error('파일 크기는 20MB 이하여야 합니다.');
        return false;
      }

      setFileList([...fileList, file]);

      return false;
    },
    fileList,
  };


  const searchCompanyNumber = async () => {
    if (hasCompanyNumber.hasNumber) {
      const validate = /^\d{3}-\d{2}-\d{5}$/.test(getValues('business_number'));

      if (!validate) {
        api.warning({ message: '정확한 사업자 등록번호를 입력해주세요.', placement: 'top' });
        return false;
      }
      setHasCompanyNumber(prev => ({ ...prev, isValid: validate }));
      //백엔드 check-company-auth 에서 이미 존재하는 사업자번호인지 확인하여 등록된 회사명 가져오기
      try {
        const baseUrl = await getApiUrl();
        const res = await axios.get(`${baseUrl}check-company-auth?companyNumber=${getValues('business_number').replace(/[^\w\s]+/g, '')}`);

        const {
          data: { msg, companyName, companyId },
        } = res;

        if (companyName && companyId) {
          // setValue('companyName', companyName);
          // setValue('companyId', companyId);
          setValue('company_name', companyName);
          api.success({
            message: (
              <p>
                조회된 회사명은 <br />
                {companyName} 입니다.
              </p>
            ),
            placement: 'top',
            duration: 1,
          });
        } else {
          api.info({
            message: (
              <p>
                등록된 회사가 없습니다. <br />
                회사명을 입력해주세요.
              </p>
            ),
            placement: 'top',
            duration: 1,
            onClose: () => {
              // setFocus('companyName');
              setFocus('company_name');
            },
          });
        }
      } catch (e) {
        console.error('사사업자번호 조회 에러', e);
      }
    }
  };

  /* 입력받은 참가사 정보 수정 action */
  const submitAction: SubmitHandler<Inputs> = async data => {
    const { new_password, business_number, company_name, manager, tel, homepage, introduction, address, address_detail } = data;

    const uploadedFileInfo = userInfo?.company_setup?.company_info?.reference ? JSON.parse(userInfo?.company_setup?.company_info?.reference) : [];
    const submitData: {
      business_number: string;
      company_name: string;
      company_setup_id: string | number;
      manager: string;
      tel: string;
      homepage: string;
      introduction: string;
      address?: string;
      address_detail?: string;
      new_password?: string; // Make new_password optional
      reference?: any;
      removeFile?: any;
    } = {
      business_number: business_number.replace(/[^\w\s]+/g, ''),
      company_name,
      company_setup_id: userInfo.company_setup_id,
      manager,
      tel: tel.replace(/[^\w\s]+/g, ''),
      homepage,
      introduction,
      reference: JSON.stringify(uploadedFileInfo.filter((item: any, index: number) => {
        return !removeFile.find((remove: any) => remove.uid === item.server_name);
      })),
      address,
      address_detail,
      removeFile: JSON.stringify(removeFile),
    };


    if (new_password) {
      submitData.new_password = new_password;
    }

    console.log('submitData ===>', submitData);
    console.log('fileList ===>', fileList);
    console.log('removeFile ===>', removeFile);

    // FormData 객체를 생성합니다.
    const formData = new FormData();
// submitData의 각 속성을 FormData에 추가합니다.
    for (const key in submitData) {
      if (Object.prototype.hasOwnProperty.call(submitData, key)) {
        // Blob인 경우에만 파일 데이터로 추가합니다.
        // @ts-ignore
        if (submitData[key] instanceof Blob) {
          // 파일 데이터 추가
          // formData.append(key, submitData[key], `${key}.${getFileExtensionFromBlob(submitData[key])}`);
        } else {
          // 일반 데이터 추가
          // @ts-ignore
          formData.append(key, submitData[key] as string);
        }
      }
    }


    fileList.forEach((file) => {
      formData.append('files[]', file as RcFile);
    });


    // // @ts-ignore
    // for (const [key, value] of formData.entries()) {
    //   console.log(`${key}: ${value}`);
    // }

    try {
      messageApi.open({
        key: 'apiMsg',
        type: 'loading',
        content: modifiedMode ? '수정중...' : '등록중...',
        duration: 0,
      });
      await axiosInstance.post('companyInfo', formData, { headers: { 'Content-Type': 'multipart/form-data' } });
      messageApi.open({
        key: 'apiMsg',
        type: 'success',
        content: modifiedMode ? '성공적으로 수정되었습니다.' : '성공적으로 등록되었습니다.',
        onClose: () => {
          // navigate(-1);
          queryClient.invalidateQueries(['userInfo']);
          location.reload();
        },
      });
    } catch (e) {
      console.error(`행사${modifiedMode ? '수정' : '등록'} 에러`, e);
      messageApi.open({
        key: 'apiMsg',
        type: 'error',
        content: `${modifiedMode ? '수정' : '등록'}에 실패하였습니다. 지속되면 문의해주세요.`,
      });
    }
  };
  /* 솔루션 등록  */
  return (
    <section className={styles.wrap}>
      {layerPopupOpen && (
        <DefaultLayer open={layerPopupOpen}>
          <SolutionPopupPanel
            actionClose={() => {
              setLayerPopupOpen(false);
            }}
            title={layerType === 'solutionInsert' ? '솔루션 등록' : 'layerType not found'}
          >
            <>{layerType === 'solutionInsert' &&
              <SolutionInsert handleSolutionInsertClose={handleSolutionInsertClose} />}</>
          </SolutionPopupPanel>
        </DefaultLayer>
      )}
      <>
        {contextHolder}
        {contextHolderNotice}
        <h2 className={styles.h2}>정보 {modifiedMode ? '수정' : '등록하기'}</h2>
        <h4 className={styles.h4}>{userInfo.type === 'host' ? '주최사' :  '참가사'} 정보</h4>
        <form className={styles.formWrap} onSubmit={handleSubmit(submitAction)}>
          <Divider style={{ borderWidth: '2px', borderColor: '#555', margin: '18px 0px 24px 0px' }} />

          <Row align={'middle'}>
            <Col span={4}>
              <span>이메일</span>
            </Col>
            <Col span={10}>
              <Input disabled={true} defaultValue={userData.account} />
            </Col>
          </Row>
          <Divider className={styles.myInfoUpdateDivider} />
          <Row align={'middle'}>
            <Col span={4}>
              <span>새 비밀번호</span>
            </Col>
            <Col span={10}>
              <Controller
                control={control}
                name={'new_password'}
                rules={{ /* required: '영문+숫자 8자리 이상으로 입력해주세요.', */ validate: hasValidPassword }}
                render={({ field }) => <Input {...field} type='password' placeholder={`새 비밀번호`} />}
              />
              {errors.new_password?.message && <FormAlert msg={errors.new_password.message} />}
            </Col>
          </Row>
          <Divider className={styles.myInfoUpdateDivider} />
          <Row align={'middle'}>
            <Col span={4}>
              <span>새 비밀번호 확인</span>
            </Col>
            <Col span={10}>
              <Controller
                control={control}
                name={'new_password_check'}
                rules={{
                  /* required: '입력하신 비밀번호와 일치하지 않습니다.', */
                  validate: {
                    checkPassword: value => checkPassword(value, watch('new_password')),
                  },
                }}
                render={({ field }) => <Input {...field} type='password' placeholder={`새 비밀번호 확인`} />}
              />
              {errors.new_password_check?.message && <FormAlert msg={errors.new_password_check.message} />}
            </Col>
          </Row>
          <Divider className={styles.myInfoUpdateDivider} />          
          <Row align={'middle'}>
            <Col span={4}>
              <span>사업자 등록번호</span>
            </Col>
            <Col span={10}>
              <Controller
                control={control}
                name={'business_number'}
                rules={{
                  required: '변경하실 사업자 등록번호를 입력해주세요.',
                }}
                render={({ field }) => (
                  <Input
                    {...field}
                    placeholder={`사업자 등록번호를 입력해주세요.`}
                    maxLength={12}
                    onChange={e => handleBusinessNumber(e.target.value)}
                  />
                )}
              />
              {errors.business_number?.message && <FormAlert msg={errors.business_number.message} />}
            </Col>

            {
              companyNumberChangeCheck &&
              <Col span={4}>
                <Button
                  className={styles.ani}
                  htmlType='button'
                  style={{ marginLeft: '8px' }}
                  disabled={!hasCompanyNumber.hasNumber}
                  onClick={() => searchCompanyNumber()}
                  tabIndex={-1}
                >
                  조회하기
                </Button>
              </Col>
            }


          </Row>
          <Divider className={styles.myInfoUpdateDivider} />
          <Row align={'middle'}>
            <Col span={4}>
              <span>회사명</span>
            </Col>
            <Col span={10}>
              <Controller
                control={control}
                name={'company_name'}
                //rules={{ required: '변경하실 참가사명을 입력해주세요.' }}
                render={({ field }) => <Input {...field} placeholder={`참가사명을 입력해주세요.`} />}
              />
              {errors.company_name?.message && <FormAlert msg={errors.company_name.message} />}
            </Col>
          </Row>
          <Divider className={styles.myInfoUpdateDivider} />
          <Row align={'middle'}>
            <Col span={4}>
              <span>담당자</span>
            </Col>
            <Col span={10}>
              <Controller
                control={control}
                name={'manager'}
                rules={{ required: '변경하실 담당자를 입력해주세요.' }}
                render={({ field }) => <Input {...field} placeholder={`담당자를 입력해주세요.`} />}
              />
              {errors.manager?.message && <FormAlert msg={errors.manager.message} />}
            </Col>
          </Row>
          <Divider className={styles.myInfoUpdateDivider} />
          <Row align={'middle'}>
            <Col span={4}>
              <span>연락처</span>
            </Col>
            <Col span={10}>
              <Controller
                control={control}
                name={'tel'}
                rules={{ required: '변경하실 연락처를 입력해주세요.' }}
                render={({ field }) => (
                  <Input {...field} maxLength={13} placeholder={`연락처를 입력해주세요.`}
                         onChange={e => handleTelChange(e.target.value)} />
                )}
              />
              {errors.tel?.message && <FormAlert msg={errors.tel.message} />}
            </Col>
          </Row>
          {userInfo.type === 'host' ? '' : 
          <>
          <Divider className={styles.myInfoUpdateDivider} />

          <Row align={'middle'}>
            <Col span={4}>
              <span>주소</span>
            </Col>
            <Col span={10}>
              <Controller
                control={control}
                name={'address'}
                //rules={{ required: '변경하실 참가사명을 입력해주세요.' }}
                render={({ field }) => <Input {...field} placeholder={`주소를 입력해주세요.`} />}
              />
              {errors.address?.message && <FormAlert msg={errors.address.message} />}
            </Col>
          </Row>
          <Divider className={styles.myInfoUpdateDivider} />

          <Row align={'middle'}>
            <Col span={4}>
              <span>주소 상세</span>
            </Col>
            <Col span={10}>
              <Controller
                control={control}
                name={'address_detail'}
                //rules={{ required: '변경하실 참가사명을 입력해주세요.' }}
                render={({ field }) => <Input {...field} placeholder={`주소 상세를 입력해주세요.`} />}
              />
              {errors.address_detail?.message && <FormAlert msg={errors.address_detail.message} />}
            </Col>
          </Row>
          <Divider className={styles.myInfoUpdateDivider} />
          
          <Row align={'middle'}>
            <Col span={4}>
              <span>홈페이지</span>
            </Col>
            <Col span={10}>
              <Controller
                control={control}
                name={'homepage'}
                rules={{ required: '변경하실 홈페이지 주소를 입력해주세요.' }}
                render={({ field }) => <Input {...field} placeholder={`변경하실 홈페이지 주소를 입력해주세요.`} />}
              />
              {errors.homepage?.message && <FormAlert msg={errors.homepage.message} />}
            </Col>
          </Row>
          
          
          <Divider className={styles.myInfoUpdateDivider} />
          

          <Row align={'middle'}>
            <Col span={4}>
              <span>회사 소개</span>
            </Col>
            <Col span={10}>
              <Controller
                control={control}
                name={'introduction'}
                rules={{ required: '회사 소개를 입력해주세요.' }}
                render={({ field }) => <Input.TextArea {...field} showCount maxLength={200} rows={5}
                                                       placeholder={`회사 소개를 입력해주세요.`}
                                                       style={{ resize: 'none' }} />}
              />
              {errors.introduction?.message && <FormAlert msg={errors.introduction.message} />}
            </Col>
          </Row>
          
          <Divider className={styles.myInfoUpdateDivider} />

          <Row align={'middle'}>
            <Col span={4}>
              <span>소개 자료</span>
            </Col>
            <Col span={10}>
              <Upload {...uploadProps}>
                <Button tabIndex={-1} icon={<UploadOutlined />}>자료 업로드</Button>
              </Upload>
            </Col>
          </Row>
          </>
          }
          <Divider style={{ borderWidth: '2px', borderColor: '#555', margin: '24px 0px 24px 0px' }} />
          <Row justify={'end'} gutter={20}>
            
            <Col>
            {userInfo.type === 'host' ? '' : 
              <Button type={'primary'} size={'large'} className={styles.submitBtn} onClick={handleSolutionInsert}>
                {'참가제품 등록하기'}
              </Button>
                      }
            </Col>
            <Col>
              <Button type={'primary'} htmlType={'submit'} size={'large'} className={styles.submitBtn}>
                {modifiedMode ? '정보수정' : '등록하기'}
              </Button>
            </Col>
            <Col>
              <Button size={'large'} onClick={onCancle}>
                {'취소'}
              </Button>
            </Col>
          </Row>
        </form>
      </>
      {' '}
      {userInfo.type === 'host' ? '' : 
      <Divider style={{ borderWidth: '2px', borderColor: '#555', margin: '24px 0px 24px 0px' }} />
    }
      {/* 참가사 솔루션 리스트 MySolutionList */}
      {userInfo.type === 'host' ? '' : 
      <MySolutionList />
        }
    </section>
  );
};

export default MyInfoUpdate;
