import { Modal } from 'antd';

interface PrivacyModalProps {
  visible: boolean;
  onClose: () => void;
}

const PrivacyModal: React.FC<PrivacyModalProps> = ({ visible, onClose }: any) => {
  const handleOnOk = () => {
    console.log('handleOkOk');
    onClose();
  };

  return (
    <Modal open={visible} onOk={handleOnOk} onCancel={onClose} width={1200} bodyStyle={{ height: 800 }} centered={true}>
      <iframe
        title='개인정보동의 처리'
        src='https://app.catchsecu.com/document/P/e128e9632695dc6'
        width='100%'
        height='100%'
        frameBorder='0'
      />
    </Modal>
  );
};

export default PrivacyModal;
