import { Button, Upload, UploadProps, message, notification } from 'antd';
import styles from './enrollment.module.css';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Address, useDaumPostcodePopup } from 'react-daum-postcode';
import { UploadOutlined } from '@ant-design/icons';

const Enrollment = () => {
  const [api, contextHolder] = notification.useNotification();
  const [messageApi, messageContextHolder] = message.useMessage();

  const [address, setAddress] = useState('');

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const enrollment = (data: { [key: string]: string }) => {
    console.log({ ...data, address });
    setAddress('');
    api.success({ message: '등록이 완료되었습니다.', placement: 'top' });
  };

  const open = useDaumPostcodePopup('//t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js');

  const getPostcode = (data: Address) => {
    const fullAddress = data.address;
    setAddress(fullAddress);
  };

  const handleAddress = () => {
    open({ onComplete: getPostcode });
  };

  const props: UploadProps = {
    name: 'file',
    action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
    headers: {
      authorization: 'authorization-text',
    },
    onChange(info) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  return (
    <>
      {contextHolder}
      {messageContextHolder}
      <form className={styles.form} onSubmit={handleSubmit(enrollment)}>
        <div className={styles.row}>
          <label>이름</label>
          <input type='text' placeholder='내용을 입력하세요.' {...register('name')} />
        </div>
        <div className={styles.row}>
          <label>주소</label>
          <input type='text' placeholder='주소찾기를 클릭해주세요.' readOnly value={address} />
          <Button htmlType='button' style={{ marginLeft: '4px' }} onClick={handleAddress}>
            주소찾기
          </Button>
        </div>
        <div className={styles.row}>
          <label>파일</label>
          <Upload {...props}>
            <Button icon={<UploadOutlined />}>파일 등록</Button>
          </Upload>
        </div>
        <div className={styles.buttonWrapper}>
          <Button htmlType='submit'>등록하기</Button>
        </div>
      </form>
    </>
  );
};

export default Enrollment;
