import React, { useEffect, useState } from 'react';
import { getUserInfoFromStorage, sleep } from '../lib/function';

const useUserInfo = () => {
  const [userInfo, setUserInfo] = useState(() => getUserInfoFromStorage());

  useEffect(() => {
    async function isLogin() {
      await sleep(200);
      setUserInfo({ ...getUserInfoFromStorage() });
    }

    isLogin();
  }, []);

  return userInfo;
};

export default useUserInfo;
