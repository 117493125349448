/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect, useMemo, useState } from 'react';
import { EventInfoTabBox } from './components/EventInfoTabBox';
import styled from 'styled-components';
import { OutlineButton, SelectWrapper } from '../../common/components/StyledComponents';
import { eventStateToHumanStringFromDate, formatDateString } from '../../common/utils/utils';
import { useAllConferences } from '../../common/utils/apiHooks';
import { useNavigate } from 'react-router-dom';
import { appRoutes } from '../../AppRoutes';
import SystemMonitors from './components/SystemMonitors';
import PruufChart from '../pruuf-status/components/PruufChart';

import type { GraphType } from '../pruuf-status/PruufStatus';
import { axiosInstance } from '../../common/providers & services/AxiosService';
import { useQuery } from '@tanstack/react-query';
import LoadingIndicator from '../../common/components/LoadingIndicator';
import { Conference, EventStatus } from '../../common/api/responseTypes';
import dayjs from 'dayjs';
import useUserInfo from '../../hooks/useUserInfo';
import { useAppDispatch } from '../../store/store';
import { fetchAllConferences } from '../../store/conference/conferenceSlice';

let dataAll: any[] = [];

const ChartTitle = ({ name, start_time, end_time }: { name: string; start_time: string; end_time: string }) => {
  return (
    <EventContainer>
      <EventTitle>{name}</EventTitle>
      <EventDate>
        <i className={'xi-clock'} />
        <div>
          {/* {formatDateString(event.start_time)} ~ {formatDateString(event.end_time)} */}
          {dayjs(start_time).format('YYYY.MM.DD')} ~ {dayjs(end_time).format('YYYY.MM.DD')}
        </div>
      </EventDate>
    </EventContainer>
  );
};

export const Home: React.FC = () => {
  const userInfo = useUserInfo();

  const initialState = {
    searchTerm: '',
    start: '',
    end: '',
    status: 'all',
    listType: userInfo.type !== 'admin' ? 'user' : '',
  };
  const conferences = useAllConferences(...Object.values(initialState));
  const dispatch = useAppDispatch();

  const activeConferences = useMemo(() => {
    return conferences.filter((event: Conference) => {
      const conferenceState = eventStateToHumanStringFromDate(event.start_time, event.end_time);
      return !conferenceState.includes('대기');
    });
  }, [conferences]);

  useEffect(() => {
    if (userInfo.type !== 'admin') {
      //@ts-ignore
      dispatch(fetchAllConferences({ listType: 'user' }));
    } else {
      //@ts-ignore
      dispatch(fetchAllConferences({ listType: '' }));
    }
  }, [userInfo]);

  const [selected, setSelected] = useState(0);
  //현재 선택된 행사 id 값 가져오기
  const nowConferenceId = activeConferences[selected]?.id;

  const [graphState, setGraphState] = useState<GraphType>('all');

  const navigate = useNavigate();

  //그래프 UI 표기용 데이터 통신
  const fetchData = async () => {
    try {
      const getInfos = {
        listType: graphState,
        start_date: activeConferences[selected]?.start_time,
        end_date: activeConferences[selected]?.end_time,
        conference_id: nowConferenceId,
      };
      const searchParams = new URLSearchParams(getInfos);
      const response = await axiosInstance.get(`pruufs?${searchParams}`);

      return response.data.data.pruufs;
    } catch (e) {
      console.error('error...', e);
      return [{}];
    }
  };

  const { data, isLoading, isError } = useQuery(['pruufStatus', graphState, nowConferenceId], fetchData, {
    refetchOnWindowFocus: false,
    notifyOnChangeProps: ['data'],
    select: data => {
      // data 변수에 필터링된 데이터를 저장
      const filteredData = data //
        // .filter((item: any) => {
        //   const { searchTerm, searchType } = filterParams;

        //   if (searchType === 'all' && searchTerm) {
        //     // 모든 조건에 대해 OR 검색을 수행합니다.
        //     return (
        //       item.conference.name_kor.includes(searchTerm) ||
        //       item.company_setup.name.includes(searchTerm) ||
        //       item.user.name.includes(searchTerm)
        //     );
        //   }

        //   if (searchType === 'conference' && searchTerm && !item.conference.name_kor.includes(searchTerm)) {
        //     return false;
        //   }

        //   if (searchType === 'host' && searchTerm && !item.company_setup.name.includes(searchTerm)) {
        //     return false;
        //   }

        //   if (searchType === 'company' && searchTerm && !item.company_setup.name.includes(searchTerm)) {
        //     return false;
        //   }

        //   if (searchType === 'user' && searchTerm && !item.user.name.includes(searchTerm)) {
        //     return false;
        //   }
        //   return true;
        // }) //
        .map((item: any) => {
          const { type } = item;
          let typeToText = '전체';
          switch (type) {
            case 'conference':
              typeToText = '행사 참여';
              break;
            case 'company':
              typeToText = '참가사 방문';
              break;
            case 'solution':
              typeToText = '솔루션 체험';
              break;
            case 'event':
              typeToText = '이벤트';
              break;
            default:
              typeToText = '전체';
              break;
          }
          return { ...item, typeToText };
        });

      // graphState가 'all'인 경우 dataAll 변수에 복사
      if (graphState === 'all') {
        dataAll = [...filteredData];
      }

      return filteredData;
    },
  });

  if (isLoading || isError) {
    return <LoadingIndicator />;
  }

  //그래프 UI 표기용 Type state 처리
  const handleGraphState = (state: GraphType) => {
    setGraphState(state);
  };

  return (
    <Container>
      {userInfo.type === 'admin' && (
        <>
          <RecentEvent>
            <>프루프 시스템 현황</>
          </RecentEvent>
          <SystemMonitors />
        </>
      )}

      {userInfo.type !== 'admin' && (
        <>
          <RecentEvent>
            <div>참여한 행사 정보</div>
          </RecentEvent>
          <EventInfoTabBox allEvents={conferences} />
        </>
      )}

      <RecentEvent>PRUUF 발급현황</RecentEvent>

      <SelectWrapper height={40}>
        <select onChange={e => setSelected(~~e.target.value)} defaultValue={selected}>
          {
            //@ts-ignore
            activeConferences.map((event, i) => {
              const conferenceState = eventStateToHumanStringFromDate(event.start_time, event.end_time);
              return (
                <option key={`event_select_${event.id}`} value={i}>
                  [{conferenceState}]
                  [{event.name_kor}]{formatDateString(event.start_time)}~ {formatDateString(event.end_time)}
                </option>
              );
            })
          }
        </select>
        <OutlineButton height={40}
                       onClick={() => navigate(appRoutes.conferenceInfo.children.detail.path(activeConferences[selected].id))}>
          자세히보기
        </OutlineButton>
      </SelectWrapper>

      <PruufChart
        apiData={data}
        apiTotalData={dataAll}
        onTypeChange={handleGraphState}
        graphState={graphState}
        header={
          <ChartTitle
            name={activeConferences[selected]?.name_kor || ''}
            start_time={activeConferences[selected]?.start_time}
            end_time={activeConferences[selected]?.end_time}
          />
        }
      />

      {userInfo.type === 'admin' && (
        <>
          <RecentEvent>
            <div>행사 정보</div>
          </RecentEvent>
          <EventInfoTabBox allEvents={conferences} isAdmin={false} />
        </>
      )}
    </Container>
  );
};

const Container = styled.div`
  width: 1200px;
  height: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  padding-top: 50px;
  padding-bottom: 20px;
  gap: 30px;
`;

const RecentEvent = styled.div`
  font-size: 26px;
  font-weight: 600;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 31px;
  padding-left: 15px;
  border-left: 10px solid var(--blue);
`;

const EventContainer = styled.div`
  margin: 20px 20px 0px 20px;
  // width: 300px;
  // //height:
  // margin: 50px;
  // display: flex;
  // flex-direction: column;
  // gap: 12px;
  // border-right: 1px solid #aaaaaa;
  // cursor: pointer;

  // &:last-of-type {
  //   border-right: none;
  // }
`;

const Events = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  // width: 100%;
  flex: 1;
  justify-content: flex-start;
`;

const EventStatusWrapper = styled('div')<{ status: EventStatus; noStatus?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: ${props => (props.noStatus ? '70px' : '140px')};
  font-size: 15px;
  border: 1px solid var(--${props => props.status});
  color: var(--${props => props.status});

  > div {
    width: ${props => (props.noStatus ? '100%' : '50%')};
    text-align: center;
  }

  > div:nth-child(1) {
    background-color: var(--${props => props.status});
    color: white;
  }

  > div:nth-child(2) {
    display: ${props => (props.noStatus ? 'none' : 'block')};
  }
`;

const EventTitle = styled.div`
  font-size: 22px;
  font-weight: 600;
  overflow: hidden;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const EventDate = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #999999;
  font-size: 15px;
  gap: 6px;

  i {
    color: #dddddd;
  }
`;
