import { message, Popconfirm } from 'antd';
import { useParams } from 'react-router-dom';
import { HTMLTable, SubTitle, Td, Th, Tr } from '../../common/components/StyledComponents';
import CompanyParticipateInfo from './components/CompnayParticpateInfo';
import { axiosInstance } from '../../common/providers & services/AxiosService';
import { useQuery } from '@tanstack/react-query';

import styles from './companyInfoDetail.module.css';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import LoadingIndicator from '../../common/components/LoadingIndicator';
import { toBusinessRegistrationNumber } from '../../lib/function';

const fixedNewPassword = 'pruuf1234';

const CompanyInfoDetail = () => {
  const { id } = useParams();

  const [referenceFileList, setReferenceFileList] = useState<{ id: string, name: string }[]>([]);

  const fetchData = async () => {
    try {
      const response = await axiosInstance.get(`userInfoDetail?user_id=${id}`);
      return response.data.userInfo;
    } catch (e) {
      console.log('e', e);
      return {};
    }
  };

  const handleResetPassword = async (e?: React.MouseEvent<HTMLElement>) => {
    try {
      await axiosInstance.put(`company/${id}`, { password: fixedNewPassword });
      message.success('비밀번호 변경 성공.');
    } catch (e) {
      message.error('비밀번호 변경에 실패했습니다. 지속되면 문의해주세요.');
    }
  };

  const { data, isLoading } = useQuery(['companyDetail', id], fetchData, {
    staleTime: 5000,
    refetchOnWindowFocus: false,
  });

  const cancel = (e?: React.MouseEvent<HTMLElement>) => {
    console.log(e);
    message.error('Click on No');
  };

  useEffect(() => {
    if (!isLoading) {
      const uploadedFileInfo = data?.company_setup?.company_info?.reference && JSON.parse(data?.company_setup?.company_info?.reference);
      if (uploadedFileInfo) {
        const newFileInfo = uploadedFileInfo.map((item: any, index: number) => {
          return {
            uid: item.server_name,
            name: item.origin_name,
            status: 'done',
            url: item.full_path,
            path: item.path,
          };
        });
        setReferenceFileList([...newFileInfo]);

        //TODO: 실제 업로드 된 파일 잘 가져오고 표기되는지 확인 필요
      }


    }
  }, [isLoading]);

  // return <LoadingIndicator />;
  if (isLoading) return <LoadingIndicator />;

  return (
    <section className={styles.wrap}>
      <SubTitle marginBottom={-20}>기본정보</SubTitle>
      <HTMLTable>
        <tbody>
        <Tr>
          <Th>참가사명</Th>
          <Td>{data.company_setup?.name}</Td>
        </Tr>
        <Tr>
          <Th>담당자이메일</Th>
          <Td>{data.email}</Td>
        </Tr>
        <Tr>
          <Th>담당자</Th>
          <Td>{data.name}</Td>
        </Tr>

        <Tr>
          <Th>담당자 연락처</Th>
          <Td>{data.tel}</Td>
        </Tr>
        <Tr>
          <Th>참가사 주소</Th>
          <Td>{data.company_setup?.company_info?.address ?? ""} {data.company_setup?.company_info?.address_detail ?? ""}</Td>
        </Tr>
        {
          <Tr>
            <Th>참가사 홈페이지</Th>
            <Td>
              {
                data.company_setup?.company_info?.homepage &&
                (<a href={data.company_setup?.company_info?.homepage}
                    target={'_blank'}
                    rel={'noreferrer'}>{data.company_setup?.company_info?.homepage}</a>)
              }
            </Td>
          </Tr>
        }
        {
          data.company_setup?.number &&
          (
            <Tr>
              <Th>사업자등록번호</Th>
              <Td>
                {
                  toBusinessRegistrationNumber(data.company_setup?.number)
                }
              </Td>
            </Tr>
          )
        }


        <Tr>
          <Th>참가사 소개</Th>
          <Td>{data.company_setup?.company_info?.description}</Td>
        </Tr>
        {/*<Tr>*/}
        {/*  <Th>소개자료</Th>*/}
        {/*  <Td>*/}
        {/*    {*/}
        {/*      referenceFileList.length > 1 &&*/}
        {/*      referenceFileList.map((file: any) => {*/}
        {/*        return <p key={file.uid}><a target={'_blank'} href={file.url} rel={'noreferrer'}>{file.name}</a></p>;*/}
        {/*      })*/}
        {/*    }*/}
        {/*  </Td>*/}
        {/*</Tr>*/}
        {/*<Tr>*/}
        {/*  <Th>솔루션</Th>*/}
        {/*  <Td>*/}
        {/*    {data.solutions.length > 0 &&*/}
        {/*      data.solutions.map((solution: any) => {*/}
        {/*        return solution?.name;*/}
        {/*      })}*/}
        {/*  </Td>*/}
        {/*</Tr>*/}
        <Tr>
          <Th>비밀번호 초기화</Th>
          <Td>
            <Popconfirm
              title={'비밀번호 초기화'}
              description={`초기비밀번호는 ${fixedNewPassword}입니다.`}
              onConfirm={handleResetPassword}
              okText='확인'
              cancelText='취소'
            >
              <span className={styles.textUnder}>비밀번호 초기화 하기</span>
            </Popconfirm>
          </Td>
        </Tr>
        <Tr>
          <Th>등록일</Th>
          <Td>{dayjs(data.created_at).format('YYYY-MM-DD')}</Td>
        </Tr>
        </tbody>
      </HTMLTable>
      <SubTitle marginBottom={-20}>참가한 행사</SubTitle>

      <CompanyParticipateInfo hostId={id} companySetupId={data.company_setup?.id} />
      {/* <HostParticipateInfo hostId={id} /> */}
    </section>
  );
};

export default CompanyInfoDetail;
