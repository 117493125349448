import React, { useState } from 'react';
import { Container, FilterBoxContainer, SubTitle } from '../../../common/components/StyledComponents';
import { TableColumn } from 'react-data-table-component';
// import { Table } from './Table';
import { Table } from '../../../common/components/Table';
import { formatDateString } from '../../../common/utils/utils';
import { SearchFilter } from '../../../common/components/filters/SearchFilter';
import { axiosInstance } from '../../../common/providers & services/AxiosService';
import { useQuery } from '@tanstack/react-query';
import DefaultLayer from '../../../common/components/panels/defaultLayer';
import DefaultPopupPanel from '../../../common/components/panels/defaultPopupPanel';
import PruufReport from './PruufReport';
import { useNavigate } from 'react-router-dom';
import { appRoutes } from '../../../AppRoutes';
import { ConferenceStatusIndicator } from './ConferenceStatusIndicator';

interface Props {
  conferenceId: string;
}

const initialState = {
  searchTerm: '',
  start: '',
  end: '',
};
type LayerType = 'qr' | 'pruuf';

const typeOptions = [
  { value: 'notice', label: '알림' },
  { value: 'random', label: '돌발' },
];

export const ConferenceInfoDetailEvents: React.FC<Props> = ({ conferenceId }) => {
  const [layerPopupOpen, setLayerPopupOpen] = useState(false);
  const [layerType, setLayerType] = useState<LayerType>('pruuf');
  const [layerValue, setLayerValue] = useState<string>('');
  const [modalOpen, setModalOpen] = useState(false);
  const [filterParams, setFilterParams] = useState(initialState);

  const navigate = useNavigate();

  const columns: TableColumn<any>[] = [
    {
      name: '번호',
      selector: row => row.listIdx,
      sortable: true,
      grow: 0,
    },
    {
      name: '이벤트명',
      selector: row => row.name,
      sortable: true,
    },
    {
      name: '이벤트 기간',
      selector: row => `${formatDateString(row.start_time)} ~ ${formatDateString(row.end_time)}`,
      sortable: true,
    },
    {
      name: '공지사용여부',
      // selector: row => targetOptions.find(option => option.value === row.target)?.label,
      selector: row => row.noticeUse ? '사용' : '미사용',
      sortable: true,
    },
    {
      name: '이벤트종류',
      selector: row => typeOptions.find(option => option.value === row.event_type)?.label,
      // selector: row => row.event_type,
      sortable: true,
    },
    {
      name: 'Pruuf 발행여부',
      selector: row => row.pruuf ? '발행' : '미발행',
      sortable: true,
    },
    {
      name: '상태',
      cell: row => <ConferenceStatusIndicator start={row.start_time} end={row.end_time} status={'complete'} />,
      sortable: true,
    },
    // {
    //   name: '당첨자수',
    //   selector: row => row.winner,
    //   sortable: true,
    //   grow: 0,
    // },
    // {
    //   name: '발급된 PRUUF',
    //   cell: row => {
    //     const handleQRpopup = (id: string) => {
    //       setLayerPopupOpen(true);
    //       setLayerType('pruuf');
    //       setLayerValue(row.id);
    //     };
    //     return (
    //       <span style={{ textDecoration: 'underline' }} onClick={() => handleQRpopup(row.id)}>
    //         {row.pruufs.length}
    //       </span>
    //     );
    //   },
    //   sortable: true,
    // },
  ];

  const handleRowClick = (row: any) => {
    navigate(`${appRoutes.conferenceInfo.children.eventDetail.base}/${row.id}`);
  };

  const fetchData = async () => {
    try {
      const response = await axiosInstance.get(`event?conference_id=${conferenceId}`);
      return response.data.data.events;
    } catch (e) {
      console.error('error...');
      return [{}];
    }
  };
  const {
    data: eventList,
    isLoading,
    isError,
  } = useQuery(['eventList', conferenceId], fetchData, {
    refetchOnWindowFocus: false,
    select: data => {
      const filteredData = data.filter((item: any) => {
        const { searchTerm } = filterParams;

        if (searchTerm && !item.name.toLowerCase().includes(searchTerm.toLowerCase())) {
          return false;
        }
        return true;
      });

      // return filteredData;

      return filteredData.map((item: any, index: number) => {
        return {
          ...item,
          listIdx: filteredData.length - index,
        };
      });
    },
  });

  if (isError || isLoading) return <></>;

  return (
    <Container gap={10}>
      {layerPopupOpen && (
        <DefaultLayer open={layerPopupOpen}>
          <DefaultPopupPanel
            actionClose={() => {
              setLayerPopupOpen(false);
            }}
            title={layerType === 'qr' ? 'QR코드 템플릿' : '이벤트 PRUUF 발급 현황'}
          >
            <>{layerType === 'pruuf' && <PruufReport listType={'event'} event_id={layerValue} />}</>
          </DefaultPopupPanel>
        </DefaultLayer>
      )}
      <SubTitle>이벤트 정보</SubTitle>
      <FilterBoxContainer>
        <SearchFilter onChange={searchTerm => setFilterParams(prev => ({ ...prev, searchTerm }))} placeholder={'이벤트명'} debounce={150} />
      </FilterBoxContainer>

      <Table data={eventList} onRowClick={handleRowClick} columns={columns} filename={`_이벤트`} showExcel={false} />
    </Container>
  );
};
