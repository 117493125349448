import { useForm } from 'react-hook-form';
import styles from './editUser.module.css';
import { Button, notification } from 'antd';
import { useNavigate } from 'react-router-dom';

type FormValues = {
  [key: string]: string;
};

const EditUser = () => {
  const [api, contextHolder] = notification.useNotification();
  const navigate = useNavigate();

  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm();

  // 정보수정
  const editUserInfo = (data: FormValues) => {
    if (checkPassword()) {
      console.log(data); // api edit user info
      api.success({ message: '회원정보 수정이 완료되었습니다.', placement: 'top' });
    }
  };

  const hasValidPassword = (value: string) => {
    const hasMinimumLength = value.length >= 8;
    const hasEnglishLetter = /[a-zA-Z]/.test(value);
    const hasNumber = /\d/.test(value);
    if (!(hasMinimumLength && hasEnglishLetter && hasNumber)) {
      return '비밀번호는 영문+숫자 8자리 이상으로 해주세요';
    }
    return true;
  };

  const checkPassword = () => {
    if (watch('password') !== '' && watch('passwordConfirm') !== '') {
      if (watch('password') === watch('passwordConfirm')) return true;
      if (watch('password') !== watch('passwordConfirm')) return '입력한 비밀번호와 일치하지 않습니다. 다시 확인해주세요.';
    }
  };

  return (
    <section className={styles.container}>
      {contextHolder}
      <div>
        <h2>정보 수정</h2>
        <h4>기본 정보</h4>
        <form className={styles.form} onSubmit={handleSubmit(editUserInfo)}>
          <div className={styles.row}>
            <label>이메일 아이디</label>
            <input type='text' readOnly defaultValue={''} />
          </div>
          <div className={styles.row}>
            <label>비밀번호</label>
            <div className={styles.col}>
              <input
                type='password'
                placeholder='영문과 숫자를 조합하여 8자리 이상 입력해주세요.'
                {...register('password', {
                  required: '비밀번호를 입력해주세요.',
                  validate: hasValidPassword,
                })}
              />
              {errors.password && <p className={styles.errorMessage}>{errors.password?.message as string}</p>}
            </div>
          </div>
          <div className={styles.row}>
            <label>비밀번호 확인</label>
            <div className={styles.col}>
              <input
                type='password'
                placeholder='비밀번호를 한번 더 입력해주세요.'
                {...register('passwordConfirm', {
                  required: '비밀번호를 한번 더 입력해주세요.',
                  validate: checkPassword,
                })}
              />
              {errors.passwordConfirm && <p className={styles.errorMessage}>{errors.passwordConfirm?.message as string}</p>}
            </div>
          </div>
          <div className={styles.buttonWrapper}>
            <Button htmlType='button' style={{ marginRight: '8px' }} onClick={() => navigate('../user/enroll')}>
              주최사/참가사 등록하기
            </Button>
            <Button htmlType='submit'>저장하기</Button>
          </div>
        </form>
      </div>
    </section>
  );
};

export default EditUser;
