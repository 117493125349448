import React, { useEffect, useMemo, useState } from 'react';
import { DateSelectAndReset } from '../../../common/components/filters/DateSelectAndReset';
import styled from 'styled-components';
import { TableColumn } from 'react-data-table-component';
import { Popconfirm, message } from 'antd';

import { appRoutes } from '../../../AppRoutes';
import { useAllConferences } from '../../../common/utils/apiHooks';
import { Conference, User } from '../../../common/api/responseTypes';

import { Table } from '../../../common/components/Table';
import { FlexRow } from '../../../common/components/FlexRow';
import {
  FilledButton,
  FilterBoxContainer,
  FilterLabel,
  OutlineButton,
  SelectWrapper,
  SubTitle,
  Title,
} from '../../../common/components/StyledComponents';
import { formatDateString } from '../../../common/utils/utils';
import { SearchFilter } from '../../../common/components/filters/SearchFilter';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { axiosInstance } from '../../../common/providers & services/AxiosService';
import { ConferenceStatusIndicator } from '../../conference-info/components/ConferenceStatusIndicator';
import dayjs from 'dayjs';

type Props = {
  solutionId?: string;
  companySetupId?: string;
};
const initialState = {
  searchTerm: '',
  start: '',
  end: '',
  status: 'all',
};

// type DataObject = {
//   id: string;
//   user: User;
//   conference: Conference;
// };

//TODO: 올바른 백엔드 연결 필요
const SolutionParticipateInfo = ({ solutionId, companySetupId }: Props) => {
  const [filterParams, setFilterParams] = useState(initialState);
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();

  const fetchData = async () => {
    try {
      const response = await axiosInstance.get(`conferenceByCompany/${companySetupId}`);
      //   const response = await axiosInstance.get(`conferenceByCompany/18`);
      return response.data.data.conference;
    } catch (e) {
      console.error('error...');
      return {};
    }
  };

  const { data: conferenceData, isLoading } = useQuery(['conferenceByCompanyList', companySetupId], fetchData, {
    select: data => {
      const { searchTerm, status } = filterParams;
      const filteredData = data.filter((item: Conference) => {
        if (
          searchTerm &&
          !(
            item.name_kor?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.name_eng?.toLowerCase().includes(searchTerm.toLowerCase())
          )
        ) {
          return false;
        }

        // 상태가 'all'이 아니고 일치하지 않는 경우
        if (status !== 'all' && item.state !== status) {
          return false;
        }

        return true;
      });

      // return filteredData;
      return filteredData.map((item: any, index: number) => {
        return {
          ...item,
          listIdx: filteredData.length - index,
        };
      });
    },
  });

  //   const handleRowClick = (row: Conference) => {
  //     navigate(appRoutes.conferenceInfo.children.detail.path(row.id));
  //   };

  const excelData = useMemo(() => {
    if (conferenceData === undefined) return [];
    return conferenceData.map((item: Conference, index: number) => {
      return {
        번호: conferenceData.length - index,
        행사명_국문: item.name_kor,
        행사명_영문: item.name_eng,
        행사일시: `${dayjs(item.start_time).format('YYYY-MM-DD HH:mm')} ~ ${dayjs(item.end_time).format('YYYY-MM-DD HH:mm')}`,
        행사주소: `${item.location} ${item.location_detail} ${item.place}`,
        주최: JSON.parse(item.hosts).join(', '),
        주관: JSON.parse(item.organizers).join(', '),
        행사홈페이지: item.homepage,
        부스정보링크: item.booth_info_link,
        행사관련정보: item.description,
        PRUUF발급갯수: item?.pruuf_count,
        등록일: dayjs(item.created_at).format('YYYY-MM-DD HH:mm'),
      };
    });
  }, [conferenceData]);

  const handleDelete = async () => {
    try {
      await axiosInstance.put(`solutions/${solutionId}`, { is_deleted: true });
      navigate(appRoutes.solution.route);
    } catch (e) {
      console.error(e);
      messageApi.open({
        type: 'error',
        content: '삭제에 실패했습니다. 지속되면 문의해주세요.',
      });
    }
  };

  if (isLoading) {
    return <></>;
  }

  return (
    <Container gap={10}>
      {contextHolder}
      <FilterBoxContainer>
        <SearchFilter onChange={searchTerm => setFilterParams(prev => ({ ...prev, searchTerm }))} placeholder={'행사명'} debounce={150} />

        <FlexRow gap={'5px'} justify={'space-between'} width={'100%'}>
          <FlexRow gap={'5px'} justify={'flex-start'}>
            <FilterLabel>상태</FilterLabel>
            <SelectWrapper>
              <select value={filterParams.status} onChange={e => setFilterParams(prev => ({ ...prev, status: e.target.value }))}>
                <option value={'all'}>전체</option>
                <option value={'prepare'}>대기중</option>
                <option value={'progress'}>진행중</option>
                <option value={'complete'}>종료</option>
              </select>
            </SelectWrapper>
          </FlexRow>
        </FlexRow>
      </FilterBoxContainer>

      <Table columns={columns} data={conferenceData} filename={`주최사 정보`} listname={''} excelData={excelData} />

      <FlexRow justify={'flex-end'} gap={'1rem'} width={'100%'}>
        <FilledButton color={'dark-blue'} onClick={() => navigate(appRoutes.solution.route)}>
          솔루션 목록
        </FilledButton>

        {/* <OutlineButton height={35} onClick={() => navigate(`${appRoutes.host.route}/form/${hostId}`)}>
          주최사 수정
        </OutlineButton> */}

        <OutlineButton height={35}>
          <Popconfirm
            title='솔루션 삭제'
            description='정말로 솔루션을 삭제하시겠습니까?'
            okText='삭제'
            cancelText='취소'
            onConfirm={handleDelete}
          >
            솔루션 삭제
          </Popconfirm>
        </OutlineButton>
      </FlexRow>
    </Container>
  );
};

export default SolutionParticipateInfo;

const Container = styled('div')<{ gap: number }>`
  width: 1200px;
  height: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: ${props => props.gap}px;
`;

const columns: TableColumn<Conference>[] = [
  {
    name: '행사명',
    selector: row => row?.name_kor ?? '',
    grow: 1,
  },
  {
    name: '행사일자',
    selector: row => `${formatDateString(row.start_time)} ~ ${row.end_time}`,
    sortable: true,
    grow: 1,
  },
  {
    name: '행사장소',
    selector: row => row.location,
    sortable: true,
    grow: 1,
  },
  {
    name: '상태',
    // cell: row => <ConferenceStatusIndicator status={row.state} />,
    cell: row => <ConferenceStatusIndicator status={row.state} start={row.start_time} end={row.end_time} />,
    sortable: true,
    grow: 1,
  },
];
