import React, { useEffect, useMemo, useState } from 'react';

import { TableColumn } from 'react-data-table-component';
import { Container, FilterBoxContainer, SubTitle } from '../StyledComponents';
import { DateSelectAndReset } from '../filters/DateSelectAndReset';
import GenerationSelect from '../filters/GenerationSelect';
import { Modal } from '../Modal';
import LocationSelect from '../filters/LocationSelect';
import { RemoteTable } from '../RemoteTable';
import { ConferenceNftData } from '../../api/responseTypes';
import { getConferenceLectureNftData } from '../../api/conferenceClient';
import { Table } from '../../../pages/conference-info/components/Table';
import { dataFilter, filterDateRange } from '../../utils/dataFilters';
import { calcRowNumber } from '../../utils/utils';
import { useAppSelector } from '../../../store/store';
import { selectorConferenceName } from '../../../store/conference/conferenceSelectors';

interface Props {
  conferenceId: string;
  open: boolean;
  onClose: () => void;
  lectureId?: string;
}

const initialState = {
  location: 'all',
  generation: 'all',
  start: '',
  end: '',
};

export const LectureModal: React.FC<Props> = ({ conferenceId, open, onClose, lectureId }) => {
  const [filterParams, setFilterParams] = useState(initialState);
  const [lectureData, setLectureData] = useState<ConferenceNftData[]>();

  const conferenceName = useAppSelector(state => selectorConferenceName(state, conferenceId));

  useEffect(() => {
    if (lectureId) {
      getConferenceLectureNftData(conferenceId, lectureId).then(data => {
        setLectureData(data);
      });
    }
  }, [lectureId]);

  const filteredData = useMemo(() => {
    if (lectureData) {
      return lectureData.filter(
        p =>
          dataFilter('location', filterParams.location, p) &&
          dataFilter('generation', filterParams.generation, p.visitor) &&
          filterDateRange(filterParams.start, filterParams.end, [{ date: p.created_at }]),
      );
    } else return [];
  }, [lectureData, filterParams]);

  return (
    <Modal open={open} title={'강연 NFT 발급현황'} onClose={onClose}>
      <Container gap={10} width={'100%'}>
        <SubTitle>참가자 정보</SubTitle>
        <FilterBoxContainer>
          <LocationSelect onChange={location => setFilterParams(prev => ({ ...prev, location }))} label={'지역'} />
          <GenerationSelect onChange={generation => setFilterParams(prev => ({ ...prev, generation }))} />
          <DateSelectAndReset
            label={'발급일시'}
            onStartChange={start => setFilterParams(prev => ({ ...prev, start }))}
            onEndChange={end => setFilterParams(prev => ({ ...prev, end }))}
            onReset={() => setFilterParams(initialState)}
          />
        </FilterBoxContainer>
        {!lectureId && <RemoteTable columns={columns} type={2} conferenceId={conferenceId} params={filterParams} />}
        {lectureData && <Table data={filteredData} columns={columns} excelData={lectureData} filename={`${conferenceName}_참가자`} />}
      </Container>
    </Modal>
  );
};
const columns: TableColumn<ConferenceNftData>[] = [
  {
    name: '번호',
    selector: (row, i) => calcRowNumber(row, i),
    sortable: true,
  },
  {
    name: '이름',
    selector: row => row.visitor.name,
    sortable: true,
  },
  {
    name: '발급일시',
    selector: row => row.visitor.created_at,
    sortable: true,
  },
  {
    name: '지역',
    selector: row => row.visitor.location,
    sortable: true,
  },
  {
    name: '연령대',
    selector: row => `${row.visitor.generation}대`,
    sortable: true,
  },
];
