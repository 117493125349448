import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components/macro';
import { FilledButton, SubTitle, Title } from '../../common/components/StyledComponents';
import { QrResponse } from '../../common/api/responseTypes';
import QRCode from 'qrcode-svg-ts';
import { FlexRow } from '../../common/components/FlexRow';
import { getQrData } from '../../common/api/qrClient';
import { QrSlug } from '../../common/api/requestTypes';

export const Qr: React.FC = () => {
  const { slug, resourceId } = useParams();
  const [data, setData] = useState<QrResponse>();
  const qrCode = useRef<QRCode>();
  const canvas = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    if (slug && resourceId) {
      getQrData(slug as QrSlug, resourceId).then(res => setData(res));
    }
    generateQrCode();
  }, [slug, resourceId]);

  useEffect(() => {
    if (data) {
      generateQrCode();
      console.log(data);
    }
  }, [data]);

  const generateQrCode = async () => {
    const qrcode = new QRCode({
      content: data?.deep_link ?? '',
      width: 1200,
      height: 1200,
      padding: 5,
      ecl: 'Q',
    });

    const dataUrl = qrcode.toDataURL();
    const img = new Image();
    img.width = 1200;
    img.height = 1200;
    canvas.current!.width = 1200;
    canvas.current!.height = 1200;
    img.onload = () => {
      const ctx = canvas.current!.getContext('2d');
      // ctx!.scale(0.3, 0.3);
      ctx!.drawImage(img, 0, 0, 1200, 1200, 0, 0, 1200, 1200);
      canvas.current!.style.width = '450px';
      canvas.current!.style.height = '450px';
      canvas.current!.style.margin = 'auto';
    };
    img.src = dataUrl;

    qrCode.current = qrcode;
  };

  const saveSvg = () => {
    if (qrCode.current) {
      const downloadLink = document.createElement('a');
      downloadLink.setAttribute('download', 'qr.svg');
      const data = qrCode.current.svg();
      downloadLink.setAttribute('href', 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(data));
      downloadLink.click();
    }
  };

  const savePng = () => {
    if (canvas.current) {
      const downloadLink = document.createElement('a');
      downloadLink.setAttribute('download', 'qr.png');
      const data = canvas.current.toDataURL('image/png');
      downloadLink.setAttribute('href', data.replaceAll('image/png', 'image/octet-stream'));
      downloadLink.click();
    }
  };

  return (
    <Container>
      <Title>QR코드 다운로드</Title>
      <SubTitle>QR코드 정보 </SubTitle>
      {data && (
        <div>
          <span>{data.resource.name}</span>
        </div>
      )}
      <CanvasWrapper>
        <canvas ref={canvas}></canvas>
        <FlexRow gap={'10px'}>
          <FilledButton color={'charcoal'} onClick={savePng}>
            PNG 저장하기
          </FilledButton>
          <FilledButton color={'charcoal'} onClick={saveSvg}>
            SVG 저장하기
          </FilledButton>
        </FlexRow>
      </CanvasWrapper>
    </Container>
  );
};

const Container = styled.div`
  width: 1200px;
  height: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  padding-top: 50px;
  padding-bottom: 20px;
  gap: 30px;
`;

const CanvasWrapper = styled.div`
  padding: 40px 125px;
  margin: auto;
  border: 1px solid #dddddd;
  border-radius: 5px;
`;
