import styled from 'styled-components';

export const Container = styled('div')<{ gap: number; width?: string }>`
  width: ${props => (props.width ? props.width : '1200px')};
  height: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: ${props => props.gap}px;
`;

export const OutlineButton = styled('button')<{ height?: number }>`
  border: 1px solid #30384b;
  height: ${props => (props.height ? props.height : 30)}px;
  padding: 0 30px;
  font-size: 14px;
  outline: none;
  background-color: white;
  border-radius: 2px;
  cursor: pointer;
`;

export const FilledButton = styled('button')<{ height?: number; color: 'charcoal' | 'dark-blue' }>`
  background-color: var(--${props => props.color});
  color: white;
  border-radius: 4px;
  border: none;
  outline: none;
  font-size: 17px;
  padding: 0 30px;
  height: ${props => (props.height ? props.height : 35)}px;
  cursor: pointer;
`;

export const HTMLTable = styled.table`
  border-top: 2px solid black;
  width: 100%;
`;
export const Tr = styled.tr`
  width: 80%;

  &:hover {
    background-color: #f6f8fc;
  }

  &:first-child:hover {
    background-color: initial;
  }
`;
export const Th = styled.th`
  font-size: 15px;
  font-weight: 600;
  padding: 15px 30px;
  width: 130px;
  box-sizing: content-box;
  text-align: left;
  border-bottom: 1px solid #dddddd;
  border-right: 1px solid #dddddd;
`;
export const Td = styled.td`
  font-size: 14px;
  color: #444444;
  padding: 15px 30px;
  text-align: left;
  border-bottom: 1px solid #dddddd;

  & + & {
    border-left: 1px solid #dddddd;
  }
`;

export const Title = styled.div`
  font-size: 34px;
  font-weight: bold;
`;

export const SubTitle = styled('div')<{ marginBottom?: number }>`
  color: var(--blue);
  font-size: 20px;
  font-weight: bold;
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : 0)}px;
`;

export const FilterBoxContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
  border-top: 1px solid #dedede;
  border-bottom: 1px solid #dedede;
  padding: 20px 0 20px 26px;
`;

export const FilterLabel = styled.div`
  font-size: 15px;
  font-weight: 600;
  min-width: 64px;
`;

export const CheckboxLabel = styled.label`
  font-size: 14px;
  color: #444444;
`;

export const Input = styled('input')<{ width: number }>`
  width: ${props => props.width}px;
  height: 30px;
  outline: none;
  font-size: 14px;
  color: #444444;
  border: 1px solid #d2d2d2;
  padding: 0 10px;
`;

export const SelectWrapper = styled('div')<{ height?: number }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  height: 30px;
  font-size: 14px;

  select {
    flex-grow: 1;
    height: ${props => (props.height ? props.height : 30)}px;
    padding-left: 20px;
    padding-right: 40px;
    border: 1px solid #d2d2d2;
    background-color: white;
    -webkit-appearance: none;
    appearance: none;
    -moz-appearance: none;
    background-image: url('https://www.svgrepo.com/show/80156/down-arrow.svg');
    background-repeat: no-repeat;
    background-size: 14px 14px;
    background-position: calc(100% - 16px);
    font-size: 14px;
    color: #444444;
    cursor: pointer;
  }
`;

export const EventInfo = styled.div`
  font-size: 22px;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  gap: 9px;
`;

export const EventDate = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #999999;
  font-size: 15px;
  gap: 6px;

  i {
    color: #dddddd;
  }
`;

export const DataSelectorWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  gap: 10px;
`;

export const DataSelector = styled('div')<{ active: boolean; height?: number }>`
  width: 150px;
  height: ${props => props.height ?? 150}px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #dddddd;
  background-color: ${props => (props.active ? '#f5f5f5' : 'white')};
  cursor: pointer;

  h2 {
    font-size: 26px;
  }
`;

export const ViewData = styled.div`
  color: #4f57db;
  text-decoration: underline #4f57db;
  text-underline-offset: 4px;
`;
