import React from 'react';
import { FlexRow } from '../FlexRow';
import { FilterLabel, Input, OutlineButton } from '../StyledComponents';

interface Props {
  label: string;
  start?: string;
  end?: string;
  onStartChange: (val: string) => void;
  onEndChange: (val: string) => void;
  onReset?: () => void;
}

export const DateSelectAndReset: React.FC<Props> = ({ label, onStartChange, onEndChange, onReset, start, end }) => {
  return (
    <FlexRow gap={'5px'} justify={'space-between'} width={'100%'}>
      <FlexRow gap={'5px'} justify={'flex-start'}>
        <FilterLabel>{label}</FilterLabel>
        <Input type={'date'} value={start} width={125} onChange={e => onStartChange(e.target.value)} />
        <div>~</div>
        <Input type={'date'} value={end} width={125} onChange={e => onEndChange(e.target.value)} />
      </FlexRow>
      {onReset && (
        <OutlineButton height={40} onClick={onReset}>
          초기화
        </OutlineButton>
      )}
    </FlexRow>
  );
};
