import { RootState } from '../store';
import { createSelector } from '@reduxjs/toolkit';
import { EventStatus } from '../../common/api/responseTypes';
import { filterConferenceState, filterDateRange, searchFilter } from '../../common/utils/dataFilters';

export const selectorAllHostUsers = createSelector(
  [
    (state: RootState) => state.conference.hostUsers,
    (state: RootState, searchTerm?: string) => searchTerm,
    (state: RootState, searchTerm?: string, fromDate?: string) => fromDate,
    (state: RootState, searchTerm?: string, fromDate?: string, toDate?: string) => toDate,
    (state: RootState, searchTerm?: string, fromDate?: string, toDate?: string, status?: EventStatus) => status,
  ],
  (conferences, searchTerm, fromDate, toDate, status) => {
    return conferences.filter(conf => searchFilter(['name'], searchTerm ?? '', conf));
  },
);
