import { Link } from 'react-router-dom';
import CountUp from 'react-countup';

import styles from './system.monitors.module.css';
import { axiosInstance } from '../../../common/providers & services/AxiosService';
import { useQuery } from '@tanstack/react-query';
import LoadingIndicator from '../../../common/components/LoadingIndicator';
import { appRoutes } from '../../../AppRoutes';

const duration = 3;

const SystemMonitors = () => {
  const fetchData = async () => {
    try {
      const response = await axiosInstance.get('systemInfo');
      return response.data.data.dashboard;
    } catch (e) {
      console.error('error...');
      return [{}];
    }
  };

  const { data, isError, isLoading } = useQuery(['dashboard'], fetchData, {
    refetchOnWindowFocus: false,
    refetchInterval: 50000,
    refetchOnMount: true,
  });

  if (isError || isLoading) {
    return <LoadingIndicator />;
  }

  return (
    <section className={styles.wrap}>
      <div>
        <ul>
          <li>
            등록된 행사 :
            <strong>
              <CountUp end={data.conferences.enroll} duration={duration} separator=',' /> 개
            </strong>
          </li>
          <li>
            진행중 행사 :{' '}
            <strong>
              <CountUp end={data.conferences.process} duration={duration} separator=',' /> 개
            </strong>
          </li>
          <li>
            대기중 행사 :{' '}
            <strong>
              <CountUp end={data.conferences.prepare} duration={duration} separator=',' /> 개
            </strong>
          </li>
          <li>
            종료된 행사 :{' '}
            <strong>
              <CountUp end={data.conferences.end} duration={duration} separator=',' /> 개
            </strong>
          </li>
        </ul>
        <Link to={appRoutes.conferenceInfo.route}>행사등록/관리</Link>
      </div>

      <div>
        <ul>
          <li>
            가입된 주최사 :{' '}
            <strong>
              <CountUp end={data.hosts.total} duration={duration} separator=',' /> 개
            </strong>
          </li>
          <li>
            행사에 참가중인 주최사 :{' '}
            <strong>
              <CountUp end={data.hosts.conferenceEnter} duration={duration} separator=',' /> 개
            </strong>
          </li>
        </ul>
        <Link to={appRoutes.host.route}>주최사 관리</Link>
      </div>

      <div>
        <ul>
          <li>
            가입한 참가사 :{' '}
            <strong>
              {' '}
              <CountUp end={data.companies.total} duration={duration} separator=',' /> 개
            </strong>
          </li>
          <li>
            행사에 참가중인 참가사 :{' '}
            <strong>
              <CountUp end={data.companies.conferenceEnter} duration={duration} separator=',' /> 개
            </strong>
          </li>
          <li>
            등록된 솔루션 :{' '}
            <strong>
              <CountUp end={data.companies.solution} duration={duration} separator=',' /> 개
            </strong>
          </li>
        </ul>
        <Link to={appRoutes.company.route}>참가사 관리</Link>
      </div>

      <div>
        <ul>
          <li>
            가입한 참가자 :{' '}
            <strong>
              {' '}
              <CountUp end={data.visitors.total} duration={3} separator=',' /> 명
            </strong>
          </li>
          <li>
            행사에 참가중인 참가자 :{' '}
            <strong>
              {' '}
              <CountUp end={data.visitors.conferenceEnter} duration={3} separator=',' /> 명
            </strong>
          </li>
          <li>
            총 발급된 PRUUF :{' '}
            <strong>
              <CountUp end={data.visitors.totalPruuf} duration={3} separator=',' /> 개
            </strong>
          </li>
          <li>
            이번달 발급된 PRUUF :{' '}
            <strong>
              <CountUp end={data.visitors.monthPruuf} duration={3} separator=',' /> 개
            </strong>
          </li>
        </ul>
        <Link to={appRoutes.visitor.route}>참가자 관리</Link>
      </div>
    </section>
  );
};

export default SystemMonitors;
