import React, { useMemo, useState } from 'react';
import { TableColumn } from 'react-data-table-component';
import { Table } from '../../../common/components/Table';
import { Container, FilterBoxContainer, SubTitle } from '../../../common/components/StyledComponents';
import { SearchFilter } from '../../../common/components/filters/SearchFilter';
import { useNavigate } from 'react-router-dom';
import DefaultLayer from '../../../common/components/panels/defaultLayer';
import DefaultPopupPanel from '../../../common/components/panels/defaultPopupPanel';
import QrTemplate from './QrTemplate';
import { useQuery } from '@tanstack/react-query';
import { axiosInstance } from '../../../common/providers & services/AxiosService';
import PruufReport from './PruufReport';
import useUserInfo from '../../../hooks/useUserInfo';
import { Link } from 'react-router-dom';


interface Props {
  conferenceId: string;
}

const initialState = {
  searchTerm: '',
  start: '',
  end: '',
};

type LayerType = 'qr' | 'pruuf';

// Todo - solution column, detail view buttons, nft data, ranking + chart
export const ConferenceInfoDetailCompanies: React.FC<Props> = ({ conferenceId }) => {
  const [layerPopupOpen, setLayerPopupOpen] = useState(false);
  const [filterParams, setFilterParams] = useState(initialState);
  const [layerType, setLayerType] = useState<LayerType>('qr');
  const [layerValue, setLayerValue] = useState<string>('');
  const [templateValue, setTemplateValue] = useState<any>({});
  const navigate = useNavigate();

  const userInfo = useUserInfo();

  const fetchData = async () => {
    try {
      const response = await axiosInstance.get(`conferences/company?conference_id=${conferenceId}`);
      return response.data.data.companies;
    } catch (e) {
      console.error('error...');
      return [{}];
    }
  };

  const columns: TableColumn<any>[] = [
    {
      name: '번호',
      selector: row => row.listIdx,
      sortable: true,
      grow: 0,
    },
    {
      name: '참가사명',
      selector: row => row.company_setup?.name,
      sortable: true,
      grow: 1,
      cell: (row: any): React.ReactNode => {
        return userInfo.type === 'admin' ? (
          <Link to={`/company/${row.company_setup.users[0]?.id}`}>{row.company_setup?.name}</Link>
        ) : (
          row.company_setup?.name
        );
      }
      
    },
    
    {
      name: '담당자',
      selector: row => row.company_setup.users[0]?.name,
      sortable: true,
    },
    {
      name: '이메일',
      selector: row => row.company_setup.users[0]?.email,
      sortable: true,
    },
    {
      name: 'QR코드',
      sortable: true,
      cell: row => {
        const handleQRpopup = (id: string) => {
          setLayerPopupOpen(true);
          setLayerType('qr');
          const baseUrl = `${process.env.REACT_APP_API_URL}/api/v1/`;
          const getInfos = {
            type: 'company',
            company_setup_id: row.company_setup.id,
            conference_id: row.conference.id,
          };
          setTemplateValue(row);
          const searchParams = new URLSearchParams(getInfos);
          setLayerValue(`${baseUrl}pruufCollect?${searchParams}`);
        };
        // return (
        //   <span style={{ textDecoration: 'underline' }} onClick={() => handleQRpopup(row.id)}>
        //     다운로드
        //   </span>
        // );
        // 로그인한 사용자가 해당 참가사의 소속인 경우에만 다운로드 링크를 표시합니다.
        const loggedInUserCompanyId = userInfo.company_setup_id;
        const companySetupId = row.company_setup.id;
        if (loggedInUserCompanyId === companySetupId || userInfo.type === 'admin' || userInfo.type === 'host') {
          return (
            <span style={{ textDecoration: 'underline' }} onClick={() => handleQRpopup(row.id)}>
              다운로드
            </span>
          );
        } else {
          return null; // 다른 경우에는 아무것도 반환하지 않습니다.
        }
      },
    },
    {
      name: '발급된 PRUUF',
      cell: row => {
        const handleQRpopup = (id: string) => {
          setLayerPopupOpen(true);
          setLayerType('pruuf');
          setLayerValue(row.company_setup_id);
        };
        const loggedInUserCompanyId = userInfo.company_setup_id;
        const companySetupId = row.company_setup.id;
        if (loggedInUserCompanyId === companySetupId || userInfo.type === 'admin' || userInfo.type === 'host') {
        return (
          <span style={{ textDecoration: 'underline' }} onClick={() => handleQRpopup(row.id)}>
            {row.company_setup.pruufs.length}
          </span>
        );
      } else {
        return null; // 다른 경우에는 아무것도 반환하지 않습니다.
      }
      },
      sortable: true,
    },
  ];

  const cancleColmn: TableColumn<any> = {
    name: '행사 참가 취소',
    cell: row => {
      const handleRegistration = async (id: string) => {
        const confirmed = confirm('정말로 참가사를 행사 참여에서 취소시키겠습니까?\n복구할 수 없습니다.');
        if (confirmed) {
          const joinId = row.id;
          try {
            await axiosInstance.delete(`conferenceRegistration/${joinId}`);
            window.location.reload();
          } catch (e) {
            alert('다시 시도해주세요.');
          }

        }
        return false;
      };
      return (
        <span style={{ textDecoration: 'underline', cursor: 'pointer', color: '#777777' }}
              onClick={() => handleRegistration(row.id)}>
            {'취소시키기'}
          </span>
      );
    },
    sortable: false,
  };

  if (userInfo.type === 'admin') {
    columns.push(cancleColmn);
  }

  const {
    data: conferenceCompany,
    isLoading,
    isError,
  } = useQuery([], fetchData, {
    refetchOnWindowFocus: false,
    select: data => {
      const filteredData = data.filter((item: any) => {

        const filterHostReturn = item.company_setup.users.map((i: any) => {
          if (i.type !== 'host') {
            return true;
          }

          return i.company_setup_id !== item.company_setup_id;

        })
          .some((e: any) => e === false);

        if (filterHostReturn) {
          return false;
        }

        const { searchTerm } = filterParams;

        if (searchTerm && !item.company_setup?.name.includes(searchTerm)) {
          return false;
        }

        return true;
      });
      // return filteredData;
      return filteredData.map((item: any, index: number) => {
        return {
          ...item,
          listIdx: filteredData.length - index,
        };
      });
    },
  });

  const excelData = useMemo(() => {
    if (conferenceCompany?.length < 1 || conferenceCompany === undefined) return [];
    return conferenceCompany.map((item: any, index: number) => {
      return {
        번호: conferenceCompany.length - index,
        참가사행사명: item.conference.name_kor,
        참가사명: item.company_setup?.name,
        담당자: item.company_setup.users.map((row: any) => row.name).join(', '),
        이메일: item.company_setup.users.map((row: any) => row.email).join(', '),
        발급된PRUUF: item.company_setup.pruufs.length,
        등록일: item.created_at,
      };
    });
  }, [conferenceCompany]);

  if (isLoading || isError) return <></>;

  return (
    <Container gap={10}>
      {layerPopupOpen && (
        <DefaultLayer open={layerPopupOpen}>
          <DefaultPopupPanel
            actionClose={() => {
              setLayerPopupOpen(false);
            }}
            title={layerType === 'qr' ? 'QR코드 템플릿' : '참가사 방문 PRUUF 발급 현황'}
          >
            <>
              {layerType === 'qr' &&
                <QrTemplate link={layerValue} templateInfo={templateValue} templateType={'company'} />}
              {layerType === 'pruuf' &&
                <PruufReport listType={'company'} conference_id={conferenceId} company_setup_id={layerValue} />}
            </>
          </DefaultPopupPanel>
        </DefaultLayer>
      )}
      <SubTitle>참가사 정보</SubTitle>
      <FilterBoxContainer>
        <SearchFilter
          onChange={searchTerm => setFilterParams(prev => ({ ...prev, searchTerm }))}
          placeholder={'참가사 이름을 입력해주세요.'}
          debounce={150}
        />
        {/* <DateSelectAndReset
          label={'행사일자'}
          onStartChange={start => setFilterParams(prev => ({ ...prev, start }))}
          onEndChange={end => setFilterParams(prev => ({ ...prev, end }))}
          onReset={() => setFilterParams(initialState)}
        /> */}
      </FilterBoxContainer>

      <Table
        data={conferenceCompany}
        columns={columns}
        filename={`${conferenceCompany[0]?.conference?.name_kor || ''}_참가사`}
        excelData={excelData}
      />
    </Container>
  );
};
