import DataTable, { TableColumn } from 'react-data-table-component';
import { FlexRow } from './FlexRow';
import styled from 'styled-components';
import { ExcelButton } from './ExcelButton';

export const Table = <D extends { id: string | number }>({
  data,
  columns,
  onRowClick,
  rows,
  filename,
  listname,
  excelData,
  showExcel = true,
  selectableRows,
  onSelectedRowsChange,
  selectableRowSelected,
}: Props<D>): JSX.Element => {
  const getRowsPerPageOptions = (): number[] => {
    const val = [10, 25, 50, 100];
    if (rows) {
      val.unshift(rows);
    }
    return val;
  };
  return (
    <>
      <FlexRow justify={'space-between'} width={'100%'} style={{ borderBottom: '2px solid black', padding: '7px 0' }}>
        <TableInfo>
          {listname} 총 <b>{data.length} </b>건
        </TableInfo>
        {showExcel && <ExcelButton data={excelData ?? data} filename={filename} />}
      </FlexRow>
      <DataTable
        className={`data-table ${onRowClick ? 'clickable' : ''}`}
        columns={columns}
        data={data}
        pagination={true}
        paginationComponentOptions={{ rowsPerPageText: '페이지당 행 수' }}
        onRowClicked={onRowClick}
        noDataComponent={'데이터가 없습니다.'}
        paginationPerPage={rows}
        paginationRowsPerPageOptions={getRowsPerPageOptions()}
        selectableRows={selectableRows ? true : false}
        onSelectedRowsChange={onSelectedRowsChange}
        selectableRowSelected={selectableRowSelected}
      />
    </>
  );
};

interface Props<D extends { id: string | number }> {
  data: Array<D>;
  columns: TableColumn<D>[];
  onRowClick?: (row: D) => void;
  rows?: number;
  filename: string;
  listname?: string;
  excelData?: unknown[];
  showExcel?: boolean;
  selectableRows?: boolean;
  onSelectedRowsChange?: (selected: { allSelected: boolean; selectedCount: number; selectedRows: D[] }) => void;
  selectableRowSelected?: (row: D) => boolean;
}

const TableInfo = styled.div`
  color: #787878;
  font-size: 15px;
`;
