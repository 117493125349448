import React from 'react';

import styles from './formAlert.module.css';

type PropsType = {
  msg: string;
};

const FormAlert = ({ msg }: PropsType) => {
  return <small className={styles.alert}>{msg}</small>;
};

export default FormAlert;
