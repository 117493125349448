import React from 'react';
import { FilterLabel, SelectWrapper } from '../StyledComponents';
import { FlexRow } from '../FlexRow';

interface Props {
  onChange: (val: string) => void;
}

const GenerationSelect: React.FC<Props> = ({ onChange }) => {
  return (
    <FlexRow gap={'5px'} justify={'flex-start'}>
      <FilterLabel>연령대</FilterLabel>
      <SelectWrapper>
        <select onChange={e => onChange(e.target.value)}>
          <option value={'all'}>전체</option>
          <option value={'10'}>10대</option>
          <option value={'20'}>20대</option>
          <option value={'30'}>30대</option>
          <option value={'40'}>40대</option>
          <option value={'50'}>50대</option>
          <option value={'60'}>60대</option>
          <option value={'70'}>70대</option>
          <option value={'80'}>80대</option>
        </select>
      </SelectWrapper>
    </FlexRow>
  );
};

export default React.memo(GenerationSelect);
