/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useAppDispatch, useAppSelector } from '../../store/store';
import { selectorAllConferences, selectorAllOrganizers, selectorDetailedConference } from '../../store/conference/conferenceSelectors';
import {
  fetchAllCompanies,
  fetchAllConferences,
  fetchAllHosts,
  fetchAllOrganizers,
  fetchConferenceCertificate,
  fetchConferenceCompanies,
  fetchConferenceEvents,
  fetchConferenceLectures,
  fetchConferenceNftData,
  fetchConferenceProducts,
  fetchConferenceVisitors,
  fetchDetailedConference,
} from '../../store/conference/conferenceSlice';
import { selectorAxiosReady, selectorLocations, selectorNftTypes } from '../../store/app/appSelectors';
import { useEffect, useRef, useState } from 'react';
import { ConferenceNftData, EventStatus, NftDataResponse } from '../api/responseTypes';
import { fetchLocations, fetchNftTypes } from '../../store/app/appSlice';
import { ConferenceNftDataRequest } from '../api/requestTypes';
import { getConferenceNftData } from '../api/nftClient';
import { selectorAllHostUsers } from '../../store/host/hostSelectors';
import { selectorAllCompanyUsers } from '../../store/company/companySelectos';

export const useAllConferences = (searchTerm?: string, start?: string, end?: string, status?: EventStatus, listType?: string) => {
  const conferences = useAppSelector(state => {
    return selectorAllConferences(state, searchTerm, start, end, status);
  });
  const axiosReady = useAppSelector(selectorAxiosReady);

  const dispatch = useAppDispatch();

  const fetchCountRef = useRef(0);

  useEffect(() => {
    const fetchInterval = setInterval(() => {
      if (fetchCountRef.current < 5 && !conferences.length && axiosReady) {
        dispatch(fetchAllConferences({ listType }));
        fetchCountRef.current += 1;
      } else {
        clearInterval(fetchInterval);
      }
    }, 500);

    return () => {
      clearInterval(fetchInterval);
    };
  }, [axiosReady, conferences, listType]);

  return conferences;
};

export const useDetailedConference = (id?: string) => {
  const pending = useRef<string[]>([]);
  if (!id) return;
  const conference = useAppSelector(state => selectorDetailedConference(state, id));

  const axiosReady = useAppSelector(selectorAxiosReady);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (axiosReady && !conference && !pending.current.includes(id)) {
      pending.current = [...pending.current, id];
      dispatch(fetchDetailedConference(id));
    }

    if (conference) {
      pending.current = pending.current.filter(p => p !== conference?.id);
    }
  }, [axiosReady, conference]);

  if (conference) return conference;
};

type ConferenceFragment = 'visitors' | 'companies' | 'lectures' | 'events' | 'certificates' | 'products';
export const useFragment = <T>(fragment: ConferenceFragment, id?: string) => {
  if (!id) return;

  const conference = useDetailedConference(id);
  const axiosReady = useAppSelector(selectorAxiosReady);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (axiosReady && conference) {
      if (!conference[fragment].length) {
        switch (fragment) {
          case 'visitors':
            dispatch(fetchConferenceVisitors(id));
            break;
          case 'companies':
            dispatch(fetchConferenceCompanies(id));
            break;
          case 'lectures':
            dispatch(fetchConferenceLectures(id));
            break;
          case 'events':
            dispatch(fetchConferenceEvents(id));
            break;
          case 'certificates':
            dispatch(fetchConferenceCertificate(id));
            break;
          case 'products':
            dispatch(fetchConferenceProducts(id));
            break;
        }
      }
    }
  }, [axiosReady, conference]);

  if (conference) return conference[fragment] as T;
};

export const useChartData = (id?: string, type?: number) => {
  if (!id) return;

  const conference = useDetailedConference(id);
  const axiosReady = useAppSelector(selectorAxiosReady);
  const lastFetched = useRef<{ id?: string; type?: number }>();

  const dispatch = useAppDispatch();

  useEffect(() => {
    // Prevent refetching same data
    if (axiosReady && conference && (lastFetched.current?.id !== id || lastFetched.current?.type !== type)) {
      lastFetched.current = { id, type };
      dispatch(fetchConferenceNftData({ id, type }));
    }
  }, [axiosReady, conference, id, type]);

  return conference?.chartData;
};

export const useLocations = () => {
  const locations = useAppSelector(selectorLocations);
  const axiosReady = useAppSelector(selectorAxiosReady);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (axiosReady && !locations.length) dispatch(fetchLocations());
  }, [locations, axiosReady]);

  return locations;
};

export const usePaginatedNftData = (
  conferenceId: string,
  type: number,
  page: number,
  per_page: number,
  params: ConferenceNftDataRequest,
) => {
  const [data, setData] = useState<NftDataResponse<ConferenceNftData[]>>();

  const axiosReady = useAppSelector(selectorAxiosReady);
  const fetchData = async () => {
    const res = await getConferenceNftData(conferenceId, type, page, per_page, params);
    setData(res);
  };

  useEffect(() => {
    if (axiosReady) {
      fetchData();
    }
  }, [conferenceId, page, per_page, params]);
  return data;
};

export const useNftTypes = () => {
  const nftTypes = useAppSelector(selectorNftTypes);
  const axiosReady = useAppSelector(selectorAxiosReady);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (axiosReady && !nftTypes.length) {
      dispatch(fetchNftTypes());
    }
  }, [axiosReady, nftTypes]);

  return nftTypes;
};

export const useOrganizers = () => {
  const organizers = useAppSelector(selectorAllOrganizers);
  const axiosReady = useAppSelector(selectorAxiosReady);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!organizers.length) {
      dispatch(fetchAllOrganizers());
    }
  }, [axiosReady, organizers]);

  return organizers;
};

export const useHostUsers = () => {
  const hosts = useAppSelector(selectorAllHostUsers);
  const axiosReady = useAppSelector(selectorAxiosReady);

  const dispatch = useAppDispatch();

  useEffect(() => {
    //@ts-ignore
    if (hosts?.length && axiosReady) {
      dispatch(fetchAllHosts());
    }
  }, [axiosReady, hosts]);

  return hosts;
};

export const useCompanyUsers = () => {
  const companies = useAppSelector(selectorAllCompanyUsers);
  const axiosReady = useAppSelector(selectorAxiosReady);

  const dispatch = useAppDispatch();

  useEffect(() => {
    //@ts-ignore
    if (!companies?.length && axiosReady) {
      dispatch(fetchAllCompanies());
    }
  }, [axiosReady, companies]);

  return companies;
};
