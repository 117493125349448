/* eslint-disable no-useless-escape */
/* eslint-disable @typescript-eslint/ban-ts-comment */

export const formatPhoneNumber = (phoneNumber: string) => {
  return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3, 7)}-${phoneNumber.slice(7)}`;
};

export const formatBusinessNumber = (business_number: string) => {
  return `${business_number.slice(0, 3)}-${business_number.slice(3, 5)}-${business_number.slice(5)}`;
};

// 사업자번호 관련 input 처리
export const handleBusinessNumberInput = (business_number: string): string | false => {
  const regex = /[^0-9]/g;
  const onlyNumber = business_number.replace(regex, '');
  let resultValue = business_number;
  if (onlyNumber.substring(0, 2) === '02') {
    if (onlyNumber.length === 10) {
      const newBusinessNumber = onlyNumber.replace(/^(\d{0,2})(\d{0,2})(\d{0,5})$/g, '$1-$2-$3').replace(/\-{1,2}$/g, '');
      resultValue = newBusinessNumber;
    } else if (onlyNumber.length < 10) {
      const newBusinessNumber = onlyNumber.replace(/^(\d{0,2})(\d{0,2})(\d{0,4})$/g, '$1-$2-$3').replace(/\-{1,2}$/g, '');
      resultValue = newBusinessNumber;
    } else {
      return false;
    }
    return resultValue;
  }
  if (onlyNumber.length === 11) {
    //@ts-ignore
    const newBusinessNumber = onlyNumber.replace(/^(\d{0,3})(\d{0,2})(\d{0,6})$/g, '$1-$2-$3').replace(/\-{1,2}$/g, '');
    resultValue = newBusinessNumber;
  } else if (onlyNumber.length < 11) {
    //@ts-ignore
    const newBusinessNumber = onlyNumber.replace(/^(\d{0,3})(\d{0,2})(\d{0,5})$/g, '$1-$2-$3').replace(/\-{1,2}$/g, '');
    resultValue = newBusinessNumber;
  } else {
    return false;
  }

  return resultValue;
};

/* 이메일(email) 유효성 체크 (SolutionInsert에서 사용중) */
export const validEmail = (value: string) => {
  if (/^\S+@\S+\.\S+$/.test(value)) return true;
  return '이메일 형식이 맞지 않습니다.';
};


/* 비밀번호 유효성 검사 */
export const hasValidPassword = (value: string) => {
  if (!value || value.length === 0) {
    return undefined; // No error for empty password
  }

  const hasMinimumLength = value.length >= 8;
  const hasEnglishLetter = /[a-zA-Z]/.test(value);
  const hasNumber = /\d/.test(value);

  if (!(hasMinimumLength && hasEnglishLetter && hasNumber)) {
    return '영문 + 숫자 8자리 이상의 조합으로 입력해주세요.';
  }

  return undefined; // No error if the password is valid
};

export const checkPassword = (value: string, password: any) => {
  if (value !== password) {
    return '입력한 비밀번호와 일치하지 않습니다. ';
  }
  return undefined;
};

