import { AuthResponse } from '../api/responseTypes';

export const KEY_SAVED_AUTH = 'key_saved_auth';
export const setSavedAuth = (val: AuthResponse | null) => {
  val ? localStorage.setItem(KEY_SAVED_AUTH, JSON.stringify(val)) : localStorage.removeItem(KEY_SAVED_AUTH);
  window.dispatchEvent(new Event(KEY_SAVED_AUTH));
};
export const getSavedAuth = (): AuthResponse | null => {
  const res = localStorage.getItem(KEY_SAVED_AUTH);
  if (res) return JSON.parse(res);
  return null;
};

export const getSavedAccessToken = (): string => {
  const res = localStorage.getItem(KEY_SAVED_AUTH);
  if (res) {
    const auth = JSON.parse(res) as AuthResponse;
    return auth.accessToken ?? '';
  }
  return '';
};

export const getSavedRefreshToken = () => {
  const res = localStorage.getItem('KEY_AUTH');
  if (res) {
    const auth = JSON.parse(res) as AuthResponse;
    return auth.refreshToken ?? '';
  }
  return '';
};

const KEY_REFRESH_RETRIES = 'key_refresh_retries';
export const getRefreshRetries = (): number | null => {
  const res = sessionStorage.getItem(KEY_REFRESH_RETRIES);
  if (res) {
    return parseInt(res, 10);
  }
  return null;
};

export const setRefreshRetries = (val: number | null) => {
  val ? sessionStorage.setItem(KEY_REFRESH_RETRIES, val.toString(10)) : sessionStorage.removeItem(KEY_REFRESH_RETRIES);
};
