import { getApiUrl } from '../providers & services/AxiosService';
import { QrSlug } from './requestTypes';
import { QrResponse } from './responseTypes';
import axios from 'axios';

export const getQrData = async (slug: QrSlug, resourceId: string): Promise<QrResponse> => {
  let baseUrl = getApiUrl();
  baseUrl = baseUrl.substring(0, baseUrl.indexOf('v1/'));
  const { data } = await axios.get(`${baseUrl}qr/${slug}/${resourceId}`);
  return data.data.qr;
};
