import { axiosInstance } from '../providers & services/AxiosService';
import { ConferenceNftDataRequest, NftDataRequest } from './requestTypes';
import { parseUrlParams } from '../utils/utils';
import { ConferenceNftData, NftData, NftDataResponse, NftType } from './responseTypes';

export const getAllNftData = async (page: number, per_page: number, params: NftDataRequest): Promise<NftDataResponse<NftData[]>> => {
  const { data } = await axiosInstance.get(`nfts?page=${page}&per_page=${per_page}&${parseUrlParams(params)}`);
  return data.data.nfts;
};

export const getNftTypes = async (): Promise<NftType[]> => {
  const { data } = await axiosInstance.get(`nfts/types`);
  return data.data.types;
};

export const getConferenceNftData = async (
  conferenceId: string,
  type: number,
  page: number,
  per_page: number,
  params: ConferenceNftDataRequest,
): Promise<NftDataResponse<ConferenceNftData[]>> => {
  const { data } = await axiosInstance.get(
    `conferences/${conferenceId}/nfts?page=${page}&type=${type}&per_page=${per_page}&${parseUrlParams(params)}`,
  );
  return data.data.nfts;
};
