import { Button, notification } from 'antd';
import styles from './enrollUser.module.css';
import { useForm } from 'react-hook-form';

const EnrollUser = () => {
  const [api, contextHolder] = notification.useNotification();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const enrollment = (data: { [key: string]: string }) => {
    console.log(data);
    api.success({ message: '등록이 완료되었습니다.', placement: 'top' });
  };

  const validateEmail = (value: string) => {
    if (/^\S+@\S+\.\S+$/.test(value)) return true;
    return '이메일 형식이 맞지 않습니다.';
  };

  return (
    <section className={styles.container}>
      {contextHolder}
      <div>
        <h2>주최사/참가사 등록하기</h2>
        <h4>기본정보</h4>
        <form className={styles.form} onSubmit={handleSubmit(enrollment)}>
          <div className={styles.row}>
            <label>이름</label>
            <input type='text' placeholder='주최사/참가사명을 입력하세요.' {...register('company')} />
          </div>
          <div className={styles.row}>
            <label>담당자</label>
            <input type='text' placeholder='담당자명을 입력해주세요.' {...register('name')} />
          </div>
          <div className={styles.row}>
            <label>담당자 연락처</label>
            <input type='text' placeholder='담당자 연락처를 입력해주세요.' {...register('phone')} />
          </div>
          <div className={styles.row}>
            <label>이메일(ID)</label>
            <div className={styles.col}>
              <input
                type='text'
                placeholder='이메일을 입력해주세요.'
                {...register('email', {
                  required: '이메일을 입력해주세요.',
                  validate: validateEmail,
                })}
              />
              {errors.email && <p className={styles.errorMessage}>{errors.email?.message as string}</p>}
            </div>
          </div>
          <div className={styles.buttonWrapper}>
            <Button htmlType='submit'>등록하기</Button>
          </div>
        </form>
      </div>
    </section>
  );
};

export default EnrollUser;
