import React from 'react';
import styled from 'styled-components';

export const AppFooter: React.FC = () => {
  return (
    <Footer>
      <div>Copyright©JiranSecurity. All Rights Reserved</div>
    </Footer>
  );
};

const Footer = styled.div`
  width: 100%;
  color: #666666;
  font-size: 14px;
  padding-top: 15px;
  padding-bottom: 70px;
  border-top: 1px solid #dddddd;
  position: relative;
  bottom: 0;
  left: 0;

  div {
    width: 100%;
    max-width: 1200px;
    text-align: end;
    margin: auto;
  }
`;
