import React from 'react';
import XLSX from 'sheetjs-style';
import { OutlineButton } from './StyledComponents';

interface Props {
  data: unknown[];
  filename: string;
}

const contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
export const ExcelButton: React.FC<Props> = ({ data, filename }) => {
  const downloadXlsx = () => {
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const buffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' }) as Buffer;

    const blob = new Blob([buffer], { type: contentType });

    const a = document.createElement('a');
    document.body.appendChild(a);
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = `${filename}.xlsx`;
    a.click();
    window.URL.revokeObjectURL(url);
  };

  return <OutlineButton onClick={downloadXlsx}>엑셀 다운로드</OutlineButton>;
};
