import React, { useEffect } from 'react';
import ReactModal from 'react-modal';
import styled from 'styled-components';

export const Modal: React.FC<Props> = ({ open, title, children, onClose }) => {
  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [open]);

  return (
    <ReactModal
      isOpen={open}
      ariaHideApp={false}
      shouldCloseOnOverlayClick={true}
      onRequestClose={onClose}
      style={{
        overlay: {
          backgroundColor: 'rgba(0,0,0,0.5)',
        },
        content: {
          width: '900px',
          // minHeight: '738px',
          height: 'fit-content',
          maxHeight: '90vh',
          top: '50%',
          left: '50%',
          transform: 'translateX(-50%) translateY(-50%)',
          padding: 0,
          border: 'none',
          borderRadius: '10px',
        },
      }}
    >
      <Container>
        <Header>
          <div>{title}</div>
          <img src={'/images/common/x-close.svg'} onClick={onClose} />
        </Header>
        <Body>{children}</Body>
      </Container>
    </ReactModal>
  );
};

interface Props {
  open: boolean;
  title: string;
  children: React.ReactNode;
  onClose: () => void;
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  background-color: #2950a7;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  color: white;
  font-size: 20px;
  font-weight: bold;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 100000;

  img {
    height: 100%;
    cursor: pointer;
  }
`;

const Body = styled.div`
  padding: 50px;
`;
