import React, { useState } from 'react';
import { HTMLTable, SubTitle, Td, Th, Tr } from '../../../common/components/StyledComponents';
import { Conference, ConferenceDetail } from '../../../common/api/responseTypes';
import { Image, message, Tooltip } from 'antd';

import styles from './conferenceInfoDetailIntro.module.css';
import DefaultLayer from '../../../common/components/panels/defaultLayer';
import DefaultPopupPanel from '../../../common/components/panels/defaultPopupPanel';
import PruufChart from '../../pruuf-status/components/PruufChart';
import type { GraphType } from '../../pruuf-status/PruufStatus';
import { axiosInstance } from '../../../common/providers & services/AxiosService';
import { useQuery } from '@tanstack/react-query';
import LoadingIndicator from '../../../common/components/LoadingIndicator';
import dayjs from 'dayjs';
import PruufBadge from '../../../common/components/pruuf/Badge';
import { defaultConferenceKey } from '../../../lib/crypto';
import { CopyOutlined } from '@ant-design/icons';
import useUserInfo from '../../../hooks/useUserInfo';
import { Link } from 'react-router-dom';

interface Props {
  data: ConferenceDetail;
  id: number | string;
  joinCheck?: boolean;
}

type Badge = 'conference' | 'visitor' | 'event' | 'solution';
type PruufType = 'event' | 'product' | 'company' | 'conference' | 'solution'
let dataAll: any[] = [];

type BadegeProps = {
  url: string;
  type: Badge;
  data: Conference;
};

const BadgeDetail = ({ url, type, data }: BadegeProps) => {
  let title = '';
  let desc = '';
  switch (type) {
    case 'conference':
      title = data.name_kor as string;
      desc = `${data.name_kor as string}에 참석하여 받으신 PRUUF 입니다.`;
      break;
    case 'event':
      title = data.name_kor as string;
      desc = `${data.name_kor as string}에서 이벤트에 당첨되어 받으신 PRUUF 입니다.`;
      break;
    case 'solution':
      title = data.name_kor as string;
      desc = `${data.name_kor as string}에서 "" 제품을 체험하여 받으신 PRUUF 입니다.`;
      break;
    case 'visitor':
      title = data.name_kor as string;
      desc = `${data.name_kor as string}에서 ""에 방문하여 받으신 PRUUF 입니다.`;
      break;

    default:
      break;
  }

  return (
    <article className={styles.badgeWrap}>
      <h1>PRUUF 상세</h1>
      <Image src={url} />
      <div>
        <h1>{title}</h1>
        <span>{desc}</span>
      </div>
    </article>
  );
};

export const ConferenceInfoDetailIntro: React.FC<Props> = ({ data, id, joinCheck }) => {
  const [modal, setModal] = useState<string>();
  const [selectedType, setSelectedType] = useState(0);
  const [graphState, setGraphState] = useState<GraphType>('all');
  const [layerPopupOpen, setLayerPopupOpen] = useState(false);
  const [layerType, setLayerType] = useState<Badge>('conference');
  const [badgeUrl, setBadgeUrl] = useState('');

  const [badgeType, setBadgeType] = useState<PruufType>('conference');

  const [badgeInfo, setBadgeInfo] = useState('');

  const [messageApi, contextHolder] = message.useMessage();

  //유저 권한 확인
  const userInfo = useUserInfo();

  const actionClose = () => {
    setLayerPopupOpen(false);
  };
  const handleBadge = (type: Badge) => {
    setLayerPopupOpen(true);
    setLayerType(type);

    if (type === 'conference') {
      setBadgeType('conference');
      setBadgeInfo(data.conference.description as string);
    }
    if (type === 'visitor') {
      setBadgeType('company');
      setBadgeInfo('참가사 이름이 들어갑니다.');
    }
    if (type === 'solution') {
      setBadgeType('solution');
      setBadgeInfo('솔루션명이 들어갑니다.');
    }
    if (type === 'event') {
      setBadgeType('event');
      setBadgeInfo('행사 이름이 들어갑니다.');
    }

    setBadgeUrl('');
    if (data.conference.conference_badge && type === 'conference') {
      setBadgeUrl(JSON.parse(data.conference.conference_badge)[0]['full_path']);
    }
    if (data.conference.company_badge && type === 'visitor') {
      setBadgeUrl(JSON.parse(data.conference.company_badge)[0]['full_path']);
    }
    if (data.conference.solution_badge && type === 'solution') {
      setBadgeUrl(JSON.parse(data.conference.solution_badge)[0]['full_path']);
    }
    if (data.conference.event_badge && type === 'event') {
      setBadgeUrl(JSON.parse(data.conference.event_badge)[0]['full_path']);
    }
  };

  //그래프 UI 표기용 데이터 통신
  const fetchData = async () => {
    try {
      const getInfos = {
        listType: graphState,
        start_date: dayjs(data.conference?.start_time).format('YYYY-MM-DD'),
        end_date: dayjs(data.conference?.end_time).format('YYYY-MM-DD'),
        conference_id: data.conference?.id,
      };
      const searchParams = new URLSearchParams(getInfos);
      const response = await axiosInstance.get(`pruufs?${searchParams}`);

      return response.data.data.pruufs;
    } catch (e) {
      console.error('error...', e);
      return [{}];
    }
  };

  const {
    data: apiData,
    isLoading,
    isError,
  } = useQuery(['pruufStatus', graphState, id], fetchData, {
    refetchOnWindowFocus: false,
    notifyOnChangeProps: ['data'],
    select: data => {
      // data 변수에 필터링된 데이터를 저장
      const filteredData = data //
        // .filter((item: any) => {
        //   const { searchTerm, searchType } = filterParams;

        //   if (searchType === 'all' && searchTerm) {
        //     // 모든 조건에 대해 OR 검색을 수행합니다.
        //     return (
        //       item.conference.name_kor.includes(searchTerm) ||
        //       item.company_setup.name.includes(searchTerm) ||
        //       item.user.name.includes(searchTerm)
        //     );
        //   }

        //   if (searchType === 'conference' && searchTerm && !item.conference.name_kor.includes(searchTerm)) {
        //     return false;
        //   }

        //   if (searchType === 'host' && searchTerm && !item.company_setup.name.includes(searchTerm)) {
        //     return false;
        //   }

        //   if (searchType === 'company' && searchTerm && !item.company_setup.name.includes(searchTerm)) {
        //     return false;
        //   }

        //   if (searchType === 'user' && searchTerm && !item.user.name.includes(searchTerm)) {
        //     return false;
        //   }
        //   return true;
        // }) //
        .map((item: any) => {
          const { type } = item;
          let typeToText = '전체';
          switch (type) {
            case 'conference':
              typeToText = '행사 참여';
              break;
            case 'company':
              typeToText = '참가사 방문';
              break;
            case 'solution':
              typeToText = '솔루션 체험';
              break;
            case 'event':
              typeToText = '이벤트';
              break;
            default:
              typeToText = '전체';
              break;
          }
          return { ...item, typeToText };
        });

      // graphState가 'all'인 경우 dataAll 변수에 복사
      if (graphState === 'all') {
        dataAll = [...filteredData];
      }

      return filteredData;
    },
  });

  //그래프 UI 표기용 Type state 처리
  const handleGraphState = (state: GraphType) => {
    setGraphState(state);
  };

  const conferenceCode = window.btoa(`${defaultConferenceKey}${data.conference.id}`);

  const handleCipboardCopy = () => {
    // 텍스트를 클립보드에 복사
    const textArea = document.createElement('textarea');
    textArea.value = conferenceCode;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('copy');
    document.body.removeChild(textArea);

    messageApi.open({
      key: 'RegisApiMsg',
      type: 'success',
      content: '클립보드에 복사되었습니다.',
    });

  };

  if (isLoading || isError) {
    return <LoadingIndicator />;
  }

  return (
    <>
      <SubTitle marginBottom={-20}>기본정보</SubTitle>

      {contextHolder}
      <HTMLTable>
        <tbody>
        <Tr>
          <Th>행사명(국문)</Th>
          <Td>{data.conference.name_kor}</Td>
        </Tr>
        <Tr>
          <Th>행사명(영문)</Th>
          <Td>{data.conference.name_eng}</Td>
        </Tr>
        <Tr>
          <Th>행사일시</Th>
          <Td>{`${dayjs(data.conference.start_time).format('YYYY-MM-DD HH:mm')} ~ ${dayjs(data.conference.end_time).format(
            'YYYY-MM-DD HH:mm',
          )}`}</Td>
        </Tr>
        <Tr>
          <Th>행사주소</Th>
          <Td>{`${data.conference.location} ${data.conference.location_detail} ${data.conference.place}`}</Td>
        </Tr>
        {
          data.conference?.conference_registrations[0]?.company_setup?.name &&
          (
            <Tr>
              <Th>주최사 정보</Th>
              <Td>
                {
                  userInfo.type !== 'admin' ? (
                    data.conference?.conference_registrations[0]?.company_setup?.name
                  ) : (
                    <Link to={`/host/${data.conference?.conference_registrations[0]?.company_setup.users[0]?.id}`}>
                      {data.conference?.conference_registrations[0]?.company_setup?.name}
                    </Link>
                  )
                }
              </Td>
            </Tr>
          )
        }

        <Tr>
          <Th>주최</Th>
          <Td>{data.conference.hostsParsed.join(', ')}</Td>
        </Tr>
        <Tr>
          <Th>주관</Th>
          <Td>{data.conference.organizersParsed.join(', ')}</Td>
        </Tr>
        <Tr>
          <Th>PRUUF 배지</Th>
          <Td>
            {/*{data.conference.conference_badge && (*/}
            {/*  <span className={styles.badge} onClick={() => handleBadge('conference')}>*/}
            {/*      행사 참가 배지 보기*/}
            {/*    </span>*/}
            {/*)}*/}

            {/*{data.conference.company_badge && (*/}
            {/*  <span className={styles.badge} onClick={() => handleBadge('visitor')}>*/}
            {/*      참가자 방문 배지 보기*/}
            {/*    </span>*/}
            {/*)}*/}

            {/*{data.conference.event_badge && (*/}
            {/*  <span className={styles.badge} onClick={() => handleBadge('event')}>*/}
            {/*      이벤트 배지 보기*/}
            {/*    </span>*/}
            {/*)}*/}

            <span className={styles.badge} onClick={() => handleBadge('conference')}>
                  행사 참가 배지 보기
                </span>
            <span className={styles.badge} onClick={() => handleBadge('visitor')}>
                  참가자 방문 배지 보기
                </span>
            <span className={styles.badge} onClick={() => handleBadge('solution')}>
                  솔루션 체험 배지 보기
                </span>
            <span className={styles.badge} onClick={() => handleBadge('event')}>
              이벤트 배지 보기
            </span>

          </Td>
        </Tr>

        <Tr>
          <Th>행사 홈페이지</Th>
          <Td>
            <a target='_blank' rel='noopener noreferrer' href={data.conference.homepage}>
              {data.conference.homepage}
            </a>
          </Td>
        </Tr>
        <Tr>
          <Th>부스정보 링크</Th>
          <Td>
            <a target='_blank' rel='noopener noreferrer' href={data.conference.booth_info_link}>
              {data.conference.booth_info_link}
            </a>
          </Td>
        </Tr>
        <Tr>
          <Th>행사 관련 정보</Th>
          <Td>{data.conference.description}</Td>
        </Tr>

        {
          userInfo.type !== 'company' &&
          <Tr>
            <Th>행사 참가 코드</Th>
            <Td>
              <Tooltip placement={'bottom'} title={'클릭하여 복사'}>
              <span className={styles.copy} onClick={handleCipboardCopy}>
                {conferenceCode} <CopyOutlined />
              </span>
              </Tooltip>
            </Td>
          </Tr>
        }

        </tbody>
      </HTMLTable>
      {layerPopupOpen && (
        <DefaultLayer open={layerPopupOpen}>
          <DefaultPopupPanel
            actionClose={actionClose}
            title={`${
              layerType === 'conference'
                ? '행사 참가'
                : layerType === 'event'
                  ? '이벤트'
                  : layerType === 'visitor'
                    ? '참가사'
                    : '솔루션정보'
            } PRUUF 배지`}
          >
            <>
              {/*<BadgeDetail url={badgeUrl} type={layerType} data={data.conference} />*/}
              <PruufBadge koName={data.conference.name_kor as string} engName={data.conference.name_eng as string}
                          type={badgeType}
                          info={badgeInfo} url={badgeUrl} />
            </>
          </DefaultPopupPanel>
        </DefaultLayer>
      )}

      {joinCheck && (
        <>
          <SubTitle marginBottom={-20}>PRUUF 발급현황</SubTitle>
          <PruufChart apiData={apiData} apiTotalData={dataAll} onTypeChange={handleGraphState}
                      graphState={graphState} />
        </>
      )}
    </>
  );
};
