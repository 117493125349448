import React, { useEffect, useRef } from 'react';
import axios, { AxiosRequestConfig } from 'axios';
import { useAppDispatch } from '../../store/store';
import { getSavedAccessToken } from '../utils/storage';
import { setAxiosReady } from '../../store/app/appSlice';
import { useAuth } from './AuthProvider';
import { tokenExpired } from './TokenRefreshService';
import { refreshToken } from '../api/authClient';

export const getApiUrl = () => {
  return `${process.env.REACT_APP_API_URL}/api/v1/`;
};

const contentType = 'Content-Type';

export const axiosInstance = axios.create({
  baseURL: getApiUrl(),
  timeout: 10000,
});

export const AxiosService: React.FC = () => {
  const isRefreshing = useRef<boolean>(false);
  const requestQueue = useRef<AxiosRequestConfig[]>([]);
  const dispatch = useAppDispatch();
  const isLoggedIn = useAuth();

  useEffect(() => {
    if (isLoggedIn) {
      axiosApplyConfig();
      dispatch(setAxiosReady(true));

      // 로그인 한 회원 정보 저장
      axiosInstance.get('getUserInfo').then(res => {
        const { data } = res;
        const userInfo = { ...data.userInfo };
        localStorage.setItem('userInfo', JSON.stringify({ ...userInfo }));
      });
    }
  }, [isLoggedIn]);

  const axiosApplyConfig = () => {
    axiosInstance.defaults.headers.common[contentType] = 'application/json; charset=utf-8';
    axiosInstance.defaults.headers.common['ngrok-skip-browser-warning'] = 'any';

    axiosInstance.interceptors.request.use(async config => {
      if (isRefreshing.current) return requestQueue.current.push(config);

      const { expired } = tokenExpired();

      if (!expired) {
        config.headers!.Authorization = `Bearer ${getSavedAccessToken()}`;
        return config;
      }

      if (expired) {
        isRefreshing.current = true;
        try {
          await refreshToken();
        } catch {
          isRefreshing.current = false;
          requestQueue.current = [];
          return;
        }
        config.headers!.Authorization = `Bearer ${getSavedAccessToken()}`;
        requestQueue.current.forEach(c => {
          c.headers!.Authorization = `Bearer ${getSavedAccessToken()}`;
          Promise.resolve(c);
        });
        isRefreshing.current = false;
        requestQueue.current = [];
        return config;
      }
    }, undefined);
  };

  return null;
};
