/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { FlexRow } from '../../common/components/FlexRow';
import { ConferenceStatusIndicator } from './components/ConferenceStatusIndicator';
import { FilledButton, OutlineButton, Title } from '../../common/components/StyledComponents';
import { useDetailedConference } from '../../common/utils/apiHooks';
import { appRoutes } from '../../AppRoutes';
import { ConferenceInfoDetailIntro } from './components/ConferenceInfoDetailIntro';
import { ConferenceInfoDetailVisitors } from './components/ConferenceInfoDetailVisitors';
import { ConferenceInfoDetailCompanies } from './components/ConferenceInfoDetailCompanies';
import { ConferenceInfoDetailProducts } from './components/ConferenceInfoDetailProducts';
import { ConferenceInfoDetailLectures } from './components/ConferenceInfoDetailLectures';
import { ConferenceInfoDetailCertificate } from './components/ConferenceInfoDetailCertificate';
import { uniqId } from '../../lib/function';
import { axiosInstance } from '../../common/providers & services/AxiosService';
import { message, Popconfirm } from 'antd';
import { useAppDispatch } from '../../store/store';
import { fetchAllConferences } from '../../store/conference/conferenceSlice';
import useUserInfo from '../../hooks/useUserInfo';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import ConferenceSolutionForm from './components/ConferenceSolutionForm';
import { eventStateToHumanStringFromDate } from '../../common/utils/utils';
import SolutionSelect from '../solution-info/components/SolutionSelect';
import { ConferenceInfoDetailEvents } from './components/ConferenceInfoDetailEvents';
import EventInsert from './components/EventInsert';

// 행사 탭 메뉴
const tabs = [
  { id: uniqId(), hash: 'intro', title: '행사소개' },
  { id: uniqId(), hash: 'companies', title: '참가사 정보' },
  { id: uniqId(), hash: 'products', title: '솔루션 정보' },
  { id: uniqId(), hash: 'visitors', title: '참가자 정보' },
  { id: uniqId(), hash: 'events', title: '이벤트 정보' },
];

const hostTabs = [
  { id: uniqId(), hash: 'intro', title: '행사소개' },
  { id: uniqId(), hash: 'companies', title: '참가사 정보' },
  { id: uniqId(), hash: 'products', title: '솔루션 정보' },
  { id: uniqId(), hash: 'visitors', title: '참가자 정보' },
  { id: uniqId(), hash: 'events', title: '이벤트 정보' },
];

const companyTabs = [
  { id: uniqId(), hash: 'intro', title: '행사소개' },
  { id: uniqId(), hash: 'companies', title: '참가사 정보' },
  { id: uniqId(), hash: 'products', title: '솔루션 정보' },
];

export const ConferenceInfoDetail: React.FC = () => {
  const { id } = useParams();
  const [messageApi, contextHolder] = message.useMessage();

  const navigate = useNavigate();
  const { hash } = useLocation();
  const [eventForm, setEventForm] = useState(false);
  const [solutionForm, setSolutionForm] = useState(false);
  const userInfo = useUserInfo();

  const queryClient = useQueryClient();

  const dispatch = useAppDispatch();

  let joinId = 0;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    // console.log('hash', hash);
    // const selectedTab = sessionStorage.getItem('conference_selected_tab');
    // if (!hash.includes('#intro') && selectedTab) navigate(selectedTab);
  }, [hash]);

  const conference = useDetailedConference(id);

  const handleSelectedTab = (selected: string) => {
    setEventForm(false);
    selected.includes('#intro')
      ? sessionStorage.removeItem('conference_selected_tab')
      : sessionStorage.setItem('conference_selected_tab', selected);

    const listType = hash.split('?').reverse()[0];

    if (listType && hash.split('?').length > 1) {
      navigate(`${selected}?${listType}`);
    } else {
      navigate(selected);
    }
  };

  //행사 상태 확인 (대기 진행 종료)
  const startTime = conference?.conference.start_time;
  const endTime = conference?.conference.end_time;

  // @ts-ignore
  const conferenceState = eventStateToHumanStringFromDate(startTime, endTime);

  const handleDeleteConference = async () => {
    if (!id) return false;

    try {
      messageApi.open({
        key: 'apiMsg',
        type: 'loading',
        content: '삭제중',
        duration: 0,
      });
      //@ts-ignore
      await axiosInstance.delete(`conferences/${id}`);
      messageApi.open({
        key: 'apiMsg',
        type: 'success',
        content: '성공적으로 삭제되었습니다.',
        onClose: () => {
          //@ts-ignore
          dispatch(fetchAllConferences());
          navigate(appRoutes.conferenceInfo.route);
        },
      });
    } catch (e) {
      console.error('행사 삭제 에러');
      messageApi.open({
        key: 'apiMsg',
        type: 'error',
        content: '행사 삭제에 실패하였습니다. 지속되면 문의해주세요.',
      });
    }
  };

  const showTabs = userInfo.type === 'admin' ? tabs : userInfo.type === 'host' ? hostTabs : companyTabs;

  const fetchData = async () => {
    try {
      const response = await axiosInstance.get(`joinCheck/${id}/${userInfo.company_setup_id}`);
      return response.data.data.joinCheck;
    } catch (e) {
      console.error('error...', e);
      return [{}];
    }
  };

  //내가 참가한 행사인지 확인하기....
  const {
    data: joinCheck,
    isLoading,
    isError,
  } = useQuery(['conferenceJoinCheck', id, userInfo.id], fetchData, {
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    select: data => {
      // return data?.id;
      joinId = data?.id;
      return data?.id && conferenceState !== '종료';
    },
  });

  const handleRegistration = async (type: 'insert' | 'delete', registrationId?: '') => {
    messageApi.open({
      key: 'RegisApiMsg',
      type: 'loading',
      content: '변경중',
      duration: 0,
    });

    try {
      if (type === 'delete') {
        await axiosInstance.delete(`conferenceRegistration/${joinId}`);
      } else {
        await axiosInstance.post('conferenceRegistration', {
          company_setup_id: userInfo.company_setup_id,
          conference_id: id,
        });
      }

      messageApi.open({
        key: 'RegisApiMsg',
        type: 'success',
        content: '성공적으로 처리되었습니다.',
        duration: 1,
        onClose: () => {
          //@ts-ignore
          dispatch(fetchAllConferences());
          const listType = hash.split('?').reverse()[0];
          const listUrl = listType ? `${appRoutes.conferenceInfo.path()}?${listType}` : appRoutes.conferenceInfo.path();
          navigate(listUrl);
        },
      });
    } catch (e) {
      console.error(e);

      messageApi.open({
        key: 'RegisApiMsg',
        type: 'error',
        content: '처리중 실패하였습니다. 지속되면 문의해주세요.',
      });
    }
  };

  return (
    <Container>
      {contextHolder}
      <FlexRow gap={'10px'} justify={'flex-start'}>
        <Title>{conference?.conference.name_kor ?? conference?.conference.name_eng}</Title>
        {conference && (
          <ConferenceStatusIndicator
            status={conference.conference.state}
            start={conference.conference.start_time}
            end={conference.conference.end_time}
          />
        )}
      </FlexRow>
      <FlexRow width={'100%'} justify={'flex-start'}>
        {showTabs.map(tab => (
          <CategorySelectItem key={tab.id} selected={hash.includes(`#${tab.hash}`)}
                              onClick={() => {
                                handleSelectedTab(`#${tab.hash}`);
                                setEventForm(false);
                                setSolutionForm(false);
                              }}>
            {tab.title}
          </CategorySelectItem>
        ))}
      </FlexRow>
      {id && conference && hash.includes('#intro') && (
        <ConferenceInfoDetailIntro data={conference} id={id} joinCheck={joinCheck || userInfo.type === 'admin'} />
      )}
      {id && hash.includes('#visitors') &&
        <ConferenceInfoDetailVisitors conferenceId={id} conferenceInfo={conference} />}
      {id && hash.includes('#companies') && <ConferenceInfoDetailCompanies conferenceId={id} />}
      {id && hash.includes('#products') && !solutionForm && <ConferenceInfoDetailProducts conferenceId={id} />}
      {id && hash.includes('#lectures') && <ConferenceInfoDetailLectures conferenceId={id} />}
      {id && hash.includes('#events') && !eventForm && <ConferenceInfoDetailEvents conferenceId={id} />}
      {id && hash.includes('#certificates') && <ConferenceInfoDetailCertificate conferenceId={id} />}
      {id && hash.includes('#events') && eventForm && (
        // <ConferenceEventFrom
        //   conferenceId={id}
        //   onCancle={() => {
        //     setEventForm(false);
        //   }}
        // />
        <EventInsert
          conferenceId={id}
          onCancle={() => {
            setEventForm(false);
          }}
        />
      )}
      {id && hash.includes('#products') && solutionForm && (
        <ConferenceSolutionForm
          conferenceId={id}
          onCancle={() => {
            setSolutionForm(false);
          }}
        />
      )}
      <FlexRow justify={'flex-end'} gap={'1rem'}>
        <FilledButton
          color={'dark-blue'}
          onClick={() => {
            const listType = hash.split('?').reverse()[0];
            const listUrl = listType ? `${appRoutes.conferenceInfo.path()}?${listType}` : appRoutes.conferenceInfo.path();
            navigate(listUrl);
          }}
        >
          행사목록
        </FilledButton>

        {/* {id && hash.includes('#products') && !solutionForm && (
          <OutlineButton height={35} onClick={() => setSolutionForm(true)}>
            솔루션 등록하기
          </OutlineButton>
        )} */}

        {id && hash.includes('#events') && !eventForm && (
          <OutlineButton height={35} onClick={() => setEventForm(true)}>
            이벤트 추가하기
          </OutlineButton>
        )}

        {(joinCheck || userInfo.type === 'admin') && id && conference && hash.includes('#intro') && (
          <>
            {
              userInfo.type !== 'company' &&
              <>
                <OutlineButton height={35} onClick={() => navigate(`${appRoutes.conferenceInfo.route}/form/${id}`)}>
                  행사수정
                </OutlineButton>

                <Popconfirm
                  title={'행사 삭제'}
                  description={`정말로 행사를 삭제하시겠습니까? 복구할 수 없습니다.`}
                  onConfirm={handleDeleteConference}
                  okText='확인'
                  cancelText='취소'
                >
                  <OutlineButton height={35}>
                    행사삭제
                  </OutlineButton>
                </Popconfirm>
              </>
            }


            {
              //   joinCheck && userInfo.type === 'company' && (
              //   <Popconfirm
              //     title={'행사 참가 취소'}
              //     description={`정말로 행사참가를 취소하시겠습니까?`}
              //     onConfirm={() => {
              //       handleRegistration('delete', joinCheck);
              //     }}
              //     okText='확인'
              //     cancelText='취소'
              //   >
              //     <OutlineButton height={35}>행사 참가 취소</OutlineButton>
              //   </Popconfirm>
              // )
            }

          </>
        )}

        {/*{!joinCheck && userInfo.type === 'company' && (*/}
        {/*  <OutlineButton height={35} onClick={() => handleRegistration('insert')}>*/}
        {/*    행사 참가 신청*/}
        {/*  </OutlineButton>*/}
        {/*)}*/}
      </FlexRow>
      {hash.includes('#intro') && joinCheck && userInfo.type === 'company' && <SolutionSelect />}
    </Container>
  );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 1200px;
    height: 100%;
    margin: auto;
    padding: 50px 0;
    gap: 30px;
`;

const CategorySelectItem = styled('div')<{ selected: boolean }>`
    flex: 1;
    min-width: 171.4px;
    height: 40px;
    background-color: ${props => (props.selected ? '#30384B' : '#F6F8FC')};
    color: ${props => (props.selected ? 'white' : '#656D86')};
    border: ${props => (props.selected ? 'none' : '1px solid #ABB6D2')};
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    font-weight: ${props => (props.selected ? 600 : '')};
    cursor: pointer;

    & + & {
        border-left: 0;
    }
`;
