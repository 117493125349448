/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { FlexRow } from '../../common/components/FlexRow';
import { Table } from './components/Table';
import { TableColumn } from 'react-data-table-component';
import { appRoutes } from '../../AppRoutes';
import { useNavigate } from 'react-router-dom';
import {
  FilledButton,
  FilterBoxContainer,
  FilterLabel,
  OutlineButton,
  SelectWrapper,
  SubTitle,
  Title,
} from '../../common/components/StyledComponents';
import { Button, Input, message, Tooltip } from 'antd';
import { Conference } from '../../common/api/responseTypes';
import { formatDateString } from '../../common/utils/utils';
import { useAllConferences } from '../../common/utils/apiHooks';
import { ConferenceStatusIndicator } from './components/ConferenceStatusIndicator';
import { SearchFilter } from '../../common/components/filters/SearchFilter';
import { DateSelectAndReset } from '../../common/components/filters/DateSelectAndReset';
import DefaultLayer from '../../common/components/panels/defaultLayer';
import DefaultPopupPanel from '../../common/components/panels/defaultPopupPanel';
import ApplicationShow from './components/ApplicationShow';
import ApplicationHistory from './components/ApplicationHistory';
import { fetchAllConferences } from '../../store/conference/conferenceSlice';
import { useAppDispatch } from '../../store/store';
import useUserInfo from '../../hooks/useUserInfo';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isBetween from 'dayjs/plugin/isBetween';
import styles from './ConferenceInfo.module.css';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { axiosInstance } from '../../common/providers & services/AxiosService';
import { defaultConferenceKey } from '../../lib/crypto';
import { CheckCircleFilled } from '@ant-design/icons';

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(isBetween);

type Application = 'show' | 'history';

const CodeMinLength = 6;
type Inputs = {
  joinCode: string
}

// todo - add data filtering
export const ConferenceInfo: React.FC = () => {
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();

  const queryParams = new URLSearchParams(window.location.search);
  const listType = queryParams.get('listType');

  const initialState = {
    searchTerm: '',
    start: '',
    end: '',
    status: 'all',
    listType: listType ?? '',
  };

  const [filterParams, setFilterParams] = useState(initialState);
  const [layerPopupOpen, setLayerPopupOpen] = useState(false);
  const [layerType, setLayerType] = useState<Application>('show');

  const conferences = useAllConferences(...Object.values(filterParams));
  const dispatch = useAppDispatch();

  //유저 권한 확인
  const userInfo = useUserInfo();

  const conferencesData = useMemo(() => {
    const { searchTerm, status } = filterParams;
    const filteredData = conferences.filter((item: Conference) => {
      const { start_time, end_time, conference_registrations } = item;

      const currentTime = dayjs();
      let statusCondition = false;

      if (status === 'all') {
        statusCondition = true; // 모든 상태를 보여줍니다.
      } else if (status === 'prepare') {
        // "prepare" 상태의 경우, start_time과 현재 시간 비교
        statusCondition = dayjs(start_time).isSameOrAfter(currentTime);
      } else if (status === 'progress') {
        // "progress" 상태의 경우, start_time과 end_time 비교
        statusCondition = dayjs(start_time).isSameOrBefore(currentTime) && dayjs(end_time).isAfter(currentTime);
      } else if (status === 'complete') {
        // "complete" 상태의 경우, end_time과 현재 시간 비교
        statusCondition = dayjs(end_time).isSameOrBefore(currentTime);
      }


      // admin일 경우, 모든 항목을 포함
      if (userInfo.type === 'admin') {
        return statusCondition;
      }
      // participation 값이 존재하는지 확인
      const participation = conference_registrations.find((reg: any) => reg.company_setup_id === userInfo.company_setup_id);
      return statusCondition && !!participation?.id;
    });
    return filteredData.map((item: any, index: number) => {
      const { conference_registrations } = item;

      const participation = conference_registrations.find((reg: any) => reg.company_setup_id === userInfo.company_setup_id);

      return {
        ...item,
        listIdx: filteredData.length - index,
        participation: !!participation?.id,
      };
    });
  }, [conferences, filterParams]);

  const excelData = useMemo(() => {
    if (conferencesData === undefined) return [];
    return conferencesData.map((item: Conference, index: number) => {
      return {
        번호: conferencesData.length - index,
        행사명_국문: item.name_kor,
        행사명_영문: item.name_eng,
        행사일시: `${dayjs(item.start_time).format('YYYY-MM-DD HH:mm')} ~ ${dayjs(item.end_time).format('YYYY-MM-DD HH:mm')}`,
        행사주소: `${item.location} ${item.location_detail} ${item.place}`,
        주최: item.hostsParsed.join(', '),
        주관: item.organizersParsed.join(', '),
        행사홈페이지: item.homepage,
        부스정보링크: item.booth_info_link,
        행사관련정보: item.description,
        PRUUF발급갯수: item?.pruuf_count,
        등록일: dayjs(item.created_at).format('YYYY-MM-DD HH:mm'),
      };
    });
  }, [conferencesData]);

  useEffect(() => {
    if (listType) {
      //@ts-ignore
      dispatch(fetchAllConferences({ listType }));
    } else {
      //@ts-ignore
      dispatch(fetchAllConferences({ listType: '' }));
    }
  }, [listType]);

  const handleRowClick = (row: Conference) => {
    if (listType) {
      navigate(`${appRoutes.conferenceInfo.children.detail.path(row.id)}?listType=${listType}`);
    } else {
      navigate(appRoutes.conferenceInfo.children.detail.path(row.id));
    }
  };

  const actionClose = () => {
    setLayerPopupOpen(false);
  };

  const handleApplication = (type: Application) => {
    setLayerPopupOpen(true);
    setLayerType(type);
  };

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm<Inputs>();
  const handleConferenceJoin: SubmitHandler<Inputs> = async (data) => {

    messageApi.open({
      key: 'RegisApiMsg',
      type: 'loading',
      content: '참가신청 중',
      duration: 10000,
    });

    const getFromCode = window.atob(data.joinCode).replaceAll(defaultConferenceKey, '');

    const isValidId = !isNaN(parseInt(getFromCode));
    if (!isValidId) {
      messageApi.open({
        key: 'RegisApiMsg',
        type: 'error',
        content: '올바르지 않은 참가코드 입니다.',
      });
      return false;
    }

    // 이미 참여한 행사인지 확인
    const isJoined = conferencesData.length < 1 ? false : conferencesData.some((e) => e.id === getFromCode);

    if (isJoined) {
      messageApi.open({
        key: 'RegisApiMsg',
        type: 'info',
        content: '이미 참여한 행사입니다.',
      });
      return false;
    }

    //복호화하여 id 값 백엔드 전달.
    try {
      await axiosInstance.post('conferenceRegistration', {
        company_setup_id: userInfo.company_setup_id,
        conference_id: getFromCode,
      });

      setValue('joinCode', '');
      messageApi.open({
        key: 'RegisApiMsg',
        type: 'success',
        content: '성공적으로 처리되었습니다.',
        duration: 1,
        onClose: () => {
          location.reload();
        },
      });

    } catch (e) {
      console.error(e);
      messageApi.open({
        key: 'RegisApiMsg',
        type: 'error',
        content: '처리중 실패하였습니다. 지속되면 문의해주세요.',
      });
    }
  };

  let conferenceTypeText = '전체 행사';
  if (listType === 'user') {
    conferenceTypeText = userInfo.type === 'host' ? '개최 행사' : '참여 행사';
  } else {
    conferenceTypeText = userInfo.type === 'company' ? '행사' : '전체 행사';
  }


  const columns: TableColumn<Conference & { listIdx: number, participation: boolean }>[] = [
    {
      name: '번호',
      selector: row => row.listIdx,
      sortable: true,
      grow: 0,
    },
    {
      name: '행사명',
      cell: row => {
        // @ts-ignore
        return <span onClick={() => handleRowClick(row)} className={styles.joined}>{row.name_kor} {row.participation &&
          <CheckCircleFilled />}</span>;
      },
      grow: 1,
    },
    {
      name: '등록자',
      cell: row => {

        const { conference_registrations } = row;

        // @ts-ignore
        return <span>{conference_registrations.length < 1 ? '최고관리자' : conference_registrations[0].company_setup.name}</span>;
      },
      grow: 1,
    },
    {
      name: '행사일자',
      selector: row => `${formatDateString(row.start_time)} ~ ${dayjs(row.end_time).format('YYYY-MM-DD HH:mm')}`,
      sortable: true,
      grow: 1,
    },
    {
      name: '행사장소',
      selector: row => row.location,
      sortable: true,
      grow: 1,
    },
    {
      name: '총 PRUUF 발급갯수',
      selector: row => row?.pruuf_count,
      sortable: true,
      grow: 0,
      minWidth: '140px',
    },
    {
      name: '상태',
      // cell: row => <ConferenceStatusIndicator status={row.state} />,
      cell: row => <ConferenceStatusIndicator status={row.state} start={row.start_time} end={row.end_time} />,
      sortable: true,
      grow: 0,
    },
  ];

  return (
    <Container gap={30} style={{ padding: '50px 0' }}>
      {contextHolder}
      <div className={styles.wrap}>

        <div className={styles.titleWrap}>
          <Title>{conferenceTypeText}</Title>
          <SubTitle>{conferenceTypeText} 리스트</SubTitle>
        </div>

        {
          userInfo.type === 'company' &&
          <form className={styles.inputWrap} onSubmit={handleSubmit(handleConferenceJoin)}>
            <Controller
              control={control}
              name={'joinCode'}
              rules={{
                required: '참가 코드는 필수입니다.',
                minLength: { value: CodeMinLength, message: `${CodeMinLength}자리 이상 입력하세요` },
              }}
              render={({ field }) =>
                <Tooltip placement='top'
                         title={errors.joinCode?.message}
                         defaultOpen={!errors.joinCode}>
                  <Input {...field}
                         placeholder={'행사 참가코드를 입력해주세요'}
                         style={{ width: '50%' }} />
                </Tooltip>
              }
            />
            <Button htmlType={'submit'}>참가 신청 </Button>
          </form>
        }


      </div>

      <Container gap={10}>
        <FilterBoxContainer>
          <SearchFilter onChange={searchTerm => setFilterParams(prev => ({ ...prev, searchTerm }))} placeholder={'행사명'}
                        debounce={500} />

          <FlexRow gap={'5px'} justify={'space-between'} width={'100%'}>
            <FlexRow gap={'5px'} justify={'flex-start'}>
              <FilterLabel>상태</FilterLabel>
              <SelectWrapper>
                <select value={filterParams.status}
                        onChange={e => setFilterParams(prev => ({ ...prev, status: e.target.value }))}>
                  <option value={'all'}>전체</option>
                  <option value={'prepare'}>대기중</option>
                  <option value={'progress'}>진행중</option>
                  <option value={'complete'}>종료</option>
                </select>
              </SelectWrapper>
            </FlexRow>
          </FlexRow>

          <DateSelectAndReset
            label={'행사일자'}
            onStartChange={start => setFilterParams(prev => ({ ...prev, start }))}
            onEndChange={end => setFilterParams(prev => ({ ...prev, end }))}
            onReset={() => setFilterParams(initialState)}
          />
        </FilterBoxContainer>

        {/*@ts-ignore */}
        <Table columns={columns} data={conferencesData} onRowClick={handleRowClick} filename={`행사정보`}
               excelData={excelData} />

        <FlexRow justify={'flex-end'} gap={'1rem'} width={'100%'}>

          {
            userInfo.type !== 'company' &&
            <FilledButton color={'dark-blue'} onClick={() => navigate(`${appRoutes.conferenceInfo.route}/form`)}>
              새로운 행사 등록하기
            </FilledButton>
          }


          {
            // 미사용... 추후 가능성있음
            // userInfo.type === 'admin' && (
            userInfo.type === 'later' && (
              <>
                <OutlineButton height={35} onClick={() => handleApplication('show')}>
                  참가 신청 보기
                </OutlineButton>

                <OutlineButton height={35} onClick={() => handleApplication('history')}>
                  참가 신청 이력
                </OutlineButton>
              </>
            )}
        </FlexRow>

        {layerPopupOpen && (
          <DefaultLayer open={layerPopupOpen}>
            <DefaultPopupPanel actionClose={actionClose} title={layerType === 'show' ? '참가 신청 보기' : '참가 신청 이력'}>
              <>
                {layerType === 'show' && <ApplicationShow />}
                {layerType === 'history' && <ApplicationHistory />}
              </>
            </DefaultPopupPanel>
          </DefaultLayer>
        )}
      </Container>
    </Container>
  );
};

const Container = styled('div')<{ gap: number }>`
    width: 1200px;
    height: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: ${props => props.gap}px;
`;

const columns: TableColumn<Conference & { listIdx: number }>[] = [
  {
    name: '번호',
    selector: row => row.listIdx,
    sortable: true,
    grow: 0,
  },
  {
    name: '행사명',
    selector: row => `${row.name_kor}`,
    grow: 1,
  },
  {
    name: '행사일자',
    selector: row => `${formatDateString(row.start_time)} ~ ${dayjs(row.end_time).format('YYYY-MM-DD HH:mm')}`,
    sortable: true,
    grow: 1,
  },
  {
    name: '행사장소',
    selector: row => row.location,
    sortable: true,
    grow: 1,
  },
  {
    name: '총 PRUUF 발급갯수',
    selector: row => row?.pruuf_count,
    sortable: true,
    grow: 0,
    minWidth: '140px',
  },
  {
    name: '상태',
    // cell: row => <ConferenceStatusIndicator status={row.state} />,
    cell: row => <ConferenceStatusIndicator status={row.state} start={row.start_time} end={row.end_time} />,
    sortable: true,
    grow: 0,
  },
];
