import axios from 'axios';
import { AuthResponse } from './responseTypes';
import { getRefreshRetries, getSavedRefreshToken, setRefreshRetries, setSavedAuth } from '../utils/storage';
import { getApiUrl } from '../providers & services/AxiosService';

export const postSignIn = async (account: string, password: string): Promise<void> => {
  const baseUrl = await getApiUrl();
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrl}sign-in/admin`, { account, password })
      .then(response => {
        const tokenData = response.data.data.token as AuthResponse;
        setSavedAuth({
          ...tokenData,
          isLogin: true,
        });
      })
      .catch(e => {
        console.log(e);
        // handleNetworkError(e);
        // todo
        // if (e?.response?.status === 404) {
        //   ToastsStore.error('아이디 일치하지 않습니다.');
        // } else if (e?.response?.status === 401) {
        //   ToastsStore.error('비밀번호가 일치하지 않습니다.');
        // }
        reject();
      });
  });
};

export const refreshToken = async () => {
  const tries = getRefreshRetries();
  if (tries && tries > 5) {
    setSavedAuth(null);
    setRefreshRetries(null);
    return Promise.reject();
  }
  setRefreshRetries(tries ? tries + 1 : 1);
  const refreshToken = getSavedRefreshToken();
  if (refreshToken) {
    const baseUrl = getApiUrl();
    const timeout = setTimeout(async () => {
      window.location.reload();
      console.log('timeout');
    }, 4500);

    await axios
      .post(`${baseUrl}refresh-token`, { refresh_token: refreshToken })
      .then(res => {
        const { data } = res;
        const auth: AuthResponse = {
          accessToken: data.token.accessToken,
          refreshToken: data.token.refreshToken,
          expired_at: data.token.expired_at,
          isLogin: true,
        };
        setSavedAuth(auth);
        clearTimeout(timeout);
      })
      .catch(err => {
        console.error(err);
        return Promise.reject();
      })
      .catch(e => console.error(e));
  } else {
    setSavedAuth(null);
    return Promise.reject();
  }
};
