import { useQuery } from '@tanstack/react-query';
import { axiosInstance } from '../../../common/providers & services/AxiosService';
import styled from 'styled-components';
import { TableColumn } from 'react-data-table-component';

import { Table } from '../../../common/components/Table';
import { FilterBoxContainer, OutlineButton, SubTitle } from '../../../common/components/StyledComponents';
import { FlexRow } from '../../../common/components/FlexRow';
import { SearchFilter } from '../../../common/components/filters/SearchFilter';

import styles from './pruufReport.module.css';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import DefaultLayer from '../../../common/components/panels/defaultLayer';
import PruufDetail from '../../pruuf-status/components/PruufDetail';
import DefaultPopupPanel from '../../../common/components/panels/defaultPopupPanel';
import UserDetailInfo from '../../../common/components/users/UserDetailInfo';

type Props = {
  listType: 'conference' | 'company' | 'solution' | 'event' | 'all';
  user_id?: string | number;
  company_setup_id?: string | number;
  conference_id?: string | number;
  solution_id?: string | number;
  event_id?: string | number;
};
const initialState = {
  searchTerm: '',
  start: '',
  end: '',
  status: 'all',
  searchType: 'all',
};

export const pruufTypetoText = (type: string) => {
  let text = '';
  switch (type) {
    case 'conference':
      text = '행사참여';
      break;
    case 'company':
      text = '참가사 방문';
      break;

    case 'solution':
      text = '솔루션 체험';
      break;
    case 'event':
      text = '이벤트';
      break;
    default:
      text = '행사참여';
      break;
  }

  return text;
};

const pruufInfoByType = (type: string, pruuf: any) => {
  let text = '';
  switch (type) {
    case 'conference':
      text = pruuf.conference.name_kor;
      break;
    case 'company':
      text = pruuf.company_setup.name;
      break;

    case 'solution':
      text = pruuf.solution.name;
      break;
    case 'event':
      text = pruuf.event.name;
      break;
    default:
      text = pruuf.conference.name_kor;
      break;
  }

  return text;
};

export type LayerType = 'pruufDetail' | 'userDetail';
const PruufReport = ({ listType, user_id, company_setup_id, conference_id, solution_id, event_id }: Props) => {
  const [filterParams, setFilterParams] = useState(initialState);

  const [layerPopupOpen, setLayerPopupOpen] = useState(false);
  const [layerType, setLayerType] = useState<LayerType>('pruufDetail');
  const [layerValue, setLayerValue] = useState<string | number>('');

  let columnSetup: TableColumn<any>[];
  if (listType === 'all') {
    columnSetup = [
      {
        name: '번호',
        selector: row => row.listIdx,
        sortable: true,
        grow: 0,
      },
      {
        name: '구분',
        selector: row => pruufTypetoText(row.type),
        sortable: true,
        grow: 1,
      },
      {
        name: '상세내용',
        selector: row => pruufInfoByType(row.type, row),
        sortable: true,
        grow: 1,
      },
      {
        name: '발급일시',
        selector: row => dayjs(row.created_at).format('YYYY.MM.DD HH:mm'),
        sortable: true,
        grow: 2,
      },
      {
        name: 'PRUUF 보기',
        // cell: row => {
        //   return <span style={{ textDecoration: 'underline' }}>상세보기</span>;
        // },
        cell(row) {
          const handlePopup = (id: string) => {
            setLayerPopupOpen(true);
            setLayerType('pruufDetail');
            setLayerValue(id);
          };
          return (
            <span style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => handlePopup(row.id)}>
              상세보기
            </span>
          );
        },
        sortable: true,
        grow: 1,
      },
    ];
  } else {
    columnSetup = [
      {
        name: '번호',
        selector: row => row.listIdx,
        sortable: true,
        grow: 0,
      },
      {
        name: '이름',
        selector: row => row.user.name,
        sortable: true,
        grow: 1,
      },
      {
        name: '발급일시',
        selector: row => dayjs(row.created_at).format('YYYY.MM.DD HH:mm'),
        sortable: true,
        grow: 2,
      },
      {
        name: '소속',
        selector: row => row.company_setup.name,
        sortable: true,
        grow: 1,
      },
      {
        name: '직급',
        selector: row => row.user.position,
        sortable: true,
        grow: 1,
      },
      {
        name: '부서',
        selector: row => row.user.department,
        sortable: true,
        grow: 1,
      },
      {
        name: 'PRUUF 보기',
        // cell: row => {
        //   return <span style={{ textDecoration: 'underline' }}>상세보기</span>;
        // },
        cell(row) {
          const handlePopup = (id: string) => {
            setLayerPopupOpen(true);
            setLayerType('pruufDetail');
            setLayerValue(id);
          };
          return (
            <span style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => handlePopup(row.id)}>
              상세보기
            </span>
          );
        },
        sortable: true,
        grow: 1,
      },
    ];
  }

  const columns: TableColumn<any>[] = columnSetup;

  const fetchData = async () => {
    try {
      const searchParams = new URLSearchParams({
        listType,
        ...(user_id && { user_id: `${user_id}` }),
        ...(company_setup_id && { company_setup_id: `${company_setup_id}` }),
        ...(conference_id && { conference_id: `${conference_id}` }),
        ...(solution_id && { solution_id: `${solution_id}` }),
        ...(event_id && { event_id: `${event_id}` }),
      });
      const response = await axiosInstance.get(`pruufs?${searchParams}`);
      return response.data.data.pruufs;
    } catch (e) {
      console.error('error...');
      return [{}];
    }
  };

  const { data, isLoading, isError } = useQuery(
    ['pruufList', listType, user_id, company_setup_id, conference_id, solution_id, event_id],
    fetchData,
    {
      refetchOnWindowFocus: false,
      select: data => {
        const filteredData = data.filter((item: any) => {
          const { searchTerm } = filterParams;

          if (searchTerm && !item.user.name.includes(searchTerm)) {
            return false;
          }

          return true;
        });
        // return filteredData;

        return filteredData.map((item: any, index: number) => {
          return {
            ...item,
            listIdx: filteredData.length - index,
          };
        });
      },
    },
  );

  const handleRowClick = (row: any) => {
    // console.log('clickedRow', row);
  };

  useEffect(() => {
    // console.log('queryKey', ['pruufList', listType, user_id, company_setup_id, conference_id, solution_id, event_id]);

    const searchParams = new URLSearchParams({
      listType,
      ...(user_id && { user_id: `${user_id}` }),
      ...(company_setup_id && { company_setup_id: `${company_setup_id}` }),
      ...(conference_id && { conference_id: `${conference_id}` }),
      ...(solution_id && { solution_id: `${solution_id}` }),
      ...(event_id && { event_id: `${event_id}` }),
    });
    // console.log('queryUrl', `pruufs?${searchParams}`);
  }, []);

  if (isLoading || isError) return <></>;

  return (
    <section className={styles.wrap}>
      <SubTitle>참가자 정보 조회</SubTitle>

      {layerPopupOpen && (
        <DefaultLayer
          open={layerPopupOpen}
          actionClose={() => {
            setLayerPopupOpen(false);
          }}
        >
          <>
            {layerType === 'pruufDetail' && <PruufDetail pruufId={layerValue} />}
            {layerType === 'userDetail' && (
              <DefaultPopupPanel
                open={layerPopupOpen}
                actionClose={() => {
                  setLayerPopupOpen(false);
                }}
                title={'참가자 정보'}
              >
                <UserDetailInfo userId={layerValue} />
              </DefaultPopupPanel>
            )}
          </>
        </DefaultLayer>
      )}

      <Container gap={10}>
        <FilterBoxContainer>
          <FlexRow gap={'5px'} justify={'space-between'} width={'100%'}>
            <FlexRow gap={'5px'} justify={'flex-start'}>
              <SearchFilter
                onChange={searchTerm => setFilterParams(prev => ({ ...prev, searchTerm }))}
                placeholder={'참가자 이름을 입력해주세요.'}
                debounce={150}
              />
              <OutlineButton height={30} onClick={() => setFilterParams(initialState)}>
                초기화
              </OutlineButton>
            </FlexRow>
          </FlexRow>
        </FilterBoxContainer>
      </Container>

      <Table columns={columns} data={data} onRowClick={handleRowClick} filename={`행사정보`} showExcel={false} />
    </section>
  );
};

export default PruufReport;

const Container = styled('div')<{ gap: number }>`
    width: 1200px;
    height: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: ${props => props.gap}px;
`;
