import { Checkbox } from 'antd';
import { Dispatch, SetStateAction, useState } from 'react';
import PrivacyModal from './PrivacyModal';
import styles from './termsPolicy.module.css';

const TermsPolicy = ({
                       terms,
                       checklist,
                       setChecklist,
                     }: {
  terms: { id: number; name: string; url: string }[];
  checklist: number[];
  setChecklist: Dispatch<SetStateAction<number[]>>;
}) => {
  const [privacyModalVisible, setPrivacyModalVisible] = useState(false);

  const handleChecklist = (checked: boolean, id: number, url: string) => {
    if (checked) {
      setChecklist([...checklist, id]);

      if (id === 2 && checked) {
        setPrivacyModalVisible(true);
      }
    }
    if (!checked) {
      setChecklist(checklist.filter(el => el !== id));
    }
  };

  const handleChecklistAll = (checked: boolean) => {
    if (checked) {
      const allList = [] as number[];
      terms.map(item => allList.push(item.id));
      setChecklist(allList);
      setPrivacyModalVisible(true);
    }
    if (!checked) {
      setChecklist([]);
    }
  };

  return (
    <div className={styles.row}>
      <label className={styles.largeRow}>약관동의</label>
      <div className={styles.col}>
        <Checkbox checked={terms.length === checklist.length} onChange={e => handleChecklistAll(e.target.checked)}>
          모두 동의합니다.
        </Checkbox>
        {terms.map(item => (
          <div key={item.id}>
            <Checkbox checked={checklist.includes(item.id)}
                      onChange={e => handleChecklist(e.target.checked, item.id, item.url)} />
            <span className={styles.terms}>{item.name}</span>
          </div>
        ))}
      </div>
      {' '}
      {/* PrivacyModal */}
      <PrivacyModal visible={privacyModalVisible} onClose={() => setPrivacyModalVisible(false)} />
    </div>
  );
};

export default TermsPolicy;
