/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable prefer-const */
import React from 'react';
import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';
import { appRoutes } from '../../AppRoutes';
import { useAppDispatch } from '../../store/store';
import { signOut } from '../../store/app/appSlice';
import { uniqId } from '../../lib/function';
import useUserInfo from '../../hooks/useUserInfo';
import DefaultLayer from './panels/defaultLayer';
import FirstLoginInfo from './users/FirstLoginInfo';

const appNavigation = [
  // 전체 메뉴
  { id: uniqId(), key: appRoutes.home.key, path: appRoutes.home.path(), title: 'Home' },
  { id: uniqId(), key: appRoutes.pruufStatus.key, path: appRoutes.pruufStatus.path(), title: 'PRUUF발급현황' },
  { id: uniqId(), key: appRoutes.conferenceInfo.key, path: appRoutes.conferenceInfo.path(), title: '행사' },
  { id: uniqId(), key: appRoutes.host.key, path: appRoutes.host.path(), title: '주최사' },
  { id: uniqId(), key: [appRoutes.company.key, appRoutes.solution.key], path: appRoutes.company.path(), title: '참가사' },
  { id: uniqId(), key: appRoutes.visitor.key, path: appRoutes.visitor.path(), title: '참가자' },
  // { id: uniqId(), key: appRoutes.myInfo.key, path: appRoutes.myInfo.path(), title: '정보수정' },
  // { id: uniqId(), key: appRoutes.editUser.key, path: appRoutes.editUser.path(), title: '정보수정' },
];

const getInfos = {
  listType: 'user',
};
const userAuthParams = new URLSearchParams(getInfos);

const appNavigationHost = [
  // 전체 메뉴
  { id: uniqId(), key: appRoutes.home.key, path: appRoutes.home.path(), title: 'Home' },
  //{ id: uniqId(), key: appRoutes.pruufStatus.key, path: appRoutes.pruufStatus.path(), title: 'PRUUF발급현황' },
  {
    id: uniqId(),
    key: appRoutes.conferenceInfo.key,
    path: `${appRoutes.conferenceInfo.path()}?${userAuthParams}`,
    title: '개최 행사',
    listType: 'user',
  },
  { id: uniqId(), key: appRoutes.myInfo.key, path: appRoutes.myInfo.path(), title: '정보수정' },
];

const appNavigationCompany = [
  // 전체 메뉴
  { id: uniqId(), key: appRoutes.home.key, path: appRoutes.home.path(), title: 'Home' },
  //{ id: uniqId(), key: appRoutes.pruufStatus.key, path: appRoutes.pruufStatus.path(), title: 'PRUUF발급현황' },
  // {
  //   id: uniqId(),
  //   key: appRoutes.conferenceInfo.key,
  //   path: `${appRoutes.conferenceInfo.path()}?${userAuthParams}`,
  //   title: '참여 행사',
  //   listType: 'user',
  // },
  {
    id: uniqId(),
    key: appRoutes.conferenceInfo.key,
    path: appRoutes.conferenceInfo.path(),
    title: '행사 보기',
    listType: 'all',
  },
  { id: uniqId(), key: appRoutes.myInfo.key, path: appRoutes.myInfo.path(), title: '정보수정' },
  // { id: uniqId(), key: appRoutes.editUser.key, path: appRoutes.editUser.path(), title: '정보수정' },
];

export const AppNavBar: React.FC = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const userInfo = useUserInfo();

  const handleSelected = (selected: string) => {
    pathname === '/' || pathname === '/home'
      ? sessionStorage.removeItem('selected_nav_tab')
      : sessionStorage.setItem('selected_nav_tab', selected);
    navigate(selected);
  };

  let navi = appNavigation;

  if (!userInfo.name) {
    return <></>;
  }

  // navi = userInfo?.type === 'admin' ? appNavigation : appNavigationUser;
  navi =
    userInfo?.type === 'admin'
      ? appNavigation
      : userInfo.type === 'host'
        ? appNavigationHost
        : userInfo.type === 'company'
          ? appNavigationCompany
          : appNavigation;

  const queryParams = new URLSearchParams(window.location.search);
  const { hash } = useLocation();
  const listType = queryParams.get('listType') ?? hash.split('?').reverse()[0].replace('listType=', '');

  return (
    <Container>
      <JiranLogo />

      {!userInfo.company_setup_id && userInfo.type !== 'admin' && !pathname.includes('/signup/') && (
        <DefaultLayer open={true}>
          <FirstLoginInfo type={userInfo.type} id={userInfo.id} />
        </DefaultLayer>
      )}

      <div style={{ display: 'flex', height: '100%', maxWidth: '65vw', overflowX: 'auto' }}>
        {navi.map(tab => {
          const { id, path, key, title } = tab;

          let isActive = key.includes(pathname.substring(1)) || pathname.split('/')[1] === key || key.includes(pathname.split('/')[1]);

          if (key === appRoutes.conferenceInfo.key) {
            isActive = false;

            //@ts-ignore
            if (tab.listType === 'user' && listType === 'user') {
              isActive = true;
            }

            //@ts-ignore
            if (tab.listType !== 'user' && listType !== 'user' && pathname.split('/')[1] === key) {
              isActive = true;
            }
          }
          return (
            <Tab key={id} active={isActive} onClick={() => handleSelected(path)}>
              {title}
            </Tab>
          );
        })}
      </div>

      <End>
        <div>{userInfo.type === 'admin' ? '최고관리자' : userInfo.company_setup?.name ?? ''}</div>
        <Logout
          onClick={() => {
            dispatch(signOut());
            localStorage.clear();
            navigate(userInfo.type === 'admin' ? '/admin' : '/');
          }}
        >
          로그아웃
        </Logout>
      </End>
    </Container>
  );
};

const Container = styled.div`
    width: 100%;
    height: 70px;
    background-color: #2d3039;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 5px 30px 6px rgba(0, 0, 0, 0.24);
`;

const JiranLogo = styled.div`
    margin-right: 5rem;
    background-color: white;
    border-radius: 15px;
    height: 70px;
    width: 166px;
    background-image: url('/images/common/pruuf_logo.png');
    background-size: 132px 58px;
    background-repeat: no-repeat;
    background-position: center;
`;

const Tab = styled('div')<{ active: boolean }>`
    width: auto;
    padding: 0 20px;
    height: 100%;
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${props => (props.active ? '#4F57DB' : '#383D4D')};
    color: white;
    font-size: 18px;
    border-left: 1px solid black;
    border-right: 1px solid black;
    cursor: pointer;

    & + & {
        border-left: 0;
    }
`;

const End = styled.div`
    width: 300px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 15px;
    color: white;
    font-size: 16px;
`;

const Logout = styled.button`
    border: 1px solid white;
    outline: none;
    border-radius: 15px;
    background-color: transparent;
    color: white;
    padding: 2px 12px;
    font-size: 14px;
    cursor: pointer;
`;
