import React, { useMemo } from 'react';
import styled from 'styled-components';

declare global {
  interface Window {
    MSStream?: any;
  }
}

export const DeeplinkFallback: React.FC = () => {
  const platform = useMemo(() => {
    const { userAgent } = navigator;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return 'Windows Phone';
    }

    if (/android/i.test(userAgent)) {
      return 'Android';
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return 'iOS';
    }

    return 'unknown';
  }, []);

  console.log(platform);

  const handleMoveToStore = (store: 'iOS' | 'Android') => {
    // todo
  };

  return (
    <Container>
      <Title>
        PRUUF앱이
        <br />
        실행되지 않았어요.
      </Title>
      <SubTitle>
        PRUUF앱이 설치되어 있는지 확인해 주세요. <br />
        설치되어 있다면 PRUUF앱에서 <br />
        qr 스캐너를 사용해 보세요 <br />
      </SubTitle>
      <Logo src={'/images/common/pruuf_logo.png'} />
      {platform === 'iOS' || platform === 'Android' ? (
        <Button onClick={() => handleMoveToStore(platform)}>PRUUF 앱 설치하기</Button>
      ) : (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '20px' }}>
          <Button onClick={() => handleMoveToStore('Android')}>Android PRUUF 앱 설치하기</Button>
          <Button onClick={() => handleMoveToStore('iOS')}>iOS PRUUF 앱 설치하기</Button>
        </div>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  justify-content: space-around;
  align-items: center;
  padding: 50px 0;
`;

const Title = styled.div`
  font-size: 32px;
  font-weight: 700;
  text-align: center;
`;

const SubTitle = styled.div`
  font-weight: 400;
  font-size: 15px;
  text-align: center;
  color: #767676;
`;

const Logo = styled.img`
  width: 214px;
`;

const Button = styled.button`
  width: 346px;
  height: 62px;
  background: linear-gradient(97.27deg, #3ab3ff -2.24%, #8516fe 125.79%);
  box-shadow: 0px 4px 20px rgba(36, 127, 215, 0.33);
  border-radius: 10px;
  border: none;
  outline: none;
  color: white;
`;

const Problem = styled.div`
  font-size: 13px;
  font-weight: 500;
  color: #767676;
`;
