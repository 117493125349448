import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { Conference, EventStatus } from '../../../common/api/responseTypes';
import { eventDDay, eventStateToHumanString, formatDateString } from '../../../common/utils/utils';
import { useNavigate } from 'react-router-dom';
import { appRoutes } from '../../../AppRoutes';
import styles from './eventInfoTabBox.module.css';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import useUserInfo from '../../../hooks/useUserInfo';

dayjs.extend(isBetween);

const currentTime = dayjs();
const defaultTabs = ['진행 중 행사', '대기 중 행사'];

export const EventInfoTabBox: React.FC<{ allEvents: Conference[], isAdmin?: boolean }> = ({
                                                                                            allEvents,
                                                                                            isAdmin = false,
                                                                                          }) => {
  // const tabs = isAdmin ? defaultTabs : [...defaultTabs, '종료된 행사'];
  const tabs = defaultTabs;
  const [active, setActive] = useState(tabs[0]);

  const tabToStateMap = (tab: string): EventStatus | undefined => {
    if (tab === tabs[0]) return 'progress';
    if (tab === tabs[1]) return 'prepare';
    if (tab === tabs[2]) return 'complete';
  };

  const userInfo = useUserInfo();

  const navigate = useNavigate();

  const countDataForTab = (status: EventStatus): number => {

    const temp = [];

    for (const event of allEvents) {

      // 이벤트 시작 시간과 종료 시간 가져오기
      const startTime = dayjs(event.start_time);
      const endTime = dayjs(event.end_time);
      // 현재 시간과 이벤트 시간 비교
      let eventStatus: EventStatus;

      if (currentTime.isBefore(startTime)) {
        // eventStatus = '대기';
        eventStatus = 'prepare';
      } else if (currentTime.isBetween(startTime, endTime)) {
        // eventStatus = '진행';
        eventStatus = 'progress';
      } else {
        // eventStatus = '종료';
        eventStatus = 'complete';
      }

      if (!status || status === 'all') temp.push({ ...event, state: eventStatus });
      else if (eventStatus === status) temp.push({ ...event, state: eventStatus });
    }

    return temp.length ?? 0;

  };

  const data = useMemo(() => {
    const temp = [];
    const status = tabToStateMap(active);

    // allEvents 배열을 start_time으로 정렬
    const sortedAllEvents = allEvents.sort((a, b) => {
      const startTimeA = dayjs(a.start_time);
      const startTimeB = dayjs(b.start_time);
      return startTimeA.diff(startTimeB);
    });

    for (const event of sortedAllEvents) {
      if (temp.length === 3) break;

      // 이벤트 시작 시간과 종료 시간 가져오기
      const startTime = dayjs(event.start_time);
      const endTime = dayjs(event.end_time);
      // 현재 시간과 이벤트 시간 비교
      let eventStatus: EventStatus;

      if (currentTime.isBefore(startTime)) {
        // eventStatus = '대기';
        eventStatus = 'prepare';
      } else if (currentTime.isBetween(startTime, endTime)) {
        // eventStatus = '진행';
        eventStatus = 'progress';
      } else {
        // eventStatus = '종료';
        eventStatus = 'complete';
      }

      if (!status || status === 'all') temp.push({ ...event, state: eventStatus });
      else if (eventStatus === status) temp.push({ ...event, state: eventStatus });
    }

    return temp;
  }, [allEvents, active]);

  return (
    <section className={styles.wrap}>
      <span
        className={styles.moreWrap}
        onClick={() => {
          const listType = userInfo.type !== 'admin' ? 'listType=user' : '';
          const listUrl = listType ? `${appRoutes.conferenceInfo.path()}?${listType}` : appRoutes.conferenceInfo.path();
          navigate(listUrl);
        }}
      >
        더보기
      </span>
      <Container>
        <TabBar>
          {tabs.map((t) => {
            const count = data.filter((i: any) => {
              if (t === '진행 중 행사') return i.state === 'progress';
              else if (t === '대기 중 행사') return i.state === 'prepare';
              // else if (t === '종료된 행사') return i.state === 'complete';
            }).length;

            return <Tab key={t} active={active === t} onClick={() => setActive(t)}>
              <div>{t} ({countDataForTab(t === '진행 중 행사' ? 'progress' : 'prepare')})</div>
            </Tab>;
          })}
        </TabBar>
        <Events>
          {data.length < 1 ? (
            <article className={styles.infoWrap}>
              <h1>{active} 정보가 없습니다.</h1>
            </article>
          ) : (
            data.map(event => (
              <EventContainer key={`event_${event.id}`}
                              onClick={() => navigate(appRoutes.conferenceInfo.children.detail.path(event.id))}>
                <EventStatusWrapper status={event.state}
                                    noStatus={eventDDay(event.start_time, event.end_time) === '00'}>
                  <div>{eventStateToHumanString(event.state)}</div>
                  <div>{eventDDay(event.start_time, event.end_time)}</div>
                </EventStatusWrapper>
                <EventTitle>{event.name ?? event.name_kor}</EventTitle>

                {
                  event.hosts && JSON.parse(event.hosts).length > 0 &&
                  <div className={styles.hostText}>주최 : {JSON.parse(event.hosts).join(', ')}</div>
                }

                <EventDate>
                  <i className={'xi-clock'} />
                  <div>
                    {formatDateString(event.start_time)} ~ {formatDateString(event.end_time)}
                  </div>
                </EventDate>
              </EventContainer>
            ))
          )}
        </Events>
      </Container>
    </section>
  );
};

const Container = styled.div`
    width: 1200px;
    height: 280px;
    background-color: white;
    display: flex;
    flex-direction: column;
    border: 1px solid #dddddd;
`;

const TabBar = styled.div`
    width: 100%;
    height: 50px;
    //min-height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const Tab = styled('div')<{ active: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    flex: 1;
    // width: 300px;
    padding: 0 50px;
    font-size: ${props => (props.active ? '18px' : '15px')};
    background-color: ${props => (props.active ? 'white' : '#F5F5F5')};
    white-space: nowrap;
    position: relative;
    cursor: pointer;
    transition: all 0.2s;
    border-left: 1px solid #dedede;
    border-right: 1px solid #dedede;

    &:first-of-type {
        border-left: none;
    }

    &:last-of-type {
        border-right: none;
    }

    & + & {
        border-left: none;
    }
`;

const EventContainer = styled.div`
    width: 300px;
    //height:
    margin: 50px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    border-right: 1px solid #aaaaaa;
    cursor: pointer;

    &:last-of-type {
        border-right: none;
    }
`;

const Events = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    // width: 100%;
    flex: 1;
    justify-content: flex-start;
`;

const EventStatusWrapper = styled('div')<{ status: EventStatus; noStatus?: boolean }>`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: ${props => (props.noStatus ? '70px' : '140px')};
    font-size: 15px;
    border: 1px solid var(--${props => props.status});
    color: var(--${props => props.status});

    > div {
        width: ${props => (props.noStatus ? '100%' : '50%')};
        text-align: center;
    }

    > div:nth-child(1) {
        background-color: var(--${props => props.status});
        color: white;
    }

    > div:nth-child(2) {
        display: ${props => (props.noStatus ? 'none' : 'block')};
    }
`;

const EventTitle = styled.div`
    font-size: 22px;
    font-weight: 600;
    overflow: hidden;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

const EventDate = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #999999;
    font-size: 15px;
    gap: 6px;

    i {
        color: #dddddd;
    }
`;
