import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { QrSlug } from './common/api/requestTypes';
import LoginAdmin from './pages/admin/login/LoginAdmin';
import ChangePassword from './pages/changePassword/ChangePassword';
import CompanyInfo from './pages/company-info/CompanyInfo';
import CompanyInfoDetail from './pages/company-info/CompanyInfoDetail';
import CompanyInfoForm from './pages/company-info/CompanyInfoForm';
import { ConferenceInfo } from './pages/conference-info/ConferenceInfo';
import { ConferenceInfoDetail } from './pages/conference-info/ConferenceInfoDetail';
import ConferenceInfoForm from './pages/conference-info/ConferenceInfoForm';
import { Template1, Template2, Template3, Template4 } from './pages/conference-info/components/QrTemplate';
import { DeeplinkFallback } from './pages/deeplink-fallback/DeeplinkFallback';
import EventDetail from './pages/event/EventDetail';
import { Home } from './pages/home/Home';
import HostInfo from './pages/host-info/HostInfo';
import HostInfoDetail from './pages/host-info/HostInfoDetail';
import HostInfoForm from './pages/host-info/HostInfoForm';
import { Login } from './pages/login/Login';
import Managements from './pages/management/Managements';
import MyInfoUpdate from './pages/myInfo/MyInfoUpdate';
import { NftStatus } from './pages/nft-status/NFTStatus';
import NotFound from './pages/notFound/notFound';
import { PrivacyPolicy } from './pages/privacy-policy/PrivacyPolicy';
import PruufStatus from './pages/pruuf-status/PruufStatus';
import { Qr } from './pages/qr/Qr';
import Signup from './pages/signup/SignUp';
import SignUpAdmin from './pages/signup/components/SignUpAdmin';
import SolutionInfo from './pages/solution-info/SolutionInfo';
import SolutionInfoDetail from './pages/solution-info/SolutionInfoDetail';
import EditUser from './pages/user/EditUser';
import EnrollUser from './pages/user/EnrollUser';
import VisitorInfo from './pages/visitor-info/VisitorInfo';
import VisitorInfoDetail from './pages/visitor-info/VisitorInfoDetail';
import EventInsert from './pages/conference-info/components/EventInsert';

export const appRoutes = {
  test: {
    key: 'test',
    route: '/test',
    path: () => '/test',
  },
  login: {
    key: 'login',
    route: '/login',
    path: () => '/login',
  },
  loginAdmin: {
    key: 'loginAdmin',
    route: '/admin/login',
    path: () => '/admin/login',
  },
  changePassword: {
    key: 'changePassword',
    route: '/changePassword/:token',
    path: (token: string) => `/changePassword/${token}`,
  },
  signup: {
    key: 'signup',
    route: '/signup',
    path: () => '/signup',
  },
  signupAdmin: {
    key: 'signupAdmin',
    route: '/signup/:id',
    path: (id: string) => `/signup/${id}`,
  },
  editUser: {
    key: 'user/edit',
    route: '/user/edit',
    path: () => '/user/edit',
  },
  enrollUser: {
    key: 'user/enroll',
    route: '/user/enroll',
    path: () => 'user/enroll',
  },
  myInfo: {
    key: 'user/myInfo',
    route: '/user/myInfo/update',
    path: () => 'user/myInfo/update',
  },
  home: {
    key: 'home',
    route: '/home',
    path: () => '/home',
  },
  conferenceInfo: {
    key: 'conference-info',
    route: '/conference-info',
    path: () => '/conference-info',
    children: {
      detail: {
        route: ':id',
        path: (id: string | number) => `/conference-info/${id}#intro`,
      },
      form: {
        route: '/conference-info/form/:id',
        path: (id: string) => `/conference-info/form/${id}`,
      },
      eventDetail: {
        base: '/conference-info/eventDetail',
        route: '/conference-info/eventDetail/:id',
      },
      eventDetailForm: {
        base: '/conference-info/eventDetail/form',
        route: '/conference-info/eventDetail/form/:eventId',
      },
    },
  },
  nftStatus: {
    key: 'NFT-status',
    route: '/NFT-status',
    path: () => '/NFT-status',
  },
  pruufStatus: {
    key: 'pruuf-status',
    route: '/pruuf-status',
    path: () => '/pruuf-status',
  },

  host: {
    key: 'host',
    route: '/host',
    path: () => '/host',
    children: {
      detail: {
        route: ':id',
        path: (id: string | number) => `/host/${id}#intro`,
      },

      form: {
        route: '/host/form/:id',
        path: (id: string) => `/host/form/${id}`,
      },
    },
  },

  company: {
    key: 'company',
    route: '/company',
    path: () => '/company',
    children: {
      detail: {
        route: ':id',
        path: (id: string | number) => `/company/${id}#intro`,
      },

      form: {
        route: '/company/form/:id',
        path: (id: string) => `/company/form/${id}`,
      },
    },
  },

  solution: {
    key: 'solution',
    route: '/solution',
    path: () => '/solution',
    children: {
      detail: {
        route: ':id',
        path: (id: string | number) => `/solution/${id}#intro`,
      },

      form: {
        route: '/solution/form/:id',
        path: (id: string) => `/solution/form/${id}`,
      },
    },
  },

  visitor: {
    key: 'visitor',
    route: '/visitor',
    path: () => '/visitor',
    children: {
      detail: {
        route: ':id',
        path: (id: string | number) => `/visitor/${id}#intro`,
      },
    },
  },

  management: {
    key: 'management',
    route: '/management',
    path: () => '/management',
  },
  qr: {
    route: '/qr/:slug/:resourceId',
    path: (slug: QrSlug, resourceId: string) => `/qr/${slug}/${resourceId}`,
  },
  privacy: {
    route: '/privacy',
    path: () => '/privacy',
  },
  deeplink: {
    route: '/dl/*',
  },
};

export const AppRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path={appRoutes.login.route} element={<Login />} />
      <Route path={appRoutes.loginAdmin.route} element={<LoginAdmin />} />
      <Route path={appRoutes.changePassword.route} element={<ChangePassword />} />
      <Route path={appRoutes.signup.route} element={<Signup />} />
      <Route path={appRoutes.signupAdmin.route} element={<SignUpAdmin />} />
      {/* <Route path={appRoutes.signupAdmin.route} element={<Signup />} /> */}
      <Route path={appRoutes.editUser.route} element={<EditUser />} />
      <Route path={appRoutes.enrollUser.route} element={<EnrollUser />} />
      <Route path={appRoutes.myInfo.route} element={<MyInfoUpdate />} />

      <Route path={appRoutes.home.route} element={<Home />} />

      <Route path={appRoutes.conferenceInfo.route} element={<ConferenceInfo />} />
      <Route path={`${appRoutes.conferenceInfo.route}/:id?`} element={<ConferenceInfoDetail />} />
      <Route path={`${appRoutes.conferenceInfo.children.form.route}?`} element={<ConferenceInfoForm />} />

      <Route path={appRoutes.host.route} element={<HostInfo />} />
      <Route path={`${appRoutes.host.route}/:id?`} element={<HostInfoDetail />} />
      <Route path={`${appRoutes.host.children.form.route}?`} element={<HostInfoForm />} />

      <Route path={appRoutes.company.route} element={<CompanyInfo />} />
      <Route path={`${appRoutes.company.route}/:id?`} element={<CompanyInfoDetail />} />
      <Route path={`${appRoutes.company.children.form.route}?`} element={<CompanyInfoForm />} />

      <Route path={appRoutes.solution.route} element={<SolutionInfo />} />
      <Route path={`${appRoutes.solution.route}/:id?`} element={<SolutionInfoDetail />} />

      <Route path={appRoutes.visitor.route} element={<VisitorInfo />} />
      <Route path={`${appRoutes.visitor.route}/:id?`} element={<VisitorInfoDetail />} />

      <Route path={`${appRoutes.conferenceInfo.children.eventDetail.route}/:id?`} element={<EventDetail />} />
      {/*<Route path={`${appRoutes.conferenceInfo.children.eventDetailForm.route}/:eventId?`} element={<ConferenceEventForm />} />*/}
      <Route path={`${appRoutes.conferenceInfo.children.eventDetailForm.route}/:eventId?`} element={<EventInsert />} />

      <Route path={appRoutes.management.route} element={<Managements />} />
      <Route path={appRoutes.nftStatus.route} element={<NftStatus />} />
      <Route path={appRoutes.test.route} element={<EventInsert />} />

      <Route path={appRoutes.pruufStatus.route} element={<PruufStatus />} />
      <Route path={appRoutes.qr.route} element={<Qr />} />
      <Route path={'/qrTemplate1'} element={<Template1 />} />
      <Route path={'/qrTemplate2'} element={<Template2 />} />
      <Route path={'/qrTemplate3'} element={<Template3 />} />
      <Route path={'/qrTemplate4'} element={<Template4 />} />
      <Route path={appRoutes.deeplink.route} element={<DeeplinkFallback />} />
      <Route path={appRoutes.privacy.route} element={<PrivacyPolicy />} />
      <Route path={'/'} element={<LoginAdmin />} />
      <Route path={'/admin'} element={<Login />} />
      <Route path={'/notfound'} element={<NotFound />} />
      <Route path={'*'} element={<Navigate to={'/notfound'} replace={true} />} />
    </Routes>
  );
};
