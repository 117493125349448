import { useEffect, useRef } from 'react';
import QRCode from 'qrcode-svg-ts';

type Props = {
  link: string;
  width?: string;
  height?: string;
  imgTitle?: string;
};

const useQRCodeGenerator = ({ link, width = '450', height = '450', imgTitle = '' }: Props) => {
  const qrCode = useRef<QRCode>();
  const canvas = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    generateQrCode();
  }, []);

  const generateQrCode = async () => {
    console.log(link)
    const qrcode = new QRCode({
      content: link,
      width: 1200,
      height: 1200,
      padding: 5,
      ecl: 'Q',
    });

    const dataUrl = qrcode.toDataURL();
    const img = new Image();
    img.width = 1200;
    img.height = 1200;
    canvas.current!.width = 1200;
    canvas.current!.height = 1200;
    img.onload = () => {
      const ctx = canvas.current!.getContext('2d');
      ctx!.drawImage(img, 0, 0, 1200, 1200, 0, 0, 1200, 1200);
      canvas.current!.style.width = `${width}px`;
      canvas.current!.style.height = `${height}px`;
      canvas.current!.style.margin = 'auto';
    };
    img.src = dataUrl;

    qrCode.current = qrcode;
  };

  const saveSvg = () => {
    if (qrCode.current) {
      const downloadLink = document.createElement('a');
      downloadLink.setAttribute('download', `${imgTitle}qr.svg`);
      const data = qrCode.current.svg();
      downloadLink.setAttribute('href', 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(data));
      downloadLink.click();
    }
  };

  const savePng = () => {
    if (canvas.current) {
      const downloadLink = document.createElement('a');
      downloadLink.setAttribute('download', `${imgTitle}qr.png`);
      const data = canvas.current.toDataURL('image/png');
      downloadLink.setAttribute('href', data.replaceAll('image/png', 'image/octet-stream'));
      downloadLink.click();
    }
  };

  return { canvas, saveSvg, savePng };
};

export default useQRCodeGenerator;
