/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable no-prototype-builtins */
/* eslint-disable @typescript-eslint/no-empty-function */
import { useNavigate, useParams } from 'react-router-dom';
import { Controller, DeepPartial, SubmitHandler, UseControllerProps, useForm } from 'react-hook-form';
import { Button, Col, DatePicker, Divider, Image, Input, message, Row, Upload, UploadFile } from 'antd';
import dayjs from 'dayjs';
import { Address, useDaumPostcodePopup } from 'react-daum-postcode';
import _ from 'lodash';
import validator from 'validator';

import styles from './conferenceInfoForm.module.css';
import FormAlert from '../../common/components/FormAlert';
import { axiosInstance } from '../../common/providers & services/AxiosService';
import { useEffect, useMemo, useState } from 'react';
import { randomFromArray } from '../../lib/function';
import { useDetailedConference } from '../../common/utils/apiHooks';
import { useAppDispatch } from '../../store/store';
import useUserInfo from '../../hooks/useUserInfo';
import { appRoutes } from '../../AppRoutes';
import PruufBadge from '../../common/components/pruuf/Badge';

const dateFormat = 'YYYY-MM-DD';
const timeFormat = 'HH:mm';
type Inputs = {
  name: string;
  name_kor: string;
  name_eng: string;
  dateRange: [dayjs.Dayjs | null, dayjs.Dayjs | null]; // day.js 타입 사용
  location: string;
  location_detail: string;
  place: string;
  host: string;
  organizer: string;
  conference_badge: string;
  company_badge: string;
  solution_badge: string;
  event_badge: string;
  homepage: string;
  booth_info_link: string;
  description: string;
};
const { RangePicker } = DatePicker;

type ExtractRulesType<T> = T extends { rules?: infer R } ? R : never;

// UseControllerProps에서 rules 타입 추출
type RulesType = ExtractRulesType<UseControllerProps>;

// type FormRules = DeepPartial<Record<keyof Inputs, RulesType>>;
type FormRules = DeepPartial<Record<keyof Inputs, any>>; // rules의 타입을 정의

const formInputInfo: Partial<Record<keyof Inputs, string>> = {
  name_kor: '행사명(국문)',
  name_eng: '행사명(영문)',
  dateRange: '행사일시',
  location: '행사주소',
  location_detail: '상세주소',
  place: '전시장정보',
  host: '주최',
  organizer: '주관',
  conference_badge: '행사 배지',
  company_badge: '참가사 배지',
  solution_badge: '솔루션 배지',
  event_badge: '이벤트 배지',
  homepage: '행사 홈페이지',
  booth_info_link: '부스 정보 링크',
  description: '행사 관련 정보',
};

const isValidWebsite = (value: string | null) => {
  if (_.isEmpty(value)) {
    return true;
  }
  return validator.isURL(value as string, { require_protocol: false });
};

const ruleGroup: Partial<FormRules> = {
  name_kor: { required: `${formInputInfo.name_kor}은 필수입니다.` },
  name_eng: { required: `${formInputInfo.name_eng}은 필수입니다.` },
  dateRange: { required: `${formInputInfo.dateRange}는 필수입니다.` },
  location: { required: `${formInputInfo.location}는 필수입니다.` },
  location_detail: { required: `${formInputInfo.location_detail}는 필수입니다.` },
  place: { required: `${formInputInfo.place}는 필수입니다.` },
  host: { required: `${formInputInfo.host}는 필수입니다.` },
  organizer: { required: `${formInputInfo.organizer}은 필수입니다.` },
  // conference_badge: { required: `${formInputInfo.conference_badge}는 필수입니다.` },
  // company_badge: { required: `${formInputInfo.company_badge}는 필수입니다.` },
  homepage: {
    validate: {
      validWebsite: (value: string | null) => isValidWebsite(value) || '유효한 홈페이지 주소 형식이 아닙니다',
    },
  },
  booth_info_link: {
    validate: {
      validWebsite: (value: string | null) => isValidWebsite(value) || '유효한 홈페이지 주소 형식이 아닙니다',
    },
  },
};

const ConferenceInfoForm = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const open = useDaumPostcodePopup('//t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js');
  const { id } = useParams();
  const navigate = useNavigate();
  const [isLocationActive, setIsLocationActive] = useState(false);

  const [fileLists, setFileLists] = useState<any[]>([[], [], [], []]);

  const [removeFileLists, setRemoveFileLists] = useState<boolean[]>([false, false, false, false]);

  const userInfo = useUserInfo();

  const isImage = (file: File) => {
    // 파일의 MIME 타입을 확인하여 이미지인지 확인
    return file.type.startsWith('image/');
  };

  const handleBeforeUpload = (file: File, index: number) => {
    if (!isImage(file)) {
      message.error('이미지 파일만 업로드 가능합니다.');
    }
    const newFileLists = [...fileLists];
    newFileLists[index] = [file];
    setFileLists(newFileLists);

    return false;
  };

  const handleFileChange = (fileList: any[], index: number) => {
    const newFileLists = [...fileLists];
    newFileLists[index] = fileList;
    setFileLists(newFileLists);
  };

  const renderUpload = (index: number) => {
    const uploadProps = {
      beforeUpload: (file: File) => handleBeforeUpload(file, index),
      onChange: ({ fileList }: { fileList: any[] }) => handleFileChange(fileList, index),
      fileList: fileLists[index],
      onRemove: (file: UploadFile) => {
        const newRemoveFiles = [...removeFileLists];
        newRemoveFiles[index] = true;
        setRemoveFileLists(newRemoveFiles);
      },
    };

    const blob = new Blob([fileLists[index][0]?.originFileObj], { type: fileLists[index][0]?.originFileObj?.type });

    // const thumNail = fileLists[index].length > 0 ? URL.createObjectURL(blob) : '';

    const thumNail = fileLists[index][0]?.url || (fileLists[index].length > 0 ? URL.createObjectURL(blob) : '');

    return (
      <>
        <Upload {...uploadProps}>
          <Button tabIndex={-1}>파일등록</Button>
        </Upload>
        {thumNail && <Image src={thumNail} width={200} />}
      </>
    );
  };

  const conferenceApiData = id !== undefined ? useDetailedConference(id) : undefined;
  const modifiedMode = !_.isEmpty(conferenceApiData?.conference);
  const conference = conferenceApiData?.conference;
  const defaultValues: Partial<Inputs> = useMemo(() => {
    return {
      name_kor: conference?.name_kor || '',
      name_eng: conference?.name_eng || '',
      dateRange: [dayjs(conference?.start_time) || null, dayjs(conference?.end_time) || null],
      location: conference?.location || '',
      location_detail: conference?.location_detail || '',
      place: conference?.place || '',
      host: conference ? conference.hostsParsed.join(',') : '',
      organizer: conference ? conference.organizersParsed.join(',') : '',

      conference_badge: conference?.conference_badge ? JSON.parse(conference?.conference_badge)[0]['full_path'] : '',
      company_badge: conference?.company_badge ? JSON.parse(conference?.company_badge)[0]['full_path'] : '',
      solution_badge: conference?.solution_badge ? JSON.parse(conference?.solution_badge)[0]['full_path'] : '',
      event_badge: conference?.event_badge ? JSON.parse(conference?.event_badge)[0]['full_path'] : '',
      homepage: conference?.homepage || '',
      booth_info_link: conference?.booth_info_link || '',
      description: conference?.description || '',
    };
  }, [conference]);

  const dispatch = useAppDispatch();

  const {
    register,
    watch,
    handleSubmit,
    control,
    setError,
    clearErrors,
    setValue,
    setFocus,
    reset,
    formState: { errors, isValid },
  } = useForm<Inputs>({
    // mode: 'onChange',
    defaultValues,
  });

  useEffect(() => {
    reset({ ...defaultValues });
    setIsLocationActive(modifiedMode);

    if (modifiedMode) {
      setFileLists([
        conference?.conference_badge
          ? [
            {
              uid: '1',
              name: JSON.parse(conference?.conference_badge)[0]['origin_name'],
              status: 'done',
              url: defaultValues.conference_badge,
            },
          ]
          : [],
        conference?.company_badge
          ? [
            {
              uid: '1',
              name: JSON.parse(conference?.company_badge)[0]['origin_name'],
              status: 'done',
              url: defaultValues.company_badge,
            },
          ]
          : [],
        conference?.solution_badge
          ? [
            {
              uid: '1',
              name: JSON.parse(conference?.solution_badge)[0]['origin_name'],
              status: 'done',
              url: defaultValues.solution_badge,
            },
          ]
          : [],
        conference?.event_badge
          ? [
            {
              uid: '1',
              name: JSON.parse(conference?.event_badge)[0]['origin_name'],
              status: 'done',
              url: defaultValues.event_badge,
            },
          ]
          : [],
      ]);
    }
  }, [conference, modifiedMode]);

  const submitAction: SubmitHandler<Inputs> = async data => {
    const submitData = {
      ...data,
      ...((modifiedMode && { conference_id: conference?.id })),
      start_time: dayjs(data.dateRange[0])?.format(`${dateFormat} ${timeFormat}`),
      state: randomFromArray(['prepare', 'complete', 'progress']),
      end_time: dayjs(data.dateRange[1])?.format(`${dateFormat} ${timeFormat}`),
      hosts: JSON.stringify(_.compact(data.host.split(','))),
      organizers: JSON.stringify(_.compact(data.organizer.split(','))),
      conference_badge: fileLists[0][0] ? new Blob([fileLists[0][0]?.originFileObj], { type: fileLists[0][0]?.originFileObj?.type }) : '',
      company_badge: fileLists[1][0] ? new Blob([fileLists[1][0]?.originFileObj], { type: fileLists[1][0]?.originFileObj?.type }) : '',
      solution_badge: fileLists[2][0] ? new Blob([fileLists[2][0]?.originFileObj], { type: fileLists[2][0]?.originFileObj?.type }) : '',
      event_badge: fileLists[3][0] ? new Blob([fileLists[3][0]?.originFileObj, { type: fileLists[3][0]?.originFileObj?.type }]) : '',
      ...(userInfo?.company_setup_id && { company_setup_id: userInfo.company_setup_id }),
    };

    // FormData 객체를 생성합니다.
    const formData = new FormData();

    // submitData의 각 속성을 FormData에 추가합니다.
    for (const key in submitData) {
      if (submitData.hasOwnProperty(key)) {
        // Blob인 경우에만 파일 데이터로 추가합니다.
        //@ts-ignore
        if (submitData[key] instanceof Blob) {
          //@ts-ignore
          // formData.append(key, submitData[key], `${key}.${getFileExtensionFromBlob(submitData[key])}`); // 파일 데이터 추가
        } else {
          //@ts-ignore
          formData.append(key, submitData[key] as string); // 일반 데이터 추가
        }
      }
    }

    if (fileLists[0][0]?.originFileObj)
      formData.append(
        'conference_badge',
        new Blob([fileLists[0][0]?.originFileObj], { type: fileLists[0][0]?.originFileObj?.type }),
        fileLists[0][0].name,
      );
    if (fileLists[1][0]?.originFileObj)
      formData.append(
        'company_badge',
        new Blob([fileLists[1][0]?.originFileObj], { type: fileLists[1][0]?.originFileObj?.type }),
        fileLists[1][0].name,
      );
    if (fileLists[2][0]?.originFileObj)
      formData.append(
        'solution_badge',
        new Blob([fileLists[2][0]?.originFileObj], { type: fileLists[2][0]?.originFileObj?.type }),
        fileLists[2][0].name,
      );
    if (fileLists[3][0]?.originFileObj)
      formData.append(
        'event_badge',
        new Blob([fileLists[3][0]?.originFileObj], { type: fileLists[3][0]?.originFileObj?.type }),
        fileLists[3][0].name,
      );

    removeFileLists.forEach((r, index) => {
      let key = 'remove_conference_badge';
      switch (index) {
        case 0:
          key = 'remove_conference_badge';
          break;
        case 1:
          key = 'remove_company_badge';
          break;
        case 2:
          key = 'remove_solution_badge';
          break;
        case 3:
          key = 'remove_event_badge';
          break;
      }
      // 삭제가 되어을 경우... remove_conference_badge, remove_company_badge, remove_solution_badge, remove_event_badge 에 값 넣기
      // @ts-ignore
      if (r) formData.append(key, r); // 일반 데이터 추가


    });

    // console.log('submitData', submitData);
    // console.log('formData', formData);
    // console.log('fileLists', fileLists);
    // console.log('removeFileLists', removeFileLists);
    // return false;

    // if (!submitData.conference_badge) {
    //   setError('conference_badge', { type: 'required', message: '행사배지는 필수입니다.' });
    // }
    // if (!submitData.company_badge) {
    //   setError('company_badge', { type: 'required', message: '참가사는 필수입니다.' });
    // }

    try {
      messageApi.open({
        key: 'apiMsg',
        type: 'loading',
        content: modifiedMode ? '수정중...' : '등록중...',
        duration: 0,
      });
      // await axiosInstance.post('conferences', { ...submitData }, { headers: { 'Content-Type': 'multipart/form-data' } });
      await axiosInstance.post('conferences', formData, { headers: { 'Content-Type': 'multipart/form-data' } });
      messageApi.open({
        key: 'apiMsg',
        type: 'success',
        content: modifiedMode ? '성공적으로 수정되었습니다.' : '성공적으로 등록되었습니다.',
        onClose: () => {
          // conference?.id && dispatch(fetchDetailedConference(conference.id));
          // navigate(appRoutes.conferenceInfo.route);
          // navigate(-1);

          // location.href = `${appRoutes.conferenceInfo.route}/${conference?.id}#intro`;
          // @ts-ignore
          if (conference?.id) {
            location.href = `${appRoutes.conferenceInfo.route}/${conference?.id}#intro`;
          } else {
            location.href = userInfo.type === 'admin' ? '/conference-info' : '/conference-info?listType=user';
          }
        },
      });
    } catch (e) {
      console.error(`행사${modifiedMode ? '수정' : '등록'} 에러`, e);
      messageApi.open({
        key: 'apiMsg',
        type: 'error',
        content: `${modifiedMode ? '수정' : '등록'}에 실패하였습니다. 지속되면 문의해주세요.`,
      });
    }
  };

  const disabledDate = (current: dayjs.Dayjs) => {
    return current && current < dayjs().startOf('day');
  };

  const getPostcode = (data: Address) => {
    const fullAddress = data.address;
    setIsLocationActive(true);
    setValue('location', fullAddress);
    clearErrors('location');
  };

  const handleAddress = () => {
    open({ onComplete: getPostcode });
  };

  return (
    <section className={styles.wrap}>
      {contextHolder}
      <h2>행사 등록하기</h2>
      <h4>기본정보</h4>
      <form className={styles.formWrap} onSubmit={handleSubmit(submitAction)}>
        <Divider style={{ borderWidth: '2px', borderColor: '#555' }} />
        <Row align={'middle'}>
          <Col span={4}>
            <span>행사명(국문) {ruleGroup?.name_kor?.required && '*'}</span>
          </Col>
          <Col span={20}>
            <Controller
              control={control}
              name={'name_kor'}
              rules={ruleGroup.name_kor}
              render={({ field }) => <Input {...field} placeholder={'국문 행사명을 입력해주세요.'} />}
            />
            {errors.name_kor?.message && <FormAlert msg={errors.name_kor.message} />}
          </Col>
        </Row>
        <Divider />
        <Row align={'middle'}>
          <Col span={4}>
            <span>행사명(영문) {ruleGroup?.name_eng?.required && '*'}</span>
          </Col>
          <Col span={20}>
            <Controller
              control={control}
              name={'name_eng'}
              rules={ruleGroup.name_eng}
              render={({ field }) => <Input {...field} placeholder={'영문 행사명을 입력해주세요.'} />}
            />
            {errors.name_eng?.message && <FormAlert msg={errors.name_eng.message} />}
          </Col>
        </Row>
        <Divider />
        <Row align={'middle'}>
          <Col span={4}>
            <span>행사일시 {ruleGroup?.dateRange?.required && '*'}</span>
          </Col>
          <Col span={6}>
            <Controller
              control={control}
              name={'dateRange'}
              rules={ruleGroup.dateRange}
              render={({ field }) => (
                <RangePicker
                  style={{ width: '330px' }}
                  {...field}
                  onChange={date => {
                    date && field.onChange(date);
                  }}
                  disabledDate={disabledDate}
                  showTime={{
                    hideDisabledOptions: true,
                    defaultValue: [dayjs('09:00:00', timeFormat), dayjs('18:00:00', timeFormat)],
                    minuteStep: 10,
                  }}
                  format={`${dateFormat} ${timeFormat}`}
                />
              )}
            />
            {errors.dateRange?.message && <FormAlert msg={errors.dateRange.message as string} />}
          </Col>
          <Col offset={14} />
        </Row>
        <Divider />
        <Row align={'middle'}>
          <Col span={4}>
            <span>행사주소 {ruleGroup.location?.required && '*'}</span>
          </Col>
          <Col span={16}>
            <Controller
              control={control}
              name={'location'}
              rules={ruleGroup.location}
              render={({ field }) => <Input {...field} placeholder={'주소찾기를 클릭해주세요'} disabled={!isLocationActive} />}
            />
            {errors.location?.message && <FormAlert msg={errors.location.message} />}
          </Col>
          <Col span={4}>
            <Button tabIndex={-1} onClick={handleAddress}>
              주소찾기
            </Button>
          </Col>
        </Row>
        <Divider />
        <Row align={'middle'}>
          <Col span={4}>
            <span>상세주소 {ruleGroup?.location_detail?.required && '*'}</span>
          </Col>
          <Col span={20}>
            <Controller
              control={control}
              name={'location_detail'}
              rules={ruleGroup.location_detail}
              render={({ field }) => (
                <Input {...field} placeholder={'상세주소를 입력해주세요. (예: 삼성코엑스)'} disabled={!isLocationActive} />
              )}
            />
            {errors.location_detail?.message && <FormAlert msg={errors.location_detail.message} />}
          </Col>
        </Row>
        <Divider />
        <Row align={'middle'}>
          <Col span={4}>
            <span>전시장 정보 {ruleGroup?.place.required && '*'}</span>
          </Col>
          <Col span={20}>
            <Controller
              control={control}
              name={'place'}
              rules={ruleGroup.place}
              render={({ field }) => (
                <Input {...field} placeholder={'행사장의 전시장 정보를 입력해주세요. (예: 제2전시장 7~8홀, 컨퍼런스룸)'} />
              )}
            />
            {errors.place?.message && <FormAlert msg={errors.place.message} />}
          </Col>
        </Row>
        <Divider />
        <Row align={'middle'}>
          <Col span={4}>
            <span>주최 {ruleGroup?.host?.required && '*'}</span>
          </Col>
          <Col span={20}>
            <Controller
              control={control}
              name={'host'}
              rules={ruleGroup.host}
              render={({ field }) => <Input {...field} placeholder={',로 구분하여 주최하는 곳을 입력해주세요.'} />}
            />
            {errors.host?.message && <FormAlert msg={errors.host.message} />}
          </Col>
        </Row>
        <Divider />
        <Row align={'middle'}>
          <Col span={4}>
            <span>주관 {ruleGroup?.organizer?.required && '*'}</span>
          </Col>
          <Col span={20}>
            <Controller
              control={control}
              name={'organizer'}
              rules={ruleGroup.organizer}
              render={({ field }) => <Input {...field} placeholder={',로 구분하여 주관 정보를 입력해주세요.'} />}
            />
            {errors.organizer?.message && <FormAlert msg={errors.organizer.message} />}
          </Col>
        </Row>
        <Divider />
        <Row align={'middle'}>
          <Col span={4}>
            <span>행사 배지 {ruleGroup?.conference_badge?.required && '*'}</span>
          </Col>
          <Col span={20}>
            <Row align={'middle'} gutter={40}>
              <Col span={6}>
                {renderUpload(0)}
                <Row align={'middle'}>
                  <small>미 등록시 우측의 기본 배지로 표기됩니다.</small>
                  <small>배지 이미지는 600*600 사이즈를 권장합니다.</small>
                </Row>
              </Col>
              <Col>
                <PruufBadge koName={`${watch('name_kor') || '국문 행사명'}`} engName={`${watch('name_eng') || '영문 행사명'}`}
                            type={'conference'}
                            info={`${watch('description') || '행사관련 정보'}`} />
              </Col>
            </Row>
          </Col>
        </Row>
        <Divider />
        <Row align={'middle'}>
          <Col span={4}>
            <span>참가사 배지 {ruleGroup?.company_badge?.required && '*'}</span>
          </Col>
          <Col span={20}>
            <Row align={'middle'} gutter={40}>
              <Col span={6}>
                {renderUpload(1)}
                <Row align={'middle'}>
                  <small>미 등록시 우측의 기본 배지로 표기됩니다.</small>
                  <small>배지 이미지는 600*600 사이즈를 권장합니다.</small>
                </Row>
              </Col>
              <Col>
                <PruufBadge koName={`${watch('name_kor') || '국문 행사명'}`} engName={`${watch('name_eng') || '영문 행사명'}`}
                            type={'company'}
                            info={'참가사 이름'} />
              </Col>
            </Row>
          </Col>
        </Row>
        <Divider />
        <Row align={'middle'}>
          <Col span={4}>
            <span>솔루션 배지{ruleGroup?.solution_badge?.required && '*'}</span>
          </Col>
          <Col span={20}>
            <Row align={'middle'} gutter={40}>
              <Col span={6}>
                {renderUpload(2)}
                <Row align={'middle'}>
                  <small>미 등록시 우측의 기본 배지로 표기됩니다.</small>
                  <small>배지 이미지는 600*600 사이즈를 권장합니다.</small>
                </Row>
              </Col>
              <Col>
                <PruufBadge koName={`${watch('name_kor') || '국문 행사명'}`} engName={`${watch('name_eng') || '영문 행사명'}`}
                            type={'solution'}
                            info={'솔루션 이름'} />
              </Col>
            </Row>
          </Col>
        </Row>
        <Divider />
        <Row align={'middle'}>
          <Col span={4}>
            <span>이벤트 배지 {ruleGroup?.event_badge?.required && '*'}</span>
          </Col>
          <Col span={20}>
            <Row align={'middle'} gutter={40}>
              <Col span={6}>
                {renderUpload(3)}
                <Row align={'middle'}>
                  <small>미 등록시 우측의 기본 배지로 표기됩니다.</small>
                  <small>배지 이미지는 600*600 사이즈를 권장합니다.</small>
                </Row>
              </Col>
              <Col>
                <PruufBadge koName={`${watch('name_kor') || '국문 행사명'}`} engName={`${watch('name_eng') || '영문 행사명'}`}
                            type={'event'}
                            info={''} />
              </Col>
            </Row>
          </Col>
        </Row>
        <Divider />
        <Row align={'middle'}>
          <Col span={4}>
            <span>행사 홈페이지 {ruleGroup?.homepage?.required && '*'}</span>
          </Col>
          <Col span={20}>
            <Controller
              control={control}
              name={'homepage'}
              rules={ruleGroup.homepage}
              render={({ field }) => <Input {...field} placeholder={'행사 홈페이지 링크를 입력해주세요.'} />}
            />
            {errors.homepage?.message && (
              <p>
                <FormAlert msg={errors.homepage.message} />
              </p>
            )}
          </Col>
        </Row>
        <Divider />
        <Row align={'middle'}>
          <Col span={4}>
            <span>부스 정보 링크 {ruleGroup?.booth_info_link?.required && '*'}</span>
          </Col>
          <Col span={20}>
            <Controller
              control={control}
              name={'booth_info_link'}
              rules={ruleGroup.booth_info_link}
              render={({ field }) => <Input {...field} placeholder={'부스 정보 링크를 입력해주세요.'} />}
            />
            {errors.booth_info_link?.message && (
              <p>
                <FormAlert msg={errors.booth_info_link.message} />
              </p>
            )}
          </Col>
        </Row>
        <Divider />
        <Row align={'middle'}>
          <Col span={4}>
            <span>행사 관련 정보 {ruleGroup?.description?.required && '*'}</span>
          </Col>
          <Col span={20}>
            <Controller
              control={control}
              name={'description'}
              render={({ field }) => (
                <Input.TextArea style={{ resize: 'none' }} {...field} rows={7}
                                placeholder={'행사에 관련된 정보가 있다면 입력해주세요.'} />
              )}
            />
          </Col>
        </Row>
        <Divider style={{ borderWidth: '2px', borderColor: '#555' }} />
        <Row justify={'end'} gutter={20}>
          <Col>
            <Button type={'primary'} htmlType={'submit'} size={'large'}>
              {modifiedMode ? '수정하기' : '등록하기'}
            </Button>
          </Col>

          <Col>
            <Button
              size={'large'}
              onClick={() => {
                navigate(-1);
              }}
            >
              뒤로가기
            </Button>
          </Col>
        </Row>
      </form>
    </section>
  );
};

export default ConferenceInfoForm;
