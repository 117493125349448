import CryptoJS from 'crypto-js';

const defaultKey = 'pruufSecretKey';
export const defaultConferenceKey = 'pruufConferenceKey__';

// 암호화 함수
export function encryptMessage(message, secretKey = defaultKey) {
  return CryptoJS.AES.encrypt(message, secretKey).toString();
}

// 복호화 함수
export function decryptMessage(encryptedMessage, secretKey = defaultKey) {
  const bytes = CryptoJS.AES.decrypt(encryptedMessage, secretKey);
  return bytes.toString(CryptoJS.enc.Utf8);
}

