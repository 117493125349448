import React, { useMemo, useState } from 'react';
import { TableColumn } from 'react-data-table-component';
import { Table } from '../../../common/components/Table';
import { Container, FilterBoxContainer, SubTitle } from '../../../common/components/StyledComponents';
import { ConferenceDetail } from '../../../common/api/responseTypes';
import { axiosInstance } from '../../../common/providers & services/AxiosService';
import { useQuery } from '@tanstack/react-query';
import DefaultLayer from '../../../common/components/panels/defaultLayer';
import DefaultPopupPanel from '../../../common/components/panels/defaultPopupPanel';
import QrTemplate from './QrTemplate';
import PruufReport from './PruufReport';
import { SearchFilter } from '../../../common/components/filters/SearchFilter';

interface Props {
  conferenceId: string;
  conferenceInfo?: ConferenceDetail;
}

const initialState = {
  searchTerm: '',
};

type LayerType = 'qr' | 'pruuf';

export const ConferenceInfoDetailVisitors: React.FC<Props> = ({ conferenceId, conferenceInfo }) => {
  const [layerPopupOpen, setLayerPopupOpen] = useState(false);
  const [filterParams, setFilterParams] = useState(initialState);
  const [layerType, setLayerType] = useState<LayerType>('qr');
  const [layerValue, setLayerValue] = useState<string>('');
  const [templateValue, setTemplateValue] = useState<any>({});

  const fetchData = async () => {
    try {
      const response = await axiosInstance.get(`conferences/user?conference_id=${conferenceId}`);
      return response.data.data.users;
    } catch (e) {
      console.error('error...');
      return [{}];
    }
  };

  const columns: TableColumn<any>[] = [
    {
      name: '번호',
      selector: row => row.listIdx,
      sortable: true,
      grow: 0,
    },
    {
      name: '이름',
      selector: row => row.userName,
      sortable: true,
    },
    {
      name: '연락처',
      selector: row => row.userTel,
      sortable: true,
    },
    {
      name: '소속',
      selector: row => row.companyName,
      sortable: true,
    },
    {
      name: '직급',
      selector: row => row.userPosition,
      sortable: true,
    },
    {
      name: '부서',
      selector: row => row.userDepartment,
      sortable: true,
    },
    {
      name: '발급된 PRUUF',
      // selector: row => row.userPruufs.length,
      cell: row => {
        const handleQRpopup = (id: string) => {
          setLayerPopupOpen(true);
          setLayerType('pruuf');
          setLayerValue(row.user_id);
        };
        return (
          <span style={{ textDecoration: 'underline' }} onClick={() => handleQRpopup(row.id)}>
            {row.userPruufs.length}
          </span>
        );
      },
      sortable: true,
    },
  ];

  const {
    data: conferenceUser,
    isLoading,
    isError,
  } = useQuery(['conferenceUsersList', conferenceId], fetchData, {
    refetchOnWindowFocus: false,
    select: data => {
      const newArray: any = [];
      const userIds: any[] = [];

      Object.keys(data).map((item: any) => {
        console.log('item', data[item]);

        const { pruuf_info, user_info } = data[item];
        const userData = {
          companyName: user_info.company,
          userName: user_info.name,
          userTel: user_info.tel,
          userPosition: user_info.position,
          userDepartment: user_info.department,
          userPruufs: pruuf_info,
          company_setup_id: user_info.company_setup,
          user_id: user_info.id,
        };
        newArray.push(userData);
      });

      const filteredData = newArray.filter((item: any) => {
        const { searchTerm } = filterParams;

        if (searchTerm && !item.userName.includes(searchTerm)) {
          return false;
        }

        return true;
      });

      return filteredData.map((item: any, index: number) => {
        return {
          ...item,
          listIdx: filteredData.length - index,
        };
      });
    },
  });

  const excelData = useMemo(() => {
    if (conferenceUser?.length < 1 || conferenceUser === undefined) return [];
    return conferenceUser.map((item: any, index: number) => {
      return {
        번호: conferenceUser.length - index,
        이름: item.userName,
        연락처: item.userTel,
        소속: item.companyName,
        직급: item.userPosition,
        부서: item.userDepartment,
        발급된PRUUF: item.userPruufs.length,
      };
    });
  }, [conferenceUser]);

  if (isLoading || isError) return <></>;

  return (
    <Container gap={10}>
      {layerPopupOpen && (
        <DefaultLayer open={layerPopupOpen}>
          <DefaultPopupPanel
            actionClose={() => {
              setLayerPopupOpen(false);
            }}
            title={layerType === 'qr' ? 'QR코드 템플릿' : '참가자 PRUUF 발급 현황'}
          >
            <>
              {layerType === 'qr' &&
                <QrTemplate link={layerValue} templateInfo={templateValue} templateType={'solution'} />}
              {layerType === 'pruuf' &&
                <PruufReport listType={'all'} user_id={layerValue} conference_id={conferenceId} />}
            </>
          </DefaultPopupPanel>
        </DefaultLayer>
      )}
      <SubTitle>참가자 정보</SubTitle>
      <FilterBoxContainer>
        <SearchFilter
          onChange={searchTerm => setFilterParams(prev => ({ ...prev, searchTerm }))}
          placeholder={'참가자 이름을 입력해주세요.'}
          debounce={150}
        />
      </FilterBoxContainer>

      <Table data={conferenceUser} columns={columns} filename={`${conferenceInfo?.conference.name_kor}_참가자`}
             excelData={excelData} />
    </Container>
  );
};
