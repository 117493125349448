import { Button, Col, Divider, Input, message, Row } from 'antd';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';

import styles from './companyInfoForm.module.css';
import { useNavigate, useParams } from 'react-router-dom';
import { appRoutes } from '../../AppRoutes';
import { axiosInstance } from '../../common/providers & services/AxiosService';
import { useQuery } from '@tanstack/react-query';
import { handleTelInput } from '../../lib/function';
import LoadingIndicator from '../../common/components/LoadingIndicator';
import { useEffect } from 'react';

type Inputs = {
  name: string;
  type: string;
  target: string;
  companyName: string;
  tel: string;
  email: string;
};

const CompanyInfoForm = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const navigate = useNavigate();
  const { id } = useParams();


  const fetchData = async () => {
    if (!id) return {};
    try {
      const response = await axiosInstance.get(`company/${id}`);
      return response.data.data.userInfo;
    } catch (e) {
      console.log('e', e);
      return {};
    }
  };

  const { data, isLoading } = useQuery(['companyDetail', id], fetchData, {
    staleTime: 5000,
  });

  const defaultValues: any = {};
  const {
    register,
    watch,
    handleSubmit,
    control,
    setError,
    clearErrors,
    setValue,
    setFocus,
    reset,
    formState: { errors, isValid },
  } = useForm<Inputs>({
    // mode: 'onChange',
    defaultValues,
  });

  const modifiedMode = id !== undefined && data !== undefined;

  const handleTelChange = (tel: string) => {
    const afterTel = handleTelInput(tel);
    if (afterTel === false) {
      return false;
    }

    setValue('tel', afterTel);
  };

  useEffect(() => {
    if (data) reset({ name: data.name, tel: data.tel, email: data.email });

  }, [data]);

  const submitAction: SubmitHandler<Inputs> = async data => {
    const { name, tel, email } = data;

    const submitData = { name, tel, user_id: id, type: 'company', email, adminJoin: true };

    messageApi.open({
      key: 'apiMsg',
      type: 'loading',
      content: modifiedMode ? '수정중...' : '등록중...',
      duration: 0,
    });

    try {
      // await axiosInstance.put(`host/${id}`, { ...submitData });
      await axiosInstance.post('host', { ...submitData });
      messageApi.open({
        key: 'apiMsg',
        type: 'success',
        content: modifiedMode ? '성공적으로 수정되었습니다.' : '성공적으로 등록되었습니다.',
        onClose: () => {
          // navigate(appRoutes.company.route);
          location.href = appRoutes.company.route;
        },
      });
    } catch (e: any) {

      if (e.response.status === 403 && e.response.data.data.message) {
        const msg = e.response.data.data.message;
        messageApi.open({
          key: 'apiMsg',
          type: 'error',
          content: `${modifiedMode ? '수정' : '등록'}에 실패하였습니다. ${msg}입니다.`,
        });
        return false;
      }

      console.error(`참가사${modifiedMode ? '수정' : '등록'} 에러`, e);
      messageApi.open({
        key: 'apiMsg',
        type: 'error',
        content: `${modifiedMode ? '수정' : '등록'}에 실패하였습니다. 지속되면 문의해주세요.`,
      });
    }
  };

  if (isLoading || data === undefined) {
    return <LoadingIndicator />;
  }

  return (
    <section className={styles.wrap}>
      <>
        {contextHolder}
        <h2>참가사 {modifiedMode ? '수정하기' : '등록하기'}</h2>
        <div className={styles.titleWrap}>
          <h4>기본정보</h4>
          <p style={{ color: 'var(--light-grey)' }}>초기 비밀번호는 pruuf1234 입니다.</p>
        </div>
        <form className={styles.formWrap} onSubmit={handleSubmit(submitAction)}>
          <Divider style={{ borderWidth: '2px', borderColor: '#555' }} />
          {/*<Row align={'middle'}>*/}
          {/*  <Col span={4}>*/}
          {/*    <span>참가사명</span>*/}
          {/*  </Col>*/}
          {/*  <Col span={10}>*/}
          {/*    <Controller*/}
          {/*      control={control}*/}
          {/*      name={'companyName'}*/}
          {/*      render={({ field }) => <Input {...field} placeholder={'참가사명을 입력하세요.'} disabled={!modifiedMode} />}*/}
          {/*    />*/}
          {/*  </Col>*/}
          {/*  <Col span={10}></Col>*/}
          {/*</Row>*/}
          {/*<Divider />*/}
          <Row align={'middle'}>
            <Col span={4}>
              <span>담당자</span>
            </Col>
            <Col span={10}>
              <Controller
                control={control}
                name={'name'}
                rules={{ required: '필수입니다.' }}
                render={({ field }) => <Input {...field} placeholder={'담당자명을 입력해주세요.'} />}
              />
            </Col>
            <Col span={10}></Col>
          </Row>
          <Divider />
          <Row align={'middle'}>
            <Col span={4}>
              <span>담당자 연락처</span>
            </Col>
            <Col span={10}>
              <Controller
                control={control}
                name={'tel'}
                rules={{
                  required: '필수입니다.',
                  pattern: /^(02|0[3-9]\d{1})-\d{3,4}-\d{4}$|^010-\d{3,4}-\d{4}$|^\+82-10-\d{4}-\d{4}$/,
                }}
                render={({ field }) => (
                  <Input
                    {...field}
                    placeholder={'담당자 연락처를 입력해주세요.'}
                    onChange={e => {
                      handleTelChange(e.target.value);
                    }}
                  />
                )}
              />
            </Col>
            <Col span={10}></Col>
          </Row>
          <Divider />
          <Row align={'middle'}>
            <Col span={4}>
              <span>이메일(ID)</span>
            </Col>
            <Col span={10}>
              <Controller
                control={control}
                name={'email'}
                rules={{ required: '필수입니다.' }}
                render={({ field }) => <Input {...field} placeholder={'이메일을 입력해주세요.'} disabled={modifiedMode} />}
              />
            </Col>
            <Col span={10}></Col>
          </Row>

          <Divider style={{ borderWidth: '2px', borderColor: '#555' }} />
          <Row justify={'end'} gutter={20}>
            <Col>
              <Button type={'primary'} htmlType={'submit'} size={'large'} className={styles.submitBtn}>
                {modifiedMode ? '수정하기' : '등록하기'}
              </Button>
            </Col>
            <Col>
              <Button
                size={'large'}
                onClick={() => {
                  navigate(appRoutes.company.route);
                }}
              >
                취소하기
              </Button>
            </Col>
          </Row>
        </form>
      </>
    </section>
  );
};

export default CompanyInfoForm;
