/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { Button, Col, DatePicker, Divider, Input, message, Radio, Row, Select, Space, Switch } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { Controller, SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import FormAlert from '../../../common/components/FormAlert';
import { axiosInstance } from '../../../common/providers & services/AxiosService';
import { onlyNumber } from '../../../lib/function';
import styles from './eventInsert.module.css';
import _ from 'lodash';

type Props = {
  conferenceId: string;
  onCancle: () => void;
};
type Inputs = {
  name: string; //이벤트이름
  notice: string; //이벤트 공지사항
  event_type: string; // 이벤트 종류
  prize: string; //당첨내용
  winner: string | number; // 당첨자 수
  introduction: string; //이벤트 내용
  pruuf: boolean; // pruuf 발행여부
  pruuf_type?: string; //pruuf 발행조건
  pruuf_count?: string | number; // pruuf 발행개수
  dateRange: [dayjs.Dayjs | null, dayjs.Dayjs | null]; // day.js 타입 사용
  designation_counts?: { d_count: string }[]; //지정 숫자
};
const { RangePicker } = DatePicker;

const typeOptions = [
  { value: 'notice', label: '알림' },
  { value: 'random', label: '돌발' },
];

const pruufOptions = [
  { value: '랜덤', label: '랜덤' },
  { value: '선착순', label: '선착순' },
  // { value: '지정', label: '지정' },
];

const dateFormat = 'YYYY-MM-DD';
const timeFormat = 'HH:mm';

const pruuf_type_text = {
  '랜덤': 'random',
  '선착순': 'target',
  '지정': 'order',
};

function getLabelByValue(options: { value: string; label: string }[], value: string): string | undefined {
  const foundOption = options.find(option => option.value === value);
  return foundOption ? foundOption.label : undefined;
}

function getKeyByValue(object: Record<string, string>, value: string): string | undefined {
  return Object.keys(object).find(key => object[key] === value);
}


const EventInsert = ({
                       conferenceId,
                       onCancle = () => {
                         history.back();
                       },
                     }: Partial<Props>) => {
  const { eventId } = useParams();

  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const [messageApi, contextHolder] = message.useMessage();

  const [pruuf, setPruuf] = useState<boolean | null>(false);
  const [pruufSelectedOption, setPruufSelectedOption] = useState('');

  const [hasDuplicatesError, setHasDuplicatesError] = useState(false);
  const [anyGreaterThanPruufCountError, setAnyGreaterThanPruufCountError] = useState(false);

  const [noticeUse, setNoticeUse] = useState(false);

  const [eventType, setEventType] = useState('random');

  const [pruufType, setPruufType] = useState('랜덤');

  const handleRadioChange = (e: RadioChangeEvent) => {
    setPruuf(e.target.value === 'true' ? true : false);

    /* '예' 선택시 발행조건을 지정으로 남겨두고 '아니오' -> '예' 로 다시 선택시 지정이 남아있던 부분 fix */
    if (pruuf) {
      setPruufSelectedOption('');
    }
  };

  const modifiedMode = eventId !== undefined;

  const defaultValues: Partial<Inputs> = {
    designation_counts: [{ d_count: '' }],
    event_type: 'random',
    pruuf_type: '랜덤',
  };
  const {
    handleSubmit,
    control,
    reset,
    setValue,
    getValues,
    watch,
    setError,
    clearErrors,
    formState: { errors, isValid },
  } = useForm<Inputs>({
    defaultValues,
  });

  const pruuf_count = watch('pruuf_count');

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'designation_counts',
  });

  const fetchData = async () => {
    try {
      if (!eventId) {
        return {};
      }
      const response = await axiosInstance.get(`event/${eventId}`);
      return response.data.data.eventInfo;
    } catch (e) {
      console.error('error...');
      return {};
    }
  };

  const {
    data: eventData,
    isLoading,
    isError,
  } = useQuery(['eventDetail', eventId], fetchData, {
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    // if (eventData) {
    if (!_.isEmpty(eventData)) {
      reset({
        name: eventData.name,
        dateRange: [dayjs(eventData?.start_time) || null, dayjs(eventData?.end_time) || null],
        notice: eventData.notice,
        introduction: eventData.introduction,
        prize: eventData.prize,
        winner: eventData.winner.toString(),
        pruuf_count: eventData.pruuf_count,

      });
      setNoticeUse(eventData.noticeUse);
      setPruuf(eventData.pruuf > 0);
      setEventType(eventData.event_type);
      setValue('event_type', eventData.event_type);
      setValue('winner', eventData.winner);
      // setPruufType('선착순')
      // @ts-ignore
      setPruufType(getKeyByValue(pruuf_type_text, eventData.pruuf_type));
      clearErrors(['event_type', 'pruuf_type']);
    }
  }, [isLoading, reset, clearErrors]);

  const disabledDate = (current: dayjs.Dayjs) => {
    return current && current < dayjs().startOf('day');
  };

  const submitAction: SubmitHandler<Inputs> = async data => {
    const { name, notice, introduction, prize, winner, event_type, pruuf_type, pruuf_count } = data;
    const tempArray: any[] = [];

    if (pruuf_type === '지정' && data.designation_counts) {
      data.designation_counts.map(item => {
        tempArray.push(item.d_count);
      });
      console.log('tempArray ===>', tempArray);
      const isDuplicate = (arr: any[], value: any) => {
        const count = arr.filter(item => item === value).length;
        return count > 1;
      };

      const hasDuplicates = tempArray.some(value => isDuplicate(tempArray, value));
      setHasDuplicatesError(hasDuplicates);

      const anyGreaterThanPruufCount = tempArray.some(value => parseInt(value, 10) > parseInt(String(pruuf_count), 10));
      setAnyGreaterThanPruufCountError(anyGreaterThanPruufCount);

      if (hasDuplicates || anyGreaterThanPruufCount) {
        return;
      }
    }

    // @ts-ignore
    const submitData: {
      event_id?: number | string;
      conference_id: number | string;
      noticeUse: boolean;
      name: string;
      notice: string;
      introduction: string;
      prize: string;
      winner: string | number;
      event_type: string;
      pruuf: boolean | null;
      pruuf_count?: string | number; // Make pruuf_count optional
      pruuf_type?: string; // Make pruuf_type optional
      designation_counts: number[] | string;
      start_time: string | undefined;
      end_time: string | undefined;
    } = {
      // event_id: eventId,
      ...(eventId && { event_id: eventId }),
      conference_id: conferenceId ?? eventData.conference_id,
      name,
      noticeUse,
      notice,
      introduction,
      prize,
      pruuf,
      winner: onlyNumber(winner as string),
      event_type,
      pruuf_count: pruuf_count ? onlyNumber(pruuf_count as string) : undefined,
      // pruuf_type: pruuf_type || undefined,
      //@ts-ignore
      pruuf_type: pruuf_type_text[pruuf_type],
      designation_counts: JSON.stringify(tempArray),
      start_time: dayjs(data.dateRange[0])?.format(`${dateFormat} ${timeFormat}`),
      end_time: dayjs(data.dateRange[1])?.format(`${dateFormat} ${timeFormat}`),
    };

    console.log('submitData', submitData);

    try {
      messageApi.open({
        key: 'apiMsg',
        type: 'loading',
        content: modifiedMode ? '수정중...' : '등록중...',
        duration: 0,
      });
      await axiosInstance.post('event', { ...submitData });
      messageApi.open({
        key: 'apiMsg',
        type: 'success',
        content: modifiedMode ? '성공적으로 수정되었습니다.' : '성공적으로 등록되었습니다.',
        onClose: () => {
          queryClient.invalidateQueries(['eventList', conferenceId]);
          queryClient.invalidateQueries(['eventDetail', eventId]);
          navigate(-1);
        },
      });
    } catch (e) {
      console.error(`행사${modifiedMode ? '수정' : '등록'} 에러`, e);
      messageApi.open({
        key: 'apiMsg',
        type: 'error',
        content: `${modifiedMode ? '수정' : '등록'}에 실패하였습니다. 지속되면 문의해주세요.`,
      });
    }
  };

  if (isLoading || isError) return <></>;

  return (
    <section className={styles.wrap}>
      <>
        {contextHolder}
        <h2>이벤트 {modifiedMode ? '수정하기' : '등록하기'}</h2>
        <h4>기본정보</h4>
        <form className={styles.formWrap} onSubmit={handleSubmit(submitAction)}>
          <Divider style={{ borderWidth: '2px', borderColor: '#555', margin: '24px 0px 20px 0px' }} />
          {/* 이벤트 이름 */}
          <Row align={'middle'}>
            <Col span={4}>
              <span>이벤트 이름</span>
            </Col>
            <Col span={10}>
              <Controller
                control={control}
                name='name'
                rules={{ required: '이벤트 이름은 필수입니다.' }}
                render={({ field }) => <Input {...field} placeholder={'이벤트 이름을 입력해주세요.'}
                                              style={{ marginRight: '8px' }} />}
              />

              {errors.name?.message && <FormAlert msg={errors.name.message} />}
            </Col>
            <Col>
              <Switch checkedChildren={'공지사항 사용'} unCheckedChildren={'공지사항 미사용'} checked={noticeUse} onChange={() => {
                setNoticeUse(!noticeUse);
              }} />
            </Col>
          </Row>

          {/* 이벤트 공지사항 */}
          {
            noticeUse && <><Divider className={styles.event_insert_divider} /><Row align={'middle'}>
              <Col span={4}>
                <span>이벤트 공지사항</span>
              </Col>
              <Col span={10}>
                <Controller
                  control={control}
                  name='notice'
                  rules={{ required: '공지사항은 필수입니다.' }}
                  render={({ field }) => <Input {...field} placeholder={'공지사항을 입력해주세요.'}
                                                style={{ marginRight: '8px' }} />} />
                {errors.name?.message && <FormAlert msg={errors.name.message} />}
              </Col>
            </Row></>
          }


          <Divider className={styles.event_insert_divider} />
          {/* 이벤트 기간 */}
          <Row align={'middle'}>
            <Col span={4}>
              <span>이벤트기간</span>
            </Col>
            <Col span={20}>
              <Controller
                control={control}
                name={'dateRange'}
                rules={{ required: '기간은 필수입니다.' }}
                defaultValue={[null, null]}
                render={({ field }) => (
                  <RangePicker
                    style={{ width: '330px' }}
                    {...field}
                    onChange={date => {
                      date && field.onChange(date);
                    }}
                    disabledDate={disabledDate}
                    showTime={{
                      hideDisabledOptions: true,
                      defaultValue: [dayjs('09:00:00', timeFormat), dayjs('18:00:00', timeFormat)],
                      minuteStep: 10,
                    }}
                    format={`${dateFormat} ${timeFormat}`}
                  />
                )}
              />
              {errors.dateRange?.message && <FormAlert msg={errors.dateRange.message as string} />}
            </Col>
          </Row>
          <Divider className={styles.event_insert_divider} />
          {/* 이벤트 소개 */}
          <Row align={'middle'}>
            <Col span={4}>
              <span>이벤트 내용</span>
            </Col>
            <Col span={10}>
              <Controller
                control={control}
                name={'introduction'}
                rules={{ required: '이벤트 내용를 입력해주세요.' }}
                render={({ field }) => (
                  <Input.TextArea
                    {...field}
                    showCount
                    maxLength={200}
                    rows={3}
                    placeholder={`이벤트 내용를 입력해주세요.`}
                    style={{ resize: 'none' }}
                  />
                )}
              />
              {errors.introduction?.message && <FormAlert msg={errors.introduction.message} />}
            </Col>
          </Row>
          <Divider className={styles.event_insert_divider} />
          {/* 당첨내용 */}
          <Row align={'middle'}>
            <Col span={4}>
              <span>당첨 내용</span>
            </Col>
            <Col span={10}>
              <Controller
                control={control}
                name={'prize'}
                rules={{ required: '당첨 내용은 필수입니다.' }}
                render={({ field }) => <Input {...field} placeholder={'당첨 내용을 입력해주세요.'}
                                              style={{ marginRight: '8px' }} />}
              />
              {errors.prize?.message && <FormAlert msg={errors.prize.message} />}
            </Col>
          </Row>
          <Divider className={styles.event_insert_divider} />
          {/* 당첨자수 */}
          <Row align={'middle'}>
            <Col span={4}>
              <span>당첨자 수</span>
            </Col>
            <Col span={5}>
              <Controller
                control={control}
                name={'winner'}
                rules={{ required: '당첨자 수는 필수입니다.' }}
                render={({ field }) => (
                  <Input
                    {...field}
                    placeholder={'당첨자 수를 입력해주세요.'}
                    onFocus={e => {
                      const value = e.target.value.replace(/\D/g, '');
                      field.onChange(value);
                    }}
                    onBlur={e => {
                      const value = e.target.value.replace(/\D/g, '');
                      if (value) field.onChange(value);
                    }}
                  />
                )}
              />
              {errors.winner?.message && <FormAlert msg={errors.winner.message} />}
            </Col>
          </Row>
          <Divider className={styles.event_insert_divider} />
          {/* 이벤트 종류 */}
          <Row align={'middle'}>
            <Col span={4}>
              <span>이벤트 종류</span>
            </Col>
            <Col span={5}>
              <Controller
                control={control}
                name={'event_type'}
                rules={{ required: '이벤트 종류는 필수입니다.' }}
                render={({ field }) => (
                  <Select
                    {...field}
                    showSearch
                    style={{ width: 300 }}
                    placeholder={'이벤트 종류 선택'}
                    onChange={data => {
                      data && field.onChange(data);
                    }}
                    filterOption={(input, option) => (option?.label ?? '').includes(input)}
                    options={typeOptions}
                    value={eventType}
                  />
                )}
              />
              {errors.event_type?.message && <FormAlert msg={errors.event_type.message} />}
            </Col>
          </Row>
          <Divider className={styles.event_insert_divider} />
          {/* PRUUF 발행여부 */}
          <Row align={'middle'}>
            <Col span={4}>
              <span>PRUUF 발행여부</span>
            </Col>
            <Col span={20}>
              <Space>
                <Radio value='true' checked={pruuf === true} onChange={handleRadioChange}>
                  예
                </Radio>
                <Radio value='false' checked={pruuf === false} onChange={handleRadioChange}>
                  아니오
                </Radio>
              </Space>
            </Col>
          </Row>
          {pruuf && (
            <>
              <Divider className={styles.event_insert_divider} />
              <Row align={'middle'}>
                <Col span={4}>
                  <span>발행조건</span>
                </Col>
                <Col span={5}>
                  <Controller
                    control={control}
                    name={'pruuf_type'}
                    rules={{ required: '발행 조건 선택은 필수입니다.' }}
                    render={({ field }) => (
                      <Select
                        showSearch
                        style={{ width: 227 }}
                        placeholder={'발행조건을 선택해주세요'}
                        onChange={data => {
                          setPruufSelectedOption(data);
                          field.onChange(data ?? '');
                          // pruuf_type 값을 설정
                          setValue('pruuf_type', data ?? '');
                        }}
                        filterOption={(input, option) => (option?.label ?? '').includes(input)}
                        options={pruufOptions}
                        value={pruufType}
                      />
                    )}
                  />
                  {errors.pruuf_type?.message && <FormAlert msg={errors.pruuf_type.message} />}
                </Col>
              </Row>
            </>
          )}
          {pruuf && (
            <>
              <Divider className={styles.event_insert_divider} />
              <Row align={'middle'}>
                <Col span={4}>
                  <span>PRUUF 발행개수</span>
                </Col>
                <Col span={5}>
                  <Controller
                    control={control}
                    name={'pruuf_count'}
                    rules={{ required: '발행개수 입력은 필수입니다.' }}
                    render={({ field }) => (
                      <Input
                        {...field}
                        placeholder={'발행개수를 입력해주세요.'}
                        onBlur={e => {
                          let value = e.target.value.replace(/\D/g, '');
                          /* 발행개수 입력이 1억이 넘으면 최대 1억까지만 출력되게 (임시) */
                          if (value) {
                            value = Math.min(parseInt(value, 10), 100000000).toString();
                            field.onChange(value);
                          }
                        }}
                      />
                    )}
                  />
                  {errors.pruuf_count?.message && <FormAlert msg={errors.pruuf_count.message} />}
                </Col>
              </Row>
            </>
          )}
          {/* 지정 숫자 */}
          {pruufSelectedOption === '지정' && (
            <>
              <Divider className={styles.event_insert_divider} />
              <Row align={'middle'}>
                <Col span={4}>
                  <span>지정 숫자</span>
                </Col>
                <Col>
                  {fields.map((item, index) => {
                    const style = index > 0 ? { marginTop: '15px' } : {};
                    return (
                      <Row gutter={5} key={item.id} style={{ ...style }}>
                        <Col>
                          <Controller
                            name={`designation_counts.${index}.d_count`}
                            control={control}
                            defaultValue={item.d_count}
                            rules={{
                              required: '지정 숫자를 입력해주세요.',
                            }}
                            render={({ field }) => (
                              <Input
                                {...field}
                                style={{ width: '226.5px' }}
                                placeholder={
                                  pruuf_count === undefined || pruuf_count === null || pruuf_count === ''
                                    ? '발행개수를 먼저 입력해주세요.'
                                    : '지정 숫자를 입력해주세요.'
                                }
                                onChange={e => {
                                  const enteredValue: number = parseInt(e.target.value, 10);
                                  console.log('enteredValue ===>', enteredValue);
                                  if (pruuf_count) {
                                    setValue(`designation_counts.${index}.d_count`, enteredValue.toString());
                                    field.onChange(e);
                                  }
                                }}
                              />
                            )}
                          />
                        </Col>
                        <Col span={1.5}>
                          <Button onClick={() => append({ d_count: '' })}>추가</Button>
                        </Col>
                        {index > 0 && (
                          <Col span={2} onClick={() => remove(index)}>
                            <Button>삭제</Button>
                          </Col>
                        )}
                        <Col span={20}>
                          {index === fields.length - 1 && (
                            <div style={{ marginTop: '7px' }}>
                              {hasDuplicatesError &&
                                <span style={{ color: 'red', marginLeft: '2px' }}>중복된 숫자가 존재합니다.</span>}
                              {anyGreaterThanPruufCountError && (
                                <span style={{ color: 'red', marginLeft: '5px' }}>발행개수보다 작은 수를 입력해주세요.</span>
                              )}
                            </div>
                          )}
                        </Col>
                      </Row>
                    );
                  })}
                </Col>
              </Row>
            </>
          )}

          <Divider style={{ borderWidth: '2px', borderColor: '#555', margin: '20px 0px 24px 0px' }} />
          <Row justify={'end'} gutter={20}>
            <Col>
              <Button type={'primary'} htmlType={'submit'} size={'large'} className={styles.submitBtn}>
                {modifiedMode ? '수정하기' : '등록하기'}
              </Button>
            </Col>
            <Col>
              <Button size={'large'} onClick={onCancle}>
                취소하기
              </Button>
            </Col>
          </Row>
        </form>
      </>
    </section>
  );
};

export default EventInsert;
