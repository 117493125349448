import React from 'react';

import styles from './Badge.module.css';

import Badge_1 from '../../../assets/image/badge_type_1.png';
import Badge_3 from '../../../assets/image/badge_type_3.png';
import Badge_4 from '../../../assets/image/badge_type_4.png';
import Badge_5 from '../../../assets/image/badge_type_5.png';
import Badge_6 from '../../../assets/image/badge_type_6.png';

type PruufType = 'event' | 'product' | 'company' | 'conference' | 'solution'
type PruufTypeString = '이벤트' | '솔루션 체험' | '참가사 방문' | '행사 참여'
type Props = {
  koName: string
  engName: string
  type: PruufType
  info: string
  url?: string
}

export const pruufTypeToString: Record<PruufType, PruufTypeString> = {
  event: '이벤트',
  product: '솔루션 체험',
  solution: '솔루션 체험',
  company: '참가사 방문',
  conference: '행사 참여',
};

const PruufBadge = ({ koName, engName, type, info, url }: Props) => {

  // if (url) {
  //   return (
  //     <img src={url} alt={'Badge 이미지'} className={styles.img} />
  //   );
  // }

  let BadgeImageSrc;

  //TODO: type에 따른 정확한 배경이미지 협의 필요.
  switch (type) {
    case 'company':
      BadgeImageSrc = Badge_3;
      break;
    case 'conference':
      BadgeImageSrc = Badge_1;
      break;
    case 'event':
      BadgeImageSrc = Badge_4;
      break;
    case 'product':
      BadgeImageSrc = Badge_4;
      break;
    case 'solution':
      BadgeImageSrc = Badge_5;
      break;
    default:
      BadgeImageSrc = Badge_6;
      break;
  }

  if (url) {
    BadgeImageSrc = url;
  }

  const truncateText = (text: string, maxLength: number): string => {
    return text ?
      text.length > maxLength ? `${text.substring(0, maxLength)}...` : text :
      '';
  };

  const korText = truncateText(koName, 50);
  const engText = truncateText(engName, 50);
  const infoText = truncateText(info, 50);

  return (
    <div className={styles.wrap}>
      <div className={styles.imgWrap}>
        <img src={BadgeImageSrc} alt={'pruuf 이미지'} />
      </div>
      <div className={styles.textWrap}>
        <div>
          <ul>
            <li>{korText}</li>
            <li className={styles.bold}>{engText}</li>
          </ul>
        </div>

        <div>
          <ul>
            <li className={styles.bold}>{pruufTypeToString[type]}</li>
            <li>{infoText}</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default PruufBadge;