import React from 'react';
import styled from 'styled-components';

export const FlexRow: React.FC<Props> = ({ children, ...rest }) => {
  return <Component {...rest}>{children}</Component>;
};

interface Props {
  justify?: 'flex-start' | 'center' | 'flex-end' | 'space-between' | 'space-evenly' | 'space-around';
  align?: 'flex-start' | 'center' | 'flex-end';
  width?: string;
  style?: React.CSSProperties;
  children: React.ReactNode;
  className?: string;
  gap?: string;
}

const Component = styled('div')<{ justify?: string; align?: string; width?: string; gap?: string }>`
  display: flex;
  flex-direction: row;
  align-items: ${props => (props.align ? props.align : 'center')};
  justify-content: ${props => (props.justify ? props.justify : 'center')};
  width: ${props => props.width};
  gap: ${props => props.gap};
`;
