import React from 'react';
import { Col, Row } from 'antd';

import styles from './pruufDetail.module.css';
import { axiosInstance } from '../../../common/providers & services/AxiosService';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { CloseOutlined } from '@ant-design/icons';
import PruufBadge from '../../../common/components/pruuf/Badge';

type Props = {
  pruufId: string | number;
};
const PruufDetail = ({ pruufId }: Props) => {
  //정보조회
  const fetchData = async () => {
    try {
      const response = await axiosInstance.get(`pruuf/${pruufId}`);

      return response.data.data.pruufInfo;
    } catch (e) {
      console.error('error...');
      return [{}];
    }
  };

  const { data, isLoading, isError } = useQuery(['pruufDetail', pruufId], fetchData, {
    staleTime: 5000,
    refetchOnWindowFocus: false,
    select: data => {
      const hostText = JSON.parse(data.conference.hosts).join(', ');
      const organizerText = JSON.parse(data.conference.organizers).join(', ');
      return { ...data, conference: { ...data.conference, hostText, organizerText } };
    },
  });

  if (isLoading || isError) {
    return <></>;
  }

  let pruufTypeFromString = '';
  let pruufInfo = '';
  switch (data.type) {
    case 'event':
      pruufTypeFromString = '이벤트에 당첨되어';
      pruufInfo = data.event.name;
      break;
    case 'product':
      pruufTypeFromString = '제품을 체험하여';
      pruufInfo = data.solution.name;
      break;
    case 'company':
      pruufTypeFromString = '참가사 방문하여';
      pruufInfo = data.company_setup.name;
      break;
    case 'conference':
      pruufTypeFromString = '행사에 참가하여';
      pruufInfo = data.conference.description;
      break;
    case 'solution':
      pruufTypeFromString = '제품을 체험하여';
      pruufInfo = data.solution.name;
      break;
  }

  // PRUUF 뱃지
  const imgSrc = <PruufBadge koName={data.conference.name_kor} engName={data.conference.name_eng} type={data.type}
                             info={pruufInfo} />;

  return (
    <section className={styles.wrap}>
      <CloseOutlined
        onClick={() => {
          // KeyboardEvent 객체를 생성
          const event = new KeyboardEvent('keydown', {
            key: 'Escape', // 'esc' 키 시뮬레이션
            code: 'Escape',
            keyCode: 27,
            which: 27,
            charCode: 0,
            bubbles: true,
            cancelable: true,
            composed: true,
          });

          // document 객체에 이벤트 연결
          document.dispatchEvent(event);
        }}
      />
      <div className={styles.head}>
        <h1>PRUUF 상세</h1>
      </div>

      {imgSrc && (
        <div className={styles.imgWrap}>
          {imgSrc}
          {/*<img src={imgSrc} alt={'PRUUF 이미지'} />*/}
          {/*<p>행사에 참여해서 수집할 수 있는 PRUUF에요.</p>*/}
        </div>
      )}

      <div className={styles.contentWrap}>
        <div className={styles.contentTitle}>
          <h2>{data.conference.name_kor}</h2>
          <p>
            {data.conference.name_kor} ({data.conference.name_eng})에서 {pruufTypeFromString} 수집된
                                       PRUUF입니다.
          </p>
        </div>

        <div className={styles.content}>
          <h2>행사 설명</h2>

          <div className={styles.table}>
            <Row>
              <Col span={9}>행사명(국문)</Col>
              <Col span={15}>{data.conference.name_kor}</Col>
            </Row>

            <Row>
              <Col span={9}>행사명(영문)</Col>
              <Col span={15}>{data.conference.name_eng}</Col>
            </Row>

            <Row>
              <Col span={9}>행사일시</Col>
              <Col span={15}>
                {dayjs(data.start_time).format('YYYY.MM.DD HH:mm')} - {dayjs(data.end_time).format('YYYY.MM.DD HH:mm')}
              </Col>
            </Row>

            <Row>
              <Col span={9}>장소</Col>
              <Col span={15}>
                {data.conference.location}, {data.conference.place}
              </Col>
            </Row>

            <Row>
              <Col span={9}>주최</Col>
              <Col span={15}>{data.conference.hostText}</Col>
            </Row>

            <Row>
              <Col span={9}>주관</Col>
              <Col span={15}>{data.conference.organizerText}</Col>
            </Row>

            <Row>
              <Col span={9}>홈페이지</Col>
              <Col span={15}>
                <a href={data.conference.homepage} target={'_blank'} rel='noreferrer'>
                  {data.conference.homepage}
                </a>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PruufDetail;
