import { Button, notification } from 'antd';
import styles from './signupAdmin.module.css';
import { useForm } from 'react-hook-form';
import TermsPolicy from '../components/TermsPolicy';
import { useState } from 'react';
import { axiosInstance, getApiUrl } from '../../../common/providers & services/AxiosService';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { signOut } from '../../../store/app/appSlice';
import { useAppDispatch } from '../../../store/store';

type FormValues = {
  [key: string]: string;
};

const prefixUrl = process.env.NODE_ENV === 'production' ? window.location.origin : 'http://localhost:3000';

const terms = [
  // 이용약관 주소 수정
  { id: 1, name: '이용약관 동의', url: `${prefixUrl}/privacy` },
  { id: 2, name: '개인정보 수집 및 이용 동의', url: `${prefixUrl}/privacy` },
];

const SignUpAdmin = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const [api, contextHolder] = notification.useNotification();
  const [checklist, setChecklist] = useState<number[]>([]);

  const [companyNumber, setCompanyNumber] = useState('');
  const [hasCompanyNumber, setHasCompanyNumber] = useState({
    hasNumber: true,
    isValid: false,
  });
  const [companyNumberCheck, setCompanyNumberCheck] = useState(false);

  const navigate = useNavigate();

  const fetchData = async () => {
    try {
      const response = await axiosInstance.get(`host/${id}`);
      return response.data.data.userInfo;
    } catch (e) {
      console.log('e', e);
      return {};
    }
  };

  const { data: userInfo, isLoading } = useQuery(['userInfoDetail', id], fetchData, {
    staleTime: 5000,
  });

  const {
    register,
    watch,
    handleSubmit,
    setFocus,
    setValue,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
  });

  // 관리자 회원가입
  const signup = async (data: FormValues) => {
    if (checkPassword()) {
      if (terms.length === checklist.length) {

        const submitData = { ...data, user_id: id, companyNumber: companyNumber.replace(/[^\w\s]+/g, '') };

        try {
          await axiosInstance.post('host', submitData);
          api.success({
            message: '프루프 시스템 회원 정보수정이 완료되었습니다.',
            placement: 'top',
            duration: 3,
            onClose: () => {
              location.href = '/home';
            },
          });
        } catch (e) {
          api.error({ message: '문제가 발생했습니다. 지속되면 문의해주세요', placement: 'top' });
        }
      } else {
        api.info({ message: '이용약관 확인 후 동의해주세요.', placement: 'top' });
      }
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCompanyNumberCheck(false);
    const rawValue = e.target.value.replace(/[^0-9]/g, '');
    const formattedValue = formatCompanyNumber(rawValue);
    setCompanyNumber(formattedValue);
  };

  const formatCompanyNumber = (rawValue: string): string => {
    const digits = rawValue.slice(0, 10);
    const formatted = digits.replace(/(\d{3})(\d{2})(\d{5})/, '$1-$2-$3');
    setValue('companyId', '');
    return formatted;
  };
  // input Enter..
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // Enter 키 기본 동작 방지
      // 같은 형제 노드에 있는 버튼 찾기
      const siblingButtons = Array.from(event.currentTarget.parentElement!.children).filter(
        element => element.tagName === 'BUTTON',
      ) as HTMLButtonElement[];

      // 첫 번째 버튼 클릭
      if (siblingButtons.length > 0) {
        siblingButtons[0].click();
      }
    }
  };

  const searchCompanyNumber = async () => {
    if (hasCompanyNumber.hasNumber) {
      const validate = /^\d{3}-\d{2}-\d{5}$/.test(companyNumber);

      if (validate === false) {
        api.warning({ message: '정확한 사업자 등록번호를 입력해주세요.', placement: 'top' });
        return false;
      }
      setHasCompanyNumber(prev => ({ ...prev, isValid: validate }));
      //백엔드 check-company-auth 에서 이미 존재하는 사업자번호인지 확인하여 등록된 회사명 가져오기
      try {
        const baseUrl = await getApiUrl();
        const res = await axios.get(`${baseUrl}check-company-auth?companyNumber=${companyNumber.replace(/[^\w\s]+/g, '')}`);

        const {
          data: { msg, companyName, companyId },
        } = res;

        if (companyName && companyId) {
          setValue('companyName', companyName);
          setValue('companyId', companyId);
          setCompanyNumberCheck(true);
          api.success({
            message: (
              <p>
                조회된 회사명은 <br />
                {companyName} 입니다.
              </p>
            ),
            placement: 'top',
            duration: 1,
          });
        } else {
          api.info({
            message: (
              <p>
                등록된 회사가 없습니다. <br />
                회사명을 입력해주세요.
              </p>
            ),
            placement: 'top',
            duration: 1,
            onClose: () => {
              setFocus('companyName');
            },
          });
        }
      } catch (e) {
        console.error('사사업자번호 조회 에러', e);
      }
    }
  };

  const hasValidPassword = (value: string) => {
    const hasMinimumLength = value.length >= 8;
    const hasEnglishLetter = /[a-zA-Z]/.test(value);
    const hasNumber = /\d/.test(value);
    if (!(hasMinimumLength && hasEnglishLetter && hasNumber)) {
      return '비밀번호는 영문+숫자 8자리 이상으로 해주세요';
    }
    return true;
  };

  const checkPassword = () => {
    if (watch('password') !== '' && watch('passwordConfirm') !== '') {
      if (watch('password') === watch('passwordConfirm')) return true;
      if (watch('password') !== watch('passwordConfirm')) return '입력한 비밀번호와 일치하지 않습니다. 다시 확인해주세요.';
    }
  };

  if (isLoading) {
    return <></>;
  }

  if (!userInfo.id || (userInfo.type !== 'host' && userInfo.type !== 'company')) {
    navigate('/home');
  }
  return (
    <section className={styles.container}>
      {contextHolder}
      <div>
        <h2>프루프 시스템 {userInfo.type === 'host' ? '주최사' : '참가사'} 정보등록</h2>
        <h4>기본 정보</h4>
        <div className={styles.info}>
          <p>아이디: {userInfo.email}</p>
        </div>
        <form className={styles.form} onSubmit={handleSubmit(signup)}>
          <div className={styles.row}>
            <label className={styles.largeRow}>사업자등록번호</label>
            <div className={styles.col}>
              <div className={styles.inRow}>
                <input
                  type='text'
                  placeholder='사업자 등록번호를 입력 후 조회하기 버튼을 클릭해주세요. (000-00-00000)'
                  disabled={!hasCompanyNumber.hasNumber}
                  value={companyNumber}
                  maxLength={12}
                  onChange={handleInputChange}
                  onKeyDown={handleKeyDown}
                />

                <input
                  disabled={!hasCompanyNumber.hasNumber}
                  type={'hidden'}
                  value={''}
                  placeholder='사업자 번호를 조회해 주세요.'
                  {...register('companyId')}
                />
                <Button
                  htmlType='button'
                  style={{ marginLeft: '8px' }}
                  disabled={!hasCompanyNumber.hasNumber}
                  onClick={() => searchCompanyNumber()}
                  tabIndex={-1}
                >
                  조회하기
                </Button>
              </div>
              {/* <div className={styles.col}>
                <Checkbox
                  style={{ marginTop: '4px' }}
                  onChange={e => {
                    setHasCompanyNumber({ ...hasCompanyNumber, hasNumber: !e.target.checked });
                    if (e.target.checked) {
                      setCompanyNumber('');
                      setValue('companyId', '');
                      setCompanyNumberCheck(false);
                    }
                  }}
                >
                  사업자등록번호 없음
                </Checkbox>
              </div> */}
            </div>
          </div>
          <div className={styles.row}>
            <label>회사명</label>
            <div className={styles.col}>
              <input
                type='text'
                disabled={companyNumberCheck}
                placeholder='회사 이름을 입력해주세요.'
                {...register('companyName', { required: '회사명을 입력해주세요' })}
              />
              {!companyNumberCheck && errors.companyName && errors.companyName.message && (
                <p className={styles.errorMessage}>{errors.companyName?.message as string}</p>
              )}
            </div>
          </div>
          <div className={styles.row}>
            <label>비밀번호</label>
            <div className={styles.col}>
              <input
                type='password'
                placeholder='영문과 숫자를 조합하여 8자리 이상 입력해주세요.'
                {...register('password', {
                  required: '비밀번호를 입력해주세요.',
                  validate: hasValidPassword,
                })}
              />
              {errors.password && <p className={styles.errorMessage}>{errors.password?.message as string}</p>}
            </div>
          </div>
          <div className={styles.row}>
            <label>비밀번호 확인</label>
            <div className={styles.col}>
              <input
                type='password'
                placeholder='비밀번호를 한번 더 입력해주세요.'
                {...register('passwordConfirm', {
                  required: '비밀번호를 한번 더 입력해주세요.',
                  validate: checkPassword,
                })}
              />
              {errors.passwordConfirm &&
                <p className={styles.errorMessage}>{errors.passwordConfirm?.message as string}</p>}
            </div>
          </div>
          <TermsPolicy terms={terms} checklist={checklist} setChecklist={setChecklist} />
          <div className={styles.buttonWrapper}>
            <Button danger onClick={() => {
              dispatch(signOut());
              localStorage.clear();
              navigate(userInfo.type === 'admin' ? '/admin' : '/');
            }}>로그아웃</Button>
            <Button htmlType='submit'>회원가입</Button>
          </div>
        </form>
      </div>
    </section>
  );
};

export default SignUpAdmin;
