import React, { useState } from 'react';
import styled from 'styled-components';
import { Table } from '../../../common/components/Table';
import { TableColumn } from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';
import { appRoutes } from '../../../AppRoutes';
import { Conference, User } from '../../../common/api/responseTypes';
import { FilterBoxContainer, FilterLabel, SelectWrapper, SubTitle } from '../../../common/components/StyledComponents';
import { SearchFilter } from '../../../common/components/filters/SearchFilter';
import { FlexRow } from '../../../common/components/FlexRow';

import styles from './applicationShow.module.css';
import { axiosInstance } from '../../../common/providers & services/AxiosService';
import { useQuery } from '@tanstack/react-query';

const initialState = {
  searchTerm: '',
  start: '',
  end: '',
  status: 'all',
  searchType: 'all',
};

type DataObject = {
  id: string;
  state: string;
  user: User;
  conference: Conference;
};
type State = 'pending' | 'approved' | 'rejected';
const RegistrationState = ({ state }: { state: State }) => {
  let stateString = '';
  switch (state) {
    case 'pending':
      stateString = '대기중';
      break;
    case 'approved':
      stateString = '승인완료';
      break;
    case 'rejected':
      stateString = '승인거절';
      break;

    default:
      break;
  }

  return <span className={styles[state]}>{stateString}</span>;
};

const ApplicationHistory = () => {
  const navigate = useNavigate();
  const [filterParams, setFilterParams] = useState(initialState);

  const fetchData = async () => {
    try {
      const response = await axiosInstance.get(`conferenceRegistration`);
      return response.data.data.conferenceRegistration;
    } catch (e) {
      console.error('error...');
      return {};
    }
  };

  const { data: registration, isLoading } = useQuery(['confercneRegistration'], fetchData, {
    select: data => {
      const { searchTerm, status, searchType } = filterParams;
      const filteredData = data.filter((item: any) => {
        // 상태가 'all'이 아니고 일치하지 않는 경우
        if (status !== 'all' && item.state !== status) {
          return false;
        }

        return !(searchTerm && (!item.company_setup?.name.includes(searchTerm) && !item.conference?.name_kor.includes(searchTerm)));


      });

      // return filteredData;
      return filteredData.map((item: any, index: number) => {
        return {
          ...item,
          listIdx: filteredData.length - index,
        };
      });
    },
  });

  const handleRowClick = (row: Conference) => {
    navigate(appRoutes.conferenceInfo.children.detail.path(row.id));
  };

  if (isLoading) {
    return <></>;
  }

  return (
    <section className={styles.wrap}>
      <SubTitle>신청 조회</SubTitle>
      <Container gap={10}>
        <FilterBoxContainer>
          <FlexRow gap={'5px'} justify={'space-between'} width={'100%'}>
            <FlexRow gap={'5px'} justify={'flex-start'}>
              <FilterLabel>상태</FilterLabel>
              <SelectWrapper>
                <select value={filterParams.status}
                        onChange={e => setFilterParams(prev => ({ ...prev, status: e.target.value }))}>
                  <option value={'all'}>전체</option>
                  <option value={'pending'}>대기중</option>
                  <option value={'approved'}>승인완료</option>
                  <option value={'rejected'}>승인거절</option>
                </select>
              </SelectWrapper>
            </FlexRow>
          </FlexRow>

          <FlexRow gap={'5px'} justify={'space-between'} width={'100%'}>
            <FlexRow gap={'5px'} justify={'flex-start'}>
              <FilterLabel>검색</FilterLabel>
              <SelectWrapper>
                <select value={filterParams.searchType}
                        onChange={e => setFilterParams(prev => ({ ...prev, searchType: e.target.value }))}>
                  <option value={'all'}>전체</option>
                  <option value={'host'}>주최사</option>
                  <option value={'company'}>참가사</option>
                </select>
              </SelectWrapper>
              <SearchFilter
                onChange={searchTerm => setFilterParams(prev => ({ ...prev, searchTerm }))}
                placeholder={'검색어를 입력해주세요.'}
                debounce={150}
                label={false}
              />
            </FlexRow>
          </FlexRow>
        </FilterBoxContainer>
      </Container>

      <Table columns={columns} data={registration} filename={`행사정보`} showExcel={false} />
    </section>
  );
};

export default ApplicationHistory;
const Container = styled('div')<{ gap: number }>`
  width: 1200px;
  height: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: ${props => props.gap}px;
`;

const columns: TableColumn<any>[] = [
  {
    name: '번호',
    selector: row => row.listIdx,
    sortable: true,
    grow: 0,
  },
  {
    name: '행사명',
    // selector: row => `[${row.conference.name_eng}] ${row.conference.name_kor}`,
    selector: row => row.conference.name_kor,
    grow: 1,
  },
  {
    name: '참가 신청',
    // selector: row => `[${row.user.company_setup?.name}] ${row.user.name}`,
    selector: row => row.company_setup?.name,
    sortable: true,
    grow: 1,
  },
  {
    name: '참가신청 일시',
    selector: row => row.created_at,
    sortable: true,
    grow: 1,
  },
  {
    name: '상태',
    cell: row => {
      return <RegistrationState state={row.state} />;
    },
    sortable: true,
    grow: 0,
  },
];
