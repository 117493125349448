import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { Input, Button, notification } from 'antd';
import { MailOutlined } from '@ant-design/icons';
import _ from 'lodash';
import axios from 'axios';

import FormAlert from '../../../common/components/FormAlert';

import styles from './passwordRest.module.css';
import { axiosInstance } from '../../../common/providers & services/AxiosService';

type Inputs = {
  email: string;
};

const PasswordReset = () => {
  const [api, contextHolder] = notification.useNotification();

  const defaultValues = {
    email: '',
  };
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<Inputs>({ defaultValues });

  const submitAction: SubmitHandler<Inputs> = async data => {
    // const networkStatusResult = _.random(1);

    //Axios로 백엔드 통신
    let networkStatusResult = _.random(1);
    try {
      await axiosInstance.post('resetPassword', { userMail: data.email });
      networkStatusResult = 1;
    } catch (error) {
      console.log('Axios error', error);
      networkStatusResult = 0;
    }

    // 메일발송완료 or 메일주소 오류 문구 처리
    if (networkStatusResult > 0) {
      api.destroy('errorMsg');
      api.success({
        message: '메일 전송완료',
        description: (
          <>
            <p>입력하신 이메일로 비밀번호를 변경할 수 있는 링크가 전송되었어요.</p>
            <p>1시간안에 비밀번호 변경 후 로그인해주세요.</p>
          </>
        ),
        duration: 20,
        placement: 'top',
        style: {
          minWidth: 500,
        },
        key: 'successMsg',
        onClose: () => {
          reset({
            email: '',
          });
        },
      });
    } else {
      api.destroy('successMsg');
      api.error({
        message: '메일 전송오류',
        description: (
          <>
            <p>메일발송 중 문제가 발생하였어요.</p>
            <p>메일주소를 확인해주시고, 지속되면 문의주세요.</p>
          </>
        ),
        duration: 20,
        placement: 'top',
        style: {
          minWidth: 500,
        },
        key: 'errorMsg',
      });
    }
  };

  return (
    <>
      {contextHolder}
      <form className={styles.wrap} onSubmit={handleSubmit(submitAction)}>
        <h1>이메일 주소</h1>
        <p>새로운 비밀번호를 변경할 수 있도록 링크를 이메일로 보내드립니다.</p>
        <Controller
          control={control}
          name={'email'}
          rules={{
            required: '이메일을 입력해주세요.',
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: '이메일 주소를 확인해주세요.',
            },
          }}
          render={({ field }) => (
            <Input {...field} placeholder='가입한 이메일 주소를 입력해주세요.' size={'large'} prefix={<MailOutlined />} />
          )}
        />
        {errors.email && errors.email.message && <FormAlert msg={errors.email.message} />}

        <Button htmlType={'submit'} size={'large'}>
          링크 보내기
        </Button>
      </form>
    </>
  );
};

export default PasswordReset;
