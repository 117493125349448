import { message, Popconfirm } from 'antd';
import { useParams } from 'react-router-dom';

import styles from './hostInfoDetail.module.css';
import HostParticipateInfo from './components/HostParticipateInfo';
import { HTMLTable, SubTitle, Td, Th, Tr } from '../../common/components/StyledComponents';
import { useQuery } from '@tanstack/react-query';
import { axiosInstance } from '../../common/providers & services/AxiosService';
import dayjs from 'dayjs';
import { toBusinessRegistrationNumber } from '../../lib/function';

const fixedNewPassword = 'pruuf1234';

const HostInfoDetail = () => {
  const { id } = useParams();

  const fetchData = async () => {
    try {
      const response = await axiosInstance.get(`host/${id}`);
      return response.data.data.userInfo;
    } catch (e) {
      console.log('e', e);
      return {};
    }
  };

  const { data, isLoading } = useQuery(['hostDetail', id], fetchData, {
    staleTime: 5000,
  });

  const handleResetPassword = async (e?: React.MouseEvent<HTMLElement>) => {
    try {
      await axiosInstance.put(`host/${id}`, { password: fixedNewPassword });
      message.success('비밀번호 변경 성공.');
    } catch (e) {
      message.error('비밀번호 변경에 실패했습니다. 지속되면 문의해주세요.');
    }
  };

  if (isLoading) {
    return <></>;
  }

  return (
    <section className={styles.wrap}>
      <SubTitle marginBottom={-20}>기본정보</SubTitle>
      <HTMLTable>
        <tbody>
        <Tr>
          <Th>주최사명</Th>
          <Td>{data.company_setup?.name}</Td>
        </Tr>

        <Tr>
          <Th>담당자</Th>
          <Td>{data.name}</Td>
        </Tr>

        <Tr>
          <Th>담당자 연락처</Th>
          <Td>{data.tel}</Td>
        </Tr>

        <Tr>
          <Th>이메일(ID)</Th>
          <Td>{data.email}</Td>
        </Tr>

        {
          data.company_setup?.number &&
          (
            <Tr>
              <Th>사업자등록번호</Th>
              <Td>
                {
                  toBusinessRegistrationNumber(data.company_setup?.number)
                }
              </Td>
            </Tr>
          )
        }

        {/* <Tr>
            <Th>주최사 소개</Th>
            <Td>주최사 소개~</Td>
          </Tr> */}

        <Tr>
          <Th>비밀번호 초기화</Th>
          <Td>
            <Popconfirm
              title={'비밀번호 초기화'}
              description={`초기비밀번호는 ${fixedNewPassword}입니다.`}
              onConfirm={handleResetPassword}
              okText='확인'
              cancelText='취소'
            >
              <span className={styles.textUnder}>비밀번호 초기화 하기</span>
            </Popconfirm>
          </Td>
        </Tr>

        <Tr>
          <Th>등록일</Th>
          <Td>{dayjs(data.created_at).format('YYYY-MM-DD')}</Td>
        </Tr>
        </tbody>
      </HTMLTable>

      <SubTitle marginBottom={-20}>참가한 행사</SubTitle>
      {/*TODO: 참가한 행사목록 보여주기 */}
      <HostParticipateInfo hostId={id} companySetupId={data.company_setup?.id} />
    </section>
  );
};

export default HostInfoDetail;
