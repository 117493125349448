import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { uniqId } from '../../lib/function';
import { appRoutes } from '../../AppRoutes';
import { FlexRow } from '../../common/components/FlexRow';

const CompanyTab = () => {
  // 탭 메뉴
  // TODO: 권한별로 표기 메뉴 수정
  const tabs = [
    { id: uniqId(), route: appRoutes.company.route, hash: 'company', title: '참가사 관리' },
    // { id: uniqId(), route: appRoutes.solution.route, hash: 'solution', title: '솔루션 관리' },
  ];

  const navigate = useNavigate();
  const { hash, pathname } = useLocation();

  return (
    <FlexRow width={'100%'} justify={'flex-start'}>
      {/*{tabs.map(tab => (*/}
      {/*  <CategorySelectItem key={tab.id} selected={hash === `#${tab.hash}` || pathname === tab.route}*/}
      {/*                      onClick={() => navigate(tab.route)}>*/}
      {/*    {tab.title}*/}
      {/*  </CategorySelectItem>*/}
      {/*))}*/}
    </FlexRow>
  );
};

export default CompanyTab;
const CategorySelectItem = styled('div')<{ selected: boolean }>`
  flex: 1;
  min-width: 171.4px;
  height: 40px;
  background-color: ${props => (props.selected ? '#30384B' : '#F6F8FC')};
  color: ${props => (props.selected ? 'white' : '#656D86')};
  border: ${props => (props.selected ? 'none' : '1px solid #ABB6D2')};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  font-weight: ${props => (props.selected ? 600 : '')};
  cursor: pointer;

  & + & {
    border-left: 0;
  }
`;
