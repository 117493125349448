/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useState } from 'react';
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  ChartData,
  ChartOptions,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import classNames from 'classnames';
import CountUp from 'react-countup';
import dayjs from 'dayjs';

import type { GraphType } from '../PruufStatus';
import styles from './pruufChart.module.css';
import { Tabs } from 'antd';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, BarElement);

type Props<T extends GraphType> = {
  header?: React.ReactElement;
  apiData: any;
  apiTotalData: any;
  onTypeChange: (state: T) => void;
  graphState?: GraphType;
};

const pruufStates: any = {
  all: {
    text: '전체',
  },
  conference: {
    text: '행사 참여',
  },
  company: {
    text: '참가사 방문',
  },
  solution: {
    text: '솔루션 체험',
  },
  event: {
    text: '이벤트',
  },
};

const PruufChart = <T extends GraphType>({ apiData, apiTotalData, onTypeChange, graphState, header }: Props<T>) => {
  const [showDate, setShowDate] = useState('all');

  const chartApiDataProcess = (apiData: any) => {
    return { dateGroup: groupDataByDate(apiData), timeGroup: groupDataByHour(apiData, showDate) };
  };

  const dataProcess = chartApiDataProcess(apiData);
  const tabItems = [{ key: 'all', label: '전체' }];

  Object.keys(dataProcess.dateGroup).map(item => {
    tabItems.push({ key: item, label: item });
  });

  const labels = Object.keys(dataProcess.timeGroup);

  // 라벨에 맞는 각 시간별 데이터 개수를 배열로 저장
  const hourCounts: number[] = Object.values(dataProcess.timeGroup).map(items => items.length);

  const handleTabChange = (key: string) => {
    setShowDate(key);
  };

  const options: ChartOptions<'bar'> = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
        position: 'top',
      },
      title: {
        display: false,
        text: '수집된 PRUUF Bar Chart',
      },
      tooltip: {
        callbacks: {
          label: function(context) {
            const label = context.dataset.label || '';
            const value = context.parsed.y;
            return label + ' : ' + value + ' 개';
          },
        },
      },
    },
    transitions: {
      show: {
        animations: {
          x: {
            from: 0,
          },
          y: {
            from: 0,
          },
        },
      },
      hide: {
        animations: {
          x: {
            to: 0,
          },
          y: {
            to: 0,
          },
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          callback: (v: any) => {
            return v % 2 === 0 ? `${labels[v]}시` : '';
          },
        },
      },
      y: {
        display: true,
        position: 'right',
        ticks: {
          stepSize: 10,
          // 우측에 표시될 레이블을 설정.
          callback: function(value, index, values) {
            return value + ' 개';
          },
        },
        grid: {
          display: false,
        },
      },
    },
  };
  const data: ChartData<'bar'> = {
    labels,
    datasets: [
      {
        label: 'PRUUF 갯수',
        data: hourCounts,
        backgroundColor: '#6A8EFC',
        barThickness: 30,
      },
    ],
  };

  return (
    <section className={styles.wrap}>
      {header}
      <div className={styles.typeWrap}>
        {Object.keys(pruufStates).map((item: string) => {
          return (
            <div
              className={classNames(styles.box, { [styles.active]: graphState === item })}
              key={item}
              onClick={() => onTypeChange(item as any)}
            >
              {pruufStates[item].text}
              <CountUp
                end={
                  apiTotalData.filter((filterItem: any) => {
                    return item === 'all' ? true : item === filterItem.type;
                  }).length
                }
                duration={3}
                separator=','
              />
            </div>
          );
        })}
      </div>
      <div className={styles.timeWrap}>
        <span>시간별 참가자 PRUUF 발급 현황</span>
        <Tabs items={tabItems} onChange={handleTabChange} />
      </div>

      <div style={{ height: '400px', display: 'flex', justifyContent: 'center' }}>
        <Bar options={options} data={data} />
      </div>
    </section>
  );
};

export default PruufChart;

// 날짜별로 데이터를 정리하는 함수
function groupDataByDate(data: any[]) {
  const result: Record<string, any[]> = {};

  for (const item of data) {
    const createdAt = new Date(item.created_at);
    const dateKey = dayjs(createdAt.toLocaleDateString()).format('MM/DD');
    const hourKey = createdAt.getHours().toString();

    if (!result[dateKey]) {
      //@ts-ignore
      result[dateKey] = {};
    }

    //@ts-ignore
    if (!result[dateKey][hourKey]) {
      //@ts-ignore
      result[dateKey][hourKey] = [];
    }

    //@ts-ignore
    result[dateKey][hourKey].push(item);
  }

  return result;
}

// 시간별로 데이터를 정리하는 함수
function groupDataByHour(data: any[], targetDateString: string) {
  const result: Record<string, any[]> = {};

  for (const item of data) {
    const createdAt = dayjs(item.created_at);

    // 날짜 필터링: targetDate와 createdAt를 비교하여 필터링.
    if (createdAt.format('MM/DD') === targetDateString || targetDateString === 'all') {
      const hourKey = createdAt.hour().toString();

      if (!result[hourKey]) {
        result[hourKey] = [];
      }

      result[hourKey].push(item);
    }
  }

  return result;
}
