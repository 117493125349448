/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { appRoutes } from '../../AppRoutes';
import { Table } from '../../common/components/Table';
import { TableColumn } from 'react-data-table-component';

import { FlexRow } from '../../common/components/FlexRow';
import { FilledButton, FilterBoxContainer, SubTitle, Title } from '../../common/components/StyledComponents';
import { Conference, User } from '../../common/api/responseTypes';
import { SearchFilter } from '../../common/components/filters/SearchFilter';
import { axiosInstance } from '../../common/providers & services/AxiosService';
import { useQuery } from '@tanstack/react-query';

const initialState = {
  searchTerm: '',
  start: '',
  end: '',
  status: 'all',
};

type DataObject = {
  id: string;
  user: User;
  conference: Conference;
};

const HostInfo = () => {
  const navigate = useNavigate();
  const [filterParams, setFilterParams] = useState(initialState);

  const fetchData = async () => {
    try {
      const response = await axiosInstance.get('hosts');
      return response.data.data.hosts.data;
    } catch (e) {
      console.error('error...');
      return [{}];
    }
  };

  const { data: hostUsers, isLoading } = useQuery(['hostsList'], fetchData, {
    select: data => {
      const { searchTerm, status } = filterParams;
      const filteredData = data.filter((item: User) => {
        if (searchTerm && !item.company_setup?.name?.toLowerCase().includes(searchTerm.toLowerCase())) {
          return false;
        }

        return true;
      });

      // return filteredData;
      return filteredData.map((item: any, index: number) => {
        return {
          ...item,
          listIdx: filteredData.length - index,
        };
      });
    },
  });

  const excelData = useMemo(() => {
    if (isLoading || hostUsers === undefined) return [];
    return hostUsers.map((item: User, index: number) => {
      const conference_registrations = item?.company_setup?.conference_registrations;

      const firstRegistration = conference_registrations ? conference_registrations[0] : [];
      return {
        번호: hostUsers.length - index,
        주최사명: item.company_setup?.name || '주최사명 미입력',
        담당자: item.name,
        이메일: item.email,
        참가한행사:
          !conference_registrations || conference_registrations.length < 1
            ? '참여 이력 없음'
            : conference_registrations.length > 1
              ? `${firstRegistration?.conference.name_kor} 외 ${conference_registrations.length - 1}`
              : firstRegistration?.conference.name_kor,
        등록일: item.created_at,
      };
    });
  }, [hostUsers]);

  const handleRowClick = (row: User) => {
    navigate(`${appRoutes.host.route}/${row.id}`);
  };

  if (isLoading) {
    return <></>;
  }

  return (
    <Container gap={30} style={{ padding: '50px 0' }}>
      <Title>주최사 관리</Title>
      <SubTitle>주최사 검색</SubTitle>
      <Container gap={10}>
        <FilterBoxContainer>
          <SearchFilter
            onChange={searchTerm => setFilterParams(prev => ({ ...prev, searchTerm }))}
            placeholder={'주최사명을 입력해주세요.'}
            debounce={150}
          />
        </FilterBoxContainer>

        <Table
          columns={columns}
          excelData={excelData}
          data={hostUsers}
          onRowClick={handleRowClick}
          filename={`주최사 정보`}
          listname={'주최사 리스트'}
        />

        <FlexRow justify={'flex-end'} gap={'1rem'} width={'100%'}>
          <FilledButton color={'dark-blue'} onClick={() => navigate(`${appRoutes.host.route}/form`)}>
            주최사 등록하기
          </FilledButton>
        </FlexRow>
      </Container>
    </Container>
  );
};

export default HostInfo;

const Container = styled('div')<{ gap: number }>`
  width: 1200px;
  height: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: ${props => props.gap}px;
`;

const columns: TableColumn<User & { listIdx: number }>[] = [
  // const columns: TableColumn<any>[] = [
  {
    name: '번호',
    selector: row => row.listIdx,
    sortable: true,
    grow: 0,
  },
  {
    name: '주최사명',
    selector: row => row.company_setup?.name || '',
    grow: 1,
  },
  {
    name: '담당자',
    selector: row => row.name,
    sortable: true,
    grow: 1,
  },
  {
    name: '이메일(ID)',
    selector: row => row.email,
    sortable: true,
    grow: 1,
  },
  {
    name: '참가한 행사',
    selector: row => {
      const conference_registrations = row?.company_setup?.conference_registrations;

      if (!conference_registrations || conference_registrations.length < 1) return '참여 이력 없음';

      const firstRegistration = conference_registrations[0];

      return conference_registrations.length > 1
        ? `${firstRegistration?.conference.name_kor} 외 ${conference_registrations.length - 1}`
        : firstRegistration?.conference.name_kor;
    },
    sortable: true,
    grow: 1,
  },
  {
    name: '등록일',
    selector: row => row.created_at,
    sortable: true,
    grow: 1,
  },
];
