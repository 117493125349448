import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { appRoutes } from '../../AppRoutes';
import { Table } from '../../common/components/Table';
import { TableColumn } from 'react-data-table-component';

import { FlexRow } from '../../common/components/FlexRow';
import { FilledButton, FilterBoxContainer, FilterLabel, SelectWrapper, SubTitle, Title } from '../../common/components/StyledComponents';
import { Conference } from '../../common/api/responseTypes';
import { SearchFilter } from '../../common/components/filters/SearchFilter';
import CompanyTab from '../company-info/CompanyTab';
import { useQuery } from '@tanstack/react-query';
import { axiosInstance } from '../../common/providers & services/AxiosService';

const initialState = {
  searchTerm: '',
  status: 'solution',
};

const SolutionInfo = () => {
  const navigate = useNavigate();
  const [filterParams, setFilterParams] = useState(initialState);

  const fetchData = async () => {
    try {
      const response = await axiosInstance.get('solutions');
      return response.data.data.solutions;
    } catch (e) {
      console.error('error...');
      return [{}];
    }
  };

  const {
    data: solutions,
    isLoading,
    isError,
  } = useQuery(['solutionList'], fetchData, {
    select: data => {
      const { searchTerm, status } = filterParams;
      const filteredData = data.filter((item: any) => {
        let searchTarget = item.name;

        if (status === 'company') {
          searchTarget = item.user.name;
        }
        if (status === 'hash') {
          searchTarget = item.hash;
        }

        if (searchTerm && !searchTarget.includes(searchTerm)) {
          return false;
        }

        return true;
      });
      return filteredData.map((item: any, index: number) => {
        return {
          ...item,
          listIdx: filteredData.length - index,
        };
      });
    },
  });

  const excelData = useMemo(() => {
    // return [];
    if (solutions?.length < 1 || solutions === undefined) {
      return [];
    }
    return solutions.map((item: any, index: number) => {
      return {
        번호: item.listIds,
        솔루션: item.mname,
        참가사: item.user.company_setup?.name,
        담당자: item.person,
        담당자연락처: item.tel,
        담당자이메일: item.email,
        해시태그: item.hash ? JSON.parse(item.hash).join(', ') : '',
        총_PRUUF_발급_갯수: item.pruufs_count || 0,
      };
    });
  }, [solutions]);

  const handleRowClick = (row: Conference) => {
    navigate(`${appRoutes.solution.route}/${row.id}`);
  };

  if (isLoading || isError) return <></>;

  return (
    <Container gap={30} style={{ padding: '50px 0' }}>
      <Title>솔루션 관리</Title>

      <CompanyTab />

      <SubTitle>솔루션 검색</SubTitle>
      <Container gap={10}>
        <FilterBoxContainer>
          <FlexRow gap={'5px'} justify={'space-between'} width={'100%'}>
            <FlexRow gap={'5px'} justify={'flex-start'}>
              <FilterLabel>검색</FilterLabel>
              <SelectWrapper>
                <select value={filterParams.status} onChange={e => setFilterParams(prev => ({ ...prev, status: e.target.value }))}>
                  <option value={'solution'}>솔루션명</option>
                  <option value={'company'}>참가사명</option>
                  <option value={'hash'}>해시태그</option>
                </select>
              </SelectWrapper>
              <SearchFilter
                onChange={searchTerm => setFilterParams(prev => ({ ...prev, searchTerm }))}
                placeholder={'검색어를 입력하세요'}
                debounce={150}
                label={false}
              />
            </FlexRow>
          </FlexRow>
        </FilterBoxContainer>

        <Table
          columns={columns}
          data={solutions}
          excelData={excelData}
          onRowClick={handleRowClick}
          filename={`솔루션 정보`}
          listname={'솔루션 리스트'}
        />
      </Container>
    </Container>
  );
};

export default SolutionInfo;

const Container = styled('div')<{ gap: number }>`
  width: 1200px;
  height: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: ${props => props.gap}px;
`;

// const columns: TableColumn<Conference>[] = [
const columns: TableColumn<any>[] = [
  {
    name: '번호',
    selector: row => row.listIdx,
    sortable: true,
    grow: 0,
  },
  {
    name: '솔루션',
    selector: row => row.name,
    grow: 1,
  },
  {
    name: '참가사',
    selector: row => row.user.company_setup?.name,
    sortable: true,
    grow: 1,
  },
  {
    name: '담당자',
    selector: row => row.person,
    sortable: true,
    grow: 1,
  },
  {
    name: '총 PRUUF 발급 갯수',
    selector: row => row.pruufs_count || 0,
    sortable: true,
    grow: 1,
  },
];
