import { Conference, EventStatus } from '../api/responseTypes';

export const filterDateRange = (from: string, to: string, dates: { date: string }[]) => {
  if (!from || !to) return true;
  if (!from) return dates.at(-1)!.date <= to;
  if (!to) return dates.at(0)!.date >= from;
  return dates.at(0)!.date >= from && dates.at(-1)!.date <= to;
};

export const dataFilter = (key: string, value: any, data: Record<string, unknown>) => {
  if (value === 'all') return true;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return data[key] === value;
};

export const searchFilter = (keys: string[], term: string, data: Record<string, string | any>) => {
  for (const key of keys) {
    if (data[key].toLowerCase().indexOf(term) >= 0) return true;
  }
  return false;
};

export const filterConferenceState = (conference: Conference, state?: EventStatus) => {
  if (!state || state == 'all') return true;
  return conference.state === state;
};
