import { Button, message, notification } from 'antd';
import styles from './signup.module.css';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import TermsPolicy from './components/TermsPolicy';
import SignupNav from './components/SignupNav';
import axios from 'axios';
import { getApiUrl } from '../../common/providers & services/AxiosService';
import { handleTelInput } from '../../lib/function';
import { useNavigate, useSearchParams } from 'react-router-dom';

type FormValues = {
  [key: string]: string;
};

const prefixUrl = process.env.NODE_ENV === 'production' ? window.location.origin : 'http://localhost:3000';

const terms = [
  // 이용약관 주소 수정
  { id: 1, name: '이용약관 동의', url: `${prefixUrl}/privacy` },
  /*   { id: 2, name: '개인정보 수집 및 이용 동의', url: `${prefixUrl}/privacy` }, */
  { id: 2, name: '개인정보 수집 및 이용 동의', url: `https://app.catchsecu.com/document/P/e128e9632695dc6` },
];

const Signup = () => {
  const [searchParams] = useSearchParams();
  const userType = searchParams.get('userType');

  const [api, notificationContextHolder] = notification.useNotification();

  const [messageApi, contextHolder] = message.useMessage();

  const [type, setType] = useState(userType === 'host' ? '주최사' : '참가사');
  const [isIdentify, setIsIdentify] = useState(false);
  const [checkIdentify, setCheckIdentify] = useState(false);
  const [mailAuth, setMailAuth] = useState(false);
  const [companyNumber, setCompanyNumber] = useState('');
  const [hasCompanyNumber, setHasCompanyNumber] = useState({
    hasNumber: true,
    isValid: false,
  });
  const [checklist, setChecklist] = useState<number[]>([]);
  const [companyNumberCheck, setCompanyNumberCheck] = useState(false);

  const navigate = useNavigate();
  const {
    register,
    watch,
    handleSubmit,
    control,
    setError,
    clearErrors,
    setValue,
    setFocus,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onChange',
  });

  // input Enter..
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // Enter 키 기본 동작 방지
      // 같은 형제 노드에 있는 버튼 찾기
      const siblingButtons = Array.from(event.currentTarget.parentElement!.children).filter(
        element => element.tagName === 'BUTTON',
      ) as HTMLButtonElement[];

      // 첫 번째 버튼 클릭
      if (siblingButtons.length > 0) {
        siblingButtons[0].click();
      }
    }
  };
  const handleTelChange = (tel: string) => {
    const afterTel = handleTelInput(tel);
    if (afterTel === false) {
      return false;
    }
    setValue('phone', afterTel);
  };

  // 회원가입
  const signup = async (data: FormValues) => {
    if (!checkIdentify) {
      api.error({ message: '인증번호를 입력해주세요.', placement: 'top' });

      return;
    }
    if (!mailAuth) {
      api.error({ message: '메일 인증을 해주세요.', placement: 'top' });
      return;
    }
    if (checkPassword()) {
      if (terms.length === checklist.length) {
        //백엔드 sign-up 과 통신하여 가입처리
        const submitData = {
          ...data,
          type,
          companyNumber: companyNumber.replace(/[^\w\s]+/g, ''),
          phone: data.phone.replace(/[^\w\s]+/g, ''),
        };

        try {
          messageApi.open({
            key: 'apiMsg',
            type: 'loading',
            content: '등록중...',
            duration: 0,
          });

          const baseUrl = await getApiUrl();
          const res = await axios.post(`${baseUrl}sign-up`, submitData);

          messageApi.success({
            key: 'apiMsg',
            content: '프루프 시스템 회원가입이 완료되었습니다.',
            duration: 1,
            onClose: () => {
              window.location.href = '/home';
            },
          });

        } catch (e) {
          console.log('Submit error!!', e);
          // api.info({ message: '다시 시도해주세요.', placement: 'top', duration: 1 });

          messageApi.open({
            key: 'apiMsg',
            type: 'error',
            content: '등록에 실패하였습니다. 지속되면 문의해주세요.',
          });

          return false;
        }
      } else {
        api.info({ message: '이용약관 확인 후 동의해주세요.', placement: 'top' });
      }
    }
  };

  const validateEmail = (value: string) => {
    if (/^\S+@\S+\.\S+$/.test(value)) return true;
    return false;
  };

  const sendEmail = async (value: string) => {
    if (value === '') {
      setError('email', { message: '이메일을 입력해주세요.' });
      return;
    }
    if (validateEmail(value)) {
      //백엔드 ( /send-mail-auth ) 에 호출
      try {
        const baseUrl = await getApiUrl();
        await axios.post(`${baseUrl}send-mail-auth`, { email: value });
        clearErrors('email');
        api.success({ message: '이메일로 인증번호가 발송되었습니다.', placement: 'top' });
        setIsIdentify(true);
        setFocus('identify');
      } catch (e) {
        console.log('메일발송 에러', e);
        api.info({ message: '메일주소를 확인 후 다시 시도해주세요.', placement: 'top' });
        setError('email', { message: '중복이거나 올바르지 않은 메일형식입니다.' });
      }
    } else {
      setError('email', { message: '이메일 형식이 맞지 않습니다.' });
    }
  };

  const handleIdentify = async (auth_number: string) => {
    try {
      const baseUrl = await getApiUrl();
      await axios.post(`${baseUrl}check-mail-auth`, { auth_number });
      clearErrors('identify');
      api.success({ message: '메일 인증이 완료되었습니다.', placement: 'top' });
      setCheckIdentify(true);
      setIsIdentify(false);
      setMailAuth(true);
    } catch (e) {
      console.log('메일인증 에러', e);
      api.error({ message: '메일 인증에 실패하였습니다.', placement: 'top' });
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCompanyNumberCheck(false);
    const rawValue = e.target.value.replace(/[^0-9]/g, '');
    const formattedValue = formatCompanyNumber(rawValue);
    setCompanyNumber(formattedValue);
  };

  const formatCompanyNumber = (rawValue: string): string => {
    const digits = rawValue.slice(0, 10);
    const formatted = digits.replace(/(\d{3})(\d{2})(\d{5})/, '$1-$2-$3');
    setValue('companyId', '');
    return formatted;
  };

  const searchCompanyNumber = async () => {
    if (hasCompanyNumber.hasNumber) {
      const validate = /^\d{3}-\d{2}-\d{5}$/.test(companyNumber);

      if (validate === false) {
        api.warning({ message: '정확한 사업자 등록번호를 입력해주세요.', placement: 'top' });
        return false;
      }
      setHasCompanyNumber(prev => ({ ...prev, isValid: validate }));
      //백엔드 check-company-auth 에서 이미 존재하는 사업자번호인지 확인하여 등록된 회사명 가져오기
      try {
        const baseUrl = await getApiUrl();
        const res = await axios.get(`${baseUrl}check-company-auth?companyNumber=${companyNumber.replace(/[^\w\s]+/g, '')}`);
        const {
          data: { msg, companyName, companyId },
        } = res;

        if (companyName && companyId) {
          setValue('companyName', companyName);
          setValue('companyId', companyId);
          setCompanyNumberCheck(true);
          api.success({
            message: (
              <p>
                조회된 회사명은 <br />
                {companyName} 입니다.
              </p>
            ),
            placement: 'top',
            duration: 1,
          });
        } else {
          api.info({
            message: (
              <p>
                등록된 회사가 없습니다. <br />
                회사명을 입력해주세요.
              </p>
            ),
            placement: 'top',
            duration: 1,
            onClose: () => {
              setFocus('companyName');
            },
          });
        }
      } catch (e) {
        console.error('사사업자번호 조회 에러', e);
      }
    }
  };

  const hasValidPassword = (value: string) => {
    const hasMinimumLength = value.length >= 8;
    const hasEnglishLetter = /[a-zA-Z]/.test(value);
    const hasNumber = /\d/.test(value);
    if (!(hasMinimumLength && hasEnglishLetter && hasNumber)) return '비밀번호는 영문+숫자 8자리 이상으로 해주세요';
    return true;
  };

  const checkPassword = () => {
    if (watch('password') !== '' && watch('passwordConfirm') !== '') {
      if (watch('password') === watch('passwordConfirm')) return true;
      if (watch('password') !== watch('passwordConfirm')) return '입력한 비밀번호와 일치하지 않습니다. 다시 확인해주세요.';
    }
  };

  return (
    <>
      <SignupNav />
      <section className={styles.container}>
        {notificationContextHolder}
        {contextHolder}
        <div>
          <h2>프루프 시스템 회원가입</h2>
          <h4>기본정보</h4>
          <form className={styles.form} onSubmit={handleSubmit(signup)}>
            {/*<div className={styles.row}>*/}
            {/*  <label>회원 구분 선택</label>*/}
            {/*  <Controller*/}
            {/*    control={control}*/}
            {/*    name='type'*/}
            {/*    render={({ field }) => (*/}
            {/*      <Radio.Group {...field} value={type} onChange={e => setType(e.target.value)}>*/}
            {/*        <Radio value='참가사'>참가사</Radio>*/}
            {/*        <Radio value='주최사'>주최사</Radio>*/}
            {/*      </Radio.Group>*/}
            {/*    )}*/}
            {/*  />*/}
            {/*</div>*/}
            <div className={styles.row}>
              <label>이메일 아이디</label>
              <div className={styles.col}>
                <div className={styles.inRow}>
                  <input
                    type='text'
                    placeholder='아이디로 사용할 이메일을 입력해주세요.'
                    {...register('email', {
                      required: true,
                    })}
                    disabled={mailAuth}
                    onKeyDown={handleKeyDown}
                  />
                  <Button
                    disabled={mailAuth}
                    htmlType='button'
                    style={{ marginLeft: '8px' }}
                    onClick={() => sendEmail(watch('email') ?? '')}
                    tabIndex={-1}
                  >
                    {!isIdentify ? '인증번호 발송' : '다시 보내기'}
                  </Button>
                </div>
                {errors.email && <p className={styles.errorMessage}>{errors.email?.message as string}</p>}
              </div>
            </div>
            {isIdentify && (
              <div className={styles.row}>
                <label>인증번호 입력</label>
                <div className={styles.col}>
                  <div className={styles.inRow}>
                    <input
                      disabled={mailAuth}
                      type='text'
                      placeholder='이메일로 발송된 인증번호를 입력해주세요.'
                      {...register('identify')}
                      onKeyDown={handleKeyDown}
                    />
                    <Button
                      disabled={mailAuth}
                      htmlType='button'
                      style={{ marginLeft: '8px' }}
                      onClick={() => handleIdentify(watch('identify'))}
                      tabIndex={-1}
                    >
                      인증하기
                    </Button>
                  </div>
                </div>
              </div>
            )}
            <div className={styles.row}>
              <label className={styles.largeRow}>사업자등록번호</label>
              <div className={styles.col}>
                <div className={styles.inRow}>
                  <input
                    type='text'
                    placeholder='사업자 등록번호를 입력 후 조회하기 버튼을 클릭해주세요. (000-00-00000)'
                    disabled={!hasCompanyNumber.hasNumber}
                    value={companyNumber}
                    maxLength={12}
                    onChange={handleInputChange}
                    onKeyDown={handleKeyDown}
                  />

                  <input
                    disabled={!hasCompanyNumber.hasNumber}
                    type={'hidden'}
                    value={''}
                    placeholder='사업자 번호를 조회해 주세요.'
                    {...register('companyId')}
                  />
                  <Button
                    htmlType='button'
                    style={{ marginLeft: '8px' }}
                    disabled={!hasCompanyNumber.hasNumber}
                    onClick={() => searchCompanyNumber()}
                    tabIndex={-1}
                  >
                    조회하기
                  </Button>
                </div>
                {/*<div className={styles.col}>*/}
                {/*  <Checkbox*/}
                {/*    style={{ marginTop: '4px' }}*/}
                {/*    onChange={e => {*/}
                {/*      setHasCompanyNumber({ ...hasCompanyNumber, hasNumber: !e.target.checked });*/}
                {/*      if (e.target.checked) {*/}
                {/*        setCompanyNumber('');*/}
                {/*        setValue('companyId', '');*/}
                {/*        setCompanyNumberCheck(false);*/}
                {/*      }*/}
                {/*    }}*/}
                {/*  >*/}
                {/*    사업자등록번호 없음*/}
                {/*  </Checkbox>*/}
                {/*</div>*/}
              </div>
            </div>
            <div className={styles.row}>
              <label>회사명</label>
              <div className={styles.col}>
                <input
                  type='text'
                  disabled={companyNumberCheck}
                  placeholder='회사 이름을 입력해주세요.'
                  {...register('companyName', { required: '회사명을 입력해주세요' })}
                />
                {!companyNumberCheck && errors.companyName && errors.companyName.message && (
                  <p className={styles.errorMessage}>{errors.companyName?.message as string}</p>
                )}
              </div>
            </div>
            <div className={styles.row}>
              <label>담당자명</label>
              <div className={styles.col}>
                <input
                  type='text'
                  placeholder='담당자 이름을 입력해주세요.'
                  {...register('name', {
                    required: '담당자명을 입력해주세요.',
                  })}
                />
                {errors.name && errors.name.message &&
                  <p className={styles.errorMessage}>{errors.name?.message as string}</p>}
              </div>
            </div>
            <div className={styles.row}>
              <label>담당자 연락처</label>
              <div className={styles.col}>
                <input
                  type='text'
                  placeholder='담당자 연락처를 입력해주세요. (000-0000-0000)'
                  {...register('phone', {
                    pattern: /^(02|0[3-9]\d{1})-\d{3,4}-\d{4}$|^010-\d{3,4}-\d{4}$|^\+82-10-\d{4}-\d{4}$/,
                    required: '담당자 연락처를 입력해주세요.',
                  })}
                  onChange={e => {
                    handleTelChange(e.target.value);
                  }}
                  maxLength={13}
                />
                {errors.phone && <p className={styles.errorMessage}>하이픈(-)을 포함하여 담당자 연락처를 입력해주세요. (000-0000-0000)</p>}
              </div>
            </div>
            <div className={styles.row}>
              <label>비밀번호</label>
              <div className={styles.col}>
                <input
                  type='password'
                  placeholder='영문과 숫자를 조합하여 8자리 이상 입력해주세요.'
                  {...register('password', {
                    required: '비밀번호를 입력해주세요',
                    validate: hasValidPassword,
                  })}
                />
                {errors.password && <p className={styles.errorMessage}>{errors.password?.message as string}</p>}
              </div>
            </div>
            <div className={styles.row}>
              <label>비밀번호 확인</label>
              <div className={styles.col}>
                <input
                  type='password'
                  placeholder='비밀번호를 한번 더 입력해주세요.'
                  {...register('passwordConfirm', {
                    required: '비밀번호를 한번 더 입력해주세요.',
                    validate: checkPassword,
                  })}
                />
                {errors.passwordConfirm &&
                  <p className={styles.errorMessage}>{errors.passwordConfirm?.message as string}</p>}
              </div>
            </div>
            <TermsPolicy terms={terms} checklist={checklist} setChecklist={setChecklist} />
            <div className={styles.buttonWrapper}>
              <Button htmlType='submit'>회원가입</Button>
            </div>
          </form>
        </div>
      </section>
    </>
  );
};

export default Signup;
