import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { getSavedAuth, KEY_SAVED_AUTH } from '../utils/storage';
import { AuthResponse } from '../api/responseTypes';
import { refreshToken } from '../api/authClient';

export const TokenRefreshService: React.FC = () => {
  const [auth, setAuth] = useState<AuthResponse>();
  const tokenTimer = useRef<NodeJS.Timer>();

  useEffect(() => {
    const res = getSavedAuth();
    if (res) {
      setAuth(res);
    }
  }, []);

  useEffect(() => {
    window.addEventListener(KEY_SAVED_AUTH, () => {
      const res = getSavedAuth();
      if (res) {
        setAuth(res);
      } else {
        setAuth(undefined);
        tokenTimer.current = undefined;
      }
    });
  }, []);

  useEffect(() => {
    if (auth?.expired_at && !tokenTimer.current) {
      const { expired, remaining } = tokenExpired();
      if (expired) {
        doRefresh();
      } else {
        tokenTimer.current = setTimeout(() => {
          doRefresh();
        }, remaining);
      }
    }
  }, [auth]);

  const doRefresh = async () => {
    await refreshToken();
    if (tokenTimer.current) {
      clearTimeout(tokenTimer.current);
      tokenTimer.current = undefined;
    }
  };

  return null;
};

export const tokenExpired = (): { expired: boolean; remaining: number } => {
  const res = getSavedAuth();
  let timeout = 0;
  if (res) {
    if (!res?.expired_at) return { expired: true, remaining: 0 };
    // const expires = moment(auth.expired_at).subtract(15, 'minutes');
    const expires = moment(res.expired_at);
    timeout = expires.diff(moment());
    if (isNaN(timeout)) timeout = 0;
  }

  return { expired: timeout <= 0, remaining: timeout };
};
