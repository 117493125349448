import React, { useState } from 'react';
import { message } from 'antd';
import { useNavigate } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';

import styles from './login.module.css';
import FormAlert from '../../common/components/FormAlert';
import { AuthResponse } from '../../common/api/responseTypes';
import { setSavedAuth } from '../../common/utils/storage';
import { getApiUrl } from '../../common/providers & services/AxiosService';
import axios from 'axios';

type ButtonAction = 'join' | 'passwordReset';

type Inputs = {
  userId: string;
  password: string;
};

export const Login: React.FC = () => {
  const [account, setAccount] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(false);
  const [layerPopupOpen, setLayerPopupOpen] = useState(false);

  const {
    register,
    handleSubmit,
    setFocus,
    formState: { isSubmitting, isDirty, errors },
  } = useForm<Inputs>();

  const navigate = useNavigate();

  const [messageApi, contextHolder] = message.useMessage();

  const onSubmit: SubmitHandler<{ [key: string]: string }> = async data => {
    // console.log(data); // api post Login

    const { userId, password } = data;

    try {
      const baseUrl = getApiUrl();

      const response = await axios.post(`${baseUrl}sign-in/admin`, {
        account: userId,
        password,
      });

      const tokenData = response.data.data.token as AuthResponse;
      setSavedAuth({
        ...tokenData,
        isLogin: true,
      });

      navigate('/');
    } catch (e) {
      messageApi.open({
        type: 'error',
        content: '로그인에 실패했습니다. 입력하신 정보를 확인해주세요.',
        duration: 2,
      });
      console.error(e);
    }

    // try {
    //   await postSignIn(account, password);
    // } catch (e) {
    //   console.error(e);
    //   setError(true);
    // }
  };

  const validEmail = (value: string) => {
    if (/^\S+@\S+\.\S+$/.test(value)) return true;
    return '이메일 형식이 맞지 않습니다.';
  };

  const validPassword = (value: string) => {
    const hasMinimumLength = value.length >= 8;
    const hasEnglishLetter = /[a-zA-Z]/.test(value);
    const hasNumber = /\d/.test(value);
    if (!(hasMinimumLength && hasEnglishLetter && hasNumber)) {
      return '비밀번호는 영문+숫자 8자리 이상으로 해주세요';
    }
    return true;
  };
  return (
    <article className={`${styles.wrap}`}>
      <div className={styles.login_wrap}>
        <div className={styles.login_tit}>
          <strong>프루프 시스템 로그인</strong>
          <p>로그인이 필요합니다</p>
        </div>
        {contextHolder}

        <form className={styles.formBox} onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.inputBox}>
            <label htmlFor={'userId'}>아이디</label>
            <input
              id={'userId'}
              placeholder={'ID를 입력해주세요'}
              aria-invalid={!isDirty ? undefined : errors.userId ? 'true' : 'false'}
              {...register('userId', {
                required: '아이디는 필수 입력입니다.',
                // validate: validEmail,
                validate: (value) => {
                  if (value.length >= 4) return true;
                  return '4자리 이상 입력해주세요';
                },
              })}
            />
            {errors.userId?.message && <FormAlert msg={errors.userId.message} />}
          </div>

          <div className={styles.inputBox}>
            <label htmlFor={'password'}>비밀번호</label>
            <input
              type={'password'}
              id={'password'}
              autoComplete={'on'}
              placeholder={'비밀번호를 입력하세요'}
              aria-invalid={!isDirty ? undefined : errors.password ? 'true' : 'false'}
              {...register('password', {
                required: '비밀번호는 필수 입력입니다.',
                validate: validPassword,
              })}
            />

            {errors.password?.message && <FormAlert msg={errors.password.message} />}
          </div>

          <button type={'submit'} disabled={isSubmitting}>
            로그인
          </button>
        </form>
      </div>
    </article>
  );
};
